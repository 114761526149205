import {
   CAMPAIGN_DETAILS, CAMPAIGN_DETAILS_ERROR, CAMPAIGN_DETAILS_SUCCESS
} from "./constant";

const campaignDetailsReducer = (state = {}, action) => {
   switch (action.type) {
      case CAMPAIGN_DETAILS:
         return { ...state, loading: true, error: '' };
      case CAMPAIGN_DETAILS_ERROR:
         return { ...state, type: CAMPAIGN_DETAILS_ERROR, loading: false, error: action.payload };
      case CAMPAIGN_DETAILS_SUCCESS:
         return { ...state, type: CAMPAIGN_DETAILS_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default campaignDetailsReducer;