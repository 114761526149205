import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getFinancingCongratulationsURL } from "routes/AppUrls";
import { LocaleContext } from 'routes/Router';

import H5Text from "components/Text/H5Text";
import ButtonInput from "components/Input/ButtonInput";
import Text from "components/Text";

const WrapperDiv = styled.div`
	
`;

const WrapperBox = styled(Box)`
	border-top: 1px solid #B8B8B8;
    padding: 10px 0px;
    margin: 15px 0px;
`;

function OrderSummary({ handleCurStep }) {
	const intl = useIntl();
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage  id={'Your Order Summary!'} />
					</H5Text>

					<div className="content" style={{ padding: "15px" }}>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12}>
									<Grid container>
										<Grid item xs={4}>
											<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
												Initial Information
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												Employer Type
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												Private Sector Type
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												Occupation
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												Accountant
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												Any Monthly Payment
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												0.00 SAR
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												Total Credit card/s Limit
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												0.00 SAR
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												Net Income
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												0.00 SAR
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												Any Mortage
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												No
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>

						<WrapperBox>
							<Grid container>
								<Grid item xs={12}>
									<Grid container>
										<Grid item xs={4}>
											<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
												Driving License
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												License Type
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												Private
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												Driving License Expiry Date
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												12/11/2030
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												License Held for (years)
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												4
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>

						<WrapperBox>
							<Grid container>
								<Grid item xs={12}>
									<Grid container>
										<Grid item xs={4}>
											<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
												Insurance Details
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												NAME**
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												Alrajhi Takaful
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												Amount
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												4,500.00 SAR
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												Insurance Price VAT
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												344.00 SAR
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												Deductible
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												1,000.00 SAR
											</Text>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={4}>
											
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="dark" fontWeight="600">
												NCD Discount
											</Text>
										</Grid>
										<Grid item xs={4}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
												2,000.00 SAR
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
					</div>
				</Grid>
			</Grid>

			<Grid container style={{marginTop: "30px"}}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput 
									title="Back"
									variant="text" 
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(4)}
								/>
								<ButtonInput 
									title="Continue"
									variant="contained" 
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									onClick={() => navigate(getFinancingCongratulationsURL(locale))}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	)
}


export default OrderSummary;