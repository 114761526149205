import { 
	CLIENT_TOKEN_SET, 
	CLIENT_TOKEN_UNSET 
} from './constant';

export function setClient (token) {
	return {
		type: CLIENT_TOKEN_SET,
    	token,
  	}
}

export function unsetClient () {
  	return {
    	type: CLIENT_TOKEN_UNSET,
  	}
}