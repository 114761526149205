import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import styled from 'styled-components'
import Box from '@mui/material/Box';
import MuiCards from 'components/MuiCards';
import CardContent from '@mui/material/CardContent';
import AnimatedImage from 'components/AnimatedImage';
import IconWithText from 'components/IconWithText';
import ColorBox from 'components/ColorBox';
import BrandImage from "assets/images/Application/Monogram.png";
import Text from "components/Text";
import SpanText from "components/Text/SpanText";
import ButtonInput from "components/Input/ButtonInput";
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import UnionImg from 'assets/images/icon/Union.png';
import PercentImg from 'assets/images/icon/percent.png';
import { PriceFomate } from 'utils/general';

import { getFinanceCalculatorURL, getVehicleComparisonURL } from "routes/AppUrls"

const IconGroup = styled.div`
	position: ${(props) => props.listtype ? "" : "absolute"}; 
	right: 0;
	display: flex;
	justify-content: end;
	padding: 10px 20px 0 0;
	border-radius: 12px;
	max-height:25%;
	z-index: 9;
`;

const IconStyle = styled.div`
	background:${(props) => props?.background};
	border-radius:50%;
	min-width:40px;
	min-height:40px;
	max-width:40px;
	max-height:40px; style={{margin:'0'}}
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:${(props) => props?.marginleft};
`;

function CampaignCarsList({ viewFlag, carData, index, locale }) {
	const navigate = useNavigate();

	const colorPosition = viewFlag ? "end" : "center";
	const GridRightPadding = viewFlag ? "15px" : "25px 15px";

	return (
		<MuiCards borderRadius="12" padding="0">
			<Grid container>
				<Grid item xs={viewFlag ? 12 : 4}>
					<Grid container>
						<Grid item xs={12} sx={{ position: "relative" }}>
							<IconGroup>
								<IconStyle background={'#FEF7FF'} >
									<img alt="" src={UnionImg} />
								</IconStyle>
								<IconStyle background={'var(--primary)'} marginleft={'8px'}>
									<img alt="" src={PercentImg} />
								</IconStyle>
							</IconGroup>
							<Box style={{ transform: locale === 'ar' ? 'scaleX(-1)' : '' }}>
								<AnimatedImage src={carData?.ModelImage} minHeight={viewFlag ? "100%" : "265px"} />
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={viewFlag ? 12 : 8}>
					<Grid container sx={{ backgroundColor: "#FEF7FF", padding: GridRightPadding,height:'100%' }}>
						<Grid item xs={12} >
							<IconWithText
								text={carData?.BrandDescription + " " + carData?.ProductTypeDescription + " " + carData?.YearModel}
								secondText={carData?.ModelDescription}
								textSize={viewFlag ? 20 : 32}
								textLineHeight={viewFlag ? 30 : 48}
								textColor="lightDark"
								secondTextSize={viewFlag ? 16 : 24}
								secondLineHeight={viewFlag ? 22 : 62}
								secondTextColor="lightDark"
								BrandIcon={carData.BrandIcon ? carData.BrandIcon : BrandImage}
								variant={'rounded'}
								BrandSize={viewFlag ? 40 : 52}
								bgColor="secondaryPink"
							/>
						</Grid>

						<Grid item xs={viewFlag ? 12 : 8}>
							<Grid container>
								{!viewFlag &&
									<Grid item xs={1}>
									</Grid>
								}
								<Grid item xs={viewFlag ? 6 : 5} sx={{ display: "inline-grid", justifyContent: "start" }}>
									<Text size={viewFlag ? 16 : 20} color="lightDark" lineHeight={viewFlag ? 16 : 32}>
										<FormattedMessage id="availableAt" />
									</Text>
									<Text size={viewFlag ? 32 : 42} color="lightDark" lineHeight={viewFlag ? 32 : 32} fontWeight="800" fontFamily="KarbonSemiBold">
										{PriceFomate(carData?.PriceShow)}
										<SpanText style={{ marginLeft: "5px" }} size={viewFlag ? 20 : 40} color="lightDark" lineHeight={viewFlag ? 20 : 40}>SAR</SpanText>
									</Text>
								</Grid>
								<Grid item xs={6} sx={{ display: "inline-grid", justifyContent: colorPosition }}>
									<Text size={viewFlag ? 16 : 20} color="lightDark" lineHeight={viewFlag ? 16 : 32}>
										<FormattedMessage id="colors" />
									</Text>

									<div style={{ display: "flex" }}>
										{carData?.AvailColorsIn?.length > 0 && carData.AvailColorsIn.map((data, index) => {
											return (
												<>
													{index < 2 &&
														<ColorBox style={{ border: "1px solid" }} width={viewFlag ? 28 : 36} color={'#' + data.SupplierColourCode} />
													}
												</>
											)
										})}
										<Text size={viewFlag ? 16 : 20} lineHeight="16" color="black">
											+4
										</Text>
									</div>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={viewFlag ? 12 : 12} xl={viewFlag ? 12 : 4} sx={{ marginTop: "20px" }}>
							<Box component="form" sx={{ display: "flex", justifyContent: locale === 'en' ? 'end' : 'end' }}>
								<Stack spacing={2} direction="row">
									<ButtonInput
										title={<FormattedMessage id="CompareCar" />}
										variant="contained"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										size={viewFlag ? "14" : "18"}
										padding={viewFlag ? "10px 16px" : "14px 32px"}
										minWidth={viewFlag ? "140" : "190"}
										height={viewFlag ? "40" : "50"}
										borderColor="lightPink"
										startIcon={<AddIcon sx={{ marginLeft: '8px', fontSize: "12px", color: "#3F1956" }} />}
										marginLeft={locale === 'en' ? "0" : "0"}
										onClick={() => navigate(getVehicleComparisonURL(locale))}
									/>
									<ButtonInput
										title={<FormattedMessage id="applyNow" />}
										variant="contained"
										bgColor="yellow"
										textColor="darkPrimary"
										borderRadius="100"
										TextTransform="capitalize"
										size={viewFlag ? "14" : "18"}
										padding={viewFlag ? "10px 16px" : "14px 32px"}
										minWidth={viewFlag ? "140" : "190"}
										height={viewFlag ? "40" : "50"}
										marginLeft={locale === 'en' ? "16" : "0"}
										marginRight={locale === 'en' ? "0" : "16"}
										onClick={() => navigate(getFinanceCalculatorURL(locale), { state: { id: carData.ModelId } })}
									/>
								</Stack>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</MuiCards>
	)
}

export default CampaignCarsList;