import {
    GET_VEHICAL_LIST,
    GET_VEHICAL_CATEGORY,
    GET_VEHICAL_CATEGORY_SUCCESS,
    SCREEN_API_ERROR,
    SCREEN_API_SUCCESS,
    GET_VEHICAL_LIST_DETAIL,
    GET_CAMPAIGNS_LIST,
} from "./constant";


const screenReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_VEHICAL_LIST:
            return { ...state, loading: true, error: '' };
        case GET_VEHICAL_CATEGORY:
            return { ...state, loading: true, error: '' };
        // case GET_VEHICAL_LIST_DETAIL:
        //     return { ...state, loading: true, error: '' };
        case GET_CAMPAIGNS_LIST:
            return { ...state, loading: true, error: '' };
        case SCREEN_API_ERROR:
            return { ...state, type: SCREEN_API_ERROR, loading: false, error: action.payload };
        case SCREEN_API_SUCCESS:
            return { ...state, type: SCREEN_API_SUCCESS, loading: false, error: '', payload: action.payload };
        case GET_VEHICAL_CATEGORY_SUCCESS:
            return { ...state, type: GET_VEHICAL_CATEGORY_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default screenReducer;