import React, { useState } from 'react';
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';
import IconWithText from "components/IconWithText";
import Text from "components/Text";
import ButtonInput from 'components/Input/ButtonInput';
import ArrowLeftIcon from "assets/images/Application/ApplicationDeatils/ArrowLeft.png";
import { FormattedMessage } from 'react-intl';

const WrapperDiv = styled.div`
	background: ${(props) => (props.theme[props.bgColor])};
	border-radius: 15px;
	margin-top: 0px;
	padding: 20px;
`;

const arr = [{ id: 1, name: "All Cars", is_select: false }, { id: 2, name: "20x  Yaris", is_select: false }, { id: 3, name: "10x  KIA Sonet", is_select: false },
{ id: 4, name: "30x  KIA Sonet X", is_select: false }, { id: 5, name: "20x  Mercedes E Class", is_select: false }, { id: 6, name: "20x  Yaris", is_select: false }];

function PaymentInstruction({ containerProps, ...props }) {

    return (
        <>
            <Grid container marginTop={"30px"}>
                <Grid item xs={12}>
                    <Text marginTop="0" color="purple" size="24" fontFamily="KarbonBold" fontWeight='800' lineHeight={'36'}>
                       
                        <FormattedMessage id="PaymentInstructions"/>
                    </Text>
                </Grid>
            </Grid>

            <WrapperDiv {...props}>
                <Grid container>
                    <Grid item xs={12}>
                        <Text marginTop="0" color="lightPrimary" size="24" fontFamily="KarbonBold" fontWeight='800' lineHeight={'36'}>
                            Lorem Ipusm
                        </Text>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Text marginTop="0" color="lightMediumBlack" size="20" fontFamily="KarbonMedium" fontWeight='600' lineHeight={'30'}>
                                Lorem ipsum dolor sit amet consectetur. Tellus iaculis neque sed ullamcorper ultrices elementum ultrices vulputate laoreet. Mauris mauris aliquam vivamus leo cras pellentesque ut. Accumsan id odio volutpat rutrum arcu interdum vel. Sed massa iaculis enim mi velit vehicula. Id urna sit lacus egestas orci. Fusce dui lorem feugiat luctus. Nullam accumsan facilisis at eu massa facilisi interdum pellentesque. Cum sed accumsan risus faucibus gravida. Scelerisque risus sed sed vel diam faucibus. Eu lorem viverra vitae et. Pellentesque eu eget sodales purus. Vitae ac nunc vulputate at nam porttitor facilisi
                            </Text>
                        </div>
                    </Grid>
                </Grid>
            </WrapperDiv>
        </>
    );
}

export default PaymentInstruction;