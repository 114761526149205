import {
    FINANCING_DROPDOWN
} from "./constant";

export const financingDropDownCallAPI = (type, data, external = {}) => {
    switch (type) {
        case FINANCING_DROPDOWN:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};