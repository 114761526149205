import React from "react";
import { FormattedMessage } from "react-intl";

import { PhoneNumberValidation } from "utils/general"

const CheckValidation = value => {

    const formError = {
        submit: true,
        contactNumber: {
            error: false,
            errorMessage: <FormattedMessage id="register.invalidPhoneNumber" />
        }
    };

    const emptyKeys = [
        "contactNumber"
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
        if (value[emptyKeys[i]] && emptyKeys[i] === "contactNumber" && !PhoneNumberValidation(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }

    return formError;
}

export default CheckValidation;