import { takeLatest, put } from "redux-saga/effects";
import {
	AUTH_LOGOUT,
	LOGOUT_API_SUCCESS,
	LOGOUT_API_ERROR,
} from "./constant";
import url from "utils/services/urls.json";
import { postMethodWithToken } from "utils/services/apis";
import { unsetClient } from 'store/token/action';
import { ClearTokenRedirectLogin } from "utils/general";

function* userLogout(action) {
	try {
		const response = yield postMethodWithToken(url.userLogout, action.data);
		if (response.status === 200 || response.status === 201) {
			yield put({ type: LOGOUT_API_SUCCESS, payload: response });
			yield put(unsetClient());
			ClearTokenRedirectLogin(action?.external?.navigate);
			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: LOGOUT_API_ERROR, payload: response });
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}

	} catch (error) {
		yield put({ type: LOGOUT_API_ERROR, payload: error });
	}
};

function* logoutSaga() {
	yield takeLatest(AUTH_LOGOUT, userLogout);
};

export default logoutSaga;
