import Login from 'screens/Pages/Login';
import Register from 'screens/Pages/Register';
import Error404 from 'screens/Pages/404/404';
import ForgotPassword from 'screens/Pages/ForgotPassword';
import ResetPassword from 'screens/Pages/ResetPassword/ResetPassword';

import Dashboard from 'screens/Dashboard';
import Contracts from 'screens/Contracts';
import ContractDetails from 'screens/Contracts/ContractsDetails';
import Application from 'screens/Applications';
import ApplicationDetails from 'screens/Applications/ApplicationDetails';

import OnlineFinancing from 'screens/Pages/OnlineFinancing';
import OnlineFinancingPersoanlInfoData from 'screens/Pages/OnlineFinancing/PersoanlInfo';
import OnlineFinancingInitialData from 'screens/Pages/OnlineFinancing/Financing';
import OnlineFinancingCongratulations from 'screens/Pages/OnlineFinancing/Congratulations';
import OnlineFinancingConsentForm from 'screens/Pages/OnlineFinancing/ConsentForm';
import OnlineFinancingVerification from 'screens/Pages/OnlineFinancing/Verification';
import OnlineFinancingPaymentDetails from 'screens/Pages/OnlineFinancing/PaymentDetails';
import OnlineFinancingWaitingPayment from 'screens/Pages/OnlineFinancing/WaitingPayment';
import OnlineFinancingApplicationApproved from 'screens/Pages/OnlineFinancing/ApplicationApproved';
import OnlineFinancingVehicleDelivered from 'screens/Pages/OnlineFinancing/VehicleDelivered';

import Account from 'screens/Account';
import Support from 'screens/Support';

import FindVehicle from 'screens/Pages/FindVehicle';
import FinanceCalculator from 'screens/Pages/FinanceCalculator/FinanceCalculator';
import VehicleComparison from 'screens/Pages/VehicleComparison';

import CashFinancingInitialData from 'screens/Pages/CashFinancing/Financing';
import CashFinancingCongratulations from 'screens/Pages/CashFinancing/Congratulations';
import CashFinancingConsentForm from 'screens/Pages/CashFinancing/ConsentForm';
import CashFinancingVerification from 'screens/Pages/CashFinancing/Verification';
import CashFinancingWaitingPayment from 'screens/Pages/CashFinancing/WaitingPayment';
import CashFinancingApplicationApproved from 'screens/Pages/CashFinancing/ApplicationApproved';
import CashFinancingVehicleDelivered from 'screens/Pages/CashFinancing/VehicleDelivered';

import CashFinancing from 'screens/Pages/CashFinancing';
import ApplyForCashFinance from 'screens/Pages/CashFinancing/ApplyForCashFinance';
import CashFinanceApplicationRejected from 'screens/Pages/CashFinancing/ApplicationRejected';

import {
    getLandingURL,
    getSignInURL,
    getSignUpURL,
    getForgotPasswordURL,
    getResetPasswordURL,
    get404URL,
    getDashboardURL,
    getContractsURL,
    getContractDetailsURL,
    getApplicationsURL,
    getApplicationDetailsURL,
    getCampaignDetailsURL,
    getAccountURL,
    getAccountWithIdURL,
    getSupportURL,
    getFinancingURL,
    getFinancingPersonalInfoURL,
    getFinancingInitialDataURL,
    getFinancingCongratulationsURL,
    getFinancingConsentFormURL,
    getFinancingVerificationURL,
    getFinancingPaymentDetailsURL,
    getFinancingWaitingPaymentURL,
    getFinancingApplicationApprovedURL,
    getFinancingVehicleDeliveredURL,
    getFindVehicleURL,
    getFinanceCalculatorURL,
    getVehicleComparisonURL,
    getCashFinancingURL,
    getCashFinancingDetailsURL,
    getCashFinancingConsentFormURL,
    getCashFinancingInitialDataURL,
    getCashFinancingCongratulationsURL,
    getCashFinanceApplicationRejected,
    getCashFinancingVerificationURL,
    getCashFinancingWaitingPaymentURL,
    getCashFinancingApplicationApprovedURL,
    getSurveyIneligible,
    getRequestIneligible,
    getVerificationCall,
    getAccountFetch,
    getUploadQuotation,
    getQuotationCongratulation,
    getApplicationSubmitted,
    getKafalaApproval,
    getKafalaUploadForm
} from './AppUrls';
import CampaignDetails from 'screens/Pages/CampaignDetails';
import SurveyIneligible from 'features/CashFinancing/SurveyIneligible';
import RequestIneligible from 'features/CashFinancing/RequestIneligible';
import VerificationCall from 'features/CashFinancing/VerificationCall';
import AmountFetch from 'features/CashFinancing/AmountFetch';
import QuotationCongratulation from 'screens/Pages/CashFinancing/QuotationCongratulation';
import UploadQuotation from 'features/CashFinancing/UploadQuotation';
import ApplicationSubmitted from 'screens/Pages/CashFinancing/ApplicationSubmitted';
import KafalaApproval from 'screens/Pages/CashFinancing/KafalaApproval';
import KafalaUploadInformation from 'features/CashFinancing/KafalaUploadInformation';

const publicRoutes = [
    {
        path: locale => getLandingURL(locale),
        element: <Login />,
        isNoSidebar: true

    },
    {
        path: locale => getSignInURL(locale),
        element: <Login />,
        isNoSidebar: true,
    },
    {
        path: locale => getSignUpURL(locale),
        element: <Register />,
        isNoSidebar: true,
    },
    {
        path: locale => get404URL(locale),
        element: <Error404 />,
        isNoSidebar: true,
    },
    {
        path: locale => getForgotPasswordURL(locale),
        element: <ForgotPassword />,
        isNoSidebar: true,
    },
    {
        path: locale => getResetPasswordURL(locale),
        element: <ResetPassword />,
        isNoSidebar: true,
    }
];

const accountsRoutes = [
    {
        path: locale => getAccountWithIdURL(locale),
        element: <Account />,
        isPrivate: true,
        isSiderBarIcon: true
    }
];

const privateRoutes = [
    {
        path: locale => getDashboardURL(locale),
        element: <Dashboard />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getContractsURL(locale),
        element: <Contracts />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getContractDetailsURL(locale),
        element: <ContractDetails />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getApplicationsURL(locale),
        element: <Application />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getApplicationDetailsURL(locale),
        element: <ApplicationDetails />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getAccountURL(locale),
        element: <Account />,
        isPrivate: true,
        isSiderBarIcon: true,
        children: accountsRoutes
    },
    {
        path: locale => getSupportURL(locale),
        element: <Support />,
        isPrivate: true,
        isSiderBarIcon: true
    },
    {
        path: locale => getFinancingURL(locale),
        element: <OnlineFinancing />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getFinancingPersonalInfoURL(locale),
        element: <OnlineFinancingPersoanlInfoData />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getFinancingInitialDataURL(locale),
        element: <OnlineFinancingInitialData />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getFinancingCongratulationsURL(locale),
        element: <OnlineFinancingCongratulations />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getFinancingConsentFormURL(locale),
        element: <OnlineFinancingConsentForm />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getFinancingVerificationURL(locale),
        element: <OnlineFinancingVerification />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getFinancingPaymentDetailsURL(locale),
        element: <OnlineFinancingPaymentDetails />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getFinancingWaitingPaymentURL(locale),
        element: <OnlineFinancingWaitingPayment />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getFinancingApplicationApprovedURL(locale),
        element: <OnlineFinancingApplicationApproved />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getFinancingVehicleDeliveredURL(locale),
        element: <OnlineFinancingVehicleDelivered />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingURL(locale),
        element: <CashFinancing />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingDetailsURL(locale),
        element: <ApplyForCashFinance />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingConsentFormURL(locale),
        element: <CashFinancingConsentForm />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingInitialDataURL(locale),
        element: <CashFinancingInitialData />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingCongratulationsURL(locale),
        element: <CashFinancingCongratulations />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinanceApplicationRejected(locale),
        element: <CashFinanceApplicationRejected />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingVerificationURL(locale),
        element: <CashFinancingVerification />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingWaitingPaymentURL(locale),
        element: <CashFinancingWaitingPayment />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getCashFinancingApplicationApprovedURL(locale),
        element: <CashFinancingApplicationApproved />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getAccountFetch(locale),
        element: <AmountFetch />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getVerificationCall(locale),
        element: <VerificationCall />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getRequestIneligible(locale),
        element: <RequestIneligible />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getSurveyIneligible(locale),
        element: <SurveyIneligible />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getQuotationCongratulation(locale),
        element: <QuotationCongratulation />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getUploadQuotation(locale),
        element: <UploadQuotation />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getApplicationSubmitted(locale),
        element: <ApplicationSubmitted />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getKafalaApproval(locale),
        element: <KafalaApproval />,
        isNoSidebar: true,
        isPrivate: true
    },
    {
        path: locale => getKafalaUploadForm(locale),
        element: <KafalaUploadInformation />,
        isNoSidebar: true,
        isPrivate: true
    },
];

const commonRoutes = [
    {
        path: locale => getFindVehicleURL(locale),
        element: <FindVehicle />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true
    },
    {
        path: locale => getCampaignDetailsURL(locale),
        element: <CampaignDetails />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true
    },
    {
        path: locale => getFinanceCalculatorURL(locale),
        element: <FinanceCalculator />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true
    },
    {
        path: locale => getVehicleComparisonURL(locale),
        element: <VehicleComparison />,
        isNoSidebar: true,
        isCommon: true,
        isPrivate: true
    }
];

export const allRoutes = [...publicRoutes, ...privateRoutes, ...commonRoutes];