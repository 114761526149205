import React, { useState, useCallback, useEffect, useContext } from "react";
import styled from 'styled-components';
import H1Text from "components/Text/H1Text";
import MuiCards from "components/MuiCards/";
import Grid from '@mui/material/Grid';
import Text from "components/Text";
import ArrowRightIcon from "assets/images/Account/ArrowUpRight.png";
import DateIcon from "assets/images/icon/date.png";
import TimeIcon from "assets/images/icon/time.png";
import LabelWithInput from "components/Input/LabelWithInput";
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import H5Text from "components/Text/H5Text";

import { LocaleContext } from 'routes/Router';

const libraries = ["places", "drawing", "geometry"];

const mapContainerStyle = {
    width: "100%",
    height: "610px",
    borderRadius:"16px"
}
const mapContainerStyleSM = {
    width: "100%",
    height: "350px",
    borderRadius:"16px"
}

const center = {
    lat: 43.6532225,
    lng: -79.383186,
};

const StyleWrapper = styled.div`
    align-items: center;
       margin:${props => props.margin};

    .application_top{
        display:flex;
        align-items:center;
    };
`

const arr = [1, 2, 3];

const AccountNearestBranch = () => {

    const mapRef = React.useRef();
    const { locale } = useContext(LocaleContext);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCusTIQ0pst_mS_rUuBsWti6cjeNIuJ2r8",
        libraries,
    });
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const [isMobile, setIsMobile] = useState(false);
    const [isMapResize, setIsMapResize] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth < 1536){
                setIsMapResize(true)
            }
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [isError, setIsError] = useState(false);
    const [formValue, setFormValue] = useState({
        iqama_number: "",
        password: ""
    })

    if (loadError) return "Error loading Maps";
    if (!isLoaded) return "Loading Maps";

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormValue((prevState) => ({
            ...formValue,
            [name]: value,
        }));
    };

    return (
        <StyleWrapper margin={isMobile ? '0' : locale === 'en' ? '2% 0% 2% 6%' : '2% 10% 2% 0%'}>
            <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily="Karbon_semiBold">
                <FormattedMessage id="account.nearestBranch"/>
            </H5Text>

            <Grid container spacing={2}>

                <Grid item xs={12} sm={12} md={12} xl={5} >
                    <Grid container>
                        <Grid item md={12} xs={12}>
                            <LabelWithInput
                                marginTop="0"
                                placeholder="Search location..."
                                name="password"
                                type="text"
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <Text color="purple" size="18" fontWeight="800" marginTop="0" lineHeight="21.6">
                                            {"Search"}
                                        </Text>
                                    )
                                }}
                                errorText={isError && isError.password.error && isError.password.errorMessage}
                            />
                        </Grid>
                    </Grid>

                    {arr.map((k) => (
                        <Grid container marginTop={'20px'}>
                            <Grid item xs={12}>
                                <MuiCards borderRadius="8" boxShadow="0px 3px 12px 0px #0000001F" padding="16px" backgroundSize="cover" minHeight="140" bgColor="white">

                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Text color="darkGray" size="16" fontfamily="KarbonLight" fontWeight="600" marginTop="0" lineHeight="16.98">
                                                {"Makkah Al-Mukarramah"}
                                            </Text>
                                        </Grid>

                                        <Grid item xs={6} display="flex" alignItems={"Center"} justifyContent="end">
                                            <Text color="purple" size="14" textDecoration="underline" fontfamily="KarbonMedium" fontWeight="700" marginTop="0" lineHeight="15">
                                                <FormattedMessage id="account.getDirections"/>
                                            </Text>
                                            <img src={ArrowRightIcon} alt='icon' style={{marginLeft:locale=="en"&&"8px",marginRight:locale=="ar"&&"8px"}} />
                                        </Grid>
                                    </Grid>

                                    <Grid container >
                                        <Grid item xs={12}>
                                            <Text color="darkPrimary" size="20" fontfamily="KarbonBold" fontWeight="700" marginTop="6" lineHeight="21.45">
                                                {"Makkah Multi-Brand F"}
                                            </Text>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} marginTop={'4px'} display="flex" alignItems={"Center"}>
                                        <Grid item  >
                                            <img src={DateIcon} alt='icon' />
                                        </Grid>
                                        <Grid item  >
                                            <Text color="darkPrimary" size="16" fontfamily="KarbonSemiBold" fontWeight="600" marginTop="4" lineHeight="16.98">
                                                {"Sunday - Thursday"}
                                            </Text>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} marginTop={'0px'} display="flex" alignItems={"Center"}>
                                        <Grid item>
                                            <img src={TimeIcon} alt='icon' />
                                        </Grid>
                                        <Grid item>
                                            <Text color="darkPrimary" size="16" fontWeight="600" fontfamily="KarbonSemiBold" marginTop="4" lineHeight="16.98">
                                                {"08:00 AM to 12:30 PM & 12:30 PM to 08:30 PM"}
                                            </Text>
                                        </Grid>
                                    </Grid>

                                </MuiCards>
                            </Grid>
                        </Grid >
                    ))}
                </Grid>

                <Grid item xs={12} sm={12} md={12} xl={7}>
                    <Grid container>
                        <Grid item md={12} xs={12} marginTop={"20px"}  style={{height:'200px'}}>
                            <GoogleMap
                                mapContainerStyle={isMapResize?mapContainerStyleSM:mapContainerStyle}
                                zoom={18}
                                center={center}
                                onLoad={onMapLoad}
                                mapTypeId={"hybrid"}
                               
                            >
                            </GoogleMap>

                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </StyleWrapper >
    );
};

export default AccountNearestBranch;
