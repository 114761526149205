import React from 'react';
import styled from 'styled-components';

const WrapperDiv = styled.div`
	background-color: ${(props) => (props.theme[props.bgColor])};
	justify-content:center;
    align-items:center;
    display:flex;
	height: 90vh;
    min-height: 100%;
	img{
		margin-left: -20px;
		margin-top: 20px;
		width: 50%;
	}

    @media screen and (max-width: 800px) {
    	height:100%;
	}
    @media screen and (max-height: 800px) {
		min-height: 100%;
	}
`;

function LeftBarImage(props) {
	return (
		<WrapperDiv {...props}>
			<img src={props.bgImage} alt="" style={{ width: props?.widthImage?props?.widthImage:'85%' }} />
		</WrapperDiv>
	);
}

export default LeftBarImage;