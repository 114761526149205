import {
   OTP_VERIFY,
   OTP_VERIFY_ERROR,
   OTP_VERIFY_INITIAL_STATE,
   OTP_VERIFY_SUCCESS
} from "./constant";

const otpVerifyReducer = (state = {}, action) => {
   switch (action.type) {
      case OTP_VERIFY:
         return { ...state, loading: true, error: '' };
      case OTP_VERIFY_INITIAL_STATE:
         return { state, loading: false, error: '' };
      case OTP_VERIFY_ERROR:
         return { ...state, type: OTP_VERIFY_ERROR, loading: false, error: action.payload };
      case OTP_VERIFY_SUCCESS:
         return { ...state, type: OTP_VERIFY_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default otpVerifyReducer;