import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';


const WrapperButton = styled(Button)`
	background-color: ${(props) => (props.theme[props.bgColor])} !important;
	font-size: ${(props) => (props.size+"px")} !important;
	font-weight: 800 !important;
	font-family:${(props)=>props.fontFamily}!important;
	line-height: 24px !important;
	color: ${(props) => (props.theme[props.textColor])} !important;
    height: ${(props) => (props.height+"px")} !important;
    border-radius: ${(props) => (props.borderRadius+"px")} !important;
    text-transform: ${(props) => (props.TextTransform)} !important;
    box-shadow: none !important;
    padding: ${(props) => (props.padding)} !important;
	width: ${(props) => (props.width)} !important;
    min-width: ${(props) => (props.minWidth+"px")} !important;
	margin-left:${(props) => (props.marginLeft)}px !important;
	margin-right:${(props) => (props.marginRight)}px !important;
    ${(props) =>
		props.borderColor && `
 	    border: 1px solid ${props.theme[props.borderColor]} !important;
    `}

    .MuiButton-endIcon {
		margin-left: 10px;
    	margin-top: -4px;
		font-size:10px;
    }
    .MuiButton-startIcon {
		margin-right: 1px;
		margin-top: -4px;
    }

    @media screen and (max-width: 700px) {
        font-size: 18px!important;
        // padding: 15px !important;
    	min-width: 160px;
    	height: 49px;
    }
`;

function ButtonInput({containerProps, ...props }) {
	return (
		<WrapperButton 
			{...props}
		>
			{props.title}
		</WrapperButton>
	);
}

ButtonInput.defaultProps = {
    minWidth: 230,
    padding: "18px 30px 15px",
    size: "24",
    height: 59,
	marginLeft:"0",
	marginRight:"0",
	fontFamily:"Karbon_medium"
};

export default ButtonInput;