export const getLandingURL = (locale) => `/${locale}`;
export const getSignInURL = (locale) => `/${locale}/signin`;
export const getSignUpURL = (locale) => `/${locale}/signup`;
export const getForgotPasswordURL = (locale) => `/${locale}/forgot-password`;
export const getResetPasswordURL = (locale) => `/${locale}/reset-password`;
export const get404URL = (locale) => `/${locale}/404`;

/** PRIVATE ROUTE **/
export const getDashboardURL = (locale) => `/${locale}/dashboard`;
export const getContractsURL = (locale) => `/${locale}/contracts`;
export const getContractDetailsURL = (locale) => `/${locale}/contracts/contract-details`;
export const getApplicationsURL = (locale) => `/${locale}/applications`;
export const getApplicationDetailsURL = (locale) => `/${locale}/applications/application-details`;
export const getAccountURL = (locale) => `/${locale}/account`;
export const getAccountWithIdURL = (locale) => `${getAccountURL(locale)}/:postId`;
export const getSupportURL = (locale) => `/${locale}/support`;

export const getFinancingURL = (locale) => `/${locale}/financing`;
export const getFinancingPersonalInfoURL = (locale) => `/${locale}/financing/personal-info`;
export const getFinancingInitialDataURL = (locale) => `/${locale}/financing/initial-data`;
export const getFinancingCongratulationsURL = (locale) => `/${locale}/financing/congratulations`;
export const getFinancingConsentFormURL = (locale) => `/${locale}/financing/consent-form`;
export const getFinancingVerificationURL = (locale) => `/${locale}/financing/verification`;
export const getFinancingPaymentDetailsURL = (locale) => `/${locale}/financing/payment-details`;
export const getFinancingWaitingPaymentURL = (locale) => `/${locale}/financing/waiting-payment`;
export const getFinancingApplicationApprovedURL = (locale) => `/${locale}/financing/application-approved`;
export const getFinancingVehicleDeliveredURL = (locale) => `/${locale}/financing/vehicle-delivered`;

export const getCashFinancingURL = (locale) => `/${locale}/cash-financing`;
export const getCashFinancingDetailsURL = (locale) => `/${locale}/cash-financing/details`;
export const getCashFinancingConsentFormURL = (locale) => `/${locale}/cash-financing/consent-form`;
export const getCashFinancingInitialDataURL = (locale) => `/${locale}/cash-financing/initial-data`;
export const getCashFinancingCongratulationsURL = (locale) => `/${locale}/cash-financing/congratulations`;
export const getCashFinanceApplicationRejected = (locale) => `/${locale}/cash-financing/application-rejected`;
export const getCashFinancingVerificationURL = (locale) => `/${locale}/cash-financing/verification`;
export const getCashFinancingWaitingPaymentURL = (locale) => `/${locale}/cash-financing/waiting-payment`;
export const getCashFinancingApplicationApprovedURL = (locale) => `/${locale}/cash-financing/application-approved`;
export const getAccountFetch = (locale) => `/${locale}/cash-financing/account-fetch`;
export const getVerificationCall = (locale) => `/${locale}/cash-financing/verification-call`;
export const getRequestIneligible = (locale) => `/${locale}/cash-financing/request-ineligible`;
export const getSurveyIneligible = (locale) => `/${locale}/cash-financing/survey-ineligible`;
export const getQuotationCongratulation = (locale) => `/${locale}/cash-financing/request-quotation`;
export const getUploadQuotation = (locale) => `/${locale}/cash-financing/upload-quotation`;
export const getApplicationSubmitted = (locale) => `/${locale}/cash-financing/application-submitted`;
export const getKafalaApproval = (locale) => `/${locale}/cash-financing/kafala-approval`;
export const getKafalaUploadForm = (locale) => `/${locale}/cash-financing/kafala-uploadForm`;

/** COMMON ROUTE **/
export const getFindVehicleURL = (locale) => `/${locale}/find-vehicle`;
export const getCampaignDetailsURL = (locale) => `/${locale}/campaign-details`;
export const getFinanceCalculatorURL = (locale) => `/${locale}/finance-calculator`;
export const getVehicleComparisonURL = (locale) => `/${locale}/vehicle-comparison`;


