import {
   SET_PASSWORD,
   SET_PASSWORD_ERROR,
   SET_PASSWORD_INITIAL_STATE,
   SET_PASSWORD_SUCCESS
} from "./constant";

const setPasswordReducer = (state = {}, action) => {
   switch (action.type) {
      case SET_PASSWORD:
         return { ...state, loading: true, error: '' };
      case SET_PASSWORD_INITIAL_STATE:
         return { state, loading: false, error: '' };
      case SET_PASSWORD_ERROR:
         return { ...state, type: SET_PASSWORD_ERROR, loading: false, error: action.payload };
      case SET_PASSWORD_SUCCESS:
         return { ...state, type: SET_PASSWORD_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default setPasswordReducer;