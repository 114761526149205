import React, { useContext } from "react";
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import SearchInput from 'components/SearchInput';
import Text from 'components/Text';
import ButtonInput from 'components/Input/ButtonInput';
import { LocaleContext } from 'routes/Router';
import AutoFinanceIcon from "assets/images/Card/auto_finance.png";
import { Box, Skeleton } from "@mui/material";
import moment from "moment";

const ListWrraper = styled.div`
min-width: 1200px;
.List_top{
    padding:10px;
    border-bottom:1px solid #E9E7FD;
    height:60px;
    align-items:center;
};

.list_style{
    height:100px;
    padding:10px;
    border-bottom:1px solid #E9E7FD;
    align-items:center;
};

.isoDotShow{
    display: inline-block;
    margin-left: -18px;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    margin-top: 35px;
    border:2px solid white;
};

`

const Listing = (props) => {
    const { locale } = useContext(LocaleContext);
    return (
        <ListWrraper>
            {!props?.loader ?
                <>
                    {props?.arr?.length > 0 && props?.arr.map((k, i) => (
                        <>
                            {i === 0 &&
                                <div className='List_top'>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        {props.headings && props?.headings[0] && <div style={{ width: "20%" }}>
                                            {props.headings && props.headings[0] && <SearchInput
                                                placeholder={props.headings[0]}
                                                endAdornment={true}
                                                height="0px"
                                                width="auto"
                                                size="16px"
                                                color="lightDark"
                                            />}
                                        </div>}

                                        {props.headings && props?.headings[1] && <div style={{ width: "12%" }}>
                                            <Text textTransform="uppercase" size="16" marginTop="0" fontWeight="800" fontFamily="KarbonSemiBold" color="lightDark" height="24" lineHeight="20">
                                                {props.headings && props.headings[1]}
                                            </Text>
                                        </div>}

                                        {props.headings && props?.headings[2] && <div style={{ width: "10%" }}>
                                            <Text textTransform="uppercase" size="16" marginTop="0" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" height="24" lineHeight="20">
                                                {props.headings && props?.headings[2]}
                                            </Text>
                                        </div>}

                                        {props.headings && props.headings[3] && <div style={{ width: "10%" }}>
                                            <Text textTransform="uppercase" size="16" marginTop="0" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" height="24" lineHeight="20">
                                                {props?.headings[3]}
                                            </Text>
                                        </div>}

                                        {props.headings && props.headings[4] && <div style={{ width: "10%" }}>
                                            <Text textTransform="uppercase" size="16" marginTop="0" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" height="24" lineHeight="20">
                                                {props?.headings[4]}
                                            </Text>
                                        </div>}

                                        <div style={{ width: "10%" }}>

                                        </div>
                                    </div>
                                </div>
                            }

                            <Row className='list_style' style={{ background: i % 2 !== 0 ? "#FCF8FE" : "" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ display: "flex", alignItems: "center", width: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <img src={AutoFinanceIcon} alt="avtarImage" />
                                            <span className="isoDotShow" style={{ backgroundColor: "#1D8841", alignItems: "end", position: locale === 'ar' && 'relative', left: '15px' }} />
                                        </div>

                                        <div style={{ marginRight: locale === 'ar' ? '20px' : '', marginLeft: locale === 'en' ? '20px' : '' }}>
                                            <Text size="20" marginTop="0" color="primary" lineHeight={30} fontFamily={'KarbonMedium'}>
                                                {"Auto Finance"}
                                            </Text>
                                            <Text size="16" marginTop="0" color="lightDark" lineHeight={24} >
                                                {k?.loanNo}
                                            </Text>
                                            <Text size="16" marginTop="0" color="lightGrey" lineHeight={24}>
                                                {moment(k?.loanApprovalDate).format("Do MMM YYYY")}
                                            </Text>
                                        </div>
                                    </div>

                                    <div style={{ width: "12%" }}>
                                        <Text size="20" marginTop="0" color="green" lineHeight={27} fontFamily={'KarbonSemiBold'} >
                                            {k?.loanEmiAmount} SAR
                                        </Text>
                                    </div>

                                    <div style={{ width: "10%" }}>
                                        <Text size="18" marginTop="0" color="darkGrey" height="24">
                                            {k?.loanBalancePrincipal}
                                        </Text>
                                    </div>

                                    <div style={{ width: "10%" }}>
                                        <Text size="18" marginTop="0" color="darkGrey" height="24">
                                            {"60"} Months
                                        </Text>
                                    </div>

                                    <div style={{ width: "10%" }}>
                                        <Text size="18" marginTop="0" color="darkBrown" height="24" lineHeight={27} fontFamily={'KarbonSemiBold'}>
                                            {k?.loanOverdueAmount} SAR
                                        </Text>
                                    </div>

                                    <div style={{ width: "10%" }}>
                                        <ButtonInput
                                            style={{ minWidth: '129px !important' }}
                                            title={<FormattedMessage id="viewDetails" />}
                                            variant="text"
                                            bgColor="transparent"
                                            textColor="purple"
                                            borderRadius="130"
                                            borderColor="lightPink"
                                            TextTransform="capitalize"
                                            size="18"
                                            padding='0'
                                            minWidth="129"
                                            height="45"
                                            onClick={() => props.onClick(k?.loanNo)}
                                            fontFamily='Karbon_semiBold'
                                        />
                                    </div>
                                </div>
                            </Row>
                        </>
                    ))}
                </>
                :
                <>
                    {Array.from({ length: 2 }).map((k, i) => (
                        <>
                            {i === 0 &&
                                <div className='List_top'>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        {props.headings && props?.headings[0] && <div style={{ width: "20%" }}>
                                            {props.headings && props.headings[0] && <SearchInput
                                                placeholder={props.headings[0]}
                                                endAdornment={true}
                                                height="0px"
                                                width="auto"
                                                size="16px"
                                                color="lightDark"
                                            />}
                                        </div>}

                                        {props.headings && props?.headings[1] && <div style={{ width: "12%" }}>
                                            <Text textTransform="uppercase" size="16" marginTop="0" fontWeight="800" fontFamily="KarbonSemiBold" color="lightDark" height="24" lineHeight="20">
                                                {props.headings && props.headings[1]}
                                            </Text>
                                        </div>}

                                        {props.headings && props?.headings[2] && <div style={{ width: "10%" }}>
                                            <Text textTransform="uppercase" size="16" marginTop="0" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" height="24" lineHeight="20">
                                                {props.headings && props?.headings[2]}
                                            </Text>
                                        </div>}

                                        {props.headings && props.headings[3] && <div style={{ width: "10%" }}>
                                            <Text textTransform="uppercase" size="16" marginTop="0" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" height="24" lineHeight="20">
                                                {props?.headings[3]}
                                            </Text>
                                        </div>}

                                        {props.headings && props.headings[4] && <div style={{ width: "10%" }}>
                                            <Text textTransform="uppercase" size="16" marginTop="0" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" height="24" lineHeight="20">
                                                {props?.headings[4]}
                                            </Text>
                                        </div>}

                                        <div style={{ width: "10%" }}>

                                        </div>
                                    </div>
                                </div>
                            }

                            <Row className='list_style' style={{ background: i % 2 !== 0 ? "#FCF8FE" : "" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ display: "flex", alignItems: "center", width: "20%" }}>
                                        <Skeleton animation="wave" variant="rectangular" width={60} height={50} style={{ borderRadius: "10px" }} />
                                        <Box width="100%" marginLeft={props.locale == 'en' && "20px"} marginRight={props.locale == 'ar' && "20px"}>
                                            <Skeleton animation="wave" height={20} width="60%" />
                                            <Skeleton animation="wave" height={20} width="60%" />
                                        </Box>
                                    </div>

                                    <div style={{ width: "12%" }}>
                                        <Text size="20" marginTop="0" color="green" lineHeight={27} fontFamily={'KarbonSemiBold'} >
                                            <Skeleton animation="wave" height={20} width="60%" />
                                        </Text>
                                    </div>

                                    <div style={{ width: "10%" }}>
                                        <Text size="18" marginTop="0" color="darkGrey" height="24">
                                            <Skeleton animation="wave" height={20} width="60%" />
                                        </Text>
                                    </div>

                                    <div style={{ width: "10%" }}>
                                        <Text size="18" marginTop="0" color="darkGrey" height="24">
                                            <Skeleton animation="wave" height={20} width="60%" />
                                        </Text>
                                    </div>

                                    <div style={{ width: "10%" }}>
                                        <Text size="18" marginTop="0" color="darkBrown" height="24" lineHeight={27} fontFamily={'KarbonSemiBold'}>
                                            <Skeleton animation="wave" height={20} width="60%" />
                                        </Text>
                                    </div>

                                    <div style={{ width: "10%" }}>
                                        <Skeleton variant="rectangular" animation="wave" height={30} width="100%" style={{ borderRadius: "130px" }} />
                                    </div>
                                </div>
                            </Row>
                        </>
                    ))}
                </>
            }
        </ListWrraper>
    );
};

export default Listing;