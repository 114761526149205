import React from 'react';
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from 'components/MuiLink';

const WrapperBreadcrumbs = styled(Breadcrumbs)`
	.MuiBreadcrumbs-separator {
		background: #333333;
	    width: 8px;
	    height: 8px;
	    border-radius: 20px;
	}
`;

function MuiBreadcrumbs({list}) {
	return (
		<Stack spacing={2}>
			<WrapperBreadcrumbs separator="" aria-label="breadcrumb">
				{list.length > 0 && list.map((value) => (
					<>
						{value.isDisbale ? (
							<MuiLink textColor="black" fontWeight="600" fontFamily="Karbon_light">{value.title}</MuiLink>
						) : (
							<MuiLink>{value.title}</MuiLink>
						)}
					</>
				))}
			</WrapperBreadcrumbs>
		</Stack>
	);
}

export default MuiBreadcrumbs;