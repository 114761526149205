import { takeLatest, put } from "redux-saga/effects";
import {
    USER_API,
    USER_API_ERROR,
    USER_API_SUCCESS,
    USER_PROFILE_UPDATE_API,
    USER_UPDATE_API,
} from "./constant";
import url from "utils/services/urls.json";
import { getMethodProfileWithToken, patchMethodToken, putMethodWithToken } from "utils/services/apis";
import LocalStorageManager from "managers/LocalStorageManger";
import { setClient, unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";


function* getUserMeDetail(action) {
    try {
        const response = yield getMethodProfileWithToken(url.getUserMeApi, action.data);
        console.log("action?.external?.navigate", action, action?.external?.navigate)
        if (response.status === 200 || response.status === 201) {
            yield put({ type: USER_API_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                if (response?.data?.data?.simah_consent === false) {
                    action.external.handleStep(action.external.step);
                } else {
                    action.external.redirectNavigate(action.external.path);
                    yield put(setClient(LocalStorageManager.getItem('RefreshToken')));
                    LocalStorageManager.setItem('token', LocalStorageManager.getItem('RefreshToken'));
                }
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {

            }
        } else {
            yield put({ type: USER_API_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: USER_API_ERROR, payload: error });
    }
};

function* getUserUpdateApi(action) {
    try {
        const response = yield patchMethodToken(url.updateUserProfile, action.data);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: USER_API_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: USER_API_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: USER_API_ERROR, payload: error });
    }
};

function* getUserProfileUpdateApi(action) {
    try {
        const response = yield putMethodWithToken(url.profileUpdateImage, action.data);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: USER_API_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: USER_API_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: USER_API_ERROR, payload: error });
    }
};

function* userSaga() {
    yield takeLatest(USER_API, getUserMeDetail);
    yield takeLatest(USER_UPDATE_API, getUserUpdateApi);
    yield takeLatest(USER_PROFILE_UPDATE_API, getUserProfileUpdateApi);
};

export default userSaga;
