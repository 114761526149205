import React from "react";
import MuiCards from "components/MuiCards/";
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Text from "components/Text";
import PolygonWithBackground from "assets/images/Dashboard/Polygon_e_service.png";
import Background_People_Image from "assets/images/Dashboard/Background_people.png";
import styled from 'styled-components'
import { Row } from "react-bootstrap";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { FormattedMessage } from "react-intl";
import H5Text from "components/Text/H5Text";
import { Skeleton } from "@mui/material";

const StyledWrapper = styled.div`
    margin-top:15px;
    
    .cardContent{
        background-image:url('${PolygonWithBackground}');
        background-repeat: no-repeat;
        // height:204px;
        display:flex;
        background-position: bottom right 0px;
        align-items:center;
    }

    .cardText{
        // background-image:url('${Background_People_Image}');
        background-repeat: no-repeat;
        height:204px;
        display:flex;
        // background-position: right 30px center;
        background-position: 92% 70%;
        align-items:center;
        // padding:28px 26px;
    }

`

const arr = [
    { name: "Ownership transfer", subText: "This calculator will help you to calculate the expected EMI on your loan amount .", list: [{ item: "Certificate of registration" }, { item: "Certificate of insurance" }, { item: "Chassis & Engine Pencil Print" }] },
    { name: "Ownership transfer", subText: "This calculator will help you to calculate the expected EMI on your loan amount .", list: [{ item: "Certificate of registration" }, { item: "Certificate of insurance" }, { item: "Chassis & Engine Pencil Print" }] },
    { name: "Ownership transfer", subText: "This calculator will help you to calculate the expected EMI on your loan amount .", list: [{ item: "Certificate of insurance" }, { item: "Chassis & Engine Pencil Print" },{ item: "Chassis & Engine Pencil Print" }] },
];
const AvailableService = ({ ...props }) => {
    return (
        <StyledWrapper {...props}>
            <Row style={{ marginBottom: "8px" }}>
                <H5Text color="primary" size="24" marginTop="0" lineHeight="24" fontFamily="Karbon_semiBold">
                    <FormattedMessage id="dashboard.availableEServices"/>
                </H5Text>
            </Row>
            {!props?.loader ?
            <Grid container spacing={1} marginLeft="-16px">
                {props?.data && props.data.length > 0 && props.data.map((k) => (
                    <>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                     <img src={k.image} alt="" style={{width:"100%"}}/>
                        {/* <MuiCards borderRadius="16" boxShadow={true} padding="0px" backgroundSize="cover" margin="0px"  bgColor="primary">
                            <Grid container className="cardContent">
                                <Grid item xs={12} >
                                    <Grid container className="cardText">
                                        <Grid item xs={9} >
                                            <div style={{marginLeft:props.locale === 'en' && '26.7px', marginRight:props.locale === 'ar' && '26.7px'}}>
                                                <Text color="white" size="25.29" marginTop="0" lineHeight="28" fontFamily="KarbonSemiBold">
                                                    {k?.name}
                                                </Text>
                                                <Text color="white" size="16.86" marginTop="0" lineHeight="20">
                                                    {k.subText}
                                                </Text>
                                                <Grid container style={{ marginTop: "8px" }}>
                                                    {k.list.map((j) => (
                                                        <Grid item xs={12}>
                                                        <Text color="white" size="16.86" marginTop="0" lineHeight="17.9">
                                                           <KeyboardDoubleArrowRightIcon style={{width:'21.62px'}}/> {j.item}
                                                        </Text>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} justifyContent="end" display="flex">
                                            <img src={Background_People_Image} style={{paddingRight: props.locale === 'en' && '30px', paddingLeft: props.locale === 'ar' && '30px', paddingTop: '50px'}}  />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MuiCards> */}
                    </Grid>
                    </>
                ))}
            </Grid>:
              <>
              <Grid container spacing={2} >
                  {Array.from({ length: 2 }).map(() => (
                      <Grid item xs={12} md={6} lg={6} xl={4} >
                          <Skeleton variant="rectangular" animation="wave" width={"100%"} height={206} style={{borderRadius:"16px"}} />
                      </Grid>
                  ))}
              </Grid>
          </>
            }
        </StyledWrapper>
    );
};


export default AvailableService;
