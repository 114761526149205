import {
   PAYMENT_HISTORY, PAYMENT_HISTORY_ERROR, PAYMENT_HISTORY_SUCCESS
} from "./constant";

const paymentHistoryReducer = (state = {}, action) => {
   switch (action.type) {
      case PAYMENT_HISTORY:
         return { ...state, loading: true, error: '' };
      case PAYMENT_HISTORY_ERROR:
         return { ...state, type: PAYMENT_HISTORY_ERROR, loading: false, error: action.payload };
      case PAYMENT_HISTORY_SUCCESS:
         return { ...state, type: PAYMENT_HISTORY_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default paymentHistoryReducer;