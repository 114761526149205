import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getCashFinancingDetailsURL } from 'routes/AppUrls';
import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import YesNoInput from "components/Input/YesNoInput";
import ButtonInput from "components/Input/ButtonInput";
import DateTimePickerInput from "components/Input/DateTimePickerInput";
import SelectAmongs from "components/SelectAmongs";
import { LocaleContext } from "routes/Router";
import { financingDropDownCallAPI } from "store/financingDropDown/action";
import { FINANCING_DROPDOWN } from "store/financingDropDown/constant";
import InputAdornment from '@mui/material/InputAdornment';

const WrapperDiv = styled.div`
	margin-bottom: 30px;
`;

function InitialDataForm({ handleCurStep }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { locale } = useContext(LocaleContext);
	const financingTransaction = useSelector((state) => (state.financingDropDownReducer && state.financingDropDownReducer));
	useEffect(() => {
		getCarCategory();
	}, []);

	const getCarCategory = () => {
		dispatch(financingDropDownCallAPI(FINANCING_DROPDOWN, {}, { navigate: navigate }));
	};

	const maritalStatus = [
		{
			id: 1,
			type: <FormattedMessage id="married" />
		},
		{
			id: 2,
			type: <FormattedMessage id="unmarried" />
		},
	]
	const purposeofLoan = [
		{
			id: 1,
			type: <FormattedMessage id="personal" />
		},
		{
			id: 2,
			type: <FormattedMessage id="familyFriends" />
		},
	]

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>

					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={'24'}>
						<FormattedMessage id={'Customer Details'} />
						<img alt="" src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<SelectInput label={<FormattedMessage id="No. of Branch's" />} placeholder="No. of Branch's" />
							</Grid>

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="Legal Entity Identifier (optional)" />}
									placeholder="845256"
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<DateTimePickerInput label={<FormattedMessage id="Legal Entity Identifier Expiry Date (optional)" />} />
							</Grid>

							<Grid item xs={12} md={6}>
								<SelectInput label={<FormattedMessage id="No. of Vehicles Owned" />} placeholder="0-49" />
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="Average Annual Income" />}
									placeholder="40,000"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end"><FormattedMessage id="sar" /></InputAdornment>
										)
									}}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<LabelWithInput
									label={<FormattedMessage id="Email" />}
									placeholder="xyz123@mail.com"
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container sx={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => navigate(getCashFinancingDetailsURL(locale))}
								/>
								<ButtonInput
									title={<FormattedMessage id="Save & Next" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									onClick={() => handleCurStep(1)}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default InitialDataForm;