import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from "react-intl";
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputAdornment from '@mui/material/InputAdornment';

import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import ButtonInput from "components/Input/ButtonInput";
import CheckboxInput from "components/Input/CheckboxInput";
import ExpensesModal from "components/Modals/ExpensesModal";
import Text from "components/Text";

import NotePencilIcon from "assets/images/icon/NotePencil.png";

const WrapperDiv = styled.div`
	
`;

function ExpensesBankForm({handleCurStep}) {
	const intl = useIntl();

	const [isModalActive, setIsModalActive] = useState(false);

	const handleModalPopup = (value) => {
    	setIsModalActive(value);
  	}

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage  id={'Expenses & Obligations'} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div style={{ display: 'flex', marginTop: "30px" }}>
									<Text marginTop="0" size="24" color="dark" fontWeight="800" fontFamily="KarbonSemiBold">
										Basic expenses 3,400.00 SAR
									</Text>
									<div style={{ marginLeft: "15px", cursor: "pointer" }}>
										<img src={NotePencilIcon} onClick={() => setIsModalActive(true)} />
									</div>
								</div>
								<ExpensesModal modalActive={isModalActive} handleModalPopup={handleModalPopup} />
							</Grid>

							<Grid item xs={6}>
								<LabelWithInput 
									label="Current Monthly Obligations" 
									placeholder="Current Monthly Obligations" 
									InputProps = {{
						      			endAdornment: (
						        			<InputAdornment position="end">SAR</InputAdornment>
						    			)
						      		}}
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput 
									label="Current Monthly Obligation Source" 
									placeholder="Current Monthly Obligation Source" 
								/>
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput 
									label="Expected Monthly Obligations" 
									placeholder="Expected Monthly Obligations" 
									InputProps = {{
						      			endAdornment: (
						        			<InputAdornment position="end">SAR</InputAdornment>
						    			)
						      		}}
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput 
									label="Expected Monthly Obligations Source" 
									placeholder="Expected Monthly Obligations Source" 
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container style={{marginTop: "15px"}}>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage  id={'Bank Details'} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabelWithInput 
									label="IBAN" 
									placeholder="IBAN"
								/>
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput 
									label="Re-enter IBAN" 
									placeholder="Re-enter IBAN"
								/>
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput 
									label="Bank Name" 
									placeholder="Bank Name"
								/>
							</Grid>
							<Grid item xs={6}>
							</Grid>
							<Grid item xs={6}>
								<CheckboxInput 
									label="I agree, IBAN is related to me and I’m responsible for all data." 
									size="18"
									fontWeight="600"
								/>
							</Grid>
							<Grid item xs={6}>
							</Grid>
							<Grid item xs={6}>
								<SelectInput 
									label="Purpose of Loan" 
									placeholder="Purpose of Loan" 
								/>
							</Grid>
							<Grid item xs={6}>
							</Grid>
							<Grid item xs={6}>
								<CheckboxInput 
									label="Pay on Delivery" subLabel="Pay with cash on delivery" 
									size="16"
									fontWeight="800"
								/>
							</Grid>
							<Grid item xs={6}>
							</Grid>
							<Grid item xs={6}>
								<CheckboxInput 
									label="Credit/Debit Cards" subLabel="Pay with your Credit / Debit Card" 
									size="16"
									fontWeight="800"
								/>
							</Grid>
							<Grid item xs={6}>
							</Grid>
							<Grid item xs={6}>
								<CheckboxInput 
									label="Direct Bank Transfer" subLabel="Make payment directly through bank account." 
									size="16"
									fontWeight="800"
								/>
							</Grid>
							<Grid item xs={6}>
							</Grid>
							<Grid item xs={6}>
								<CheckboxInput 
									label="Other Payment Methods" subLabel="Make payment through Gpay, Paypal, Paytm etc" 
									size="16"
									fontWeight="800"
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container style={{marginTop: "30px"}}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput 
									title="Back"
									variant="text" 
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(2)}
								/>
								<ButtonInput 
									title="Save & Proceed to Attach & Apply"
									variant="contained" 
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									onClick={() => handleCurStep(4)}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default ExpensesBankForm;