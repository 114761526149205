import {
    AUTH_NAFATH_AUTHENTICATION,
} from "./constant";

export const nafathAuthCallAPI = (type, data, external = {}) => {
    switch (type) {
        case AUTH_NAFATH_AUTHENTICATION:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
}

