import React, { useState, useCallback, useContext, useEffect } from "react";
import styled from 'styled-components';
import H1Text from "components/Text/H1Text";
import MuiCards from "components/MuiCards/";
import Grid from '@mui/material/Grid';
import Text from "components/Text";
import ArrowRightIcon from "assets/images/Account/ArrowRight.png";
import CarImage from "assets/images/Account/car.png";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import H5Text from "components/Text/H5Text";

import { LocaleContext } from 'routes/Router';

const StyleWrapper = styled.div`
    align-items: center;
    margin:${props => props.margin};

    .application_top{
        display:flex;
        align-items:center;
    };

    .img{
        background-image:url('${CarImage}');
        background-repeat: no-repeat;
        display:flex;
        background-size: cover;
        background-position: 0px;
        align-items:center;
    }
`

const arr = [1, 2, 3];

const AccountOffers = () => {

    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [isError, setIsError] = useState(false);
    const [formValue, setFormValue] = useState({
        iqama_number: "",
        password: ""
    })

    const handleClick = () => {
        navigate("/"+locale+"/account/offer-details");
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormValue((prevState) => ({
            ...formValue,
            [name]: value,
        }));
    };

    return (
        <StyleWrapper margin={isMobile ? '0%' : locale === 'en' ? '2% 0% 2% 6%' : '2% 10% 2% 0%'}>
            <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily="Karbon_semiBold">
                <FormattedMessage id="account.availableOffers"/>
            </H5Text>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
                    {arr.map((k) => (
                        <Grid container marginTop={'20px'}>

                            <Grid item xs={12}>
                                <MuiCards borderRadius="8" boxShadow='0px 0px 10px 0px #0000003D' padding="0px" backgroundSize="cover" minHeight="140" bgColor="white">

                                    <Grid container>
                                        <Grid item xs={12} sm={5} md={5} className="img" minHeight='183px' >
                                            {/* <img src={CarImage}  alt="image" /> */}
                                        </Grid>

                                        <Grid item xs={12} sm={7} md={7} padding={'20px 20px 20px 20px'}>
                                            <Grid container >
                                                <Grid item xs={6}>
                                                    <H5Text color="black" size="16" fontfamily="Karbon_medium" fontWeight="600" marginTop="0" lineHeight="24">
                                                        {"Special Summer Offer"}
                                                    </H5Text>
                                                </Grid>
                                            </Grid>

                                            <Grid container >
                                                <Grid item xs={12}>
                                                    <H5Text color="darkGreen" size="28" fontfamily="Karbon_bold" fontWeight="800" marginTop="0" lineHeight="42">
                                                        {"40%  OFF"}
                                                    </H5Text>
                                                </Grid>
                                            </Grid>

                                            <Grid container >
                                                <Grid item xs={12}>
                                                    <Text color="darkPrimary" size="16" fontfamily="KarbonSemiBold" fontWeight="600" marginTop="0" lineHeight="16.98">
                                                        {"Get 40% off  on the purchase of 20,000 SAR"}
                                                    </Text>
                                                </Grid>
                                            </Grid>

                                            <Grid container marginTop={'32px'}>
                                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                                    <H5Text color="lightBlue" size="17.5" fontfamily="Karbon_medium" fontWeight="600" marginTop="0" lineHeight="26.25">
                                                        <FormattedMessage id="account.useCode"/> TOYOTA40
                                                    </H5Text>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={7} lg={7} textAlign={'right'} onClick={() => handleClick()} display='flex' justifyContent={{xs:'start',md:'end'}} alignItems='end'>
                                                    <H5Text color="purple" size="20" fontfamily="Karbon_bold" fontWeight="800" marginTop="0" lineHeight="21.55">
                                                      <FormattedMessage id="account.viewDetails"/>
                                                    </H5Text>
                                                    <img src={ArrowRightIcon} style={{ marginLeft: "8px", cursor: "pointer", marginBottom:'8px' }} alt='icon' />
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </MuiCards>
                            </Grid>

                        </Grid >
                    ))}
                </Grid>
            </Grid>
        </StyleWrapper >
    );
};

export default AccountOffers;
