import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';
import IconWithText from "components/IconWithText";
import Text from "components/Text";
import ButtonInput from 'components/Input/ButtonInput';
import ArrowLeftIcon from "assets/images/Application/ApplicationDeatils/ArrowLeft.png";
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import H5Text from 'components/Text/H5Text';
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
	background: ${(props) => (props.theme[props.bgColor])};
	border-radius: 15px;
	margin-top: 30px;
	padding: 20px;
`;

const arr = [{ id: 1, name: "All Cars", is_select: false }, { id: 2,count:"20x", name: "Yaris", is_select: false }, { id: 3,count:"10x", name: "KIA Sonet", is_select: false },
{ id: 4,count:"30x", name: "KIA Sonet X", is_select: false }, { id: 5,count:"20x", name: "Mercedes E Class", is_select: false }, { id: 6,count:"20x", name: "Yaris", is_select: false }];

function ProductDetails({ containerProps, ...props }) {
	const [carlistArray, setCarListArray] = useState(arr);
    const { locale } = useContext(LocaleContext);

	const handleClick = (data) => {
		carlistArray.map((k, i) => {
			if (k.id === data.id) {
				setCarListArray([...carlistArray], carlistArray[i].is_select = true);
			} else {
				setCarListArray([...carlistArray], carlistArray[i].is_select = false);
			}
		})
	};

	return (
		<WrapperDiv {...props}>
			{/* <IconWithText 
				boxShadow={false} 
				BrandIcon={BrandImage} 
				variant={'rounded'} 
				BrandSize={48} 
				text="Kia Sonet X-Line" 
				textSize="24"
				secondText="SAR 20,000" 
				secondTextSize="20"
				secondFontWeight="Karbon_semibold"
				thirdText="2021 GU M/R 4x215" 
				thirdTextSize="18"
				thirdTextColor="gray"
				{...props}
			/> */}

			<Grid container>
				<Grid item xs={12}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<Text marginTop="0" color="lightGrey" size="24" fontFamily="KarbonSemiBold" fontWeight='700' lineHeight={'24'}>
							
							<FormattedMessage id="contract.totalNoOfVehicles"/>:
						</Text>
						<Text marginTop="0" color="purple" size="32" fontFamily="KarbonBold" fontWeight='800' lineHeight={'32'} style={{marginRight:locale === 'ar' && '8px', marginLeft:locale === 'en' && '8px'}}>
							80
						</Text>
					</div>
				</Grid>
			</Grid>

			<Grid container spacing={2} style={{ display: "flex", alignItems: "center", marginTop: "12px", flexWrap: "wrap" }}>
				{carlistArray.map((k, i) => (
					<Grid item >
						<ButtonInput
							title={<><H5Text size={'20'} lineHeight='21.23' color='purple' fontFamily='Karbon_light' marginTop={'0'} style={{marginRight:'6px'}} >{k.count}</H5Text><H5Text size={'20'} lineHeight='24' fontFamily='Karbon_semiBold' marginTop={'0'}>{k.name}</H5Text> </>}
							variant="text"
							bgColor={k.is_select ? "checkboxPurple" : "transparent"}
							textColor="lightGray"
							borderRadius="50"
							fontSize="20"
							border={k.is_select ? '1px solid #D4BDE0' : '1px solid #E2E2E2'}
							icon={ArrowLeftIcon}
							minWidth='auto'
							padding="12px 16px"
							onClick={() => handleClick(k)}
						/>
					</Grid>
				))}
			</Grid>
		</WrapperDiv>
	);
}

export default ProductDetails;