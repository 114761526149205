import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';



function CommonModal(props) {
  const isSmallScreen = useMediaQuery('(max-width:1200px)');
  const style = {
    width:'100%',
    height:'fit-content',
    maxWidth: 1260,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
  };
  return (
    <Modal
    sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			overflow: 'scroll',
			'@media (max-width: 900px)': {
			  alignItems: 'flex-start !important', // Adjust for xs breakpoint
			},
		  }}
      open={props?.modalActive}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        {props.innerContent}
      </Box>
    </Modal>
  );
}
CommonModal.defaultProps = {
  width: 862,
  padding:'32'
};
export default CommonModal;