import { takeLatest, put } from "redux-saga/effects";
import {
	CREATE_PASSWORD,
	CREATE_PASSWORD_ERROR,
	CREATE_PASSWORD_SUCCESS,
} from "./constant";
import url from "utils/services/urls.json";
import { postMethod } from "utils/services/apis";
import { ClearTokenRedirectLogin } from "utils/general";
import { unsetClient } from "store/token/action";
import LocalStorageManager from "managers/LocalStorageManger";

function* createPassword(action) {
	try {
		const response = yield postMethod(url.userCreatePassword, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: CREATE_PASSWORD_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: CREATE_PASSWORD_ERROR, payload: response });
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}

	} catch (error) {
		yield put({ type: CREATE_PASSWORD_ERROR, payload: error });
	}
};
function* createPasswordSaga() {
	yield takeLatest(CREATE_PASSWORD, createPassword);
};

export default createPasswordSaga;
