import React, { useEffect, useState, useContext } from "react";
import styled from 'styled-components';
import MuiCards from "components/MuiCards/";
import Grid from '@mui/material/Grid';
import Text from "components/Text";
import PhoneIcon from "assets/images/Account/PhoneIcon.png";
import DeleteIcon from "assets/icons/Trash.png";
import { accountCallAPI } from "store/account/action";
import CircularProgress from '@mui/material/CircularProgress';
import { ACCOUNT_REGISTER_DEVICE } from "store/account/constant";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import H5Text from "components/Text/H5Text";
import { LocaleContext } from 'routes/Router';
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StyleWrapper = styled.div`
    align-items: center;
    margin:${props => props.margin};

    .application_top{
        display:flex;
        align-items:center;
    };

    .isoDotShow{
        margin-left: -13px;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        margin-top: 32px;
        background:#1D8841;
        z-index:1;
    };

`

const AccountRegisterDevice = () => {
    const [isMobile, setIsMobile] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const deviceListData = useSelector((state) => (state.accountReducer && state.accountReducer?.payload?.data));
    const AccountError = useSelector((state) => (state.accountReducer?.error?.data?.message));
    const loader = useSelector((state) => state.accountReducer?.loading);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getAccountAPI = () => {
        dispatch(accountCallAPI(ACCOUNT_REGISTER_DEVICE, {}, { navigate: navigate }));
    };

    useEffect(() => {
        getAccountAPI();
    }, []);

    return (
        <StyleWrapper margin={isMobile ? '0%' : locale === 'en' ? '2% 0% 2% 6%' : '2% 10% 2% 0%'}>
            <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily="Karbon_semiBold">
                <FormattedMessage id="account.registeredDevices" />
            </H5Text>

            {!loader ? (
                <>
                    {deviceListData?.data?.length > 0 && deviceListData?.data.map((k) => (
                        <Grid container marginTop={'20px'}>
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                                <MuiCards borderRadius="20" border="1px solid #3F1956" backgroundSize="cover" minHeight="250" bgColor="white">

                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <div style={{ display: "flex" }}>
                                                <div>
                                                    <img src={PhoneIcon} alt='icon' />
                                                </div>
                                                {k?.isActiveSession ?
                                                    <span className="isoDotShow" />
                                                    : ""
                                                }
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} alignItems={'center'}>
                                            <Text color="purple" size="24" marginTop="0" fontWeight="800" lineHeight="25.86">
                                                {k?.device_Name ? k?.device_Name : ""}
                                            </Text>
                                            <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                                                <Text color="darkPrimary" size="18" fontWeight="600" marginTop="0" lineHeight="21.6">
                                                    {"Version:"}
                                                </Text>
                                                <Text color="darkPrimary" size="18" fontWeight="700" marginTop="0" lineHeight="21.6">
                                                    {k?.device_Version ? k?.device_Version : ""}
                                                </Text>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} textAlign={'right'}>
                                            <img src={DeleteIcon} alt='icon' />
                                        </Grid>
                                    </Grid>

                                    <Grid container marginTop={'10px'}>
                                        <Grid item xs={6}>
                                            <Text color="darkPrimary" size="16" fontWeight="600" marginTop="0" lineHeight="17">
                                                <FormattedMessage id="platform" />:
                                            </Text>
                                        </Grid>
                                        <Grid item xs={6} textAlign={'right'}>
                                            <Text color="darkPrimary" size="16" fontWeight="700" TextAlign="right" marginTop="0" lineHeight="19.2">
                                                {k?.os_Type ? k?.os_Type : ""}
                                            </Text>
                                        </Grid>
                                    </Grid>

                                    <Grid container marginTop={'10px'}>
                                        <Grid item xs={6}>
                                            <Text color="darkPrimary" size="16" fontWeight="600" marginTop="0" lineHeight="17">
                                                <FormattedMessage id="uniqueDeviceID" />:
                                            </Text>
                                        </Grid>
                                        <Grid item xs={6} textAlign={'right'}>
                                            <Text color="darkPrimary" size="16" fontWeight="700" TextAlign="right" marginTop="0" lineHeight="19.2">
                                                {k?.device_Id ? k?.device_Id : ""}
                                            </Text>
                                        </Grid>
                                    </Grid>

                                    <Grid container marginTop={'10px'}>
                                        <Grid item xs={6}>
                                            <Text color="darkPrimary" size="16" fontWeight="600" marginTop="0" lineHeight="17">
                                                <FormattedMessage id="IMEINumber" />:
                                            </Text>
                                        </Grid>
                                        <Grid item xs={6} textAlign={'right'}>
                                            <Text color="darkPrimary" size="16" fontWeight="700" TextAlign="right" marginTop="0" lineHeight="19.2">
                                                {k?.device_IMEI ? k?.device_IMEI : ""}
                                            </Text>
                                        </Grid>
                                    </Grid>

                                    <Grid container marginTop={'10px'}>
                                        <Grid item xs={6}>
                                            <Text color="darkPrimary" size="16" fontWeight="600" marginTop="0" lineHeight="18">
                                                <FormattedMessage id="deviceIdentifier" />:
                                            </Text>
                                        </Grid>
                                        <Grid item xs={6} textAlign={'right'}>
                                            <Text color="darkPrimary" size="16" fontWeight="700" TextAlign="right" marginTop="0" lineHeight="19.2">
                                                {k?.device_Type ? k?.device_Type : ""}
                                            </Text>
                                        </Grid>
                                    </Grid>

                                    <Grid container marginTop={'10px'}>
                                        <Grid item xs={6}>
                                            <Text color="darkPrimary" size="16" fontWeight="600" marginTop="0" lineHeight="18">
                                                <FormattedMessage id="token" />:
                                            </Text>
                                        </Grid>
                                        <Grid item xs={6} textAlign={'right'}>
                                            <Text color="darkPrimary" size="16" fontWeight="700" TextAlign="right" marginTop="0" lineHeight="19.2">
                                                {"******"}{k?.fcm_Token ? (k?.fcm_Token).substr(k?.fcm_Token.length - 4) : ""}
                                            </Text>
                                        </Grid>
                                    </Grid>

                                </MuiCards>
                            </Grid>
                        </Grid >
                    ))}
                </>
            ) : (
                <Grid container marginTop={'20px'}>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                        <MuiCards borderRadius="20" border="1px solid #3F1956" backgroundSize="cover" minHeight="250" bgColor="white">

                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Skeleton animation="wave" height={40} width={40} marginLeft={20} />
                                </Grid>
                                <Grid item xs={6} alignItems={'center'}>
                                    <Skeleton animation="wave" height={20} />
                                    <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                                        <Skeleton animation="wave" height={20} width={100} marginLeft={20} />
                                        <Skeleton animation="wave" height={20} width={100} marginLeft={20} />
                                    </div>
                                </Grid>
                                <Grid item xs={3} display="flex" justifyContent={'end'}>
                                    <Skeleton animation="wave" height={20} width={20} />
                                </Grid>
                            </Grid>

                            <Grid container marginTop={'10px'}>
                                <Grid item xs={6}>
                                    <Skeleton animation="wave" height={20} width={100} marginLeft={20} />
                                </Grid>
                                <Grid item xs={6} textAlign={'right'}>
                                    <Skeleton animation="wave" height={20} />
                                </Grid>
                            </Grid>

                            <Grid container marginTop={'10px'}>
                                <Grid item xs={6}>
                                    <Skeleton animation="wave" height={20} width={100} marginLeft={20} />
                                </Grid>
                                <Grid item xs={6} textAlign={'right'}>
                                    <Skeleton animation="wave" height={20} />
                                </Grid>
                            </Grid>

                            <Grid container marginTop={'10px'}>
                                <Grid item xs={6}>
                                    <Skeleton animation="wave" height={20} width={100} marginLeft={20} />
                                </Grid>
                                <Grid item xs={6} textAlign={'right'}>
                                    <Skeleton animation="wave" height={20} />
                                </Grid>
                            </Grid>

                            <Grid container marginTop={'10px'}>
                                <Grid item xs={6}>
                                    <Skeleton animation="wave" height={20} width={100} marginLeft={20} />
                                </Grid>
                                <Grid item xs={6} textAlign={'right'}>
                                    <Skeleton animation="wave" height={20} />
                                </Grid>
                            </Grid>

                            <Grid container marginTop={'10px'}>
                                <Grid item xs={6}>
                                    <Skeleton animation="wave" height={20} width={100} marginLeft={20} />
                                </Grid>
                                <Grid item xs={6} textAlign={'right'}>
                                    <Skeleton animation="wave" height={20} />
                                </Grid>
                            </Grid>

                        </MuiCards>
                    </Grid>
                </Grid >
            )}

        </StyleWrapper >
    );
};

export default AccountRegisterDevice;
