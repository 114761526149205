import {
    USER_LOGIN, USER_LOGIN_INITIAL_STATE
} from "./constant";

export const userLoginCallAPI = (type, data, external = {}) => {
    switch (type) {
        case USER_LOGIN:
            return {
                type: type,
                data: data,
                external: external
            }
        case USER_LOGIN_INITIAL_STATE:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

