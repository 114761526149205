import { AUTH_TAHAKKUQ_ERROR, AUTH_TAHAKKUQ_SUCCESS, AUTH_TAHAKKUQ_VERIFY } from "./constant";

export const tahakukCallAPI = (type, data, external = {}) => {
    switch (type) {
        case AUTH_TAHAKKUQ_VERIFY:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
}

export const tahakukAPIStatus = (type, data) => {
    switch (type) {
        case AUTH_TAHAKKUQ_ERROR:
            return {
                type: type,
                payload: data
            }
        case AUTH_TAHAKKUQ_SUCCESS:
            return {
                type: type,
                payload: data
            }
        default:
            return {
                type: type,
            }
    }
}
