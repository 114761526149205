import React from 'react';
import { FormattedMessage } from "react-intl";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';

import Text from "components/Text";
import LabelWithInput from "components/Input/LabelWithInput";
import ButtonInput from "components/Input/ButtonInput";
import CrossIcon from "assets/images/Application/ApplicationDeatils/X.png";
import DeleteIcon from "assets/icons/Trash.png";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
};

function DocumentUploadModal({ modalActive, handleModalPopup }) {

    return (
        <Modal
            open={modalActive}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="header">
                    <Grid container spacing={2} borderBottom={'0.5px solid #D5D5D5'} marginBottom={'5px'}>
                        <Grid item xs={10} >
                            <Text size="32" marginTop="0" padding="0px" color="purple" fontWeight="700" lineHeight={'38.4'} fontFamily="Karbon_semibold">
                                <FormattedMessage id="Tax & Zatka Certificate" />
                            </Text>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent={'end'}>
                                <img src={CrossIcon} alt='icon' style={{ cursor: "pointer" }} onClick={() => handleModalPopup(false)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <div className="content">
                    <Grid container spacing={2} marginTop={'32px'}>
                        <Grid item xs={10} >
                            <Text size="24" marginTop="0" padding="0px" color="black" fontWeight="600" lineHeight={'28.8'} fontFamily="KarbonMedium">
                                <FormattedMessage id="abc.pdf" />
                            </Text>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent={'end'}>
                                <img src={DeleteIcon} alt='icon' style={{ cursor: "pointer" }} onClick={() => handleModalPopup(false)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </Modal>
    );
}

export default DocumentUploadModal;