import React from "react";
import { FormattedMessage } from "react-intl";

import { PasswordValidation } from "utils/general"

const CheckValidation = value => {

	const formError = {
        submit: true,
		password: {
	      	error:false,
	      	errorMessage: <FormattedMessage id='invalidPassword' /> 
		}, 
		rePassword: {
      		error:false,
      		errorMessage:<FormattedMessage id='register.InvalidreEnterPassword' /> 
    	}
  	};

  	const emptyKeys = [
  		"password",
  		"rePassword"
	];
  	
    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "password" && !PasswordValidation(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "rePassword" && !(value?.[emptyKeys[0]] === value?.[emptyKeys[1]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }
    
    return formError;
}

export default CheckValidation;