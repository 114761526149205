import { takeLatest, put } from "redux-saga/effects";
import {
	USED_CARS, USED_CARS_ERROR, USED_CARS_SUCCESS,
} from "./constant";
import url from "utils/services/urls.json";
import { getMethod } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";

function* usedCarsCall(action) {
	try {
		const response = yield getMethod(url.usedCarsList, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: USED_CARS_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: USED_CARS_ERROR, payload: response });
			if (action.external && action.external.errorStep) {
				action.external.handleStep(action.external.errorStep);
			}
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}
	} catch (error) {
		yield put({ type: USED_CARS_ERROR, payload: error });
	}
};


function* usedCarsCallSaga() {
	yield takeLatest(USED_CARS, usedCarsCall);
};

export default usedCarsCallSaga;
