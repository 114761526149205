import React from 'react';
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';

import H5Text from "components/Text/H5Text";
import SideBySideText from "components/SideBySideText";
import Text from "components/Text";
import MuiCards from "components/MuiCards";

const WrapperContent = styled.div`
	padding: 10px 15px;
`;

function FinancingResultsData({containerProps, ...props }) {
	const intl = useIntl();

	return (
		<MuiCards borderRadius="16" boxShadow={false} padding="0">
			<H5Text bgColor="secondary" color="lightDark" borderRadius="8" marginTop="0" padding="8px 16px">
				<FormattedMessage id={props.title} />
			</H5Text>

			<CardContent>
				<Grid container>
					<Grid item xs={12}>
						<SideBySideText 
							leftText={<FormattedMessage  id="monthlyPayment" />}
							RightText="13,000" 
							subText={<FormattedMessage  id="sar" />}
							leftFontFamily="Karbon_semibold" 
							leftTextColor="green" 
							rightTextColor="green" 
							leftFontWight="800" 
							size="20" 
							border={true} 
							borderColor="green" 
						/>

						<SideBySideText 
							leftText={<FormattedMessage  id="financePeriod" />}
							RightText="60 Months" 
							leftTextColor="dark" 
							rightTextColor="lightPrimary" 
							leftFontWight="600" 
							size="18" 
						/>

						<SideBySideText 
							leftText={<FormattedMessage  id="residualValue" />}
							RightText="30,000 SAR" 
							leftTextColor="dark" 
							rightTextColor="lightPrimary" 
							leftFontWight="600" 
							size="18"  
						/>

						<SideBySideText 
							leftText={<FormattedMessage  id="downPayment" />} 
							RightText="0 SAR" 
							leftTextColor="dark" 
							rightTextColor="lightPrimary" 
							leftFontWight="600" 
							size="18" 
						/>

						<SideBySideText 
							leftText={<FormattedMessage  id="requiredPayment" />} 
							RightText="13,191 SAR" 
							leftTextColor="dark" 
							rightTextColor="lightPrimary" 
							leftFontWight="600" 
							size="18"  
						/>

						<SideBySideText 
							leftText={<FormattedMessage  id="totalVAT" />} 
							RightText="10,351 SAR" 
							leftTextColor="dark" 
							rightTextColor="lightPrimary" 
							leftFontWight="600" 
							size="18"  
						/>

						<SideBySideText 
							leftText={<FormattedMessage  id="issueExpenses" />} 
							RightText="862 SAR" 
							leftTextColor="dark" 
							rightTextColor="lightPrimary" 
							leftFontWight="600" 
							size="18"  
						/>
					</Grid>
					<Grid item xs={12}>
						<Text fontWeight="800" fontFamily="KarbonSemiBold" size="16" color="lightDark">
							<FormattedMessage id="totalCostVATIncluded" /> : 144,844 <FormattedMessage id="sar" /> , <FormattedMessage id="annualRate" /> 16%
						</Text>
					</Grid>
					<Grid item xs={12}>
						<Text size="14" color="lightDark">
							<FormattedMessage id="autoFinance.desclaimer" />
						</Text>
					</Grid>
				</Grid>
			</CardContent>
		</MuiCards>
	);
}

FinancingResultsData.propTypes = {
    bgColor: string.isRequired,
};

export default FinancingResultsData;