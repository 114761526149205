import React, { useContext } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import DateIcon from "assets/images/icon/date.png";
import TimeIcon from "assets/images/icon/time.png";
import Text from 'components/Text';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { LocaleContext } from 'routes/Router';

const StyleWrapper = styled.div`

    .accordian_inner_style{
        display: flex;
        align-items: center;
    };

    .MuiStepConnector-root {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        margin-left: 28px;
    };

    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
        height: 60px;
        width: 60px;
        border-radius: 50%;
    };

    .MuiStepLabel-iconContainer {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding-right: 28px;
    }

    .MuiStepConnector-line {
        display: block;
        border-color: #DDDDDD;
        border-left-style: solid;
        border-left-width: 3px;
        min-height: 35px;
    };

    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
        color: #EB9A21;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background: transparent;
        padding:3px;
        border:3px solid #efefef;
        border-top-color: #EB9A21;
        border-right-color: #EB9A21;
        border-bottom-color: #EB9A21;
        border-left-color: transparent;  
    };

    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
        color: #ffffff;
        height: 60px;
        width: 60px;
        background:#8BD086;
        border:2px solid #8BD086;
    };
`

const steps = [
    { name: <FormattedMessage id="informationverification"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon, }, { name: <FormattedMessage id="quotationApproval"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon },
    { name: <FormattedMessage id="applicationProcessing"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon }, { name: <FormattedMessage id="applicationApproval"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon },
    { name: <FormattedMessage id="softReservingVehicle"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon }, { name: <FormattedMessage id="waitingApplicationMoney"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon },
    { name: <FormattedMessage id="paymentReceived"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon }, { name: <FormattedMessage id="hardReservingVehicle"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon },
    { name: <FormattedMessage id="issueDigitalPromissoryNotes"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon }, { name: <FormattedMessage id="issueDigitalContract"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon },
    { name: <FormattedMessage id="IssueofVehicleDeliveryOrder"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon }, { name: <FormattedMessage id="vehicleDelivery"/>, date: "12/09/23", dateIcon: DateIcon, time: "16:24", timeIcon: TimeIcon }
];



export default function VerticalLinearStepper() {
    const [activeStep, setActiveStep] = React.useState(5);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const { locale } = useContext(LocaleContext);

    return (
        <StyleWrapper>
            <Box sx={{ maxWidth: 400 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                <Text size="24" marginTop="0" color="darkPrimary" style={{display:'flex'}}>
                                    {step.name}
                                </Text>
                                <div className="accordian_inner_style">
                                    <img src={step.dateIcon} alt="logo" style={{ marginRight: locale === 'en' && "12px",  marginLeft: locale === 'ar' && "12px" }} />
                                    <Text size="20" marginTop="0px" color="lightGrey">
                                        {step.date}
                                    </Text>
                                    <img src={step.timeIcon} alt="logo" style={{ marginLeft: locale === 'en' ? "20px" : "12px", marginRight: locale === 'en' ? "12px" : "20px" }} />
                                    <Text size="20" marginTop="0px" color="lightGrey">
                                        {step.time}
                                    </Text>
                                </div>

                            </StepLabel>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </StyleWrapper>
    );
}