import * as React from 'react';
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import styled from 'styled-components'
import Box from '@mui/material/Box';
import MuiCards from 'components/MuiCards';
import CardContent from '@mui/material/CardContent';
import AnimatedImage from 'components/AnimatedImage';
import IconWithText from 'components/IconWithText';
import ColorBox from 'components/ColorBox';
import BrandImage from "assets/images/Application/Monogram.png";
import carTransprent from "assets/images/carTransprent.png";
import redcar from "assets/images/redcar.png";
import Text from "components/Text";
import SpanText from "components/Text/SpanText";
import ButtonInput from "components/Input/ButtonInput";
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import UnionImg from 'assets/images/icon/Union.png';
import PercentImg from 'assets/images/icon/percent.png';
import { PriceFomate } from 'utils/general';
import MuiDrawer from 'components/MuiDrawer';

import Drawer from '@mui/material/Drawer';	
import H5Text from "components/Text/H5Text";

import { getFinanceCalculatorURL, getVehicleComparisonURL } from "routes/AppUrls"

const DrawerStyled = styled(Drawer)(({ theme, padding }) => ({
  	'& .MuiDrawer-content': {
    	width: '838px',
  	},
  	'& .MuiDrawer-backdrop': {
    	backdropFilter: 'none',
  	},
  	'& .close': {
    	display: 'inline-block',
    	position: 'absolute',
    	right: '4.5%',
    	marginTop: '6.8%',
  	},
  	'& .container': {
    	padding: padding ? padding : '3% 3% 3% 3%',
    	width: '838px',
  	},
  	'@media (max-width: 1000px)': {
    	'& .container': {
      	padding: '3% 3% 3% 3%', // Use default or provided padding
      	margin: '0',
    	},
  	},
  	'@media (max-width: 991px)': {
    	'& .MuiDrawer-content': {
      	width: '100% !important',
    	},
    	'& .close': {
      	marginTop: '5.8%',
    	},
  	},
}));

const IconGroup = styled.div`
	position: ${(props) => props.listtype ? "" : "absolute"}; 
	right: 0;
	display: flex;
	justify-content: end;
	padding: 10px 20px 0 0;
	border-radius: 12px;
	max-height:25%;
	z-index: 9;
`;

const IconStyle = styled.div`
	background:${(props) => props?.background};
	border-radius:50%;
	min-width:40px;
	min-height:40px;
	max-width:40px;
	max-height:40px; style={{margin:'0'}}
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:${(props) => props?.marginleft};
`;

function CardList({ viewFlag, carData, index, locale, vehicalCartList, handleCartValue }) {
	const [open, setOpen] = React.useState(false);

  	const toggleDrawer = (inOpen) => (event) => {
    	if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   		return;
    	}

    	setOpen(inOpen);
  	};

	const navigate = useNavigate();

	const colorPosition = viewFlag ? "end" : "center";
	const GridRightPadding = viewFlag ? "15px" : "25px 15px";

  	const handleCartQuantity = (color, ModelId) => {
  		const vlist = [...vehicalCartList];
  		vlist.push({"color": color, "ModelId": ModelId, "quantity": 1});
  		handleCartValue(vlist);
  	}

  	const handleCartQuantityIncrement = (color, ModelId) => {
  		let index = cartValuesIndexCheck(vehicalCartList, color, ModelId);
  		const vlist = [...vehicalCartList];
  		vlist[index].quantity = vlist[index].quantity + 1;
  		handleCartValue(vlist);
  	}

  	const handleCartQuantityDecrement = (color, ModelId) => {
  		let index = cartValuesIndexCheck(vehicalCartList, color, ModelId);
  		const vlist = [...vehicalCartList];

  		if(vlist[index].quantity > 0) {
  			vlist[index].quantity = vlist[index].quantity - 1;
  			handleCartValue(vlist);
  		}
  	}

	function cartValuesExist(multiArray, color, ModelId) {
  		return multiArray.some(
    		(obj) => obj.color === color && obj.ModelId === ModelId
  		);
	}	

	function cartValuesIndexCheck(multiArray, color, ModelId) {
  		return multiArray.findIndex(
    		(obj) => obj.color === color && obj.ModelId === ModelId
  		);
	}	

	return (
		<MuiCards borderRadius="12" padding="0">
			<Grid container>
				<Grid item xs={viewFlag ? 12 : 4}>
					<Grid container>
						<Grid item xs={12} sx={{ position: "relative" }}>
							<IconGroup>
								<IconStyle background={'var(--primary)'} marginleft={'8px'}>
									<img alt="" src={PercentImg} />
								</IconStyle>
							</IconGroup>
							<Box style={{ transform: locale === 'ar' ? 'scaleX(-1)' : '' }}>
								<AnimatedImage src={carData?.ModelImage} minHeight={viewFlag ? "100%" : "265px"} />
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={viewFlag ? 12 : 8}>
					<Grid container sx={{ backgroundColor: "#FEF7FF", padding: GridRightPadding,height:'100%' }}>
						<Grid item xs={12} >
							<IconWithText
								text={carData?.BrandDescription + " " + carData?.ProductTypeDescription + " " + carData?.YearModel}
								secondText={carData?.ModelDescriptionEn}
								textSize={viewFlag ? 20 : 32}
								textLineHeight={viewFlag ? 30 : 48}
								textColor="lightDark"
								secondTextSize={viewFlag ? 16 : 24}
								secondLineHeight={viewFlag ? 22 : 62}
								secondTextColor="lightDark"
								BrandIcon={carData.BrandIcon ? carData.BrandIcon : BrandImage}
								variant={'rounded'}
								BrandSize={viewFlag ? 40 : 52}
								bgColor="secondaryPink"
							/>
						</Grid>

						<Grid item xs={viewFlag ? 12 : 8}>
							<Grid container>
								{!viewFlag &&
									<Grid item xs={1}>
									</Grid>
								}
								<Grid item xs={viewFlag ? 6 : 5} sx={{ display: "inline-grid", justifyContent: "start" }}>
									<Text size={viewFlag ? 16 : 20} color="lightDark" lineHeight={viewFlag ? 16 : 32}>
										<FormattedMessage id="availableAt" />
									</Text>
									<Text size={viewFlag ? 32 : 42} color="lightDark" lineHeight={viewFlag ? 32 : 32} fontWeight="800" fontFamily="KarbonSemiBold">
										{PriceFomate(carData?.PriceShow)}
										<SpanText style={{ marginLeft: "5px" }} size={viewFlag ? 20 : 40} color="lightDark" lineHeight={viewFlag ? 20 : 40}>SAR</SpanText>
									</Text>
								</Grid>
								<Grid item xs={6} sx={{ display: "inline-grid", justifyContent: colorPosition }}>
									<Text size={viewFlag ? 16 : 20} color="lightDark" lineHeight={viewFlag ? 16 : 32}>
										<FormattedMessage id="colors" />
									</Text>

									<div style={{ display: "flex" }}>
										{carData.AvailColorsIn.length > 0 && carData.AvailColorsIn.map((data, index) => {
											return (
												<>
													{index < 2 &&
														<ColorBox style={{ border: "1px solid" }} width={viewFlag ? 28 : 36} color={'#' + data.SupplierColourCode} />
													}
												</>
											)
										})}
										<Text size={viewFlag ? 16 : 20} lineHeight="16" color="black">
											+4
										</Text>
									</div>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={viewFlag ? 12 : 12} xl={viewFlag ? 12 : 4} sx={{ marginTop: "20px" }}>
							<Box component="form" sx={{ display: "flex", justifyContent: locale === 'en' ? 'end' : 'end' }}>
								<Stack spacing={2} direction="row">
									<ButtonInput
										title={<FormattedMessage id="CompareCar" />}
										variant="contained"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										size={viewFlag ? "14" : "18"}
										padding={viewFlag ? "10px 16px" : "14px 32px"}
										minWidth={viewFlag ? "140" : "190"}
										height={viewFlag ? "40" : "50"}
										borderColor="lightPink"
										startIcon={<AddIcon sx={{ marginLeft: '8px', fontSize: "12px", color: "#3F1956" }} />}
										marginLeft={locale === 'en' ? "0" : "0"}
										onClick={() => navigate(getVehicleComparisonURL(locale))}
									/>
									<ButtonInput
										title={<FormattedMessage id="Select" />}
										variant="contained"
										bgColor="yellow"
										textColor="darkPrimary"
										borderRadius="100"
										TextTransform="capitalize"
										size={viewFlag ? "14" : "18"}
										padding={viewFlag ? "10px 16px" : "14px 32px"}
										minWidth={viewFlag ? "140" : "190"}
										height={viewFlag ? "40" : "50"}
										marginLeft={locale === 'en' ? "16" : "0"}
										marginRight={locale === 'en' ? "0" : "16"}
										onClick={toggleDrawer(true)}
									/>
								</Stack>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<DrawerStyled
	           	anchor={'right'}
    			open={open}
    			onClose={toggleDrawer(false)}
          	>
            	<Box className="container">
            		<Grid container spacing={2}>
            			<Grid item xs="12">
            				<H5Text color="primary" size="40" lineHeight="48" fontFamily="Karbon_semiBold">
								<FormattedMessage id="Available Colors" />
							</H5Text>
            			</Grid>

            			<Grid item xs="6">
            				<AnimatedImage src={carTransprent} minHeight={viewFlag ? "100%" : "265px"} />
            			</Grid>

            			<Grid item xs="12">
            				<IconWithText 
								boxShadow={false} 
								BrandIcon={BrandImage} 
								variant={'rounded'} 
								BrandSize={48} 
								text="Kia Sonet X-Line" 
								textSize="24"
								secondText="SAR 20,000" 
								secondTextSize="20"
								secondFontWeight="Karbon_semibold"
								thirdText="2021 GU M/R 4x215" 
								thirdTextSize="18"
								thirdTextColor="gray"
							/>
            			</Grid>
            		</Grid>

            		<Grid container spacing={2}>
            			<Grid item xs="12">
            				<Text color="purple" size="24" lineHeight="28" fontFamily="KarbonLight" fontWeight="700">
								<FormattedMessage id="Select the no, of vehicles" />
							</Text>
            			</Grid>

            			{carData.AvailColorsIn && carData.AvailColorsIn.length > 0  && carData.AvailColorsIn.map((key, idx) => {
            				return (
	            				<Grid item xs="6">
		            				<div style={{display: "flex"}}>
		            					<AnimatedImage style={{width: "130px", height: "100px"}} src={redcar} />
		            					{cartValuesExist(vehicalCartList, key.SupplierColourCode, carData.ModelId) ? (
		            						<div style={{display: "flex", marginTop: "25px", marginLeft: "25px"}}>
			            						<ButtonInput 
				  										title="-"
				  										variant="text"
				  										bgColor="transparent"
				  										textColor="purple"
				  										borderRadius="0"
				  										minWidth="24"
				  										height="24"
				  										onClick={() => handleCartQuantityDecrement(key.SupplierColourCode, carData.ModelId)}
													/>
				  									<div>{vehicalCartList[cartValuesIndexCheck(vehicalCartList, key.SupplierColourCode, carData.ModelId)].quantity}</div>
				  									<ButtonInput 
				  										title="+"
				  										onClick={() => handleCartQuantityIncrement(key.SupplierColourCode, carData.ModelId)}
				  										variant="text"
				  										bgColor="transparent"
				  										textColor="purple"
				  										borderRadius="0"
				  										size="24"
				  										minWidth="24"
				  										height="24"
													/>
			            					</div>
		            					) : (
		            						<div style={{display: "flex", marginTop: "15px", marginLeft: "25px"}}>
			            						<ButtonInput 
				  										title="Add"
				  										variant="text"
				  										bgColor="purple"
				  										textColor="white"
				  										borderRadius="6"
				  										minWidth="136"
				  										height="42"
				  										size="18"
				  										onClick={() => handleCartQuantity(key.SupplierColourCode, carData.ModelId)}
													/>
			            					</div>
		            					)}
		            				</div>
		            			</Grid>
	            			)
            			})}
            		</Grid>

            		<Grid container spacing={2}>
            			<Grid item xs="12">
            				<Box component="form" sx={{ display: "flex", justifyContent: locale === 'en' ? 'end' : 'end' }}>
								<Stack spacing={2} direction="row">
									<ButtonInput
										title={<FormattedMessage id="Add Selected Cars" />}
										variant="contained"
										bgColor="yellow"
										textColor="darkPrimary"
										borderRadius="100"
										TextTransform="capitalize"
										size={viewFlag ? "14" : "18"}
										padding={viewFlag ? "10px 16px" : "14px 32px"}
										minWidth={viewFlag ? "140" : "190"}
										height={viewFlag ? "40" : "50"}
										marginLeft={locale === 'en' ? "16" : "0"}
										marginRight={locale === 'en' ? "0" : "16"}
										onClick={toggleDrawer(false)}
									/>
								</Stack>
							</Box>
            			</Grid>
            		</Grid>
            	</Box>
          	</DrawerStyled>
		</MuiCards>
	)
}

export default CardList;