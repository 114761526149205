import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import H5Text from 'components/Text/H5Text';

const FormControlWrapper = styled(FormControl)`
	${(props) =>
		props.isBackground && `
 	    background: ${props.theme[props.bgColor]};
		border-radius: 8px;
		padding: 8px 16px !important;
		margin-top: 20px !important;
    `}
	.MuiFormControlLabel-root{
		margin-right:0;
	}
`;

const WrapperLabel = styled.p`
	font-size: ${(props) => (props.size + "px")};
	color: ${(props) => (props.theme.darkPrimary)};
	margin: 0px;
	margin-left: 5px;
	font-family: ${(props) => (props.theme.KarbonLight)};
	font-weight: ${(props) => (props.fontWeight)};
	line-height: 22px;
	
`;

const WrapperSpan = styled.span`
	color: ${(props) => (props.theme.dark)};
	font-weight: 800;
	display: block;
	font-size: 13px;
`;

function CheckboxInput({ containerProps, ...props }) {
	return (
		<FormControlWrapper {...props}>
			<FormControlLabel
				control={
					<Checkbox
						defaultChecked sx={{
							color: props.checkColor,
							borderRadius: '10px',
							transform: "scale(1.3)",
							'&.Mui-checked': {
								color: "#3F1956",
							},
						}}
						disableRipple
						checked={props.checked}
					/>
				}
				label={
					<WrapperLabel {...props}>
					
								{props.label}

						{props.subLabel &&
							<WrapperSpan>
								{props.subLabel}
							</WrapperSpan>
						}
					</WrapperLabel>
				}
				
			/>
		</FormControlWrapper>
	);
}

CheckboxInput.defaultProps = {
	isBackground: false,
	bgColor: 'white',
	checkColor: "#3F1956"
};

export default CheckboxInput;