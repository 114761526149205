import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import H5Text from "components/Text/H5Text";
import IconWithText from "components/IconWithText";
import SelectInput from "components/Input/SelectInput";
import CheckboxInputDesignable from "components/Input/CheckboxInputDesignable";
import DiscountBadge from "components/DiscountBadge";
import ButtonInput from "components/Input/ButtonInput";

import AccordianImage from "assets/images/financing/alrajhi-takaful.png";
import discountBadge from "assets/images/financing/discountBadge.png";
import discountIcon from "assets/images/financing/discountIcon.png";

const WrapperDiv = styled.div`
`;

const WrapperAccordion = styled(Accordion)`
	border-radius: 8px !important;
	border: 1px solid ${(props) => props.theme.gray} !important;
	box-shadow: none !important;
	padding: 0px 15px !important;
`;

const WrapperAccordionSummary = styled(AccordionSummary)`
	margin: 0 !important;
`;

function RevisedFinancingForm({handleCurStep}) {
	const location = useLocation();
	const intl = useIntl();

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage  id={'Insurance Details'} />
					</H5Text>

					<Box sx={{ marginTop: "30px" }}>
						<Grid container>
							<Grid item xs={12}>
								<WrapperAccordion expanded={true}>
									<WrapperAccordionSummary
										expandIcon={<ExpandMoreIcon />}
							          	aria-controls="alrajhi-takaful"
							          	id="alrajhi-takaful"
									>
										<IconWithText 
											boxShadow={false} 
											BrandIcon={AccordianImage} 
											text="Alrajhi Takaful"
											textSize="18"
											secondText="Insurance Price VAT 344 SAR" 
											secondTextSize="18"
											secondFontWeight="Karbon_light"
											thirdText="4500"
											thirdTextSize="24"
											thirdTextColor="lightDark"
											variant={'rounded'} 
											BrandSize={80} 
											marginBottom="0"
										/>
									</WrapperAccordionSummary>
									<AccordionDetails>
						            	<Grid container spacing={2}>
						            		<Grid item xs={6}>
							            		<SelectInput 
													label="Deductible" 
													placeholder="Deductible" 
												/>
											</Grid>
											<Grid item xs={6}>
											</Grid>

											<Grid item xs={12}>
												<CheckboxInputDesignable heading="Free Services" label="Own Damage" />
												<CheckboxInputDesignable label="Third Party Liability" />
											</Grid>
											<Grid item xs={12}>
												<CheckboxInputDesignable heading="Paid Services" label="Third Party Liability" />
												<CheckboxInputDesignable label="Personal accident for driver & passengers- 50,000 SAR" />
												<CheckboxInputDesignable label="Care Replacement (Hire Car)- 325,000 SAR" />
												<CheckboxInputDesignable label="Geo-graphical for all GCC 150.00 SAR" />
											</Grid>

											<Grid item xs={12}>
												<DiscountBadge heading="Discounts Applied" bgImage={discountBadge} icon={discountIcon} />
												<DiscountBadge bgImage={discountBadge} icon={discountIcon} />
												<DiscountBadge bgImage={discountBadge} icon={discountIcon} />
											</Grid>
						            	</Grid>
							        </AccordionDetails>
								</WrapperAccordion>
							</Grid>
							<Grid item xs={12} style={{marginTop:"30px"}}>
								<WrapperAccordion>
									<WrapperAccordionSummary
										expandIcon={<ExpandMoreIcon />}
							          	aria-controls="alrajhi-takaful"
							          	id="alrajhi-takaful"
									>
										<IconWithText 
											boxShadow={false} 
											BrandIcon={AccordianImage} 
											text="Alrajhi Takaful"
											textSize="18"
											secondText="Insurance Price VAT 344 SAR" 
											secondTextSize="18"
											secondFontWeight="Karbon_light"
											thirdText="4500"
											thirdTextSize="24"
											thirdTextColor="lightDark"
											thirdSubText="SAR"
											variant={'rounded'} 
											BrandSize={80} 
											marginBottom="0"
										/>
									</WrapperAccordionSummary>
									<AccordionDetails>
							            	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
							            	malesuada lacus ex, sit amet blandit leo lobortis eget.
							        </AccordionDetails>
								</WrapperAccordion>
							</Grid>
							<Grid item xs={12} style={{marginTop:"30px"}}>
								<WrapperAccordion>
									<WrapperAccordionSummary
										expandIcon={<ExpandMoreIcon />}
							          	aria-controls="alrajhi-takaful"
							          	id="alrajhi-takaful"
									>
										<IconWithText 
											boxShadow={false} 
											BrandIcon={AccordianImage} 
											text="Alrajhi Takaful"
											textSize="18"
											secondText="Insurance Price VAT 344 SAR" 
											secondTextSize="18"
											secondFontWeight="Karbon_light"
											thirdText="4500"
											thirdTextSize="24"
											thirdTextColor="lightDark"
											thirdSubText="SAR"
											variant={'rounded'} 
											BrandSize={80} 
											marginBottom="0"
										/>
									</WrapperAccordionSummary>
									<AccordionDetails>
						            	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
						            	malesuada lacus ex, sit amet blandit leo lobortis eget.
							        </AccordionDetails>
								</WrapperAccordion>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container style={{marginTop: "30px"}}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput 
									title="Back"
									variant="text" 
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(0)}
								/>
								<ButtonInput 
									title="Save & Proceed to Personal & Work"
									variant="contained" 
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									onClick={() => handleCurStep(2)}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default RevisedFinancingForm;