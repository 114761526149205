import {
    CAR_GOSI,
    CAR_PENSION,
    CAR_INSURANCE_QUOTE,
    FINANCING_SUCCESS,
    FINANCING_ERROR,
    FINANCE_CALCULATOR,
    MANUAL_DBR,
    EMI_CALCULATOR,
    EXPENSE_BANK_VALIDATION,
    YAKEEN_SEARCH_NONSAUDI_DATA,
    YAKEEN_SEARCH_SAUDI_DATA
} from "./constant";


const financingReducer = (state = {}, action) => {
    switch (action.type) {
        case CAR_GOSI:
            return { ...state, loading: true, error: '' };
        case CAR_PENSION:
            return { ...state, loading: true, error: '' };
        case CAR_INSURANCE_QUOTE:
            return { ...state, loading: true, error: '' };
        case YAKEEN_SEARCH_NONSAUDI_DATA:
            return { ...state, loading: true, error: '' };
        case YAKEEN_SEARCH_SAUDI_DATA:
            return { ...state, loading: true, error: '' };
        case FINANCE_CALCULATOR:
            return { ...state, loading: true, error: '' };
        case MANUAL_DBR:
            return { ...state, loading: true, error: '' };
        case EMI_CALCULATOR:
            return { ...state, loading: true, error: '' };
        case EXPENSE_BANK_VALIDATION:
            return { ...state, loading: true, error: '' };
        case FINANCING_ERROR:
            return { ...state, type: FINANCING_ERROR, loading: false, error: action.payload };
        case FINANCING_SUCCESS:
            return { ...state, type: FINANCING_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default financingReducer;