import {
    AUTH_LOGIN,
    AUTH_OTP_VERIFY,
    AUTH_SET_PASSWORD,
    AUTH_CREATE_PASSWORD,
    AUTH_USER_VERIFY,
    AUTH_INITIAL_STATE,
    AUTH_API_ERROR_RESET,
    AUTH_WATHIQ_VERIFY
} from "./constant";

export const authCallAPI = (type, data, external = {}) => {
    switch (type) {
        case AUTH_USER_VERIFY:
            return {
                type: type,
                data: data,
                external: external
            }
        case AUTH_LOGIN:
            return {
                type: type,
                data: data,
                external: external
            }
        case AUTH_OTP_VERIFY:
            return {
                type: type,
                data: data,
                external: external
            }
        case AUTH_SET_PASSWORD:
            return {
                type: type,
                data: data,
                external: external
            }
            case AUTH_WATHIQ_VERIFY:
                return {
                    type: type,
                    data: data,
                    external: external
                }    
        case AUTH_CREATE_PASSWORD:
            return {
                type: type,
                data: data,
                external: external
            }
        case AUTH_INITIAL_STATE:
            return {
                type: type,
                data: data,
                external: external
            }
        case AUTH_API_ERROR_RESET:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

