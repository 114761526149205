import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getFinancingPersonalInfoURL } from "routes/AppUrls";
import { LocaleContext } from 'routes/Router';

import layoutImage from "assets/images/LayoutImage/layoutImage.png";

import H1Text from "components/Text/H1Text";
import H5Text from "components/Text/H5Text";
import Text from "components/Text";
import SpanText from "components/Text/SpanText";
import CheckBoxInput from "components/Input/CheckboxInput";
import SwitchWithLabel from "components/Input/SwitchWithLabelInput";
import ButtonInput from "components/Input/ButtonInput";
import LeftBarImage from "components/LeftBarImage";
import BoxSmallCard from "components/Box/SmallCard";

import LicenseLineImage from "assets/images/financing/clarity_license-line.png";

export const WrapperArrowBackIcon = styled(ArrowBackIcon)`
    width: "24px";
    height: "20px";
    color: ${(props) => props.theme.purple}
`;

const OnlineFinancing = () => {
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const [isMobile, setIsMobile] = useState(false);

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChange = () => {
        setChecked((c) => !c);
    };

    return (
        <Box>
            <Grid container spacing={{ md: 10 }}>
                <Grid item xs={12} md={5} display={{ xs: 'none', md: 'block' }}>
                    <LeftBarImage bgImage={layoutImage} width="100%" bgColor="lightPurple" />
                </Grid>

                <Grid item xs={12} md={7} style={{ marginTop: "60px" }}>
                    <Grid container padding={{ xs: "0 5px", sm: "20px", md: "" }}>
                        <WrapperArrowBackIcon fontSize="large" style={{ cursor: 'pointer', transform: locale === 'ar' ? 'scaleX(-1)' : '' }} onClick={() => navigate(-1)} />

                        <Grid item xs={12}>
                            <H1Text marginTop="40" color="primary" lineHeight="50">
                                <FormattedMessage id="autoFinance.onlineFinancing" />
                            </H1Text>
                            <Text marginTop="10" color="lightDark">
                                <FormattedMessage id="autoFinance.onlineFinancingSubText" />
                            </Text>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Text marginTop="50" color="fincinceDarkColor" size="24">
                                Lorem ipsum dolor sit amet consectetur. Pellentesque dictum tristique lacus tellus. Posuere consectetur lacus mentum et amet morbi.
                            </Text>
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: "30px" }} width='100%'>
                        <Grid item xs={!isMobile ? 8 : 12}>
                            <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                                <Box component="form">
                                    <Stack spacing={2} direction="row">
                                        <ButtonInput
                                            title="Cancel"
                                            variant="text"
                                            bgColor="transparent"
                                            textColor="purple"
                                            borderRadius="100"
                                            TextTransform="uppercase"
                                            height='59'
                                        />
                                        <ButtonInput 
                                            title="Get Started"
                                            variant="contained" 
                                            bgColor="yellow"
                                            textColor="purple"
                                            borderRadius="100"
                                            TextTransform="capitalize"
                                            endIcon={<ArrowForwardIcon />}
                                            onClick={() => navigate(getFinancingPersonalInfoURL(locale))}
                                        />
                                    </Stack>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OnlineFinancing;