import {
    ACCOUNT_REGISTER_DEVICE,
    ACCOUNT_PASSWORD_CHANGE,
    ACCOUNT_INITIAL_STATE,
    ACCOUNT_AUDIT_LOG_DATA
} from "./constant";

export const accountCallAPI = (type, data, external = {}) => {
    switch (type) {
        case ACCOUNT_REGISTER_DEVICE:
            return {
                type: type,
                data: data,
                external: external
            }
        case ACCOUNT_PASSWORD_CHANGE:
            return {
                type: type,
                data: data,
                external: external
            }
        case ACCOUNT_INITIAL_STATE:
            return {
                type: type,
                data: data,
                external: external
            }
        case ACCOUNT_AUDIT_LOG_DATA:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};


