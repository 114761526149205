import React, { useState, useContext, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getFinancingURL, getFinancingCongratulationsURL } from "routes/AppUrls";

import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import YesNoInput from "components/Input/YesNoInput";
import ButtonInput from "components/Input/ButtonInput";
import DateTimePickerInput from "components/Input/DateTimePickerInput";
import UploadIcon from "assets/images/financing/UploadIcon.png";
import ThreeDotIcon from "assets/images/financing/DotThreeIcon.png";
import ExpensesModal from "components/Modals/ExpensesModal";
import DocumentUploadModal from 'features/OnlineFinancing/DocmentUploadModal';
import { LocaleContext } from 'routes/Router';
import { useDispatch, useSelector } from 'react-redux';
import { uploadDocumentCallAPI } from 'store/uploadDocument/action';
import { UPLOAD_DOCUMENT } from 'store/uploadDocument/constant';
import { uploadDocumentGosiCallAPI } from 'store/uploadDocument/uploadDocumentGosi/action';
import { UPLOAD_DOCUMENT_GOSI } from 'store/uploadDocument/uploadDocumentGosi/constant';
import { uploadDocumentVatCallAPI } from 'store/uploadDocument/uploadDocumentVat/action';
import { UPLOAD_DOCUMENT_VAT } from 'store/uploadDocument/uploadDocumentVat/constant';
import { uploadDocumentIbanCallAPI } from 'store/uploadDocument/uploadDocumentIban/action';
import { UPLOAD_DOCUMENT_IBAN } from 'store/uploadDocument/uploadDocumentIban/constant';

const WrapperDiv = styled.div`
	margin-bottom: 30px;
`;

function InitialDataForm({ handleCurStep }) {
	const intl = useIntl();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const fileInputRefTammReport = useRef(null);
	const fileInputRefBank = useRef(null);
	const fileInputRefZatka = useRef(null);
	const fileInputRefCommercial = useRef(null);
	const fileInputRefAssociation = useRef(null);
	const fileInputRefBudget = useRef(null);
	const fileInputRefIban = useRef(null);
	const fileInputRefVat = useRef(null);
	const fileInputRefGosi = useRef(null);
	const [fileName, setFileName] = useState('');
	const [isModalActive, setIsModalActive] = useState(false);
	const { locale } = useContext(LocaleContext);
	const [uploadValue, setUploadValue] = useState({
		"bank-statement": "",
		"tax-&-zatka-certificate": "",
		"commercial-activity": "",
		"article-of-association": "",
		"budget-documents": "",
		"tamm-report":""
	});

	const uploadDocumentGosiData = useSelector((state) => state.uploadDocumentGosiReducer && state.uploadDocumentGosiReducer?.payload?.data?.data);
	const uploadDocumentIbanData = useSelector((state) => state.uploadDocumentIbanReducer && state.uploadDocumentIbanReducer?.payload?.data?.data);
	const uploadDocumentVatData = useSelector((state) => state.uploadDocumentVatReducer && state.uploadDocumentVatReducer?.payload?.data?.data);

	const handleModalPopup = (value) => {
		setIsModalActive(value);
	};

	const handleUploadClick = (ref) => {
		if (ref === fileInputRefBank.current.name) {
			fileInputRefBank.current.click();
		} else if (ref === fileInputRefZatka.current.name) {
			fileInputRefZatka.current.click();
		} else if (ref === fileInputRefCommercial.current.name) {
			fileInputRefCommercial.current.click();
		} else if (ref === fileInputRefAssociation.current.name) {
			fileInputRefAssociation.current.click();
		} else if (ref === fileInputRefBudget.current.name) {
			fileInputRefBudget.current.click();
		} else if (ref === fileInputRefTammReport.current.name) {
			fileInputRefTammReport.current.click();
		}
	};

	const handleUploadIbanClick = () => {
		fileInputRefIban.current.click();
	};

	const handleUploadVatClick = () => {
		fileInputRefVat.current.click();
	};

	const handleUploadGosiClick = () => {
		fileInputRefGosi.current.click();
	};

	const handleFileChange = (e) => {
		const { name, value } = e.target;
		const file = e.target.files[0]; // Get the first selected file
		if (file) {
			setFileName(file.name); // Update the state with the file name
			setUploadValue((prev) => ({
				...prev,
				[name]: file.name
			}));
			let formData = new FormData();
			formData.append("document", e.target.files[0]);
			formData.append("document_for", name);
			formData.append("language", locale === "en" ? "eng" : "_ar");
			formData.append("ocr_required", (name === "gosi" || name === "vat" || name === "iban") ? 1 : 0);
			if (name === "gosi") {
				dispatch(uploadDocumentGosiCallAPI(UPLOAD_DOCUMENT_GOSI, formData, { navigate: navigate }));
			} else if (name === "vat") {
				dispatch(uploadDocumentVatCallAPI(UPLOAD_DOCUMENT_VAT, formData, { navigate: navigate }));
			} else if (name === "iban") {
				dispatch(uploadDocumentIbanCallAPI(UPLOAD_DOCUMENT_IBAN, formData, { navigate: navigate }));
			} else {
				dispatch(uploadDocumentCallAPI(UPLOAD_DOCUMENT, formData, { navigate: navigate }));
			}
		}
	};

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id={'autoFinance.autoLeaseInformation'} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.LEIOptional'} />}
									placeholder="845256"
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput
									label={<FormattedMessage id={'autoFinance.reasonToFinance'} />}
									placeholder="Lorem Ipsum"
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput
									label={<FormattedMessage id={'autoFinance.NoOfBranch'} />}
									placeholder="+30"
								/>
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.VATNumber'} />}
									placeholder="7896541356"
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput
									label={<FormattedMessage id={'autoFinance.noOfEmployees'} />}
									placeholder="0-49"
								/>
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.IBAN'} />}
									placeholder="0.00"
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput
									label={<FormattedMessage id={'autoFinance.noOfVehicles'} />}
									placeholder="0"
								/>
							</Grid>
							<Grid item xs={6}>
								<input
									ref={fileInputRefTammReport}
									type="file"
									name="tamm-report"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.TAMMReport'} />}
									placeholder="0.00"
									value={uploadValue?.['tamm-report']}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={(e) => handleUploadClick("tamm-report")} />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container sx={{ marginTop: "15px" }}>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id={'autoFinance.documentUpload'} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<input
									ref={fileInputRefIban}
									type="file"
									name="iban"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.IBANCertificate'} />}
									placeholder="0.00"
									value={uploadDocumentIbanData?.ocr_result?.[0]?.IbanNumber}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={handleUploadIbanClick} />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<input
									ref={fileInputRefVat}
									type="file"
									name="vat"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.VATCertificate'} />}
									placeholder="0.00"
									value={uploadDocumentVatData?.ocr_result?.[0]?.VatNumber}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={handleUploadVatClick} />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<input
									ref={fileInputRefGosi}
									type="file"
									name="gosi"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.GOSICertificate'} />}
									placeholder="0.00"
									value={uploadDocumentGosiData?.ocr_result?.[0]?.TotalMembers}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={handleUploadGosiClick} />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<input
									ref={fileInputRefZatka}
									type="file"
									name="tax-&-zatka-certificate"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.taxZatkaCertificate'} />}
									placeholder="0.00"
									value={uploadValue?.['tax-&-zatka-certificate']}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={() => handleUploadClick("tax-&-zatka-certificate")} />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<input
									ref={fileInputRefBank}
									type="file"
									name="bank-statement"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.bankStatement'} />}
									placeholder="0.00"
									value={uploadValue?.['bank-statement']}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={() => handleUploadClick("bank-statement")} />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<input
									ref={fileInputRefCommercial}
									type="file"
									name="commercial-activity"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.commercialActivityLicense'} />}
									placeholder="0.00"
									value={uploadValue?.['commercial-activity']}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={() => handleUploadClick("commercial-activity")} />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<input
									ref={fileInputRefAssociation}
									type="file"
									name="article-of-association"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.articleOfAssociation'} />}
									placeholder="0.00"
									value={uploadValue?.['article-of-association']}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={() => handleUploadClick("article-of-association")} />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<input
									ref={fileInputRefBudget}
									type="file"
									name="budget-documents"
									disabled={false}
									style={{ display: 'none' }} // Hide the file input
									onChange={handleFileChange}
								/>
								<LabelWithInput
									label={<FormattedMessage id={'autoFinance.budgetDocuments'} />}
									placeholder="0.00"
									value={uploadValue?.['budget-documents']}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px', cursor: "pointer" }} className="standard-required" onClick={(e) => handleUploadClick("budget-documents")} />
												<img src={ThreeDotIcon} onClick={() => setIsModalActive(true)} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>

						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container sx={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => navigate(getFinancingURL(locale))}
								/>
								<ButtonInput
									title={<FormattedMessage id="submit" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									onClick={() => navigate(getFinancingCongratulationsURL(locale))}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<DocumentUploadModal modalActive={isModalActive} handleModalPopup={handleModalPopup} />
				</Grid>
			</Grid>

		</WrapperDiv>
	);
}

export default InitialDataForm;