import React, { useState, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import LayoutStyleWrapper from "styles/common.styles";
import styled from 'styled-components';
import TileIcon from "assets/images/Application/tile.png";
import TileIconCard from "assets/images/Application/tile1.png";
import sortIcon from "assets/images/Application/sortbutton.png";
import { useNavigate } from "react-router-dom";
import SearchInput from "components/SearchInput";
import H1Text from "components/Text/H1Text";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LocaleContext } from 'routes/Router';
import { getApplicationDetailsURL } from "routes/AppUrls";
import { APPLICATION_LIST } from "store/application/applicationList/constant";
import { applicationListCallAPI } from "store/application/applicationList/action";
import CardApplication from "components/CardApplication";
import ApplicationListing from "components/ApplicationListing";

const StyleWrapper = styled.div`
    align-items: center;
    margin:0 2.5% 0 2.5%;
    @media screen and (max-width:576px){
        margin:0;
    }
    .application_top{
        display:flex;
        align-items:center;
        margin-top:16px;
    };

    .search{
        display:flex;
        align-items:center;
    }

    .notification{
        display:flex;
        align-items:center;
        justify-content:end;
    };
`
const headings = [
    "APPLICATION NUMBER",
    <FormattedMessage id="monthlyPayment" />,
    <FormattedMessage id="totalAmount" />,
    <FormattedMessage id="Tenure" />,
];

const Application = () => {
    const [tile, setTile] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { locale } = useContext(LocaleContext);
    const applicationList = useSelector((state) => state.applicationListReducer?.payload?.data);
    const applicationData = applicationList?.['data']?.['Result'] && JSON.parse(applicationList?.['data']?.['Result']);
    const applicationListLoader = useSelector((state) => state.applicationListReducer?.loading);
    const userDetails = useSelector((state) => (state.userReducer && state.userReducer?.payload?.data));

    const handleTile = () => {
        setTile(!tile);
    };

    const handleClick = () => {
        navigate(`/${locale}/application-details`);
        navigate(getApplicationDetailsURL(locale));
    };

    useEffect(() => {
        ApplicationList();
    }, []);

    const ApplicationList = (value) => {
        let body = {
            "ID Type": "Iqama ID",
            "ID Number": userDetails?.data?.nationalId === '1000038685' ? '2094907534' : userDetails?.data?.nationalId,
            "Entity Name": "",
            "Mobile Number": ""
        }
        dispatch(applicationListCallAPI(APPLICATION_LIST, body,{navigate: navigate}));
    };

    return (
        <LayoutStyleWrapper locale={locale} >
            <StyleWrapper>
                <Grid container className="application_top">
                    <Grid item md={12} sm={12} xs={12}>
                        <H1Text>
                            <FormattedMessage id="dashboard.applications" />
                        </H1Text>
                    </Grid>
                </Grid>

                <Grid container className="search" spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        {!tile && <SearchInput width="100%" endAdornment={true} placeholder="Search..." border="0.5px solid #B8B8B8" boxShadow="0px 4px 4px 0px #3C105314" borderRadius="20px" height="40px" marginTop="0px" />}
                    </Grid>
                    <Grid item lg={8} md={6} sm={12} xs={12}>
                        <Grid container justifyContent={"flex-end"}>
                            <div className="notification">
                                <img src={sortIcon} style={{ marginRight: "16px" }} alt="notificaton" />
                                <img src={!tile ? TileIconCard : TileIcon} onClick={() => handleTile()} alt="notificaton" style={{ cursor: "pointer" }} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <div style={{ marginTop: "6px" }}>
                    {tile ? <div style={{ overflow: 'auto' }}> <ApplicationListing locale={locale} arr={applicationData} loader={applicationListLoader} onClick={() => handleClick()} headings={headings} /></div> : <CardApplication arr={applicationData} locale={locale} loader={applicationListLoader} onClick={() => handleClick()} />}
                </div>

            </StyleWrapper>
        </LayoutStyleWrapper>
    );
};

export default Application;