import {
   GET_VEHICLE_DETAILS, GET_VEHICLE_DETAILS_ERROR, GET_VEHICLE_DETAILS_SUCCESS
} from "./constant";

const vehicleDetailsReducer = (state = {}, action) => {
   switch (action.type) {
      case GET_VEHICLE_DETAILS:
         return { ...state, loading: true, error: '' };
      case GET_VEHICLE_DETAILS_ERROR:
         return { ...state, type: GET_VEHICLE_DETAILS_ERROR, loading: false, error: action.payload };
      case GET_VEHICLE_DETAILS_SUCCESS:
         return { ...state, type: GET_VEHICLE_DETAILS_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default vehicleDetailsReducer;