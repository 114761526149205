import { takeLatest, put } from "redux-saga/effects";
import {
    GET_VEHICAL_LIST,
    GET_VEHICAL_CATEGORY,
    SCREEN_API_ERROR,
    SCREEN_API_SUCCESS,
    GET_VEHICAL_CATEGORY_SUCCESS,
    // GET_VEHICAL_LIST_DETAIL,
    GET_CAMPAIGNS_LIST,
} from "./constant";
import url from "utils/services/urls.json";
import { getMethodParamWithToken, getMethodWithToken } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";

function* getVehicalList(action) {
    try {
        const response = yield getMethodParamWithToken(url.getVehicalList, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: SCREEN_API_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: SCREEN_API_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: SCREEN_API_ERROR, payload: error });
    }
};

function* getVehicalCategory(action) {
    try {
        const response = yield getMethodWithToken(url.getVehicalCategory, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: GET_VEHICAL_CATEGORY_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: SCREEN_API_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: SCREEN_API_ERROR, payload: error });
    }
};

// function* getVehicleDetails(action) {
//     try {
//         const response = yield getMethodParamSlashWithToken(url.getVehicleListDetails, action.data);

//         if (response.status === 200 || response.status === 201) {
//             yield put({ type: SCREEN_API_SUCCESS, payload: response });

//             if (action.external && action.external.redirect === true) {
//                 action.external.redirectNavigate(action.external.path);
//             } else if (action.external && action.external.run === true) {
//                 action.external.handleFunction();
//             } else if (action.external && action.external.step) {
//                 action.external.handleStep(action.external.step);
//             }
//         } else {
//             yield put({ type: SCREEN_API_ERROR, payload: response });
//         }

//     } catch (error) {
//         yield put({ type: SCREEN_API_ERROR, payload: error });
//     }
// };

function* getCampaignsList(action) {
    try {
        const response = yield getMethodWithToken(url.getDashboardCampaigns, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: SCREEN_API_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: SCREEN_API_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: SCREEN_API_ERROR, payload: error });
    }
};


function* workerScreenSaga() {
    yield takeLatest(GET_VEHICAL_LIST, getVehicalList);
    yield takeLatest(GET_VEHICAL_CATEGORY, getVehicalCategory);
    // yield takeLatest(GET_VEHICAL_LIST_DETAIL, getVehicleDetails);
    yield takeLatest(GET_CAMPAIGNS_LIST, getCampaignsList);
};

export default workerScreenSaga;
