import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import styled from 'styled-components';


const WrapperDrawer = styled(Drawer)`
`;

function MuiDrawer(props) {
	return (
		<WrapperDrawer {...props} >
			{props.children}
		</WrapperDrawer>
	);
}

export default MuiDrawer;