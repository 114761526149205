import { takeLatest, put } from "redux-saga/effects";
import { AUTH_TAHAKKUQ_ERROR, AUTH_TAHAKKUQ_SUCCESS, AUTH_TAHAKKUQ_VERIFY } from "./constant";
import url from "utils/services/urls.json";
import { postMethod } from "utils/services/apis";
import { ClearTokenRedirectLogin } from "utils/general";
import { unsetClient } from "store/token/action";



function* userTahakkuqVerify(action) {
    try {
        const response = yield postMethod(url.userTahakkuqVerification, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: AUTH_TAHAKKUQ_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: AUTH_TAHAKKUQ_ERROR, payload: response });
            console.log(action.external && action.external.errorStep && response?.data?.status_code === 400, action, response?.data?.status_code)
            if (action.external && action.external.errorStep && response?.data?.data?.code === 102) {
                action.external.handleStep(action.external.errorStep);
            } else if (action.external && action.external.errorStep && response?.data?.status_code === 400) {
                action.external.handleStep(action.external.errorStep);
            }
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: AUTH_TAHAKKUQ_ERROR, payload: error });

    }
};

function* tahakukSaga() {
    yield takeLatest(AUTH_TAHAKKUQ_VERIFY, userTahakkuqVerify);
}

export default tahakukSaga;
