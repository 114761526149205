import React, { useContext } from "react";
import Slider from "react-slick";
import styled from 'styled-components';
import AnimatedImage from 'components/AnimatedImage';
import Banner from "features/Dashboard/Banner";
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';

import { LocaleContext } from 'routes/Router';

const ContainerStyle = styled.div`
.slick-active {
    z-index:1;
    .myClass{
        background:var(--primary) !important;
        border:none !important;
    }
}
.iconImg {
    margin-top:-15px;
}
.slick-slide {
    margin-bottom:10px;
}
.slick-dots {
    z-index:0;
}
.slick-arrow {
    display:none !important;
}`;

const SlickCarousel = (props) => {
    const { locale } = useContext(LocaleContext);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: i => (
            <div
                className='myClass'
                style={{
                    height: '8px',
                    width: "43px",
                    background: '#dbdbdb',
                    borderRadius: '20px',
                    border: 'none'
                }}
            >

            </div>
        ),
        prevArrow: null, // Set to null to turn off the left arrow
        nextArrow: null, // Set to null to turn off the right arrow
    };
    
    return (
        <ContainerStyle {...props}>
            <Slider {...settings}>
                {
                    props?.items?.map((data, index) => (
                        <div key={index}>
                            <Box style={{ transform: locale === 'ar' ? 'scaleX(-1)' : '' }}>
                                <AnimatedImage src={data?.img} />
                            </Box>
                        </div>
                    ))
                }
                {props?.banner &&
                    (!props?.loader ?
                        props?.campaignData?.length > 0 && props?.campaignData.map((k, i) => (
                            <div>
                                <Banner data={k} loader={props.loader} />
                            </div>
                        ))
                        :
                        Array.from({ length: 1 }).map((k) => (
                            <div>
                                <Banner data={k} loader={props.loader} />
                            </div>
                        ))
                    )
                }
            </Slider>
            {props.rotateView &&
                <img className='iconImg' src={require('assets/icons/360Degree.png')} alt="" />
            }
        </ContainerStyle>
    )
}

export default SlickCarousel;
