import React from 'react';
import styled from 'styled-components';
import Card from '@mui/material/Card';

const WrapperCard = styled(Card)`
	box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "0 3px 3px 0 rgba(60, 16, 83, 0.08)")} !important;
	border-radius: ${(props) => (props.borderRadius) + "px"} !important;
	border: ${(props) => (props.border)} !important;

	${(props) =>
		props.boxShadow === false && `
 	    box-shadow: none !important;
    `}

    background-color: ${(props) => (props.theme[props.bgColor])} !important;
	background-image:url('${(props) => props.backgroundImage}');
	background-repeat: no-repeat;
	background-size: ${(props) => (props.backgroundSize)} !important;
	height: ${(props) => (props.height) + "px"} !important;

    padding: ${(props) => (props.padding)} !important;
    margin: ${(props) => (props.margin)} !important;
`;


function MuiCards(props) {
	return (
		<WrapperCard {...props} >
			{props.children}
		</WrapperCard>
	);
}

MuiCards.defaultProps = {
	bgColor: 'white',
	padding: '30px',
	margin: "0px"
};

export default MuiCards;