import { Box, Grid } from '@mui/material'
import H5Text from 'components/Text/H5Text'
import React, { useState } from 'react'
import styled from 'styled-components'

const SelectAmongStyle = styled.div`
    margin-top: ${(props)=>props.marginTop}px;

    .itemContainer{
        display:flex;
        gap: 10px;

        .item {
            margin-right: 10px;
            color:#909FA6;
            border:1px solid ${(props)=>props.theme[props.borderColor]};;
            border-radius:5px;
            width:${(props)=>props.width}px;
            background:${(props)=>props.theme[props.bgColor]};
            height:40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor:pointer;
        }
        .selected {
           color:#14303A;
           border:1px solid #14303A;
        }
    }
`
const SelectAmongs = (props) => {
    const [selected, setSelected] = useState(props?.selected);

    const handleSelect =(id)=>{
        setSelected(id)
        if(props.getSelected){
            props.getSelected(id);
        }
    }

    return (
        <SelectAmongStyle {...props}>
            <Box display='flex' alignItems={'center'}>
                <H5Text size='16' fontFamily='Karbon_medium' marginTop={'0'}>{props.label}</H5Text>
            </Box>
            <Grid className='itemContainer' container  >
                {props.data.map((data) => (
                    <Grid
                        className={`item ${selected === data?.id ? 'selected' : ''}`}
                        item
                        key={data?.id}
                        onClick={() => handleSelect(data?.id)}
                    >
                        <H5Text size='16' fontFamily='Karbon_medium' marginTop={'0'}>{data?.type}</H5Text>
                    </Grid>
                ))}
            </Grid>
        </SelectAmongStyle>
    )
}

SelectAmongs.defaultProps = {
	height:'40',
    width:'156',
    marginTop:'24',
    bgColor:'transparent',
    borderColor:'#909FA6'
};

export default SelectAmongs
