import React from "react";
import { FormattedMessage } from "react-intl";
import { PasswordValidation } from "utils/general"

const CheckValidation = value => {

    const formError = {
        submit: true,
        currentPassword: {
            error: false,
            errorMessage: <FormattedMessage id="account.forgot.invalidCurrentPassword" />
        },
        newPassword: {
            error: false,
            errorMessage: <FormattedMessage id="account.forgot.invalidNewPassword" />
        },
        confirmPassword: {
            error: false,
            errorMessage: <FormattedMessage id="account.forgot.invalidConfirmPassword" />
        },
    };

    const emptyKeys = [
        "currentPassword",
        "newPassword",
        "confirmPassword"
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "currentPassword" && !PasswordValidation(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "newPassword" && !PasswordValidation(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
        
        if (value[emptyKeys[i]] && emptyKeys[i] === "confirmPassword") {
            if (value.newPassword !== value.confirmPassword) {
                formError[emptyKeys[i]].error = true;
                formError[emptyKeys[i]].errorMessage = "current password and confirm password does not match";
                formError.submit = false;
            }
        }
    }

    return formError;
}

export default CheckValidation;