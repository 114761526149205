import React from 'react';
import styled from 'styled-components';
import { MuiOtpInput } from 'mui-one-time-password-input';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const WrapperDiv = styled.div`
	margin-top: ${(props) => (props.marginTop+"px")};
	justify-content: ${(props) => (props.justifyContent)};
    display: flex;
	max-width:720px;
	@media screen and (max-width:900px){
		max-width:100%;
	}
`;

const WrapperMuiOtpInput = styled(MuiOtpInput)`
	.MuiFormControl-root {
		.MuiInputBase-root {
			// width: 115px;
			height: 64px;
			border: 1px solid ${(props) => (props.theme.lightPink)};
			border-radius: 20px;
			.MuiOutlinedInput-notchedOutline {
				border: none;
			}
		}

		.MuiInputBase-root.Mui-focused {
			background-color: ${(props) => (props.theme.purple)};

			.MuiInputBase-input {
				color: ${(props) => (props.theme.white)};
			}
		}
	}

	.MuiFormControl-root:nth-child(-n + ${(props) => props.value.length}) {
		.MuiInputBase-root {
			background: ${(props) => (props.theme.purple)};
			color: ${(props) => (props.theme.white)}; !important;
		}
	}
`;

const WrapperErrorDiv = styled.div`
	font-size: 18px;
	color: ${(props) => props.theme.errorColor};
	line-height: 27px;
	font-weight: 600;
	font-family: ${(props) => props.theme.KarbonLight};
	margin-top: 5px;
`;

const OtpInput = ({marginTop, justifyContent, ...props }) => {
	return (
		<>
			<WrapperDiv 
				{...marginTop}
				{...justifyContent}
			>
				<WrapperMuiOtpInput 

					{...props}
				/>
			</WrapperDiv>
			{props.errorText && 
				<WrapperErrorDiv>
		      		<ErrorOutlineIcon /> {props.errorText}
	      		</WrapperErrorDiv>
			}
		</>
	);
}

OtpInput.defaultProps = {
  	justifyContent: "center",
};

export default OtpInput;