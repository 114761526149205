import Dashboard from 'features/Dashboard/Banner';
import React, { Suspense } from 'react';
import { Routes, Navigate, BrowserRouter as Router, Route, useResolvedPath } from 'react-router-dom';
import AccountAuditLogs from 'screens/Account/AccountAuditLogs';
import AccountChangePassword from 'screens/Account/AccountChangePassword';
import AccountComplaints from 'screens/Account/AccountComplaints';
import AccountComplaintForm from 'screens/Account/AccountComplaintsForm';
import AccountNearestBranch from 'screens/Account/AccountNearestBranch';
import AccountOffers from 'screens/Account/AccountOffers';
import AccountoffersDetails from 'screens/Account/AccountOffersDetails';
import AccountRegisterDevice from 'screens/Account/AccountRegisterDevice';
import Error404 from 'screens/Pages/404/404';

const publicRoutes = [
    {
        path: "",
        element: <AccountChangePassword />
    },
    {
        path: "change-password",
        element: <AccountChangePassword />
    },
    {
        path: "register-devices",
        element: <AccountRegisterDevice />
    },
    {
        path: "nearest-branch",
        element: <AccountNearestBranch />
    },
    {
        path: "offers",
        element: <AccountOffers />
    },
    {
        path: "audit-logs",
        element: <AccountAuditLogs />
    },
    {
        path: "offer-details",
        element: <AccountoffersDetails />
    },
    {
        path: "complaints",
        element: <AccountComplaints />
    },
    {
        path: "complaint-form",
        element: <AccountComplaintForm />
    },
];


export const PublicRoute = ({ children }) => {
    const isLoggedIn = false;

    console.log(children)

    if (!isLoggedIn) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Navigate to="/" />
        </React.Fragment>
    );
};



export default function AccountRoute() {
    return (
        <Suspense >
            <Routes>
                {publicRoutes.map((route, index) => {
                    return (
                        <Route key={index} path={route.path} exact={route.exact} element={<PublicRoute>{route.element}</PublicRoute>} />
                    )
                })}
                <Route path={"*"} element={<Error404 />} />
            </Routes>
        </Suspense>
    );
}