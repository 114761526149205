import React, { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import H5Text from "components/Text/H5Text";
import IconWithText from "components/IconWithText";
import SelectInput from "components/Input/SelectInput";
import CheckboxInputDesignable from "components/Input/CheckboxInputDesignable";
import DiscountBadge from "components/DiscountBadge";
import ButtonInput from "components/Input/ButtonInput";
import H1Text from 'components/Text/H1Text';
import AccordianImage from "assets/images/financing/alrajhi-takaful.png";
import discountBadge from "assets/images/financing/discountBadge.png";
import discountIcon from "assets/images/financing/discountIcon.png";
import { financingCallAPI } from "store/financing/action";
import { CAR_INSURANCE_QUOTE } from "store/financing/constant";
import { LocaleContext } from 'routes/Router';
import { useNavigate } from "react-router-dom";

const WrapperDiv = styled.div`
`;

const WrapperAccordion = styled(Accordion)`
	border-radius: 8px !important;
	border: 1px solid ${(props) => props.theme.gray} !important;
	box-shadow: none !important;
	padding: 0px 15px !important;
	.MuiAccordionSummary-content{
		padding:0;
	}
`;

const WrapperAccordionSummary = styled(AccordionSummary)`
	margin: 0 0 !important;
	padding-${(props) => props.locale === 'en' ? 'right' : 'left'}: 5.4% !important;
`;

function RevisedFinancingForm({ handleCurStep }) {
	const dispatch = useDispatch();
	const { locale } = useContext(LocaleContext);
	const navigate = useNavigate();
	const [expanded, setExpanded] = React.useState('panel1');

	useEffect(() => {
		getCarInsuranceQuote();
	}, []);

	const getCarInsuranceQuote = (value) => {
		dispatch(financingCallAPI(CAR_INSURANCE_QUOTE, {}, { navigate: navigate }));
	}

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	const toggleAcordion = () => {
		setExpanded((prev) => !prev);
	};

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id="insuranceDetails" />
						<img alt="" src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '15px' }} />
					</H5Text>

					<Box sx={{ marginTop: "30px" }}>
						<Grid container>
							<Grid item xs={12}>
								<WrapperAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >

									<WrapperAccordionSummary locale={locale}
										marginTop='0px'
										expandIcon={<Box display={'flex'} flexDirection='column' alignItems='center'><ExpandMoreIcon />
										</Box>}
										aria-controls="alrajhi-takaful"
										id="alrajhi-takaful"
									>
										<ButtonInput
											title={expanded === 'panel1' ? <FormattedMessage id='selected' /> : <FormattedMessage id='select' />}
											variant="contained"
											bgColor={expanded === 'panel1' ? "yellow" : "white"}
											textColor="purple"
											borderRadius="6"
											TextTransform="capitalize"
											borderColor={expanded === 'panel1' ? "yellow" : "purple"}
											width='100%'
											style={{ maxWidth: '100px', position: 'absolute', right: locale === 'en' ? '2%' : '', left: locale === 'ar' ? '2%' : '', marginTop: '14px' }}
											height='40'
											minWidth='100'
											fontFamily='Karbon_semiBold'
											padding='0'
											size='16'
											lineHeight='17.16'
										// onClick={() => handleSubmit(props?.actionText)}
										/>
										<Box>
											<Box display={'flex'} alignItems='center' marginBottom='10px'>
												<GppGoodOutlinedIcon style={{ color: 'green' }} />
												<H1Text size='24' lineHeight='36' color='green' fontFamily='Karbon_semiBold' marginTop={'4'}>
													Sum Insured: 55,771.00 SAR
												</H1Text>

											</Box>
											<IconWithText
												boxShadow={false}
												BrandIcon={AccordianImage}
												text="Alrajhi Takaful"
												textSize="18"
												secondText="Insurance Price VAT 344 SAR"
												secondTextSize="18"
												secondFontWeight="Karbon_light"
												thirdText="4500"
												thirdTextSize="24"
												thirdTextColor="lightDark"
												thirdSubText="SAR"
												variant={'rounded'}
												BrandSize={80}
												marginBottom="0"
											/>
										</Box>
									</WrapperAccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											<Grid item xs={12} md={6}>
												<SelectInput
													label={<FormattedMessage id="autoFinance.deductible" />}
													placeholder="Deductible"
													marginTop='0'
												/>
											</Grid>
											<Grid item xs={12} md={6}>
											</Grid>

											<Grid item xs={12}>
												<CheckboxInputDesignable heading={<FormattedMessage id="autoFinance.freeServices" />} label="Own Damage" />
												<CheckboxInputDesignable label="Third Party Liability" />
											</Grid>
											<Grid item xs={12}>
												<CheckboxInputDesignable heading={<FormattedMessage id="autoFinance.paidServices" />} label="Third Party Liability" />
												<CheckboxInputDesignable label="Personal accident for driver & passengers- 50,000 SAR" />
												<CheckboxInputDesignable label="Care Replacement (Hire Car)- 325,000 SAR" />
												<CheckboxInputDesignable label="Geo-graphical for all GCC 150.00 SAR" />
											</Grid>

											<Grid item xs={12}>
												<DiscountBadge heading={<FormattedMessage id="autoFinance.discountsApplied" />} bgImage={discountBadge} icon={discountIcon} />
												<DiscountBadge bgImage={discountBadge} icon={discountIcon} />
												<DiscountBadge bgImage={discountBadge} icon={discountIcon} />
											</Grid>
										</Grid>
									</AccordionDetails>
								</WrapperAccordion>
							</Grid>
							<Grid item xs={12} style={{ marginTop: "30px" }}>
								<WrapperAccordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
									<WrapperAccordionSummary
										locale={locale}
										expandIcon={<ExpandMoreIcon />}
										aria-controls="alrajhi-takaful"
										id="alrajhi-takaful"
									>
										<ButtonInput
											title={expanded === 'panel2' ? <FormattedMessage id='selected' /> : <FormattedMessage id='select' />}
											variant="contained"
											bgColor={expanded === 'panel2' ? "yellow" : "white"}
											textColor="purple"
											borderRadius="6"
											TextTransform="capitalize"
											borderColor={expanded === 'panel2' ? "yellow" : "purple"}
											width='100%'
											style={{ maxWidth: '100px', position: 'absolute', right: locale === 'en' ? '2%' : '', left: locale === 'ar' ? '2%' : '', marginTop: '14px' }}
											height='40'
											minWidth='100'
											fontFamily='Karbon_semiBold'
											padding='0'
											size='16'
											lineHeight='17.16'
										// onClick={() => handleSubmit(props?.actionText)}
										/>
										<Box>
											<Box display={'flex'} alignItems='center' marginBottom='10px'>
												<GppGoodOutlinedIcon style={{ color: 'green' }} />
												<H1Text size='24' lineHeight='36' color='green' fontFamily='Karbon_semiBold' marginTop={'4'}>
													Sum Insured: 55,771.00 SAR
												</H1Text>

											</Box>
											<IconWithText
												boxShadow={false}
												BrandIcon={AccordianImage}
												text="Alrajhi Takaful"
												textSize="18"
												secondText="Insurance Price VAT 344 SAR"
												secondTextSize="18"
												secondFontWeight="Karbon_light"
												thirdText="4500"
												thirdTextSize="24"
												thirdTextColor="lightDark"
												thirdSubText="SAR"
												variant={'rounded'}
												BrandSize={80}
												marginBottom="0"
											/>
										</Box>
									</WrapperAccordionSummary>
									<AccordionDetails>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										malesuada lacus ex, sit amet blandit leo lobortis eget.
									</AccordionDetails>
								</WrapperAccordion>
							</Grid>
							<Grid item xs={12} style={{ marginTop: "30px" }}>
								<WrapperAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
									<WrapperAccordionSummary
										locale={locale}
										expandIcon={<ExpandMoreIcon />}
										aria-controls="alrajhi-takaful"
										id="alrajhi-takaful"
									>
										<ButtonInput
											title={expanded === 'panel3' ? <FormattedMessage id='selected' /> : <FormattedMessage id='select' />}
											variant="contained"
											bgColor={expanded === 'panel3' ? "yellow" : "white"}
											textColor="purple"
											borderRadius="6"
											TextTransform="capitalize"
											borderColor={expanded === 'panel3' ? "yellow" : "purple"}
											width='100%'
											style={{ maxWidth: '100px', position: 'absolute', right: locale === 'en' ? '2%' : '', left: locale === 'ar' ? '2%' : '', marginTop: '14px' }}
											height='40'
											minWidth='100'
											fontFamily='Karbon_semiBold'
											padding='0'
											size='16'
											lineHeight='17.16'
										// onClick={() => handleSubmit(props?.actionText)}
										/>
										<Box>
											<Box display={'flex'} alignItems='center' marginBottom='10px'>
												<GppGoodOutlinedIcon style={{ color: 'green' }} />
												<H1Text size='24' lineHeight='36' color='green' fontFamily='Karbon_semiBold' marginTop={'4'}>
													Sum Insured: 55,771.00 SAR
												</H1Text>

											</Box>
											<IconWithText
												boxShadow={false}
												BrandIcon={AccordianImage}
												text="Alrajhi Takaful"
												textSize="18"
												secondText="Insurance Price VAT 344 SAR"
												secondTextSize="18"
												secondFontWeight="Karbon_light"
												thirdText="4500"
												thirdTextSize="24"
												thirdTextColor="lightDark"
												thirdSubText="SAR"
												variant={'rounded'}
												BrandSize={80}
												marginBottom="0"
											/>
										</Box>
									</WrapperAccordionSummary>
									<AccordionDetails>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										malesuada lacus ex, sit amet blandit leo lobortis eget.
									</AccordionDetails>
								</WrapperAccordion>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container style={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(2)}
								/>
								<ButtonInput
									title={<FormattedMessage id="autoFinance.SaveProceedtoPersonalWork" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									onClick={() => handleCurStep(4)}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default RevisedFinancingForm;