import { LOGIN_DETAILS } from "./constant";

export function setLoginDetails(data) {
    return {
        type: LOGIN_DETAILS,
        data,
    }
}


