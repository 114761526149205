import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	border: 1px solid ${(props) => (props.theme[props.borderColor])};
	padding:${(props) => props.padding};
	height: 420px;
    overflow: overlay;
    margin-top: 30px;
	@media screen and (max-width:768px){
		padding:20px;
	}
`;

function TermsCondition(props) {
	return (
		<Wrapper {...props}>
			{props.children}
		</Wrapper>
	);
}

TermsCondition.defaultProps = {
  	borderColor: "lightBlackBorder",
	padding: '30px 45px 0px'
};

export default TermsCondition;
