import React from "react";
import { Row } from "react-bootstrap";
import styled from 'styled-components';
import Accordians from "components/Accordians";
import DeliverStatusImage from "assets/images/icon/deliveryStatus.png";
import DateIcon from "assets/images/icon/date.png";
import TimeIcon from "assets/images/icon/time.png";
import H5Text from 'components/Text/H5Text';
import { FormattedMessage } from "react-intl";

const StyleWrapper = styled.div`
    align-items: center;

    .right_container{
        background:#ffffff;
        border-radius:16px;
    };

    p{
        margin:0px;
    };

    .right_container_top{
        background:#E6D9F5;
        padding: 8px 24px 8px 24px;
        border-radius:8px;
        height:52px;
        align-items:center;
        display:flex;
    };

    .right_container_style{
        padding:20px 28px 16px 28px;
    };
    @media screen and (max-width:576px){
        .right_container_style{
            padding:0;
        };
    }

    .right_container_body{
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-bottom:20px;
    };
 
`

const DeliveryStatus = () => {

    return (
        <StyleWrapper>
            <Row className="right_container">
                <div className="right_container_top">
                    <H5Text size="24" marginTop="0" color="purple" >
                        <FormattedMessage id="status"/>
                    </H5Text>
                </div>

                <Row className="right_container_style">
                    <Accordians
                        head={"7 of 12 complete"} title={<FormattedMessage id="hardReservingVehicle"/>} time={"16:24"}
                        date={"12/09/23"} image={DeliverStatusImage} dateIcon={DateIcon} timeIcon={TimeIcon}
                    />
                </Row>
            </Row>
        </StyleWrapper>
    );
};

export default DeliveryStatus;