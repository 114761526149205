import {
   SECURITY_TYPE,
} from "./constant";

const initialValue = {
   securityType: "Mortgage"
};

const cashSecurityTypeReducer = (state = initialValue, action) => {
   switch (action.type) {
      case SECURITY_TYPE:
         return { ...state, securityType: action.data };
      default:
         return state;
   }
};

export default cashSecurityTypeReducer;