import { FINANCING_TYPE, LANGUAGE_TOGGLE, SIDEBAR_TOGGLE } from "./constant";

export const ToggleSideBar = (type, data) => {
    switch (type) {
        case SIDEBAR_TOGGLE:
            return {
                type: type,
                data: data
            }
    }
};

export const ToggleLanguage = (type, data) => {
    switch (type) {
        case LANGUAGE_TOGGLE:
            return {
                type: type,
                data: data
            }
    }
};

export const financingType = (type, data) => {
    switch (type) {
        case FINANCING_TYPE:
            return {
                type: type,
                data: data
            }
    }
};

