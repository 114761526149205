import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import styled from 'styled-components';
import LabelWithInput from 'components/Input/LabelWithInput';
import { InputAdornment } from '@mui/material';
import TextFields from 'components/TextFields/TextFields';

const SliderStyleLabel = styled.div`
    font-family: Karbon_medium;
    font-size: 16px;
    font-weight: 700;
    display:flex;
    justify-content:space-between;
    align-items:center;
    .star{
        color:red;
    }
    .MuiInputBase-root{
        height:44px;
    }
    .MuiFormControl-root{
        max-width:122px;

    }
`
const BottomRange = styled.div`
font-family: Karbon_light;
font-size: 18px;
font-weight: 600;
display:flex;
justify-content:space-between;
color:${(props) => props.color};
.left{
color:${(props) => props.leftTextColor?props.leftTextColor:props.theme.gray};
font-family:${(props) => props.fontFamily?props.theme[props.fontFamily]:props.theme.KarbonLight};
font-size:${(props) => props.fontSize?props.fontSize:'18px'};
}
.right{
  color:${(props) => props.rightTextColor?props.rightTextColor:props.theme.gray};
  font-family:${(props) => props.fontFamily?props.theme[props.fontFamily]:props.theme.KarbonLight};
font-size:${(props) => props.fontSize?props.fontSize:'18px'};
}
`
const CustomSlider = styled(Slider)(({ props }) => ({
  // Customize the track color
  '& .MuiSlider-track': {
    backgroundColor: props.sliderBgColor ? props.sliderBgColor : 'var(--primary)',
    height: props.height ? props.height + "px" : '10px',
    border: props.border ? props.border : ''
  },
  // Customize the rail color
  '& .MuiSlider-rail': {
    backgroundColor: '#ECE2F2',
    height: props.height ? props.height + "px" : '10.87px'
  },
  // Customize the thumb color
  '& .MuiSlider-thumb': {
    backgroundColor: props.sliderBgColor ? props.sliderBgColor : 'var(--primary)',
    border: `2px solid ${props.sliderBgColor ? props.sliderBgColor : 'var(--primary)'}`,
    height: props.height ? props.height + "px" : '24px',
    width: props.width ? props.width + "px" : '24px',
    marginLeft:"-4px"
  },
  // Customize the value label color
  '& .MuiSlider-valueLabel': {
    color: 'var(--primary)',
    background: '#efe8f3',
    borderRadius: '20px'
  },
}));

const OneWaySlider = (props) => {
  const [sliderValue, setSliderValue] = useState(props.value);

  const handleChange = (e) => {
    const newValue = parseFloat(e.target.value);

    if (!isNaN(newValue)) {
      setSliderValue(newValue);
      props.setValue(newValue);
    } else {
      setSliderValue(0);
      props.setValue(0);
    }
  };
  const formatValueLabel = (value) => {
    return `${value}`; // Add '%' to the value label
  };
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    props.setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }} {...props}>
      {props.sliderLabel &&
        <SliderStyleLabel>
          <div>
            <span>
              {props.label}
              <span className='star'>*</span>
            </span>
            <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
          </div>
          <TextFields
            icon={props.measure}
            borderRadius={'6px'}
            height={'44px'}
            width={'122px'}
            border={'1px solid #DEDEDE'}
            value={sliderValue} // Use sliderValue instead of props.value
            fontSize={'20px'}
            onChange={(e) => handleChange(e)}
          />
        </SliderStyleLabel>
      }
      <CustomSlider
        value={sliderValue} // Use sliderValue instead of props.value
        aria-label="Always visible"
        onChange={handleSliderChange}
        // valueLabelDisplay="onMouseEnter"
        min={props.startLimit}
        max={props.endLimit}
        props={props}
        disabled={props.notChangeable?true:false}
        valueLabelDisplay={props.valueLabelDisplay?"on":"onMouseEnter"}
        valueLabelFormat={(val)=>`${formatValueLabel(val)} ${props.percentage?"%":""}`}
      />
      <BottomRange {...props}>
        <span className='left'>{props.startLimitText}{props.startLimit} {props.leftmeasure}</span>
        <span className='right'>{props.endLimitText}{props.endLimit} {props.rightmeasure}</span>
      </BottomRange>
    </Box>
  );
};

export default OneWaySlider;
