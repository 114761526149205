import React, { useContext, useState } from "react";
import styled from 'styled-components';
import H1Text from "components/Text/H1Text";
import Grid from '@mui/material/Grid';
import Text from "components/Text";
import CarImage from "assets/images/Account/car_detail.png";
import TransparentCarImage from "assets/images/carTransprent.png";
import Cards from "components/Cards/Cards";
import filterIcon from "assets/images/Account/filter.svg";
import sortIcon from "assets/images/Account/sort.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormattedMessage } from "react-intl";
import H5Text from "components/Text/H5Text";
import { Navigate, useNavigate } from "react-router-dom";
import { LocaleContext } from "routes/Router";
import { 
    getAccountURL
} from 'routes/AppUrls';

const StyleWrapper = styled.div`
    align-items: center;
   
    .application_top{
        display:flex;
        align-items:center;
    };

    ol, ul {
        padding-left: 1.5rem;
        font-size:25px;
    }

`

const arr = [{
    id: 1,
    img: TransparentCarImage,
    heading: "COROLLA 2023",
    subHeading: "1.5 XLI EXE 4X2 1.5 PETROL SEDAN 4Dr CVT",
    price: "2,099",
},
{
    id: 2,
    img: TransparentCarImage,
    heading: "Toyota COROLLA",
    subHeading: "Toyota COROLLA 2024 1.5 XLI EXE 4X2 1.5 PETROL SEDAN 4Dr CVT",
    price: "2,099",
},
{
    id: 3,
    img: TransparentCarImage,
    heading: "Toyota COROLLA",
    subHeading: "Toyota COROLLA 2024 1.5 XLI EXE 4X2 1.5 PETROL SEDAN 4Dr CVT",
    price: "2,099",
},];

const AccountoffersDetails = () => {
    const isMobile = window.innerWidth < 700;
    const [isToggle, setIsToggle] = useState(true);
    const { locale } = useContext(LocaleContext);
    const navigate = useNavigate();
    const handleSort = () => {
        setIsToggle(!isToggle);
    }

    return (
        <StyleWrapper>
            <Grid container alignItems={'center'}>
                <ArrowBackIcon sx={{color: "#3F1956", fontSize: "24" , cursor:'pointer', marginRight:locale=='en'&&'10px', marginLeft:locale=='ar'&&'10px'}} onClick={()=>navigate(`/${locale}/account/offers`)} />
                <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily="Karbon_semiBold">
                    <FormattedMessage id="account.offerDetails"/>
                </H5Text>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <img src={CarImage} alt='image' style={{ width: "-webkit-fill-available",height:"-webkit-fill-available" }} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: "20px" }}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Text color="lightDark" size="28" fontWeight="700" marginTop="0" lineHeight="22">
                                {"Ferrari Car Models"}
                            </Text>
                        </Grid>
                    </Grid>

                    <Grid container marginTop={'10px'}>
                        <Grid item xs={12}>
                            <Text color="darkPrimary" size="20" fontWeight="600" marginTop="0" lineHeight="22">
                                {"Enjoy your dream vehicle with Abdul Latif Jameel United Finance, which provides you  the financial leasing all vehicles."}
                            </Text>
                        </Grid>
                    </Grid>

                    <Grid container marginTop={'45px'}>
                        <Grid item xs={12}>
                            <Text color="lightDark" size="20" fontWeight="700" marginTop="0" lineHeight="22">
                                <FormattedMessage id="account.benefits"/>:
                            </Text>
                        </Grid>
                    </Grid>

                    <Grid container marginTop={'10px'}>
                        <Grid item xs={12}>
                            <ul>
                                <li>
                                    <Text color="darkPrimary" size="20" fontWeight="600" marginTop="0" lineHeight="21">
                                        {"Free admin fees and we pay the last  monthly lease."}
                                    </Text>
                                </li>
                                <li style={{ marginTop: '10px' }}>
                                    <Text color="darkPrimary" size="20" fontWeight="600" marginTop="0" lineHeight="21">
                                        {"Calculation is based on 60 mos. and will change on other contract period"}
                                    </Text>
                                </li>
                                <li style={{ marginTop: '10px' }}>
                                    <Text color="darkPrimary" size="20" fontWeight="600" marginTop="0" lineHeight="21">
                                        {"No guarantor required"}
                                    </Text>
                                </li>
                                <li style={{ marginTop: '10px' }}>
                                    <Text color="darkPrimary" size="20" fontWeight="600" marginTop="0" lineHeight="21">
                                        {"Shariah compliant."}
                                    </Text>
                                </li>
                            </ul>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            <Grid container marginTop={'50px'} padding={'0 0 0 10px'} width={isMobile?'100%':'90%'}>
                <Grid item xs={6}>
                    <Text color="darkPrimary" size="20" fontWeight="600" marginTop="0" lineHeight="22">
                        <FormattedMessage id="account.offersSelection"/>
                    </Text>
                </Grid>

                <Grid item xs={6} textAlign={'right'} >
                    <img src={filterIcon} alt='icon' style={{boxShadow: '0px 1px 69px 0px #00000017'}} />
                    <img src={sortIcon} style={{ marginLeft: "10px", cursor: 'pointer',boxShadow: '0px 1px 69px 0px #00000017' }} alt='icon' onClick={() => handleSort()} />
                </Grid>
            </Grid>

            <Grid container marginTop={'0px'} spacing={6} gap={isToggle ? "40px 0px" : "0"} width={isMobile?'100%':'95%'}>
                {arr.map((data, index) => {
                    return (
                        <Grid item xs={isToggle ? '12' : '12'} sm={isToggle ? '12' : '12'} md={isToggle ? '6' : '12'} lg={isToggle ? '4' : '12'} xl={isToggle ? '4' : '12'} >
                            <Cards listtype={isToggle} data={data} />
                        </Grid>
                    )
                })}
            </Grid>

            <Grid container marginTop={'45px'}>
                <Grid item xs={12}>
                    <Text color="lightDark" size="20" fontWeight="700" marginTop="0" lineHeight="22">
                        <FormattedMessage id="account.disclaimer"/>:
                    </Text>
                </Grid>
            </Grid>

            <Grid container marginTop={'10px'}>
                <Grid item xs={12}>
                    <ul>
                        <li>
                            <Text color="darkPrimary" size="20" fontWeight="600" marginTop="0" lineHeight="21">
                                <FormattedMessage id="account.freeAdmin"/>
                            </Text>
                        </li>
                        <li style={{ marginTop: '10px' }}>
                            <Text color="darkPrimary" size="20" fontWeight="600" marginTop="0" lineHeight="21">
                                <FormattedMessage id="account.calculation"/>
                            </Text>
                        </li>
                        <li style={{ marginTop: '10px' }}>
                            <Text color="darkPrimary" size="20" fontWeight="600" marginTop="0" lineHeight="21">
                                <FormattedMessage id="account.noGuarantor"/>
                            </Text>
                        </li>
                        <li style={{ marginTop: '10px' }}>
                            <Text color="darkPrimary" size="20" fontWeight="600" marginTop="0" lineHeight="21">
                                <FormattedMessage id="account.shariah"/>
                            </Text>
                        </li>
                    </ul>

                </Grid>
            </Grid>

        </StyleWrapper >
    );
};

export default AccountoffersDetails;
