import React from 'react'
import styled from 'styled-components';

const WrapperSpan = styled.span`
    color: ${(props) => props.color};
    font-style: italic;
    font-size: 16px;
    font-weight: 700;
    font-family: Karbon_semibold;
    display: flex;
    align-items: center;

    .TextData {
        margin-left: 5px;
    }
`;
const StartWithIcon = (props) => {
    return (
        <WrapperSpan {...props}>
            <img src={props.icon} alt="" />
            <div className='TextData'>{props.text}</div>
            {props.children}
        </WrapperSpan>
    )
}
StartWithIcon.defaultProps = {

};
export default StartWithIcon
