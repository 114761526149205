import React from "react";
import LayoutStyleWrapper from "styles/common.styles";


const Support = () => {

    return (
        <LayoutStyleWrapper>
            <h1>Support</h1>
        </LayoutStyleWrapper>
    );
};

export default Support;