import {
    USER_API,
    USER_PROFILE_UPDATE_API,
    USER_UPDATE_API,
} from "./constant";

export const userCallApi = (type, data, external = {}) => {
    switch (type) {
        case USER_API:
            return {
                type: type,
                data: data,
                external: external
            }
        case USER_UPDATE_API:
            return {
                type: type,
                data: data,
                external: external
            }
        case USER_PROFILE_UPDATE_API:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};


