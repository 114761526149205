import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import YesNoInput from "components/Input/YesNoInput";
import ButtonInput from "components/Input/ButtonInput";
import DateTimePickerInput from "components/Input/DateTimePickerInput";

import { getFinancingInitialDataURL } from "routes/AppUrls";
import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
	
`;

function PersonalWorkForm({handleCurStep}) {
	const intl = useIntl();
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage  id={'Personal Information'} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabelWithInput 
									label="ID Number" 
									placeholder="ID Number"
								/>
							</Grid>
							<Grid item xs={6}>
								<DateTimePickerInput label="Date of Birth (Hijri/Gregorian)" />
							</Grid>
							<Grid item xs={12} display='flex' justifyContent='end'>
				                <ButtonInput
									title={<FormattedMessage id={"YAKEEN Search"} />}
									variant="contained"
									bgColor="purple"
									textColor="white"
									borderRadius="100"
									TextTransform="capitalize"
									borderColor="purple"
									width='189px'
									height='54'
									fontFamily='Karbon_semiBold'
									padding='0'
									size='16'
				                />
              				</Grid>
              				<Grid item xs={6}>
								<LabelWithInput 
									label="Name" 
									placeholder="Name"
								/>
							</Grid>
							<Grid item xs={6}>
								<DateTimePickerInput label="ID Expiration Date" />
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput 
									label="Contact Number" 
									placeholder="Contact Number"
								/>
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput 
									label="Number of Dependents" 
									placeholder="Number of Dependents"
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput 
									label="Level of Education" 
									placeholder="Level of Education" 
								/>
							</Grid>
							<Grid item xs={6}>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container sx={{ marginTop: "15px" }}>
				<Grid item xs={12}>
					<H5Text padding="8px 16px" color="lightDark">
						<FormattedMessage  id={'Address'} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabelWithInput 
									label="Building Number" 
									placeholder="Building Number"
								/>
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput 
									label="Street" 
									placeholder="Street"
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput 
									label="District" 
									placeholder="District" 
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput 
									label="City" 
									placeholder="City" 
								/>
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput 
									label="Additional Number" 
									placeholder="Additional Number"
								/>
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput 
									label="Postal Code" 
									placeholder="Postal Code"
								/>
							</Grid>
							<Grid item xs={6}>
								<LabelWithInput 
									label="Unit Number" 
									placeholder="Unit Number"
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput 
									label="Residence Type" 
									placeholder="Residence Type" 
								/>
							</Grid>
							<Grid item xs={6}>
								<SelectInput 
									label="House Type" 
									placeholder="House Type" 
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>


			<Grid container sx={{marginTop: "30px"}}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput 
									title="Back"
									variant="text" 
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(1)}
								/>
								<ButtonInput 
									title="Save & Proceed to Finance Information"
									variant="contained" 
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									onClick={() => navigate(getFinancingInitialDataURL(locale))}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default PersonalWorkForm;