import React, { useState, useContext } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import MuiCards from "components/MuiCards";
import Text from "components/Text";
import ButtonInput from "components/Input/ButtonInput";

import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import { FormattedMessage } from "react-intl";
import H5Text from "components/Text/H5Text";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { LocaleContext } from 'routes/Router';
import { useNavigate } from "react-router-dom";
import { getDashboardURL, getQuotationCongratulation } from "routes/AppUrls";
import HeaderBanner from "features/CashFinancing/HeaderBanner";
import { Accordion, AccordionSummary, IconButton } from "@mui/material";
import rightIcon from "assets/icons/rightIconColor.png";
import calenderIcon from "assets/icons/calenderIcon.png";
import styled from 'styled-components';

const StyleWrapper = styled.div`
     
.payment_history{
	background:#F4E1FF;
	height:50px;
	border-radius:8px;
	color:#FFFFFF;
	display:flex;
	justify-content:space-between;
	align-items:center;
	cursor:pointer;
	padding: 10px 5px 10px 25px;
}

`

const ApplicationApproved = () => {
	const { locale } = useContext(LocaleContext);
	const navigate = useNavigate();
	const [breadcrumbsList, setBreadcrumbsList] = useState([
		{
			title: <FormattedMessage id="home" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="applyFinance" />,
			Link: ""
		},
		{
			title: <FormattedMessage id="onlineFinancing" />,
			Link: "",
			isDisbale: true,
		},
	])
	return (
		<div style={{ display: 'flex', backgroundColor: '#F1EDF5', paddingBottom: "30px", minHeight: "100vh" }}>
			<Container maxWidth={'xl'} style={{ padding: "10px" }} disableGutters>
				<Grid container>
					<Grid item xs={12}>
						<HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} />
					</Grid>
				</Grid>
				<Box margin='32px 0'>
					<MuiBreadcrumbs size='20' list={breadcrumbsList} />
				</Box>
				<Grid container >
					<Grid item xs={12}>
						<MuiCards borderRadius="16" padding="30px 0">
							<CardContent>
								<Container style={{ maxWidth: '689px' }}>
									<Grid container textAlign={'center'}>
										<Grid item xs={12}>
											<Grid container>
												<Grid item xs={12}>
													<div style={{ textAlign: "center" }}>
														<CheckCircleIcon sx={{ width: "130px", height: "130px", color: "#1DB510" }} />
													</div>
												</Grid>
											</Grid>
											<Grid container display='flex' justifyContent='center'>

												<Grid item xs={12} marginTop='0px'>
													<Text color="black" size="40" fontWeight="800" lineHeight={'48px'} fontFamily="KarbonSemiBold" TextAlign="center">
														<FormattedMessage id="Application Approved" />
													</Text>
												</Grid>
												<Grid item xs={12}>
													<Text color="secondaryBlack" size="24" lineHeight={'28.8'} fontWeight="800" TextAlign="center">
														<FormattedMessage id="Your application has been approved" />
													</Text>
												</Grid>

												<Grid item xs={12} marginTop='0px'>
													<div style={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
														<Text color="lightBlack" size="24" fontWeight="800" TextAlign="center">
															<FormattedMessage id="with reference number:" />:
														</Text>
														<Text color="purple" size="24" fontWeight="800" TextAlign="center" fontFamily="KarbonMedium">
															&nbsp; 7897-897987-89
														</Text>

													</div>
												</Grid>
											</Grid>

											<Grid container>
												<Grid item xs={12}>
													<MuiCards bgColor="pink" borderRadius="8" boxShadow={false} padding="10px 15px" margin="30px 0 0 0">
														<Grid container paddingLeft='17px'>
															<Grid item xs={11} display='flex' justifyContent='start' alignItems='center'>
																<H5Text color="purple" lineHeight="24" size="20" fontWeight="800" marginTop="0" style={{ textAlign: 'start' }}>
																	<FormattedMessage id="downloadYourQuotation" />
																</H5Text>
															</Grid>
															<Grid item xs={1}>
																<div style={{ textAlign: locale === 'en' ? "right" : 'left' }}>
																	<SaveAltIcon sx={{ width: "32px", height: "32px", color: "#3F1956", textAlign: "right" }} />
																</div>
															</Grid>
														</Grid>
													</MuiCards>
												</Grid>

												<Grid item xs={12} sx={{ marginTop: "20px"}}>
													<Accordion >
														<StyleWrapper>
															<AccordionSummary className="payment_history"
																expandIcon={
																	<IconButton style={{ color: 'red' }} aria-label="expand">
																		{/* Add your custom image here */}
																		<img src={rightIcon} alt="Custom Icon" />
																	</IconButton>
																}
																aria-controls="panel1-content"
																id="panel1-header"
															// onClick={handleAccordionChange}
															>
																<div style={{ display: "flex", alignItems: "center" }}>
																	<img src={calenderIcon} alt="icon" height={"25px"} width={"24px"} style={{ marginRight: locale === 'en' && "16px", marginLeft: locale === 'ar' && "16px" }} />
																	<H5Text color="purple" lineHeight="24" size="20" fontWeight="800" marginTop="0" style={{ textAlign: 'start' }}>
																		<FormattedMessage id="Repayment Schedule" />
																	</H5Text>
																</div>
															</AccordionSummary>
														</StyleWrapper>
													</Accordion>
												</Grid>
											</Grid>

										</Grid>
									</Grid>
								</Container>

								<Grid container style={{ marginTop: "50px" }} >
									<Grid item xs={12} marginRight={{ xs: "0", lg: "100px" }}>
										<div className="content" style={{ display: "flex", justifyContent: "end" }}>
											<Box component="form">
												<Stack spacing={2} direction="row">
													<ButtonInput
														title={<FormattedMessage id="Application Tracker" />}
														variant="contained"
														bgColor="yellow"
														textColor="purple"
														borderRadius="100"
														TextTransform="capitalize"
														endIcon={<ArrowForwardIcon />}
														// onClick={() => navigate(getDashboardURL(locale))}
														onClick={() => navigate(getDashboardURL(locale))}
													/>
												</Stack>
											</Box>
										</div>
									</Grid>
								</Grid>
							</CardContent>
						</MuiCards>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default ApplicationApproved;
