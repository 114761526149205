import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';
import H1Text from "components/Text/H1Text";
import MaskInput from "components/Input/MaskInput";
import ButtonInput from "components/Input/ButtonInput";
import { getSignInURL } from "routes/AppUrls"
import CheckValidation from "./validation";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_INITIAL_STATE, AUTH_USER_VERIFY } from "store/auth/constant";
import Text from "components/Text";
import { authCallAPI } from "store/auth/action";
import { LocaleContext } from 'routes/Router';
import { InputAdornment } from "@mui/material";
import { PhoneNumberValidation } from "utils/general";

function Register({ handleCurStep, handleFormData }) {
	const isMobile = window.innerWidth < 900;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isContactValid, setIsContactValid] = useState(false);
	const { locale } = useContext(LocaleContext);
	const [isError, setIsError] = useState(false);
	const [formValue, setFormValue] = useState({
		crNumber: "",
		nationalId: "",
		contactNumber: "",
		routingKey: "signup"
	});
	const AuthError = useSelector((state) => (state.authReducer?.error?.data?.message));
	const AuthData = useSelector((state) => (state.authReducer && state.authReducer?.payload?.data));
	const loader = useSelector((state) => state.authReducer?.loading);

	useEffect(() => {
		dispatch(authCallAPI(AUTH_INITIAL_STATE));
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValue((prevState) => ({
			...formValue,
			[name]: value,
		}));
		if (name === "contactNumber") {
			let number = value?.split(' ').join('')
			if (PhoneNumberValidation(number)) {
				setIsContactValid(true);
			} else {
				setIsContactValid(false);
			}
		}
	};

	const handleSubmit = () => {
		formValue.nationalId = formValue?.nationalId?.split(' ').join('');
		formValue.contactNumber = `${formValue?.contactNumber?.split(' ').join('')}`;
		const error = CheckValidation(formValue);
		setIsError(error);
		if (error.submit) {
			let bodyValue = {
				"countryCode": "966",
				"contactNumber": formValue?.contactNumber?.substring(4),
				"nationalId": formValue?.nationalId,
				"crNumber": formValue?.crNumber,
			};
			dispatch(authCallAPI(AUTH_USER_VERIFY, bodyValue, { handleFunction: userVerify, navigate: navigate, run: true }));
		}
	};

	const userVerify = () => {
		handleFormData(formValue);
		handleCurStep('terms');
	};

	return (
		<>
			<H1Text color="primary" size={isMobile ? 40 : 64} lineHeight="96">
				<FormattedMessage id="register.title" />
			</H1Text>

			<Grid container>

				<Grid item xs={12} md={10} lg={8}>
					<MaskInput
						mask="9999999999"
						disabled={false}
						maskChar=""
						label={<FormattedMessage id="CRNumber" />}
						placeholder="0000000000"
						name="crNumber"
						onChange={handleInputChange}
						errorText={isError && isError.crNumber.error && isError.crNumber.errorMessage}
					/>
				</Grid>
				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<MaskInput
						mask="9999999999"
						disabled={false}
						maskChar=""
						label={<FormattedMessage id="idIqamaNumber" />}
						placeholder="0000000000"
						name="nationalId"
						onChange={handleInputChange}
						errorText={isError && isError.nationalId.error && isError.nationalId.errorMessage}
					/>
				</Grid>
				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<MaskInput
						mask="+\966 999 999 999"
						disabled={false}
						maskChar=""
						label={<FormattedMessage id="phoneNumber" />}
						placeholder="eg: 978-9854-4666"
						name="contactNumber"
						onChange={handleInputChange}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{isContactValid &&
										<img alt="" src={require('assets/icons/verified.png')} height='21px' width='21px' style={{ marginLeft: locale === 'en' && '4px', marginRight: locale === 'ar' && '4px' }} />
									}
								</InputAdornment>
							)
						}}
						errorText={isError && isError.contactNumber.error && isError.contactNumber.errorMessage}
					/>
				</Grid>

				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "70px" }}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="goBack" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									minWidth="100px"
									marginRight="40"
									padding="0px"
									onClick={() => navigate(getSignInURL(locale))}
								/>
								<ButtonInput
									title={<FormattedMessage id="continue" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									disabled={loader}
									endIcon={loader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : <ArrowForwardIcon />}
									onClick={() => handleSubmit()}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>

				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "10px" }}>
					{!loader &&
						<Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
							{AuthError ?
								<Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
									{AuthError}
								</Text>
								:
								<Text size="18" marginTop="5" color="sucessColor" fontWeight="600" lineHeight="27">
									{AuthData?.message}
								</Text>
							}
						</Grid>
					}
				</Grid>
			</Grid>
		</>
	);
}

export default Register;