import {
    GET_NOTIFICATIONS_LIST,
    NOTIFICATIONS_ERROR,
    NOTIFICATIONS_SUCCESS,
} from "./constant";


const screenReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_LIST:
            return { ...state, loading: true, error: '' };
        case NOTIFICATIONS_ERROR:
            return { ...state, type: NOTIFICATIONS_ERROR, loading: false, error: action.payload };
        case NOTIFICATIONS_SUCCESS:
            return { ...state, type: NOTIFICATIONS_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default screenReducer;