import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import FirebaseConfig from './Firebase.Config';
import SessionStorageManager from 'managers/SessionStorageManager';

const app = initializeApp(FirebaseConfig);
const messaging = getMessaging(app);

//////FCM Token////////////////////////////////////
export const RequestPermission = () => {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            getToken(messaging, { vapidKey: "BEm1LLPaOAEkUikaI0ktYD-ZsKHnIMEFW6x3_cALzORmFQ0VRgSCKD0qEZFuS4qGJXkRDq7e6zE4_UQd6LSfmEM" }).then((currentToken) => {
                if (currentToken) {
                    console.log(currentToken);
                    SessionStorageManager.setItem('fcmToken', currentToken);
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        }
    });
};
//////FCM Token////////////////////////////////////






