import {
    CAMPAIGN_SEARCH
} from "./constant";

export const campaignSearchCallAPI = (type, data, external = {}) => {
    switch (type) {
        case CAMPAIGN_SEARCH:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

