import React from 'react';
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RobotImage from 'assets/images/rob.png';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import ButtonInput from 'components/Input/ButtonInput';
import { Navigate, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Error404 = () => {
    const navigate = useNavigate()

    return (
        <Box sx={{ backgroundColor: "#F1EDF5", display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container maxWidth={"600px"} margin="auto" alignItems={"center"} display="flex" justifyContent={"center"} padding="20px">
                        <Grid item xs={12} md={10} order={{ xs: 2, md: 1 }} >
                            <H1Text color="errorColor">
                                404
                            </H1Text>

                            <Text size="24" color="lightGrey">
                                <FormattedMessage id="page404.heading" />
                            </Text>
                            <Text size="18" color="lightGrey">
                                <FormattedMessage id="page404.subscription" />
                            </Text>
                            <Box marginTop={"20px"}>
                                <ButtonInput
                                    title={<FormattedMessage id="back"/>}
                                    variant="contained"
                                    bgColor="yellow"
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={() => navigate(-1)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={2} display="flex" justifyContent={"center"} order={{ xs: 1, md: 2 }}>
                            <img width="150" alt="AJLF" src={RobotImage} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Error404;
