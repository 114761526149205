import React from 'react';
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const WrapperFormLabel = styled(FormLabel)`
	font-size: 16px !important;
	line-height: 24px !important;
	font-weight: 700 !important;
	font-family: ${(props) => props.theme.KarbonLight} !important;
	color: ${(props) => props.theme.darkSecondary} !important;
	margin-top: 15px;
	margin-bottom: 5px;
`;

function DateTimePickerInput({containerProps, ...props }) {
	return (
		<FormControl fullWidth>
			<WrapperFormLabel id="yes-no-input-radio-button">{props.label}</WrapperFormLabel>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
	        	<DatePicker label={props.placeholder} />
	    	</LocalizationProvider>
    	</FormControl>
	);
}

export default DateTimePickerInput;