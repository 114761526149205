import {
   PAYMENT_SCHEDULE, PAYMENT_SCHEDULE_ERROR, PAYMENT_SCHEDULE_SUCCESS
} from "./constant";

const paymentScheduleReducer = (state = {}, action) => {
   switch (action.type) {
      case PAYMENT_SCHEDULE:
         return { ...state, loading: true, error: '' };
      case PAYMENT_SCHEDULE_ERROR:
         return { ...state, type: PAYMENT_SCHEDULE_ERROR, loading: false, error: action.payload };
      case PAYMENT_SCHEDULE_SUCCESS:
         return { ...state, type: PAYMENT_SCHEDULE_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default paymentScheduleReducer;