import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Text from "components/Text";
import listImage from "assets/icons/Tabs/listImage.png";
import arrowRightIcon from "assets/icons/Tabs/arrowRight.png";
import styled from 'styled-components';
import EmptyListImage from "assets/icons/Tabs/amico.png";
import { FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';

import { notificationsCallAPI } from "store/notifications/action";
import { GET_NOTIFICATIONS_LIST } from "store/notifications/constant";

export const TabWrapper = styled.div`
    .isoDotShow{
        margin-left:5px;
        border-radius: 50%;
        height: 8px;
        width: 8px;
        z-index:1;
        background:#FECB26;
    };

    .MuiAppBar-root {
        box-shadow:none;
        background:none;
        color:#3F1956;
        width:100%;
    }

    .Mui-selected {
        opacity: 1;
        border:none;
        background:#3D1053;
        color:#FFFFFF;
        width:130px;
        border:0.5px solid #3D1053;
    }

    .MuiTabs-indicator {
        height: 0px;
        background-color: #FFFFFF;
    }

    .MuiTab-root {
        min-width: 90px;
        min-height: 44px;
        margin: 0 5px 0 5px;
        border-radius:28px;
        padding:4px;
        height:44px;
        border:0.5px solid #3F1956;
    }
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const arr = [1, 2, 3, 4];
function FullWidthTabs() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();

    const notificationsList = useSelector((state) => (state?.notificationsReducer?.payload?.data?.data?.results));
    const loader = useSelector((state) => state.notificationsReducer?.loading);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    useEffect(() => {
        getNotificationsList();
    }, []);

    const getNotificationsList = () => {
        dispatch(notificationsCallAPI(GET_NOTIFICATIONS_LIST));
    };

    return (
        <Box sx={{ bgcolor: 'background.paper', maxWidth: 400 }}>
            <TabWrapper>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label={<div style={{ display: "flex", alignItems: "center" }}><span> <FormattedMessage id="general"/></span> <span className="isoDotShow" /></div>} {...a11yProps(0)} />
                        <Tab label={<div style={{ display: "flex", alignItems: "center" }}><span><FormattedMessage id="action"/></span> <span className="isoDotShow" /></div>} {...a11yProps(1)} />
                        <Tab label={<div style={{ display: "flex", alignItems: "center" }}><span><FormattedMessage id="archived"/></span> <span className="isoDotShow" /></div>} {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        {!loader ? (
                            <>
                                {notificationsList && notificationsList.length > 0 ? notificationsList.map((key, idx) => (
                                    <>
                                        <Grid container>
                                            <Grid item md={12} style={{ padding: "0px" }}>
                                                <Text size="16" marginTop="24" color="darkGray" fontWeight="900" fontFamily="Karbon">
                                                    {moment(key?.sent_on).format("DD MMM' YYYY")}
                                                </Text>
                                            </Grid>
                                        </Grid>

                                        <Grid container style={{ marginTop: "8px", alignItems: "center", borderBottom: "0.5px solid #D9D9D9", padding: " 0 0 8px 0" }}>
                                            <Grid item md={8} style={{ padding: "0px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <img src={listImage} alt='logo' height={'44px'} width={'44px'} />
                                                    <div style={{ marginLeft: "12px" }}>
                                                        <Text size="16" marginTop="0" color="lightDark" fontWeight="800" fontFamily="Karbon">
                                                            {key?.title}
                                                        </Text>
                                                        <Text size="16" marginTop="4" lineHeight="16" padding="0" color="darkPrimary" fontWeight="600" fontFamily="Karbon">
                                                            {key?.message}
                                                        </Text>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item md={4} style={{ padding: "0px" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                    <Text size="12" marginTop="0" color="lightDark" fontWeight="600" fontFamily="Karbon">
                                                        {moment(key?.sent_on).format("hh:mm a")}
                                                    </Text>
                                                    <span className="isoDotShow" />
                                                    <img src={arrowRightIcon} alt='logo' height={'24px'} width={'24px'} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </>
                                )) : (
                                    <>
                                        <Grid conatiner style={{ marginTop: "70px" }}>
                                            <Grid item md={12} style={{ padding: "0px" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img src={EmptyListImage} alt="image" />
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid conatiner style={{ marginTop: "24px" }}>
                                            <Grid item md={12} style={{ padding: "0px" }}>
                                                <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                                    <Text size="20" marginTop="0" color="black_secondary" fontWeight="700" fontFamily="Karbon">
                                                       <FormattedMessage id="noNotifications"/>
                                                    </Text>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </>
                        ) : ( 
                            <Grid conatiner style={{ marginTop: "70px" }}>
                                <Grid item md={12} style={{ padding: "0px", display: "flex", justifyContent: "center" }}>
                                    <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />
                                </Grid>
                            </Grid>
                        )}
                    </TabPanel>

                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <Grid conatiner style={{ marginTop: "70px" }}>
                            <Grid item md={12} style={{ padding: "0px" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={EmptyListImage} alt="image" />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid conatiner style={{ marginTop: "24px" }}>
                            <Grid item md={12} style={{ padding: "0px" }}>
                                <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <Text size="20" marginTop="0" color="black_secondary" fontWeight="700" fontFamily="Karbon">
                                       <FormattedMessage id="noNotifications"/>
                                    </Text>
                                </div>
                            </Grid>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={2} dir={theme.direction} >
                        <Grid conatiner style={{ marginTop: "70px" }}>
                            <Grid item md={12} style={{ padding: "0px" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={EmptyListImage} alt="image" />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid conatiner style={{ marginTop: "24px" }}>
                            <Grid item md={12} style={{ padding: "0px" }}>
                                <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <Text size="20" marginTop="0" color="black_secondary" fontWeight="700" fontFamily="Karbon">
                                       <FormattedMessage id="noNotifications"/>
                                    </Text>
                                </div>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </SwipeableViews>
            </TabWrapper>
        </Box >
    );
};

export default FullWidthTabs;
