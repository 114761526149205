import {
   CHANGE_PASSWORD,
   CHANGE_PASSWORD_ERROR,
   CHANGE_PASSWORD_SUCCESS
} from "./constant";

const changePasswordReducer = (state = {}, action) => {
   switch (action.type) {
      case CHANGE_PASSWORD:
         return { ...state, loading: true, error: '' };
      case CHANGE_PASSWORD_ERROR:
         return { ...state, type: CHANGE_PASSWORD_ERROR, loading: false, error: action.payload };
      case CHANGE_PASSWORD_SUCCESS:
         return { ...state, type: CHANGE_PASSWORD_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default changePasswordReducer;