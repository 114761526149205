import React from 'react';
import FormControl from '@mui/material/FormControl';
import LabelWithInput from "components/Input/LabelWithInput";
import InputMask from "react-input-mask";

function MaskInput({containerProps, ...props }) {
	return (
		<FormControl fullWidth>
			<InputMask
		        
		        {...props}
		      >
		        {() => <LabelWithInput {...props} />}
	      	</InputMask>
		</FormControl>
	);
}

export default MaskInput;