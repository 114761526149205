import { takeLatest, put } from "redux-saga/effects";
import {
	DETAILS_LOAN, DETAILS_LOAN_ERROR, DETAILS_LOAN_SUCCESS,
} from "./constant";
import url from "utils/services/urls.json";
import { postMethod } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";

function* detailsLoanCall(action) {
	try {
		const response = yield postMethod(url.detailsLoan, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: DETAILS_LOAN_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: DETAILS_LOAN_ERROR, payload: response });
			if (action.external && action.external.errorStep) {
				action.external.handleStep(action.external.errorStep);
			}
			if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
		}
	} catch (error) {
		yield put({ type: DETAILS_LOAN_ERROR, payload: error });
	}
};


function* detailsLoanCallSaga() {
	yield takeLatest(DETAILS_LOAN, detailsLoanCall);
};

export default detailsLoanCallSaga;
