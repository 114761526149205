import { takeLatest, put } from "redux-saga/effects";
import {
    ACCOUNT_API_SUCCESS,
    ACCOUNT_API_ERROR,
    ACCOUNT_REGISTER_DEVICE,
    ACCOUNT_PASSWORD_CHANGE,
    ACCOUNT_AUDIT_LOG_DATA
} from "./constant";
import url from "utils/services/urls.json";
import { getMethodWithToken, postMethodWithToken } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";

function* getUserRegisterDevice(action) {
    try {
        const response = yield getMethodWithToken(url.getUserDeviceList);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: ACCOUNT_API_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: ACCOUNT_API_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: ACCOUNT_API_ERROR, payload: error });
    }
};

function* userChangePassword(action) {
    try {
        const response = yield postMethodWithToken(url.getUserChangePassword, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: ACCOUNT_API_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: ACCOUNT_API_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: ACCOUNT_API_ERROR, payload: error });
    }
};

function* getAuditLogData(action) {
    try {
        const response = yield getMethodWithToken(url.getAuditLogData);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: ACCOUNT_API_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: ACCOUNT_API_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: ACCOUNT_API_ERROR, payload: error });
    }
};

function* accountSaga() {
    yield takeLatest(ACCOUNT_REGISTER_DEVICE, getUserRegisterDevice);
    yield takeLatest(ACCOUNT_PASSWORD_CHANGE, userChangePassword);
    yield takeLatest(ACCOUNT_AUDIT_LOG_DATA, getAuditLogData);
};

export default accountSaga;
