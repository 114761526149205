import React from 'react';
import { FormattedMessage } from "react-intl";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';

import Text from "components/Text";
import LabelWithInput from "components/Input/LabelWithInput";
import ButtonInput from "components/Input/ButtonInput";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 1260,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: '16px',
};

function ExpensesModal({modalActive, handleModalPopup}) {

	return (
		<Modal 
			open={modalActive}
			aria-labelledby="modal-modal-title"
    		aria-describedby="modal-modal-description">
    		<Box sx={style}>
    			<div className="header">
			       	<Grid container spacing={2}>
			       		<Grid item xs={12}>
			       			<Text size="40" marginTop="0" color="lightDark" fontWeight="800" fontFamily="Karbon_semibold">
			       				<FormattedMessage id="Expenses" />
			       			</Text>
			       		</Grid>
			       	</Grid>
		       	</div>
		       	<div className="content">
		       		<Grid container spacing={2}>
			       		<Grid item xs={6}>
			       			<LabelWithInput 
								label="Groceries Expenses" 
								placeholder="Groceries Expenses" 
								InputProps = {{
					      			endAdornment: (
					        			<InputAdornment position="end">SAR</InputAdornment>
					    			)
					      		}}
							/>
			       		</Grid>
			       		<Grid item xs={6}>
			       			<LabelWithInput 
								label="Housing Expenses" 
								placeholder="Housing Expenses" 
								InputProps = {{
					      			endAdornment: (
					        			<InputAdornment position="end">SAR</InputAdornment>
					    			)
					      		}}
							/>
			       		</Grid>
			       		<Grid item xs={6}>
			       			<LabelWithInput 
								label="Water & Electricity" 
								placeholder="Water & Electricity" 
								InputProps = {{
					      			endAdornment: (
					        			<InputAdornment position="end">SAR</InputAdornment>
					    			)
					      		}}
							/>
			       		</Grid>
			       		<Grid item xs={6}>
			       			<LabelWithInput 
								label="Home Labor Expenses" 
								placeholder="Home Labor Expenses" 
								InputProps = {{
					      			endAdornment: (
					        			<InputAdornment position="end">SAR</InputAdornment>
					    			)
					      		}}
							/>
			       		</Grid>
			       		<Grid item xs={6}>
			       			<LabelWithInput 
								label="Education Expenses" 
								placeholder="Education Expenses" 
								InputProps = {{
					      			endAdornment: (
					        			<InputAdornment position="end">SAR</InputAdornment>
					    			)
					      		}}
							/>
			       		</Grid>
			       		<Grid item xs={6}>
			       			<LabelWithInput 
								label="Communication & Transport" 
								placeholder="Communication & Transport" 
								InputProps = {{
					      			endAdornment: (
					        			<InputAdornment position="end">SAR</InputAdornment>
					    			)
					      		}}
							/>
			       		</Grid>
			       		<Grid item xs={6}>
			       			<LabelWithInput 
								label="Healthcare Expenses" 
								placeholder="Healthcare Expenses" 
								InputProps = {{
					      			endAdornment: (
					        			<InputAdornment position="end">SAR</InputAdornment>
					    			)
					      		}}
							/>
			       		</Grid>
			       		<Grid item xs={6}>
			       			<LabelWithInput 
								label="Insurance Expenses" 
								placeholder="Insurance Expenses" 
								InputProps = {{
					      			endAdornment: (
					        			<InputAdornment position="end">SAR</InputAdornment>
					    			)
					      		}}
							/>
			       		</Grid>
			       		<Grid item xs={6}>
			       			<LabelWithInput 
								label="Future Expenses" 
								placeholder="Future Expenses" 
								InputProps = {{
					      			endAdornment: (
					        			<InputAdornment position="end">SAR</InputAdornment>
					    			)
					      		}}
							/>
			       		</Grid>
		       		</Grid>
		       	</div>
		       	<div className="footer" style={{ marginTop: "30px" }}>
			       	<Grid container spacing={2}>
			       		<Grid item xs={12}>
			       			<div className="content" style={{ display: "flex", justifyContent: "end" }}>
			       				<Box component="form">
					       			<Stack spacing={2} direction="row">
					       				<ButtonInput 
											title="Cancel"
											variant="text" 
											bgColor="transparent"
											textColor="purple"
											borderRadius="100"
											TextTransform="uppercase"
											onClick={() => handleModalPopup(false)}
										/>
										<ButtonInput 
											title="Save"
											variant="contained" 
											bgColor="yellow"
											textColor="purple"
											borderRadius="100"
											TextTransform="capitalize"
											onClick={() => handleModalPopup(false)}
										/>
									</Stack>
								</Box>
							</div>
			       		</Grid>
			       	</Grid>
		       	</div>
	       </Box>
		</Modal>
	);
}

export default ExpensesModal;