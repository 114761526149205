import MaskInput from 'components/Input/MaskInput'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, Grid, Stack } from '@mui/material';
import ButtonInput from 'components/Input/ButtonInput';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import H5Text from 'components/Text/H5Text';
import { getApplicationSubmitted, getCashFinancingApplicationApprovedURL, getCashFinancingCongratulationsURL, getCashFinancingConsentFormURL, getRevisedFinancingApplicationApprovedURL, getVerificationCall } from 'routes/AppUrls';
import { useNavigate } from 'react-router-dom';
import { LocaleContext } from 'routes/Router';
import LocalStorageManager from 'managers/LocalStorageManger';
import SelectInput from 'components/Input/SelectInput';
import LabelWithInput from 'components/Input/LabelWithInput';
import DateTimePickerInput from 'components/Input/DateTimePickerInput';
import CheckboxInput from 'components/Input/CheckboxInput';

const CertificateStatement = ({ handleCurStep }) => {
	const navigate = useNavigate();
	const { locale } = useContext(LocaleContext);

	const handleClick = () => {
		navigate(getApplicationSubmitted(locale))
		// navigate(getVerificationCall(locale))
		LocalStorageManager.setItem('approved', true);
	};

	return (
		<>
			<Grid container >
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id={'Reason for Finance Request'} />
						{/* <img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} /> */}
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>

							<Grid item xs={12} md={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span><FormattedMessage id={'Expenses Coverage'} /></span>
									</Box>}
									placeholder="Office Space"
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<SelectInput
									label={<Box display='flex' justifyContent='space-between' width='100%' alignItems={'center'}>
										<span><FormattedMessage id={'Investment or Expansion'} /></span>
									</Box>}
									placeholder="None"
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<LabelWithInput
									label={<FormattedMessage id="Comment" />}
									placeholder="Lorem ipsum dolor sit amet consectetur. A sed maecenas semper posuere pharetra dictum blandit vivamus eget. Quis lacinia gravi"
								/>
							</Grid>

						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container sx={{ marginTop: "79px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(1)}
								/>
								<ButtonInput
									title={<FormattedMessage id="Submit Application" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									onClick={() => handleClick()}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</>
	)
}

export default CertificateStatement
