import {
    UPLOAD_DOCUMENT_VAT
} from "./constant";

export const uploadDocumentVatCallAPI = (type, data, external = {}) => {
    switch (type) {
        case UPLOAD_DOCUMENT_VAT:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

