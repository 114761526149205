import { takeLatest, put } from "redux-saga/effects";
import {
    FINANCING_DROPDOWN,
    FINANCING_DROPDOWN_SUCCESS,
    FINANCING_DROPDOWN_ERROR
} from "./constant";
import url from "utils/services/urls.json";
import { getMethodWithToken } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";

function* getFinancingDropdown(action) {
    try {
        const response = yield getMethodWithToken(url.getCarListDropdown);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: FINANCING_DROPDOWN_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: FINANCING_DROPDOWN_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: FINANCING_DROPDOWN_ERROR, payload: error });
    }
}

function* financingDropDownSaga() {
    yield takeLatest(FINANCING_DROPDOWN, getFinancingDropdown);
}

export default financingDropDownSaga;