import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import IconWithText from 'components/IconWithText';
import SlickCarousel from 'components/SlickCarousel';
import H5Text from 'components/Text/H5Text';
import BrandImage from "assets/images/Application/Monogram.png";
import IconImage from "assets/images/icon/UnionSmall.png";
import SummaryText from 'components/Text/SummaryText';
import ButtonInput from "components/Input/ButtonInput";
import { Grid, InputAdornment } from '@mui/material';
import StartWithIcon from 'components/Text/StartWithIcon';
import SelectInput from 'components/Input/SelectInput';
import LabelWithInput from 'components/Input/LabelWithInput';
import ContinueButton from 'components/Buttons/ContinueButton/ContinueButton';
import OneWaySlider from 'components/RangeSlider/OneWaySlider';
import NavList from 'components/Navbar/NavList';
import backArrow from 'assets/images/icon/backArrow.png';
import { FormattedMessage } from 'react-intl';
import { LocaleContext } from 'routes/Router';
import Text from "components/Text";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getFindVehicleURL, getFinancingURL } from "routes/AppUrls";
import ArrowLeftIcon from "assets/images/Application/ApplicationDeatils/ArrowLeft.png";

const ContainerStyle = styled.div`
padding: 0 5% 5% 5%;
.BackHeading{
    display: flex;
    align-items: center;
    img{
        margin-top: 7px;
        margin-right:24px;
    }
}
@media screen and (max-width:768px){
    .BackHeading{
        img{
            margin-top: 8px;
            margin-right:12px;
            height:18px;
        }
    }
}
background:#f1edf5;
.labelInput{
    margin-top:24px;
}
.labelDesign{
    font-family: Karbon_medium;
    font-size: 16px;
    font-weight: 700;
    // display:flex;
    // justify-content:space-between;
    // align-items:center;
    .star{
        color:red;
    }
}
.image{
    height:20px;
    margin-left:14px;
}
.percent{
    margin-left:14px;
}
.ApplyButton{
color:var(--primary);
 font-family: Karbon_medium;
font-size: 18px;
font-weight: 800;
cursor:pointer;

}
`
const ProductDetail = styled.div`
background:#ffffff;
padding:32px;
border-radius:16px;
height:100%;
.IconWithText{
    margin-top:24px;
}
@media screen and (max-width:767px){
    padding: 32px 16px;
}
`
const FinanceCal = styled.div`
background:#ffffff;
border-radius:16px;
position: relative;
.innerFinance{
padding:20px 24px;
}
.Slider{
    margin-top:25px;
}
.CalBtn{
    margin:40px 0px 28px;
    display:flex;
    justify-content:end;
}
@media screen and (max-width:1199px){
    margin-top:40px;
}
@media screen and (max-width:1199px){
    height:fit-content;
    .CalBtn{
        margin: 20px 0px 0px;
       width:100% !important;
       button{
        width:100%;
       }
    }
    .innerFinance{
        padding: 20px 16px;
    }

}

`
const FinanceResult = styled.div`
background:#ffffff;
border-radius:16px;
@media screen and (max-width:1399px) {
    margin-top:40px;
}
@media screen and (max-width:991px) {
    margin-top:40px;
}
`
const IconGroup = styled.div`
// position:absolute; 
right: 0;
display: flex;
justify-content: end;
padding: 10px 20px 0 0;
border-radius: 12px;
max-height:25%;
`
const IconStyle = styled.div`
background:${(props) => props?.background};
border-radius:50%;
min-width:40px;
min-height:40px;
max-width:40px;
max-height:40px; style={{margin:'0'}}
display:flex;
align-items:center;
justify-content:center;
margin-left:${(props) => props?.marginleft};
`
const UpperHead = styled.div`
display: flex;
justify-content: space-between;
`
const FinanceData = styled.div`

font-family:Karbon_light;
font-weight:600;
color:#1D1B20;
padding:0 28px 0 28px;
font-size:20px;

.containerData{
    padding:20px 0 14px 0;
    border-bottom:1px solid #E8E8E8;
    .star{
        color:red;
    }
    .Price{
        color:var(--primary);
        font-size:32px;
        font-family:Karbon_semibold;
        font-weight:800;
        display: flex;
        justify-content: end;
        align-items: center;
        span{
            font-size:20px;
            font-family:Karbon_medium;
            font-weight:700;
            color:#8F8D95;
            margin-left:8px;
            
        }
    }
}
@media screen and (max-width:1199px){
    .containerData{
    .Price{
        justify-content: start;
    }
}
}
`

const GirdContainer = styled.div`
margin-top:30px;
.GirdItem{
    border:1.44px solid #DEDEDE;
    border-radius:11.52px;
    cursor:pointer;
}
.selected{
    border:2px solid #3F1956;
}
.unionimg{
    position:absolute;
    height:18px;
    width:25px;
    margin-left:-20px;
    margin-top:-7px;
    background:#ffffff;
    
}
`
const SelectAmong = styled.div`
margin-top:24px;
.itemContainer{
display:flex;
margin-top:8px !important;
gap: 10px;
.item{
    margin-right: 10px;
    // padding:12.5px 20px;
    color:#909FA6;
   border:1px solid #909FA6;
    border-radius:5px;
    width:156px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.selected{
   color:#14303A;
   border:1px solid #14303A;
}
}
`
const arr = [
    { id: 1, name: "All Cars", is_select: false }, 
    { id: 2,count:"20x", name: "Yaris", is_select: false }, 
    { id: 3,count:"10x", name: "KIA Sonet", is_select: false },
    { id: 4,count:"30x", name: "KIA Sonet X", is_select: false }, 
    { id: 5,count:"20x", name: "Mercedes E Class", is_select: false }, 
    { id: 6,count:"20x", name: "Yaris", is_select: false }
];

const FinanceCalculator = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { locale } = useContext(LocaleContext);

    const [selectedCar, setSelectedCar] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState(null);
    const [selectedVATPaymentType, setSelectedVATPaymentType] = useState(null);

    const [carlistArray, setCarListArray] = useState(arr);

    const handleClick = (data) => {
        carlistArray.map((k, i) => {
            if (k.id === data.id) {
                setCarListArray([...carlistArray], carlistArray[i].is_select = true);
            } else {
                setCarListArray([...carlistArray], carlistArray[i].is_select = false);
            }
        })
    };
    

    const items = [
        {
            id: 1,
            img: require('assets/images/carTransprent.png')
        },
        {
            id: 2,
            img: require('assets/images/carTransprent.png')
        },
    ]
    const demoCarData = [
        {
            id: 1,
            img: require('assets/images/carTransprent.png')
        },
        {
            id: 2,
            img: require('assets/images/carTransprent.png')
        },
        {
            id: 3,
            img: require('assets/images/carTransprent.png')
        },
        {
            id: 4,
            img: require('assets/images/carTransprent.png')
        },
    ]

    const demoResult = [
        {
            type: <FormattedMessage id="Monthly Payment"/>,
            price: "1,845.00",
            redDot: true,
            tag: "SAR"
        },
        {
            type: <FormattedMessage id="Selling Price"/>,
            price: "4,845.00",
            tag: "SAR"
        },
        {
            type: <FormattedMessage id="Selling Price VAT"/>,
            price: "654.00",
            exclemation: true,
            percentage: '15%',
            tag: "SAR"
        },
        {
            type: <FormattedMessage id="Down Payment"/>,
            price: "0.00",
            redDot: true,
            tag: "SAR"
        },
        {
            type: <FormattedMessage id="Finance Amount/Formula Pricet"/>,
            price: "0.00",
            exclemation: true,
            tag: "SAR"
        },
        {
            type: <FormattedMessage id="Period"/>,
            price: "60",
            tag: "Months"
        },
        {
            type: <FormattedMessage id="Residual Amount"/>,
            price: "1,845.00",
            percentage: '35%',
            redDot: true,
            tag: "SAR"
        },
    ]
    const handleCarSelect = (id) => {
        setSelectedCar(id);
    };
    const [monthlyAmount , setMonthlyAmount] = useState(80000)
    const [period , setPeriod] = useState(12)

    const paymentType = [
        {
            id: 1,
            type: <FormattedMessage id="Down Payment"/>
        },
        {
            id: 2,
            type: <FormattedMessage id="Monthly Payment"/>
        },
    ]
    const VATpaymentType = [
        {
            id: 1,
            type: <FormattedMessage id="Online"/>
        },
        {
            id: 2,
            type: <FormattedMessage id="Finance"/>
        },
    ]
    return (
        <ContainerStyle>
                <NavList />
            <div className='BackHeading'>
               <img src={backArrow} onClick={() => navigate(-1)} alt="" style={{ cursor: "pointer", transform: locale === 'ar' ? 'scaleX(-1)' : '', margin: locale === 'en' ? '7px 24px 0 0' : '7px 0 0 24px' }} />
            <H5Text size="32" marginTop={'14'} color={'purple'}><FormattedMessage id ="Find your perfect vehicle"/></H5Text>
            </div>
            <Row style={{ marginTop: '24px' }}>
                <Col sm={12} md={12} lg={12} xl={6} xxl={4}>
                    <ProductDetail>
                            <Grid container>
                                <Grid item xs={12}>
                                    <H5Text color="purple" size="32"><FormattedMessage id ="Selection Details"/></H5Text>
                                </Grid>
                            
                                <Grid item xs={12}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Text marginTop="15" color="lightGrey" size="24" fontFamily="KarbonSemiBold" fontWeight='700' lineHeight={'24'}>
                                            
                                            <FormattedMessage id="contract.totalNoOfVehicles"/>:
                                        </Text>
                                        <Text marginTop="15" color="purple" size="32" fontFamily="KarbonBold" fontWeight='800' lineHeight={'32'} style={{marginRight:locale === 'ar' && '8px', marginLeft:locale === 'en' && '8px'}}>
                                            80
                                        </Text>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{alignItems: "center", marginTop: "12px", flexWrap: "wrap" }}>
                                {carlistArray.map((k, i) => (
                                    <Grid item>
                                        <ButtonInput
                                            title={<><H5Text size={'20'} lineHeight='21.23' color='purple' fontFamily='Karbon_light' marginTop={'0'} style={{marginRight:'6px'}} >{k.count}</H5Text><H5Text size={'20'} lineHeight='24' fontFamily='Karbon_semiBold' marginTop={'0'}>{k.name}</H5Text> </>}
                                            variant="text"
                                            bgColor={k.is_select ? "checkboxPurple" : "transparent"}
                                            textColor="lightGray"
                                            borderRadius="50"
                                            fontSize="20"
                                            border={k.is_select ? '1px solid #D4BDE0' : '1px solid #E2E2E2'}
                                            endIcon={<ArrowForwardIcon fontSize="small" />}
                                            minWidth='auto'
                                            padding="12px 16px"
                                            onClick={() => handleClick(k)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        
                    </ProductDetail>
                </Col>
                <Col sm={12} md={12} lg={6} xl={6} xxl={4} >
                    <FinanceCal>
                        <H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={0}><FormattedMessage id="Finance Calculator"/></H5Text>
                        <div className='innerFinance'>
                            <SelectAmong>
                                <span className='labelDesign'>
                                    <span><FormattedMessage id="Payment Type"/></span>
                                    <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                </span>
                                <Grid className='itemContainer' container  >
                                    {paymentType.map((data) => (
                                        <Grid
                                            className={`item ${selectedPaymentType === data.id ? 'selected' : ''}`}
                                            item
                                            key={data.id}
                                            onClick={() => setSelectedPaymentType(data.id)}
                                        >
                                            <span>{data.type}</span>
                                        </Grid>
                                    ))}
                                </Grid>
                            </SelectAmong>
                            <div className='Slider'>
                                <OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={10} endLimit={90000} label={<FormattedMessage id="Monthly Payment Amount"/>} defaultValue={monthlyAmount} value={monthlyAmount}  setValue={(data)=>setMonthlyAmount(data)} />
                            </div>
                            <div className='Slider'>
                                <OneWaySlider measure={"Months"} sliderLabel={true} startLimit={1} endLimit={60} label={<FormattedMessage id="Period"/>} defaultValue={period} value={period}  setValue={(data)=>setPeriod(data)}/>
                            </div>
                            <SelectAmong >
                                <span className='labelDesign'>
                                    <span><FormattedMessage id="Finance VAT"/></span>
                                    <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                </span>
                                <Grid className='itemContainer' container  >
                                    {VATpaymentType.map((data) => (
                                        <Grid
                                            className={`item ${selectedVATPaymentType === data.id ? 'selected' : ''}`}
                                            item
                                            key={data.id}
                                            onClick={() => setSelectedVATPaymentType(data.id)}
                                        >
                                            <span>{data.type}</span>
                                        </Grid>
                                    ))}
                                </Grid>
                            </SelectAmong>
                            <div className='labelInput'>
                                <LabelWithInput
                                    label={
                                        <span className='labelDesign'>
                                            <span><FormattedMessage id="Promo Code"/></span>
                                        </span>
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <span className='ApplyButton'>Apply</span>
                                        )
                                    }}
                                />
                            </div>
                            <div className='CalBtn' >
                                <ContinueButton background={'var(--primary)'} text={<FormattedMessage id="Calculate"/>} color={'#ffffff'} width={'222px'} />
                            </div>
                        </div>
                    </FinanceCal>
                </Col>
                <Col sm={12} md={12} lg={6} xl={6} xxl={4}>
                    <FinanceResult>
                        <H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark" marginTop={0}><FormattedMessage id="Financing Results"/></H5Text>
                        {
                            demoResult.map((data) => (
                                <FinanceData>
                                    <div className="containerData">
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={8}>
                                                <span style={{display:'flex', alignItems:'center'}}>
                                                    <span>{data.type}{data.redDot && <span className='star'>*</span>}</span>
                                                    {data.exclemation &&
                                                        <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                                                    }
                                                    <div>

                                                     <span className='percent' >{data.percentage}</span>
                                                    </div>
                                                </span>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <span className='Price'>{data.price}<span>{data.tag}</span></span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </FinanceData>
                            ))
                        }
                        <Grid container spacing={4} style={{ padding: '64px 28px 20px' }}>
                            <Grid item xs={12} md={6}  >
                                <ButtonInput
                                    title={<FormattedMessage id="Offline Financin" />}
                                    variant="text"
                                    bgColor="transparent"
                                    borderColor="purple"
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    size={"24"}
                                    padding={"10px 16px"}
                                    width={'100%'}
                                    minWidth={'0'}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ButtonInput
                                    title={<FormattedMessage id="fastFinancing" />}
                                    variant="text"
                                    bgColor="yellow"
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    size={"24"}
                                    padding={"10px 16px"}
                                    width={'100%'}
                                    minWidth={'0'}
                                    onClick={() => navigate(getFinancingURL(locale))}
                                />
                            </Grid>
                        </Grid>
                    </FinanceResult>
                </Col>
            </Row>
        </ContainerStyle>
    )
}

export default FinanceCalculator
