import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";

const WrapperDiv = styled.div`
	display: flex;
    justify-content: center;
    position: relative;
`;

const WrapperCircleDiv = styled.div`
	width: 130px;
    height: 130px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    background-image: conic-gradient(  #3F1956 0deg,  #3F1956 300deg,  #DFD3E7 312deg,  #DFD3E7 360deg);
`;

const WrapperFirstDiv = styled.div`
	width: 120px;
    height: 120px;
    background: #F2F1F6;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
`;

const WrapperSecondDiv = styled.div`
	width: 100px;
    height: 100px;
    background: #f9f9f9;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
`;

const WrapperCodeDiv = styled.div`
	width: 78px;
    height: 78px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 48px;
    line-height: 58px;
    font-family: Karbon_light;
    color: #3F1956;
    font-weight: 700;
`;

const WrapperTimerDiv = styled.div`
	width: 70px;
	height: 33px;
	background: #fff;
	box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
	font-size: 20px;
	line-height: 24px;
	font-weight: 700;
	font-family: Karbon_light;
	color: #3F1956;
	border-radius: 50px;
	text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    bottom: -12px;
`;

function TimerWithCode({...props}) {

	const backgroundImageTpl = `conic-gradient(
		#3F1956 0deg,
  		#3F1956 {angle}deg,
  		#DFD3E7 {angle}deg,
  		#DFD3E7 360deg
	)`;

	const [timerCount, setTimerCount] = useState("1:30");
	const [minSecondsLeft, setMinSecondsLeft] = useState(90);
	const [maxSecondsLeft, setMaxSecondsLeft] = useState(90);

	useEffect(() => {
		setTimeout(() => {
            countDowntiming();
        }, 500);

	}, [minSecondsLeft])

	function pad2Digits(text) {
  		let out = '00' + text;
  		return out.substring(out.length - 2);
	}

	function countDowntiming() {
    	let timing = pad2Digits(Math.floor(minSecondsLeft / 60))+':'+pad2Digits(minSecondsLeft % 60);
    	setTimerCount(timing);

    	let angle = 360 - Math.floor(360 * minSecondsLeft / maxSecondsLeft);
    	
    	if(document.getElementById('timecountdown')) {
    		document.getElementById('timecountdown').style.backgroundImage = backgroundImageTpl.replace(/\{angle\}/g, angle);
    	}
    	
    	if (minSecondsLeft > 0) {
    		let timingLeft = minSecondsLeft-1;
	        setMinSecondsLeft(timingLeft);
      	}  else {
      		setMinSecondsLeft(90);
      	}
	}

	return (
		<>
			<WrapperDiv data-seconds-left={props.maxSecond} data-max-seconds={props.maxSecond}>
				<WrapperCircleDiv id="timecountdown">
					<WrapperFirstDiv>
						<WrapperSecondDiv>
							<WrapperCodeDiv>
								{props.authCode}
							</WrapperCodeDiv>
						</WrapperSecondDiv>
					</WrapperFirstDiv>
				</WrapperCircleDiv>
				<WrapperTimerDiv classname="timertime">
					{timerCount}
				</WrapperTimerDiv>
			</WrapperDiv>
		</>
	)
}

export default TimerWithCode;