import React, { useEffect, useState } from 'react';
import { FormattedMessage } from "react-intl";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Text from "components/Text";
import LabelWithInput from "components/Input/LabelWithInput";
import ButtonInput from "components/Input/ButtonInput";
import CrossIcon from "assets/icons/cancel_button.png";
import { Emailverification, PhoneNumberValidation } from 'utils/general';
import MaskInput from 'components/Input/MaskInput';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95vw",
    maxWidth:500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
};

function ModalWithInput({ modalActive, handleCloseModal, title, handleEmailUpdate, handleContactUpdate, loader, error }) {
    const [value, setValue] = useState("");
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        setValue("");
        setIsValid(false);
    }, [modalActive]);

    const handleMobileChange = (e) => {

        if (PhoneNumberValidation(e.target.value.split(' ').join(''))) {
            setValue(e.target.value.split(' ').join(''));
            setIsValid(true);
        } else {
            setValue(e.target.value);
            setIsValid(false);
        }
    };

    const handleEmailChange = (e) => {
        setValue(e.target.value);
        if (Emailverification(e.target.value)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }

    const handlePostData = () => {
        if (title === "Email Update") {
            if (isValid) {
                handleEmailUpdate(value);
            }
        } else {
            if (isValid) {
                handleContactUpdate(value);
            }
        }
    };

    return (
        <Modal
            open={modalActive}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <div className="header">
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Text size="40" lineHeight={"40"} marginTop="0" color="lightDark" fontWeight="800" fontFamily="Karbon_semibold">
                                <FormattedMessage id={title} />
                            </Text>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "end" }}>
                            <img src={CrossIcon} alt='icon' style={{ cursor: "pointer" }} onClick={() => handleCloseModal(false)} />
                        </Grid>
                    </Grid>
                </div>

                <div className="content" style={{ marginTop: "20px" }}>
                    <Grid container spacing={2}>
                        {
                            title === "Email Update" ?
                                <Grid item xs={12}>
                                    <LabelWithInput
                                        placeholder="Enter Text"
                                        onChange={(e) => handleEmailChange(e)}

                                    />
                                </Grid>
                                :

                                <Grid item xs={12} md={12} lg={12}>
                                    <MaskInput
                                        mask="+\966 999 999 999"
                                        disabled={false}
                                        maskChar=" "
                                        placeholder="eg: 978-9854-4666"
                                        name="contactNumber"
                                        onChange={handleMobileChange}
                                    />
                                </Grid>
                        }
                    </Grid>
                </div>

                <div className="footer" style={{ marginTop: "30px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                                <Box component="form">
                                    <Stack spacing={2} direction="row">
                                        <ButtonInput
                                            title={<FormattedMessage id="save" />}
                                            variant="contained"
                                            bgColor={(!isValid || loader) ? "" : "yellow"}
                                            textColor="purple"
                                            borderRadius="100"
                                            disabled={!isValid || loader}
                                            TextTransform="capitalize"
                                            endIcon={loader && <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} />}
                                            onClick={() => handlePostData()}
                                        />
                                    </Stack>
                                </Box>
                            </div>
                        </Grid>

                        {!loader &&
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                {error &&
                                    <Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
                                        {error}
                                    </Text>
                                }
                            </Grid>
                        }
                    </Grid>
                </div>
            </Box>
        </Modal>
    );
}

export default ModalWithInput;