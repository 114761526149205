import {
   FINANCING_TYPE,
   LANGUAGE_TOGGLE,
   SIDEBAR_TOGGLE,
} from "./constant";

const initialValue = {
   sidebarToggle: true,
   languageToggle: 'en', // Assuming 'english' is the initial language
   financingType:true
};

const basicReducer = (state = initialValue, action) => {
   switch (action.type) {
      case SIDEBAR_TOGGLE:
         return { ...state, sidebarToggle: action.data };
      case LANGUAGE_TOGGLE:
         return { ...state, languageToggle: action.data };
      case FINANCING_TYPE:
         return { ...state, financingType: action.data };
      default:
         return state;
   }
};

export default basicReducer;