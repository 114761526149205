import {
    AUTH_TAHAKKUQ_VERIFY,
    AUTH_TAHAKKUQ_ERROR,
    AUTH_TAHAKKUQ_SUCCESS
} from "./constant";

const tahakukReducer = (state = {}, action) => {
    switch (action.type) {
        case AUTH_TAHAKKUQ_VERIFY:
            return { ...state, loading: true, error: '' };
        case AUTH_TAHAKKUQ_ERROR:
            return { ...state, type: AUTH_TAHAKKUQ_ERROR, loading: false, error: action.payload };
        case AUTH_TAHAKKUQ_SUCCESS:
            return { ...state, type: AUTH_TAHAKKUQ_SUCCESS, loading: false, payload: action.payload, error: '' };
        default:
            return state;
    }
};

export default tahakukReducer;