import React, { useContext, useState } from "react";
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import HeaderBanner from "features/OnlineFinancing/HeaderBanner";
import FinancingResults from "features/OnlineFinancing/FinancingResults";
import MuiCards from "components/MuiCards";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import Payments from "screens/Applications/payments";
import PaymentInstruction from "features/OnlineFinancing/PaymentInstruction";
import Payment from "features/OnlineFinancing/Payment";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { FormattedMessage } from "react-intl";
import { getFinancingWaitingPaymentURL } from "routes/AppUrls";
import { LocaleContext } from "routes/Router";


export const WrapperBox = styled(Box)`
    display: "flex";
    background-color: ${(props) => props.theme.lightSecondary}
`;

const PaymentDetails = () => {
    const [curStep, setCurStep] = useState(0);
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);

    const handleCurStep = (value) => {
        setCurStep(value);
    }

    const steps = [
        'Initial Data',
        'Revised Financing',
        'Personal & Work',
        'Expenses & Bank',
        'Attach & Apply'
    ];
    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="dashboard" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="vehicleSelection" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="financeCalculator" />,
            Link: "",
        },
        {
            title: <FormattedMessage id="autoFinancing" />,
            Link: "",
            isDisbale: true,
        },
    ])
    return (
        <WrapperBox>
            <Container maxWidth={'xl'} style={{ padding: "10px" }} disableGutters>
                <Grid container>
                    <Grid item xs={12}>
                        <HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} />
                    </Grid>
                </Grid>
                <Box margin='48px 0 16px 0'>
                    <MuiBreadcrumbs size='20' list={breadcrumbsList} />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Payment bgColor={"white"} />
                        <PaymentInstruction bgColor={"white"} />
                    </Grid>
                    <Grid item xs={12} md={4} marginTop={"37px"}>
                        <MuiCards borderRadius="16" padding="0">
                            <Payments onClick={() => navigate(getFinancingWaitingPaymentURL(locale))} />
                        </MuiCards>
                    </Grid>
                </Grid>
            </Container>
        </WrapperBox>
    );

};

export default PaymentDetails;