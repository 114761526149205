export const AUTH_USER_VERIFY = "AUTH_USER_VERIFY";
export const AUTH_OTP_VERIFY = "USER_OTP_VERIFY";
export const AUTH_LOGIN = "USER_LOGIN";
export const AUTH_SET_PASSWORD = "USER_SET_PASSWORD";
export const AUTH_CREATE_PASSWORD = "USER_CREATE_PASSWORD";
export const AUTH_API_ERROR = "USER_API_ERROR";
export const AUTH_API_SUCCESS = "USER_API_SUCCESS";
export const AUTH_INITIAL_STATE = "AUTH_INITIAL_STATE";
export const AUTH_API_ERROR_RESET = "AUTH_API_ERROR_RESET";
export const AUTH_WATHIQ_VERIFY = "AUTH_WATHIQ_VERIFY";
