import React from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Text from "components/Text";

const WrapperFormLabel = styled(FormLabel)`
	font-size: 16px !important;
	line-height: 24px !important;
	font-weight: 700 !important;
	font-family: ${(props) => (props.theme.KarbonSemiBold)} !important;
	color: ${(props) => (props.theme.darkSecondary)} !important;
`;

const WrapperDiv = styled.div`
	background: linear-gradient(267deg, rgba(60, 16, 83, 0.79) 22.21%, rgba(60, 16, 83, 0) 36.67%), 
		linear-gradient(88.35deg, #3C1053 14.18%, rgba(60, 16, 83, 0) 72.19%);
	background-image: url('${(props) => (props.bgImage)}');
	width: 100%;
	background-repeat: no-repeat;
    margin-right: 15px;
`;

const WrapperDivIcon = styled.div`
    display: inline-flex;
    align-items: center;
    border-right: 1px dotted ${(props) => (props.theme.green)};
    padding: 0px 15px;
`;

function DiscountBadge({containerProps, ...props }) {
	return (
		<FormControl>
			{props.heading ? (
				<WrapperFormLabel id="yes-no-input-radio-button">{props.heading}</WrapperFormLabel>
			) : (
				<WrapperFormLabel id="yes-no-input-radio-button">&nbsp;</WrapperFormLabel>
			)}
			
			<WrapperDiv {...props}>
				<div style={{ display: "flex" }}>
					<WrapperDivIcon>
						<img alt="" width="24" height="24" src={props.icon} />
					</WrapperDivIcon>
					<div style={{ padding: "5px 15px" }}>
						<Text color="green" size="24" marginTop="2" fontWeight="800" fontFamily="Karbon_semibold">
							30% off
						</Text>
						<Text color="dark" size="16" marginTop="0" fontWeight="800" fontFamily="Karbon_semibold">
							NCD Discount: 2,000 SAR
						</Text>
					</div>
				</div>
			</WrapperDiv>
		</FormControl>
	);
}

export default DiscountBadge;