import React, { useState, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import LayoutStyleWrapper from "styles/common.styles";
import styled from 'styled-components';
import TileIcon from "assets/images/Application/tile.png";
import TileIconCard from "assets/images/Application/tile1.png";
import sortIcon from "assets/images/Application/sortbutton.png";
import Card from "components/Card";
import Listing from "components/Listing";
import { useNavigate } from "react-router-dom";
import SearchInput from "components/SearchInput";
import H1Text from "components/Text/H1Text";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LocaleContext } from 'routes/Router';
import { getContractDetailsURL } from "routes/AppUrls";
import { CONTRACT_LIST } from "store/contracts/contractList/constant";
import { contractListCallAPI } from "store/contracts/contractList/action";

const StyleWrapper = styled.div`
    align-items: center;
    margin:0 2.5% 0 2.5%;
    @media screen and (max-width:576px){
        margin:0;
    }
    .application_top{
        display:flex;
        align-items:center;
        margin-top:16px;
    };

    .search{
        display:flex;
        align-items:center;
    }

    .notification{
        display:flex;
        align-items:center;
        justify-content:end;
    };
`

const headings = [
    "CONTRACT NUMBER",
    <FormattedMessage id="monthlyPayment" />,
    <FormattedMessage id="balance" />,
    <FormattedMessage id="remainingPeriod" />,
    <FormattedMessage id="overdue" />
];

const Contracts = () => {
    const [tile, setTile] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const contractList = useSelector((state) => state.contractListReducer?.payload?.data?.['data']?.['S:Envelope']?.['S:Body']?.['ns2:fetchCustomerLoanDetailsResponse']?.['return']?.['customerLoanDetailList']);
    const contractListloader = useSelector((state) => state.contractListReducer?.loading);

    const handleTile = () => {
        setTile(!tile);
    };

    const handleClick = (props) => {
        navigate(getContractDetailsURL(locale), { state: { loanData: props } });
    };

    useEffect(() => {
        ContractListApi();
    }, []);

    const ContractListApi = (value) => {
        let body = {
            "customerID": 10004090
        }
        dispatch(contractListCallAPI(CONTRACT_LIST, body, { navigate: navigate }));
    };

    return (
        <LayoutStyleWrapper locale={locale} >
            <StyleWrapper>
                <Grid container className="application_top">
                    <Grid item md={12} sm={12} xs={12}>
                        <H1Text>
                            <FormattedMessage id="dashboard.contracts" />
                        </H1Text>
                    </Grid>
                </Grid>

                <Grid container className="search" spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12} >
                        {!tile && <SearchInput width="100%" endAdornment={true} placeholder="Search..." border="0.5px solid #B8B8B8" boxShadow="0px 4px 4px 0px #3C105314" borderRadius="20px" height="40px" marginTop="0px" />}
                    </Grid>
                    <Grid item lg={8} md={6} sm={12} xs={12} >
                        <Grid container justifyContent={"flex-end"}>
                            <div className="notification">
                                <img src={sortIcon} style={{ marginRight: "16px" }} alt="notificaton" />
                                <img src={!tile ? TileIconCard : TileIcon} onClick={() => handleTile()} alt="notificaton" style={{ cursor: "pointer" }} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <div style={{ marginTop: "6px" }}>
                    {tile ? <div style={{ overflow: 'auto' }}><Listing arr={contractList} locale={locale} onClick={(props) => handleClick(props)} headings={headings} loader={contractListloader} /></div> : <Card arr={contractList} locale={locale} onClick={(props) => handleClick(props)} loader={contractListloader} />}
                </div>

            </StyleWrapper>
        </LayoutStyleWrapper>
    );
};

export default Contracts;