import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Text from "components/Text";

const BoxDiv = styled.div`
	text-align: center;
`;

const BoxGridDiv = styled(Grid)`
	border-radius: 8px;
	box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.1);
`;

function BoxSmallCard({containerProps, ...props }) {
	return (
		<Box sx={{ flexGrow: 1 }}  style={{ marginTop: '40px' }}>
        	<Grid container spacing={props.spacing}>
                <BoxGridDiv item xs={props.col}>
					<BoxDiv>
						<img src={props.src} alt={props.title} />
						<div>
							<Text marginBottom="15" color="lightDark" fontWeight="800" TextAlign="center" size="18" fontFamily="KarbonSemiBold" lineHeight="27">
								{props.title}
							</Text>
						</div>
					</BoxDiv>
				</BoxGridDiv>
            </Grid>
        </Box>
	);
}

export default BoxSmallCard;