import React from 'react';
import styled from 'styled-components';
import { string } from "prop-types";

const Wrapper = styled.sub`
	margin-bottom:0;
	font-size: ${(props) => (props.size+"px")};
	color:  ${(props) => (props.theme[props.color])};
	top: ${(props) => (props.top+"px")};
	font-family: ${(props) => (props.fontFamily)};
	font-weight: ${(props) => (props.fontWeight)};
	text-align: ${(props) => (props.TextAlign)};
	vertical-align: top;

	${(props) =>
      	props.textTransform && `
 	    text-transform:  ${props.textTransform};
    `}

    ${(props) =>
		props.padding && `
 	    padding:  ${props.padding};
    `}
`;

function Sub(props) {
	return (
		<Wrapper {...props}>
			{props.children}
		</Wrapper>
	);
}

Sub.propTypes = {
  	marginTop: string.isRequired,
  	size: string.isRequired,
  	fontWeight: string,
  	fontFamily: string
};

Sub.defaultProps = {
  	top: 10,
  	size: 24,
  	fontWeight: 600,
  	fontFamily: 'Karbon_light',
  	TextAlign: 'left',
};

export default Sub;