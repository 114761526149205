import React, { useContext } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import StepperVertical from "components/StepperFlow/StepperVertical";
import Text from 'components/Text';
import { useSelector } from "react-redux";
import { LocaleContext } from 'routes/Router';

export const AccordianStyleWrapper = styled.div`

    .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
        box-shadow:none;
    };

    .accordian_style{
        display: flex;
        margin-top: 16px;
        align-items: center;
    };

    .accordian_inner_style{
        display: flex;
        align-items: center;
    };
    .MuiStepLabel-iconContainer{
        padding-right: ${(props) => props.locale === 'en' ? '28px' : '0'} !important;
        padding-left: ${(props) => props.locale === 'ar' ? '28px' : ''} !important;
    }
    .MuiStepConnector-root{
        margin-right: ${(props) => props.locale === 'ar' ? '28px' : ''} !important;
        margin-left: ${(props) => props.locale === 'en' ? '28px' : '0'} !important;
        .MuiStepConnector-line {
            border-left-style: ${(props) => props.locale === 'en' ? 'solid' : ''} !important;
            border-left-width: ${(props) => props.locale === 'en' ? '3px' : '0'} !important;
            border-right-style: ${(props)=> props.locale === 'ar' ? 'solid' : ''} !important;
            border-right-width: ${(props)=> props.locale === 'ar' ? '3px' : ''} !important;
        }
    }
`

const BasicAccordion = (props) => {
    const { locale } = useContext(LocaleContext);

    return (
        <AccordianStyleWrapper locale={locale}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        <Text size="20" marginTop="0px" color="lightGrey">
                            {props.head}
                        </Text>
                        <div className="accordian_style">
                            <img src={props.image} alt="logo" />
                            <div style={{ marginLeft: locale === 'en' && "24px", marginRight: locale === 'ar' && "24px" }}>
                                <Text size="24" marginTop="0px" color="darkPrimary">
                                    {props.title}
                                </Text>
                                <div className="accordian_inner_style">
                                    <img src={props.dateIcon} alt="logo" style={{ marginRight: "12px" }} />
                                    <Text size="20" marginTop="0px" color="lightGrey">
                                        {props.date}
                                    </Text>
                                    <img src={props.timeIcon} alt="logo" style={{ marginLeft: "20px", marginRight: "12px" }} />
                                    <Text size="20" marginTop="0px" color="lightGrey">
                                        {props.time}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Typography>
                        <div>
                            <Text size="20" marginTop="0px" color="lightGrey">
                                All steps
                            </Text>
                        </div>
                        <div>
                            <StepperVertical />
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </AccordianStyleWrapper>
    );
};

export default BasicAccordion;