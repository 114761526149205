import { takeLatest, put } from "redux-saga/effects";
import {
	USER_LOGIN,
	USER_LOGIN_ERROR,
	USER_LOGIN_SUCCESS,
} from "./constant";
import url from "utils/services/urls.json";
import { postMethod } from "utils/services/apis";
import { ClearTokenRedirectLogin } from "utils/general";
import { unsetClient } from "store/token/action";

function* userLogin(action) {
	try {
		const response = yield postMethod(url.userLogin, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: USER_LOGIN_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: USER_LOGIN_ERROR, payload: response });
			if (action.external && action.external.errorStep) {
				action.external.handleStep(action.external.errorStep);
			}
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}
	} catch (error) {
		yield put({ type: USER_LOGIN_ERROR, payload: error });
	}
};


function* userLoginSaga() {
	yield takeLatest(USER_LOGIN, userLogin);
};

export default userLoginSaga;
