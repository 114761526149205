import React from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "react-bootstrap";
import styled from 'styled-components';
import downloadIcon from "assets/icons/downloadIcon.png";
import H5Text from 'components/Text/H5Text';
import Text from 'components/Text';
import moment from "moment";

const StyleWrapper = styled.div`
  align-items: center;

    .right_container {
        background:#ffffff;
        border-radius:16px;
    };

    p {
        margin:0px;
    }

    .right_container_top {
        background:#E6D9F5;
        padding: 8px 24px 8px 24px;
        border-radius:8px;
        height:52px;
        align-items:center;
        display:flex;
        justify-content:space-between;
    };

    .right_container_style {
        padding:20px 20px 0px 20px;
    };

    .right_container_body {
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-bottom:20px;
    };
`

const InsuranceDetail = (props) => {

    const arr = [
        { name: "company", value: "Private Sector" }, { name: "policyNumber", value: "Accountant" },
        { name: "startDate", value: "0.00 SAR" }, { name: "additionalCoverageDetails", value: "0.00 SAR" },
        { name: "status", value: "0.00 SAR" }, { name: "expiryDate", value: "No" }
    ];
    return (
        <StyleWrapper>
            <Row className="right_container">
                <div className="right_container_top">
                    <H5Text size="24" marginTop="0" color="purple" >
                        <FormattedMessage id="insuranceDetails" />
                    </H5Text>
                    <div>
                        <img src={downloadIcon} alt="logo" />
                    </div>
                </div>

                <div className="right_container_style">
                    <div className="right_container_body">
                        <div>
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"company"} />
                            </Text>
                        </div>
                        <div>
                            <Text size="20" marginTop="0" color="lightDark">
                                {props?.data?.InsuranceCompany}
                            </Text>
                        </div>
                    </div>

                    <div className="right_container_body">
                        <div>
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"startDate"} />
                            </Text>
                        </div>
                        <div>
                            <Text size="20" marginTop="0" color="lightDark">
                                {props?.data?.StartDate !== "" && moment(props?.data?.StartDate).format("Do MMM YYYY")}
                            </Text>
                        </div>
                    </div>

                    <div className="right_container_body">
                        <div>
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"policyNumber"} />
                            </Text>
                        </div>
                        <div>
                            <Text size="20" marginTop="0" color="lightDark">
                                {props?.data?.InsurancePolicyNumber}
                            </Text>
                        </div>
                    </div>

                    <div className="right_container_body">
                        <div>
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"additionalCoverageDetails"} />
                            </Text>
                        </div>
                        <div>
                            <Text size="20" marginTop="0" color="lightDark">
                                {"0.00 SAR"}
                            </Text>
                        </div>
                    </div>

                    <div className="right_container_body">
                        <div>
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"status"} />
                            </Text>
                        </div>
                        <div>
                            <Text size="20" marginTop="0" color="lightDark">
                                {"Active"}
                            </Text>
                        </div>
                    </div>

                    <div className="right_container_body">
                        <div>
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"expiryDate"} />
                            </Text>
                        </div>
                        <div>
                            <Text size="20" marginTop="0" color="lightDark">
                                {props?.data?.EndDate !== "" && moment(props?.data?.EndDate).format("Do MMM YYYY")}
                            </Text>
                        </div>
                    </div>

                </div>
            </Row>
        </StyleWrapper>
    );
};

export default InsuranceDetail;