import {
   ACTIVE_FINANCE,
   ACTIVE_FINANCE_ERROR,
   ACTIVE_FINANCE_SUCCESS
} from "./constant";

const activeFinanceReducer = (state = {}, action) => {
   switch (action.type) {
      case ACTIVE_FINANCE:
         return { ...state, loading: true, error: '' };
      case ACTIVE_FINANCE_ERROR:
         return { ...state, type: ACTIVE_FINANCE_ERROR, loading: false, error: action.payload };
      case ACTIVE_FINANCE_SUCCESS:
         return { ...state, type: ACTIVE_FINANCE_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default activeFinanceReducer;