import {
    ACCOUNT_COMPLAINTS_FORM,
    ACCOUNT_COMPLAINTS_FORM_ERROR,
    ACCOUNT_COMPLAINTS_FORM_SUCCESS,
} from "./constant";

const accountComplaintsFormReducer = (state = {}, action) => {
    switch (action.type) {
        case ACCOUNT_COMPLAINTS_FORM:
            return { ...state, loading: true, error: '' };
        case ACCOUNT_COMPLAINTS_FORM_ERROR:
            return { ...state, type: ACCOUNT_COMPLAINTS_FORM_ERROR, loading: false, error: action.payload };
        case ACCOUNT_COMPLAINTS_FORM_SUCCESS:
            return { ...state, type: ACCOUNT_COMPLAINTS_FORM_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default accountComplaintsFormReducer;
