import React, { useState, useContext } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MuiCards from "components/MuiCards";
import Text from "components/Text";
import ButtonInput from "components/Input/ButtonInput";
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import { FormattedMessage } from "react-intl";
import H5Text from "components/Text/H5Text";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import { LocaleContext } from 'routes/Router';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardURL, getKafalaApproval, getQuotationCongratulation } from "routes/AppUrls";
import HeaderBanner from "features/CashFinancing/HeaderBanner";

const ApplicationSubmitted = () => {
    const { locale } = useContext(LocaleContext);
    const navigate = useNavigate();
    const selectSecurityType = useSelector((state) => state?.cashSecurityTypeReducer?.securityType);

    console.log(selectSecurityType)

    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="home" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="onlineFinancing" />,
            Link: "",
            isDisbale: true,
        },
    ]);


    return (
        <div style={{ display: 'flex', backgroundColor: '#F1EDF5', paddingBottom: "30px", minHeight: "100vh" }}>
            <Container maxWidth={'xl'} style={{ padding: "10px" }} disableGutters>
                <Grid container>
                    <Grid item xs={12}>
                        <HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} />
                    </Grid>
                </Grid>
                <Box margin='32px 0'>
                    <MuiBreadcrumbs size='20' list={breadcrumbsList} />
                </Box>
                <Grid container >
                    <Grid item xs={12}>
                        <MuiCards borderRadius="16" padding="30px 0">
                            <CardContent>
                                <Container style={{ maxWidth: '689px' }}>
                                    <Grid container textAlign={'center'}>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div style={{ textAlign: "center" }}>
                                                        <CheckCircleIcon sx={{ width: "130px", height: "130px", color: "#1DB510" }} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container display='flex' justifyContent='center'>

                                                <Grid item xs={12} marginTop='32px'>
                                                    <Text color="black" size="40" fontWeight="800" lineHeight={'48px'} fontFamily="KarbonSemiBold" TextAlign="center">
                                                        <FormattedMessage id="Application submitted for approval" />
                                                    </Text>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Text color="secondaryBlack" size="24" lineHeight={'28.8'} fontWeight="800" TextAlign="center">
                                                        <FormattedMessage id="ourApplication" />
                                                    </Text>
                                                </Grid>

                                                <Grid item xs={12} marginTop='16px'>
                                                    <div style={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                                                        <Text color="lightBlack" size="24" fontWeight="800" TextAlign="center">
                                                            <FormattedMessage id="withReferenceNumber" />:
                                                        </Text>
                                                        <Text color="purple" size="24" fontWeight="800" TextAlign="center" fontFamily="KarbonMedium">
                                                            &nbsp; 7897-897987-89
                                                        </Text>

                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} style={{ maxWidth: '569px' }}>
                                                    <H5Text color="gray" size="24" fontFamily="Karbon_light" TextAlign="center"  >
                                                        Our Our System will send you an SMS to update you about the application status within 24 hours, after the approval you will receive an automated call to approve your loan
                                                    </H5Text>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Container>
                                <Grid container style={{ marginTop: "30px" }} >
                                    <Grid item xs={12} marginRight={{ xs: "0", lg: "100px" }}>
                                        <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                                            <Box component="form">
                                                <Stack spacing={2} direction="row">
                                                    <ButtonInput
                                                        title={<FormattedMessage id="Return to Dashboard" />}
                                                        variant="contained"
                                                        bgColor="yellow"
                                                        textColor="purple"
                                                        borderRadius="100"
                                                        TextTransform="capitalize"
                                                        endIcon={<ArrowForwardIcon />}
                                                        // onClick={() => navigate(getDashboardURL(locale))}
                                                        onClick={() => selectSecurityType === "Kafala" ? navigate(getKafalaApproval(locale)) : navigate(getQuotationCongratulation(locale))}
                                                    />
                                                </Stack>
                                            </Box>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </MuiCards>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default ApplicationSubmitted;
