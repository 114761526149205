export const CAR_GOSI = "CAR_GOSI";
export const CAR_PENSION = "CAR_PENSION";
export const CAR_INSURANCE_QUOTE = "CAR_INSURANCE_QUOTE";
export const FINANCING_SUCCESS = "FINANCING_SUCCESS";
export const FINANCING_ERROR = "FINANCING_ERROR";
export const YAKEEN_SEARCH_NONSAUDI_DATA = "YAKEEN_SEARCH_NONSAUDI_DATA";
export const YAKEEN_SEARCH_SAUDI_DATA = "YAKEEN_SEARCH_SAUDI_DATA";
export const FINANCE_CALCULATOR = "FINANCE_CALCULATOR";
export const MANUAL_DBR = "MANUAL_DBR";
export const EMI_CALCULATOR = "EMI_CALCULATOR";
export const EXPENSE_BANK_VALIDATION = "EXPENSE_BANK_VALIDATION";