import React from "react";

const CheckValidation = value => {

    if(value.length !== 6) {
        return true;
    }

    return false;
}

export default CheckValidation;