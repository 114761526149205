import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import H5Text from "components/Text/H5Text";
import SideBySideText from "components/SideBySideText";
import Text from "components/Text";
import MuiCards from "components/MuiCards";
import { Box, InputAdornment } from '@mui/material';
import CommonModal from 'components/Modals/CommonModal';
import ButtonInput from 'components/Input/ButtonInput';
import OneWaySlider from 'components/RangeSlider/OneWaySlider';
import { LocaleContext } from 'routes/Router';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import MuiBreadcrumbs from 'components/MuiBreadcrumbs';
import NotePencilIcon from "assets/images/icon/NotePencil.png";
import { Stack } from 'react-bootstrap';
import LabelWithInput from 'components/Input/LabelWithInput';
import SelectInput from 'components/Input/SelectInput';
import { useSelector } from 'react-redux';

const WrapperContent = styled.div`
	padding: 10px 15px;
`;

function FinancingResultsData({ containerProps, ...props }) {
	const intl = useIntl();
	const [isModalActive, setIsModalActive] = useState(false);
	const [isTotalModalActive, setIsTotalModalActive] = useState(false);
	const [editQuotationModalActive, setEditQuotationModalActive] = useState(false);
	const [monthlyAmount, setMonthlyAmount] = useState(80000);
	const [period, setPeriod] = useState(12);
	const { locale } = useContext(LocaleContext);
	const [tenure, setTenure] = useState(6);
	const gosiPension = useSelector((state) => state)?.basicReducer?.financingType;

	return (
		<MuiCards borderRadius="16" boxShadow={false} padding="0">
			<H5Text bgColor="secondary" color="lightDark" borderRadius="8" marginTop="0" padding="8px 16px">
				<FormattedMessage id={props.title} />
			</H5Text>
			<CardContent>
				<Grid container>
					<Grid item xs={12}>
						<SideBySideText
							bgColor='#EAFCF0'
							leftText={<FormattedMessage id="financeAmount" />}
							RightText="13,000"
							subText={<FormattedMessage id="sar" />}
							leftFontFamily="Karbon_semibold"
							leftTextColor="green"
							rightTextColor="green"
							leftFontWight="800"
							size='24'
							border={true}
							borderColor="green"
						/>
						<SideBySideText
							leftText={<FormattedMessage id="financePeriod" />}
							RightText={<Box>60 Months <img src={require('assets/icons/Financing/PencilSimple.png')} onClick={() => setIsModalActive(true)} /></Box>}
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
						/>
						<CommonModal padding={'32px 48px'} width={'1260px'} modalActive={isModalActive} innerContent={
							<Box >
								<Box >
									<Box maxWidth={'415px'}>
										<H5Text size={40} lineHeight={60} fontFamily='Karbon_semiBold' marginTop={0}>Change Details</H5Text>
									</Box>
									<Grid container spacing={10} >
										<Grid item xs={12} lg={6} className='Slider' marginTop='32px'>
											<OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={10} endLimit={90000} label={<FormattedMessage id="monthlyPaymentAmount" />} defaultValue={monthlyAmount} value={monthlyAmount} setValue={(data) => setMonthlyAmount(data)} />
										</Grid>
										<Grid item xs={12} lg={6} className='Slider' marginTop='32px'>
											<OneWaySlider measure={"Months"} sliderLabel={true} startLimit={1} endLimit={60} label={<FormattedMessage id="period" />} defaultValue={period} value={period} setValue={(data) => setPeriod(data)} />
										</Grid>
									</Grid>
								</Box>
								<Box component="form" display='flex' justifyContent='end' marginTop='48px'>
									<ButtonInput
										title="Cancel"
										variant="text"
										bgColor="transparent"
										textColor="purple"
										borderRadius="100"
										TextTransform="uppercase"
										height='59'
										onClick={() => setIsModalActive(false)}
									/>
									<ButtonInput
										title="Save"
										variant="contained"
										bgColor="yellow"
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										height='59'
									// onClick={() => navigate(PUBLIC_ROUTE.APPLICATION_REFERRED)}
									/>
								</Box>
							</Box>
						} />
						<SideBySideText
							leftText={<FormattedMessage id="cashFinance.administrativeFees" />}
							RightText="13,191 SAR"
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
						/>

						<SideBySideText
							leftText={<FormattedMessage id="VAT" />}
							RightText="10,351 SAR"
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
						/>
						<SideBySideText
							leftText={<FormattedMessage id="cashFinance.totalCost" />}
							RightText={<Box>10,351 SAR <img src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '6px' }} onClick={() => setIsTotalModalActive(true)} /></Box>}
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
						/>
						<CommonModal padding={'32px 48px'} width={'736px'} modalActive={isTotalModalActive} innerContent={
							<Box >
								<Box display='flex' justifyContent={'space-between'}>
									<H5Text size={40} lineHeight={60} fontFamily='Karbon_semiBold' marginTop={0}>Total VAT</H5Text>
									<img src={require('assets/icons/cancel_button.png')} onClick={() => setIsTotalModalActive(false)} style={{ display: 'flex', justifyContent: 'end', height: '18px', width: '18px', cursor: 'pointer' }} />
								</Box>
								<Box marginTop={'17px'}>
									<SideBySideText
										leftText={<FormattedMessage id="autoFinance.issueExpenseVAT" />}
										RightText="115.8 SAR"
										size={'24'}
										pBottom={'24'}
									/>
									<SideBySideText
										leftText={<FormattedMessage id="sellingPriceVAT" />}
										RightText="13,072.5 SAR"
										size={'24'}
										pBottom={'24'}
									/>
									<SideBySideText
										leftText={<FormattedMessage id="autoFinance.premiumVAT" />}
										RightText="2,614.5 SAR"
										size={'24'}
										pBottom={'24'}
									/>
								</Box>
							</Box>
						} />
						<SideBySideText
							leftText={<FormattedMessage id="cashFinance.monthlyInstallment" />}
							RightText={<Box>0 SAR</Box>}
							leftTextColor="dark"
							rightTextColor="lightPrimary"
							leftFontWight="600"
							size="18"
							pBottom='0'
						/>
						{/* <Grid item xs={12}>
							<MuiCards bgColor="pink" borderRadius="8" boxShadow={false} padding="10px 15px" margin="16px 0 0 0">
								<Grid container paddingLeft='17px'>
									<Grid item xs={11} display='flex' justifyContent='start' alignItems='center'>
										<H5Text color="purple" lineHeight="24" size="20" fontWeight="800" marginTop="0" style={{ textAlign: 'start' }}>
											<FormattedMessage id="downloadYourQuotation" />
										</H5Text>
									</Grid>
									<Grid item xs={1}>
										<div style={{ textAlign: locale === 'en' ? "right" : 'left' }}>
											<SaveAltIcon sx={{ width: "32px", height: "32px", color: "#3F1956", textAlign: "right" }} />
										</div>
									</Grid>
								</Grid>
							</MuiCards>
						</Grid> */}
					</Grid>
					<Grid item xs={12}>
						<Text fontWeight="800" fontFamily="KarbonSemiBold" size="16" color="lightDark">
							<FormattedMessage id="APR Percentage: " /> 10.8.%
						</Text>
					</Grid>
					<Grid item xs={12}>
						<Text size="14" color="lightDark">
							<FormattedMessage id="autoFinance.desclaimer" />
						</Text>
					</Grid>

					{props?.curStep === 2 &&
						<Box display='flex' alignItems={"center"} width="100%" justifyContent={"center"} style={{ cursor: "pointer" }} onClick={() => setEditQuotationModalActive(true)}>
							<img src={NotePencilIcon} style={{ height: "24px", width: "24px", marginRight: locale == "en" && "8px", marginLeft: locale == "ar" && "8px" }} />
							<H5Text size={"16"} lineHeight={"19.2"} fontFamily="Karbon_semiBold" marginTop="4" >Edit Quotation</H5Text>

						</Box>
					}
					<CommonModal padding={'32px 48px'} width={'736px'} modalActive={editQuotationModalActive} innerContent={
						<>
							<Grid item xs={12} padding='0 16px'>
								<H5Text size="40" lineHeight="60" fontFamily='Karbon_semiBold'>Edit Final Quotation</H5Text>
								<Box display='flex' marginTop='30px' alignItems={{ xs: 'baseline', md: 'center' }}>
									<Text marginTop="0" size="24" color="dark" fontWeight="800" fontFamily="KarbonSemiBold">
										<FormattedMessage id="Maximum Allowed Amount:" />  : 20,000.00 <FormattedMessage id="sar" />
									</Text>
								</Box>
								<Grid container>
									<Grid item xs={12} lg={9} >
										<Grid container>
											<Grid item xs={12} lg={6} display={"flex"}>
												<H5Text size={"20"} fontFamily="Karbon_light">
													Total Expenses:
												</H5Text>
												<H5Text size={"20"} fontFamily="Karbon_medium">
													&nbsp;2,000 <sup>SAR</sup>
												</H5Text>
											</Grid>
											<Grid item xs={12} lg={6} display={"flex"}>
												<H5Text size={"20"} fontFamily="Karbon_light">
													Net Monthly Income registered at {gosiPension == "gosi" ? "GOSI" : gosiPension == "pension" ? "Pension" : ""}:
												</H5Text>
												<H5Text size={"20"} fontFamily="Karbon_medium">
													&nbsp;2,000 <sup>SAR</sup>
												</H5Text>
											</Grid>
										</Grid>
										{/* <H5Text size="20" lineHeight={'21.23'} color="lightGrey" fontFamily="Karbon_light"> <FormattedMessage id="Monthly Obligations:" /></H5Text> */}
									</Grid>
								</Grid>
								<Grid container spacing={4} marginTop="9px">
									<Grid item xs={12} lg={6} >
										<Grid item xs={12} md={10} >
											<OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={0} endLimit={90000} label={<FormattedMessage id="Cash Needed" />} defaultValue={monthlyAmount} value={monthlyAmount} setValue={(data) => setMonthlyAmount(data)} />
										</Grid>
									</Grid>
									<Grid item xs={12} lg={6} >
										<Grid item xs={12} md={10} >
											<OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={1} endLimit={60} label={<FormattedMessage id="Tenure" />} defaultValue={tenure} value={tenure} setValue={(data) => setTenure(data)} />
										</Grid>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Grid item xs={12} md={10} >
											<OneWaySlider measure={"SAR"} sliderLabel={true} startLimit={0} endLimit={90000} label={<FormattedMessage id="Monthly Installment" />} defaultValue={monthlyAmount} value={monthlyAmount} setValue={(data) => setMonthlyAmount(data)} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container sx={{ marginTop: "40px" }}>
								<Grid item xs={12}>
									<div className="content" style={{ display: "flex", justifyContent: "end" }}>
										<ButtonInput
											title={<FormattedMessage id="cancel" />}
											variant="text"
											bgColor="transparent"
											textColor="purple"
											borderRadius="100"
											TextTransform="capitalize"
											onClick={() => setEditQuotationModalActive(false)}
										/>
										<ButtonInput
											title={<FormattedMessage id="Recalculate" />}
											variant="contained"
											bgColor="yellow"
											textColor="purple"
											borderRadius="100"
											TextTransform="capitalize"
											// endIcon={<ArrowForwardIcon />}
											onClick={() => setEditQuotationModalActive(false)}
										/>
									</div>
								</Grid>
							</Grid>
						</>
					} />
				</Grid>
			</CardContent>
		</MuiCards>
	);
}

FinancingResultsData.propTypes = {
	bgColor: string.isRequired,
};

export default FinancingResultsData;