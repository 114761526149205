import React, { useState } from 'react';
import { IconButton, Input, InputLabel, TextField, inputComponent, InputAdornment } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import VerifiedIcon from '@mui/icons-material/Verified';
import styled from 'styled-components';

const IconStyle = styled.div`
font-family: Karbon_medium;
font-size: 20px;
font-weight: 700;
line-height: 21px;
letter-spacing: 0em;
text-align: left;

`
const ContainerStyle = styled.div`
.Mui-focused {
  fieldset {
    border: 0.5px solid #000000 !important;
  }
}
.MuiButtonBase-root{
  padding-left: 0px !important;
  &:hover{
    background:none;
  }
}

.MuiInputBase-input {
  font-size:  ${(props) => props.fontSize?props.fontSize:'16px'};
  line-height: 24px;
  font-weight: 700;
  transform: none;
  font-family: Karbon_medium;
  color: ${(props) => props.theme.darkSecondary};
  top: -5px;
  width:100%;
}
.MuiInputBase-root{
  padding-right:8px !important;
}
`
const TextFields = ({ error, helperText, label,placeholder, width, height, type, inputComponent, onChange, name, icon, maxLength, borderRadius, border,defaultValue,value,fontSize }) => {

  const [showPassword, setShowPassword] = useState(true);
  const [password, setPassword] = useState('');
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => { event.preventDefault() };

  return (
    <ContainerStyle style={{ width: width ? width : '100%', height: height ? height : '64px' }} fontSize={fontSize}>
      <TextField
        type={showPassword ? type : 'text'}
        // type={type}
        
        name={name}
        onChange={onChange}
        helperText={error && helperText}
        error={error}
        value={value}
        sx={{ fieldset: { borderColor: "#C7B7CF", borderRadius:borderRadius, border:border } }} style={{ width: '100%' }} id="outlined-basic" label={label} placeholder={placeholder} variant="outlined"
        onInput={(e) => {
          e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
        }}
        InputProps={{
          style: { borderRadius: "20px" },
          inputComponent: inputComponent,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? type === "password" && <VisibilityOff /> : type === "password" && <Visibility />}
                <IconStyle >{icon}</IconStyle> 
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%',
            color: '#B8B8B8',
            fontWeight: '600'
          }
        }}

      />
    </ContainerStyle>
  )
}

export default TextFields
