import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from '@mui/material/Slider';

const WrapperSliderField = styled(Slider)`

 	.MuiSlider-track {
		background-color: #3F1956;
		height: 10px;
		border: 1px solid #3F1956;
 	}

 	.MuiSlider-rail {
 		background-color: #EFE8F3;
 		height: 10.87px;
 	}

 	.MuiSlider-thumb {
 		background-color: #FFEDB1;
 		border: 1.55px solid #FECB26;
 		width: 18px;
    	height: 18px;
    	&:hover {
    		box-shadow: none;
    	}
 	}
 	.MuiSlider-valueLabel {
 		background-color: #EFE8F3;
 		border-radius: 12px;
 		color: #3D3D4E;
 		font-size: 14px;
 		font-weight: 800;
 		font-family: Karbon_semibold;

 		.MuiSlider-valueLabelLabel {
 			&:after {
	    		content: "SAR";
	    		margin-left: 5px;
	    		font-size: 14px;
	    		font-weight: 600;
	    		font-family: Karbon_light;
 			}
 		}
 	}
`;

function MuiRangeSlider({ ...props }) {
	


	return (
		<WrapperSliderField
			valueLabelDisplay="auto"
			{...props}
   	/>
	)
}

export default MuiRangeSlider;