import {
   APPLICATION_LIST,
   APPLICATION_LIST_ERROR,
   APPLICATION_LIST_SUCCESS,
} from "./constant";

const applicationListReducer = (state = {}, action) => {
   switch (action.type) {
      case APPLICATION_LIST:
         return { ...state, loading: true, error: '' };
      case APPLICATION_LIST_ERROR:
         return { ...state, type: APPLICATION_LIST_ERROR, loading: false, error: action.payload };
      case APPLICATION_LIST_SUCCESS:
         return { ...state, type: APPLICATION_LIST_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default applicationListReducer;