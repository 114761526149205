import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LabelWithInput from "components/Input/LabelWithInput";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ButtonInput from "components/Input/ButtonInput";
import { accountCallAPI } from "store/account/action";
import { ACCOUNT_INITIAL_STATE, ACCOUNT_PASSWORD_CHANGE } from "store/account/constant";
import CheckValidation from "features/Account/AccountPasswordVerification";
import CircularProgress from '@mui/material/CircularProgress';
import H5Text from "components/Text/H5Text";
import { LocaleContext } from 'routes/Router';
import Text from "components/Text";
import { useNavigate } from "react-router-dom";

const StyleWrapper = styled.div`
    align-items: center;
    margin:${props => props.margin};

    .application_top{
        display:flex;
        align-items:center;
    };
`;

const AccountChangePassword = () => {
    const [isMobile, setIsMobile] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const { locale } = useContext(LocaleContext);
    const [isPassVisible, setIsPassVisible] = useState(false);
    const [isNewPassVisible, setIsNewPassVisible] = useState(false);
    const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false);
    const [formValue, setFormValue] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const AccountData = useSelector((state) => (state.accountReducer && state.accountReducer?.payload?.data));
    const getUserDetails = useSelector(state => state?.userReducer?.payload?.data?.data);
    const AccountError = useSelector((state) => (state?.accountReducer?.error?.data?.message));
    const loader = useSelector((state) => state?.accountReducer?.loading);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(accountCallAPI(ACCOUNT_INITIAL_STATE));
    }, []);

    const handleVisiblePassword = (value) => {
        setIsPassVisible(!value);
    };

    const handleVisibleNewPassword = (value) => {
        setIsNewPassVisible(!value);
    };

    const handleVisibleConfirmPassword = (value) => {
        setIsConfirmPassVisible(!value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevState) => ({
            ...formValue,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit) {
            let bodyValue = {};
            bodyValue.nationalId = getUserDetails?.nationalId;
            bodyValue.password = formValue.newPassword;
            bodyValue.old_password = formValue.currentPassword;
            bodyValue.action = "reset-password";
            dispatch(accountCallAPI(ACCOUNT_PASSWORD_CHANGE, bodyValue, { navigate: navigate }));
        }
    };

    return (
        <StyleWrapper margin={isMobile ? '0%' : locale === 'en' ? '2% 0% 2% 6%' : '2% 10% 2% 0%'}>
            <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily="Karbon_semiBold">
                <FormattedMessage id="account.changePassword" />
            </H5Text>
            <Grid container sx={{ marginTop: "0px" }}>
                <Grid item xs={12} sm={12} md={12} lg={10} xl={8} sx={{ marginTop: "0px" }}>
                    <LabelWithInput
                        label={<FormattedMessage id="account.currentPassword" />}
                        placeholder="Current Password"
                        name="currentPassword"
                        type={isPassVisible ? "text" : "password"}
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {isPassVisible ? (
                                        <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
                                    )}
                                </>
                            )
                        }}
                        errorText={isError && isError.currentPassword.error && isError.currentPassword.errorMessage}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={10} xl={8} sx={{ marginTop: "20px" }}>
                    <LabelWithInput
                        label={<FormattedMessage id="account.newPassword" />}
                        placeholder="New Password"
                        name="newPassword"
                        type={isNewPassVisible ? "text" : "password"}
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {isNewPassVisible ? (
                                        <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleNewPassword(isNewPassVisible)} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleNewPassword(isNewPassVisible)} />
                                    )}
                                </>
                            )
                        }}
                        errorText={isError && isError.newPassword.error && isError.newPassword.errorMessage}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={10} xl={8} sx={{ marginTop: "20px" }}>
                    <LabelWithInput
                        label={<FormattedMessage id="account.confirmPassword" />}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        type={isConfirmPassVisible ? "text" : "password"}
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {isConfirmPassVisible ? (
                                        <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleConfirmPassword(isConfirmPassVisible)} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisibleConfirmPassword(isConfirmPassVisible)} />
                                    )}
                                </>
                            )
                        }}
                        errorText={isError && isError.confirmPassword.error && isError.confirmPassword.errorMessage}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={10} xl={8} sx={{ marginTop: "20px" }} style={{ alignItems: "end", display: "flex", justifyContent: "end" }}>
                    <ButtonInput
                        title={<FormattedMessage id="continue" />}
                        variant="contained"
                        bgColor={"yellow"}
                        textColor="purple"
                        borderRadius="100"
                        TextTransform="capitalize"
                        disabled={loader}
                        endIcon={loader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : <ArrowForwardIcon />}
                        onClick={() => handleSubmit()}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={10} xl={8} sx={{ marginTop: "10px" }}>
                    {!loader &&
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                            {AccountError ? (
                                <Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
                                    {AccountError}
                                </Text>
                            ) : (
                                <Text size="18" marginTop="5" color="sucessColor" fontWeight="600" lineHeight="27">
                                    {AccountData?.message}
                                </Text>
                            )}
                        </Grid>
                    }
                </Grid>

            </Grid>


        </StyleWrapper>
    );
};

export default AccountChangePassword;
