import {
    NAFATH_ERROR,
    NAFATH_SUCCESS,
    NAFATH_VERIFY
} from "./constant";

export const nafathCallAPI = (type, data, external = {}) => {
    switch (type) {
        case NAFATH_VERIFY:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
}

export const nafathAPIStatus = (type, data) => {
    switch (type) {
        case NAFATH_ERROR:
            return {
                type: type,
                payload: data
            }
        case NAFATH_SUCCESS:
            return {
                type: type,
                payload: data
            }
        default:
            return {
                type: type,
            }
    }
}
