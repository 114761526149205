import React from 'react';
import styled from 'styled-components';
import { string } from "prop-types";

const WrapperH1 = styled.h1`
	margin-bottom: ${(props) => (props.marginBottom + "px")};
    font-size: ${(props) => (props.size + "px")};
    font-weight: ${(props) => (props.fontWeight)};;
    font-family: ${(props) => (props.fontFamily)};
    font-style: ${(props) => (props.fontStyle)};
    line-height: ${(props) => (props.lineHeight + "px")};
    color: ${(props) => (props.theme[props.color])};
    margin-top: ${(props) => (props.marginTop + "px")};
    background: ${(props) => (props.theme[props.bgColor])};
    border-radius: ${(props) => (props.borderRadius + "px")};
    padding-top: ${(props) => (props.paddingTop + "px")};
    padding-bottom: ${(props) => (props.paddingBottom + "px")};
    padding-left: ${(props) => (props.paddingLeft + "px")};
    padding-right: ${(props) => (props.paddingRight + "px")};
    border-bottom:${(props) => (props.borderBottom)};
`;

function H1Text(props) {
    return (
        <WrapperH1 {...props}>
            {props.children}
        </WrapperH1>
    );
}

H1Text.propTypes = {
    color: string.isRequired,
};

H1Text.defaultProps = {
    marginTop: 10,
    marginBottom: 0,
    size: 64,
    fontFamily: 'Karbon_semibold',
    fontWeight: 800,
    fontStyle: 'normal',
    lineHeight: 96,
    color: 'lightDark',
    borderBottom:'none'
};

export default H1Text;