import { Grid } from '@mui/material';
import NavList from 'components/Navbar/NavList'
import H5Text from 'components/Text/H5Text';
import React from 'react'
import styled from 'styled-components'
import backArrow from 'assets/images/icon/backArrow.png';
import mathOperations from 'assets/icons/MathOperations.png';
import Cards from 'components/Cards/Cards';
import SelectInput from 'components/Input/SelectInput';
import ContinueButton from 'components/Buttons/ContinueButton/ContinueButton';
import { useState, useContext } from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import { LocaleContext } from 'routes/Router';

const VehicleComparisonStyle = styled.div`
padding:0 5% 5% 5%;
background:#f1edf5;
.InnerItem{
    background:#ffffff;
    border-radius:16px;
    margin-top:24px;
}
.BackHeading{
    margin-top:10px;
    display: flex;
    align-items: center;
    img{
        margin-top: 7px;
        margin-right:24px;
    }
}
.headingCompare{
    p{
        margin:0;
        padding: 28px 24px;
        max-height:80px;
        font-family: Karbon_light;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

    }
    .h5Style{
        display:inline;
        margin-left:10px;
    }
    .image{
        height:24px;
        margin-left:10px;
    }
}
.detailPrice{
    .priceContainer{
        margin:0;
        padding: 28px 24px;
        max-height:80px;
        text-align:center;
        justify-content:center
    }
}
.gridContainer{
    .gridItem{
        background:#ffffff;
        .iconDiv{
            height: 100%;
            padding: 48px 24px;
        }
    }
    .addCar{
        display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid #D8C7E3;
    img{
        margin-right:10px;
    }
    }
    .AddVehicleDesign{
       padding: 32px 16px;
       position: relative;

       border:1px solid  #D8C7E3;
       border-top-right-radius:16px;
       .addVehicleBtn{
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right:16px; 
        margin-bottom:16px;
       }
    }
    .carItem{
        display: flex;
        // overflow: scroll;
        padding:20px 20px 0 20px;
    }
}
.PriceTag{
    font-size:20px;
            font-family:Karbon_medium;
            font-weight:700;
            color:#8F8D95;
            margin-left:8px;
}
@media screen and (max-width:768px){
    .BackHeading{
        img{
            margin-top: 8px;
            margin-right:12px;
            height:18px;
        }
    }
}
`

const VehicleComparison = () => {
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);

    const items = [
        {
            id: 1,
            img: require('assets/images/carTransprent.png'),
            heading: "COROLLA 2023",
            subHeading: "1.5 XLI EXE 4X2 1.5 PETROL SEDAN 4Dr CVT",
            price: "2,099",
        },
    ]
    const demoResult = [
        {
            type: "Monthly Payment",
            price: "1,845.00",
            redDot: true,
            tag: "SAR"
        },
        {
            type: "Selling Price",
            price: "4,845.00",
            tag: "SAR"
        },
        {
            type: "Selling Price VAT",
            price: "654.00",
            exclemation: true,
            percentage: '15%',
            tag: "SAR"
        },
        {
            type: "Down Payment",
            price: "0.00",
            redDot: true,
            tag: "SAR"
        },
        {
            type: "Finance Amount/Formula Price",
            price: "0.00",
            exclemation: true,
            tag: "SAR"
        },
        {
            type: "Period",
            price: "60",
            tag: "Months"
        },
        {
            type: "Residual Amount",
            price: "1,845.00",
            percentage: '35%',
            redDot: true,
            tag: "SAR"
        },
    ]

    const [detailFields, setDetailFields] = useState(false)
    const addCard = ()=>{
    setDetailFields(!detailFields);
    }
    return (
        <VehicleComparisonStyle>
            <NavList />
            <div className='BackHeading'>
                <img src={backArrow} onClick={() => navigate(-1)} alt="" style={{ cursor: "pointer", transform: locale === 'ar' ? 'scaleX(-1)' : '', margin: locale === 'en' ? '7px 24px 0 0' : '7px 0 0 24px' }} />
                <H5Text size="32" marginTop={'14'} color={'purple'}>Vehicle Comparison</H5Text>
            </div>
            {/* <Cards listtype={true} CardsData={items} /> */}
            <div className='InnerItem' >
                <Grid className='gridContainer' container>
                    <Grid className='gridItem' item xs={3} md={3}>
                        <div className='iconDiv'>
                            <H5Text size="32" marginTop={'14'} color={'purple'}>Calculator Results</H5Text>
                            <img src={mathOperations} alt="" />
                        </div>

                    </Grid>
                    <Grid className='carItem' item xs={3} md={3}>
                        <Cards listtype={true} data={items[0]} boxShadow={'none'} />
                    </Grid>
                    <Grid className='carItem' item xs={3} md={3}>
                        <Cards listtype={true} data={items[0]} boxShadow={'none'} />
                    </Grid>
                   
                    {
                        detailFields?
                    <Grid className='AddVehicleDesign' item xs={3} md={3}>
                            <SelectInput label="Brand" placeholder="Brand" />
                            <SelectInput label="Product" placeholder="Product" />
                            <SelectInput label="Year" placeholder="Year" />
                            <SelectInput label="Vehicle Model" placeholder="Vehicle Model" />
                            <div className='addVehicleBtn'>
                            <ContinueButton width="129px" height="40px" fontSize="14px" border="0" text="Apply" margin="0 0 0 16px" padding="0" 
                                            boderRadius="131px" background="#FECB26" onClick={()=>addCard()}  />
                            </div>
                    </Grid>:
                     <Grid className='addCar' item xs={3} md={3} onClick={()=>addCard()} >
                     <img alt="" src={require('assets/images/icon/plus.png')} /> <H5Text size="32"marginTop={'8'} color={'purple'}>Add another car</H5Text>
                     </Grid>
                    }
                </Grid>
                <Grid container>
                    <Grid className='headingCompare' item xs={3} md={3} >
                        {demoResult.map((data) => (
                            <p>{data.type} 
                             {data.exclemation &&
                                <img src={require('assets/images/icon/exclamation.png')} alt="" className='image' />
                            } 
                           <H5Text size="24"  color={'purple'} className="h5Style">{data.percentage}</H5Text></p>
                        ))}
                    </Grid>
                    <Grid className='detailPrice' item xs={3} md={3}>
                        {demoResult.map((data) => (
                            // <p>{data.price}</p>
                            <>
                                <Grid className='priceContainer' container>
                                    <Grid className='' item>
                                        <H5Text size="32" color={'purple'} marginTop={'0'}>{data.price}</H5Text>
                                    </Grid>
                                    <Grid item>
                                        <span className='PriceTag'>{data.tag}</span>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                    <Grid className='detailPrice' item xs={3} md={3}>
                        {demoResult.map((data) => (
                            <>
                                <Grid className='priceContainer' container>
                                    <Grid className='' item>
                                        <H5Text size="32" color={'purple'} marginTop={'0'}>{data.price}</H5Text>
                                    </Grid>
                                    <Grid item>
                                        <span className='PriceTag'>{data.tag}</span>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                    <Grid className='detailPrice' item xs={3} md={3}>
                        {demoResult.map((data) => (
                            <H5Text className='priceContainer'  size="32" color={'purple'} marginTop={'0'}>-</H5Text>
                        ))}
                    </Grid>

                </Grid>
            </div>
        </VehicleComparisonStyle>
    )
}

export default VehicleComparison
