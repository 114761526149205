import {
	CLIENT_TOKEN_SET,
	CLIENT_TOKEN_UNSET
} from './constant';


const initialSate = {
	token: null,
}

const tokenReducer = function tokenReducer(state = initialSate, action) {
	switch (action.type) {
		case CLIENT_TOKEN_SET:
			return {
				token: action.token,
			}
		case CLIENT_TOKEN_UNSET:
			return {
				token: null,
			}
		default:
			return state
	}
}

export default tokenReducer;