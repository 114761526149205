import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import MaskInput from "components/Input/MaskInput";
import ButtonInput from "components/Input/ButtonInput";
import CheckValidation from "./validation";
import { Stack } from "@mui/material";
import { tahakukCallAPI } from "store/tahakuk/action";
import { AUTH_TAHAKKUQ_VERIFY } from "store/tahakuk/constant";
import { useNavigate } from "react-router-dom";

function TahakukMobileVerification({ handleCurStep, postFormData }) {
    const isMobile = window.innerWidth < 900;
    const [isError, setIsError] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formValue, setFormValue] = useState({
        contactNumber: ""
    });
    const tahakukVerificationData = useSelector((state) => (state.tahakukReducer?.payload?.data?.data));
    const loader = useSelector((state) => state.tahakukReducer?.loading);
    const AuthError = useSelector((state) => (state.tahakukReducer?.error?.data?.message));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevState) => ({
            ...formValue,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        formValue.contactNumber = formValue?.contactNumber?.split(' ').join('');
        const error = CheckValidation(formValue);
        setIsError(error);
        if (error.submit) {
            tahakkuqVerify();
        }
    };

    const tahakkuqVerify = () => {
        let bodyValue = {};
        bodyValue.crNumber = postFormData?.crNumber;
        bodyValue.nationalId = postFormData?.nationalId;
        bodyValue.countryCode = postFormData?.countryCode;
        bodyValue.contactNumber = formValue?.contactNumber?.substring(4);
        bodyValue.user_role = 'sme-user';
        dispatch(tahakukCallAPI(AUTH_TAHAKKUQ_VERIFY, bodyValue, { handleStep: handleCurStep, navigate: navigate, step: 4 }));
    };

    return (
        <>
            <H1Text color="primary" size={isMobile ? 46 : 64} lineHeight="50">
                <FormattedMessage id="Mobile Verification" />
            </H1Text>

            <Text size="24" color="lightDark" lineHeight="36" fontWeight="600" marginTop="0">
                <FormattedMessage id="Only a few more requests to get your car!" />
            </Text>

            <Grid container sx={{ marginTop: "30px" }}>
                <Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
                    <MaskInput
                        mask="+\966 999 999 999"
                        disabled={false}
                        maskChar=" "
                        label={<FormattedMessage id="Mobile Number" />}
                        placeholder="eg: 978-9854-4666"
                        name="contactNumber"
                        onChange={handleInputChange}
                        errorText={isError && isError.contactNumber.error && isError.contactNumber.errorMessage}
                    />
                </Grid>
                <Grid item xs={12} md={10} lg={8} sx={{ marginTop: "70px" }}>
                    <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                        <Box component="form">
                            <Stack spacing={2} direction="row">
                                <ButtonInput
                                    title={<FormattedMessage id="goBack" />}
                                    variant="text"
                                    bgColor="transparent"
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    minWidth="100px"
                                    marginRight="40"
                                    onClick={() => handleCurStep(3)}
                                />
                                <ButtonInput
                                    title={<FormattedMessage id="Next" />}
                                    variant="contained"
                                    bgColor={loader ? "" : "yellow"}
                                    textColor="purple"
                                    borderRadius="100"
                                    TextTransform="capitalize"
                                    disabled={loader}
                                    endIcon={loader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : <ArrowForwardIcon />}
                                    onClick={() => handleSubmit()}
                                />
                            </Stack>
                        </Box>
                    </div>
                </Grid>

                <Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
                    <Grid container>
                        {!loader &&
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                {AuthError ?
                                    <Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
                                        {AuthError}
                                    </Text>
                                    :
                                    <Text size="18" marginTop="5" color="sucessColor" fontWeight="600" lineHeight="27">
                                        {tahakukVerificationData?.message}
                                    </Text>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
}

export default TahakukMobileVerification;