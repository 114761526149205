import styled from 'styled-components';

const WrapperDiv = styled.div`
	width: ${(props) => (props.width)+"px"};
	height: ${(props) => (props.width)+"px"};
	border-radius: 4px;
	background-color: ${(props) => (props.color)};
	margin-right: 5px;
`;

function ColorBox({...props }) {
	return (
		<WrapperDiv {...props}>
		</WrapperDiv>
	)
}

export default ColorBox;