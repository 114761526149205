import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { bool, oneOf, string } from "prop-types";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import BookmarksIcon from '@mui/icons-material/Bookmarks';

import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import ButtonInput from "components/Input/ButtonInput";

const WrapperDiv = styled.div`
	background: linear-gradient(267deg, rgba(60, 16, 83, 0.79) 22.21%, rgba(60, 16, 83, 0) 36.67%), 
		linear-gradient(88.35deg, #3C1053 14.18%, rgba(60, 16, 83, 0) 72.19%);
	background-image: url('${(props) => (props.bgBanner)}');
	background-size:cover;
	background-repeat:no-repeat;
	width: 100%;
	border-radius: 15px;
	
`;

const WrapperLeftDiv = styled.div`
	padding: 35px;
	border-radius: 15px 35% 10% 15px;
	background: ${(props) => (props.theme[props.bgColor])};
	@media (max-width: 1200px) {
		background: none;
	  }
	@media (max-width: 1200px) {
		.h5text{
			font-size:32px !important;
		}
	  }
	height:100%;
	display: flex;
    flex-direction: column;
    justify-content: center;
`;

const WrapperRightDiv = styled.div`
	padding: 35px;
	@media (max-width: 576px) {
		padding: 12px;
	  }
	border-radius: 65% 15px 15px 20%;
	background: ${(props) => (props.theme[props.bgColor])};

	height: 184px;
	height:100%;
	display: flex;
    flex-direction: column;
    justify-content: end;
`;

function HeaderBanner({ containerProps, ...props }) {
	const intl = useIntl();

	return (
		<WrapperDiv {...props}>
			<Grid container minHeight={'261px'}>
				<Grid item xs={12} md={8}  lg={4}>
					<WrapperLeftDiv {...props}>
						<H1Text className="h5text" size="50" color="white" lineHeight="75">
						<FormattedMessage id="autoFinance.autoFinancing" />
						</H1Text>
						<Text size="20" color="white" marginTop="0">
						<FormattedMessage id="autoFinance.onlineFinancingSubText" />
						</Text>
					</WrapperLeftDiv>
				</Grid>
				<Grid item xs={0} lg={4}>
				</Grid>
				<Grid item xs={12} md={4} lg={4}>
					<WrapperRightDiv {...props}>
						<Box component="form" marginTop={{sx:'0',lg:'70px'}}>
							<Grid container spacing={2} direction="row">
								<Grid item xs={6} sm={6} md={12} xl={6} xxl={6}>
								<ButtonInput
										title={<FormattedMessage id="cancelRequest" />}
									variant="text"
									bgColor="transparent"
									textColor="white"
									borderRadius="100"
									minWidth="201"
									padding="0px 15px"
									height='56'
									TextTransform="capitalize"
								/>
								</Grid>
								<Grid item xs={6} sm={6} md={12} xl={6} xxl={6}>
								<ButtonInput
									title={<FormattedMessage id="saveForLater" />}
									variant="contained"
									bgColor="white"
									textColor="purple"
									minWidth="201"
									borderRadius="100"
									TextTransform="capitalize"
									padding="0px 15px"
									height='56'
									startIcon={<BookmarksIcon />}
								/>
								</Grid>
								
							</Grid>
						</Box>
					</WrapperRightDiv>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

HeaderBanner.propTypes = {
	bgColor: string.isRequired,
};


export default HeaderBanner;