import {
   RESUME_ONLINE_FORM
} from "./constant";

const initialValue = {
   resumeForm: false,
   step: 0
};

const ResumeOnlineFormReducer = (state = initialValue, action) => {
   switch (action.type) {
      case RESUME_ONLINE_FORM:
         return { ...state, data: action.data };
      default:
         return state;
   }
};

export default ResumeOnlineFormReducer;
