import React from "react";
import { Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import sadadLogo from "assets/images/Application/ApplicationDeatils/sadadPayment.png";
import InfoIcon from "assets/images/Application/infoIcon.png";
import ButtonInput from "components/Input/ButtonInput";
import H5Text from 'components/Text/H5Text';
import Text from 'components/Text';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { propsToClassKey } from "@mui/styles";

const StyleWrapper = styled.div`
  align-items: center;

    .right_container{
        background:#ffffff;
        border-radius:16px;
        display:flex;
        align-items:center;
    };

    .right_container_top{
        background:#E6D9F5;
        padding: 8px 24px 8px 24px;
        border-radius:8px;
        height:52px;
        align-items:center;
        display:flex;
    };

    .right_container_style{
        padding:20px 28px 20px 28px;
    };

    .right_container_body{
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-bottom:20px;
    };
 
`

const Payments = (props) => {

    return (
        <StyleWrapper>
            <Row className="right_container">
                <div className="right_container_top">
                    <H5Text size="24" marginTop="0" color="purple" >
                        <FormattedMessage id="paymentOption" />
                    </H5Text>
                </div>

                <div className="right_container_style">
                    <div className="right_container_body">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <H5Text size="18" color="purple" >
                                <FormattedMessage id="sadad" />
                            </H5Text>
                            <div style={{ marginLeft: "26px", marginRight: "8px" }}>
                                <img src={InfoIcon} alt="icon" />
                            </div>
                            <Text size="18" color="purple">
                                ( <FormattedMessage id="userGuide" />)
                            </Text>
                        </div>
                        <div>
                            <img src={sadadLogo} alt="logo" />
                        </div>
                    </div>

                    <div className="right_container_body">
                        <Text size="20" marginTop="0" color="lightGrey">
                            <FormattedMessage id="billerName" />
                        </Text>
                        <Text size="20" marginTop="0" color="lightDark">
                            Abdul Latif Jameel Finance
                        </Text>
                    </div>

                    <div className="right_container_body">
                        <Text size="20" marginTop="0" color="lightGrey">
                            <FormattedMessage id="billerCode" />
                        </Text>
                        <Text size="20" marginTop="0" color="lightDark">
                            065
                        </Text>
                    </div>

                    <div className="right_container_body">
                        <Text size="20" marginTop="0" color="lightGrey">
                            <FormattedMessage id="invoiceNumber" />
                        </Text>
                        <Text size="20" marginTop="0" color="lightDark">
                            878946545645653
                        </Text>
                    </div>

                    <div className="right_container_body">
                        <H5Text size="18" marginTop="0" color="purple" >
                            {(<FormattedMessage id="bankTransfer" />)}
                        </H5Text>
                    </div>

                    <div className="right_container_body">
                        <Text size="20" marginTop="0" color="lightGrey">
                            <FormattedMessage id="bankName" />
                        </Text>
                        <Text size="20" marginTop="0" color="lightDark">
                            Arab National Bank
                        </Text>
                    </div>

                    <div className="right_container_body">
                        <Text size="20" marginTop="0" color="lightGrey">
                            <FormattedMessage id="accountNumber" />
                        </Text>
                        <Text size="20" marginTop="0" color="lightDark">
                            897464551246
                        </Text>
                    </div>

                    <div className="right_container_body">
                        <Text size="20" marginTop="0" color="lightGrey">
                            <FormattedMessage id="IBANNumber" />
                        </Text>
                        <Text size="20" marginTop="0" color="lightDark">
                            SA78465236548451326
                        </Text>
                    </div>

                    <div className="right_container_body">
                        <H5Text size="18" marginTop="0" color="purple" >
                            {(<FormattedMessage id="cardPayment" />)}
                        </H5Text>
                    </div>

                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <ButtonInput
                            title={<FormattedMessage id="payViaCard" />}
                            variant="text"
                            bgColor="transparent"
                            borderColor="purple"
                            textColor="purple"
                            borderRadius="100"
                            TextTransform="capitalize"
                            size={"24"}
                            padding={"10px 16px"}
                            endIcon={<ArrowForwardIcon />}
                            onClick={props?.onClick}
                        />
                    </div>

                </div>
            </Row>
        </StyleWrapper>
    );
};

export default Payments;