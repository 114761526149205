import React from 'react';
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getRevisedFinancingCongratulationsURL } from 'routes/AppUrls';

import H5Text from "components/Text/H5Text";
import ButtonInput from "components/Input/ButtonInput";
import Text from "components/Text";

const WrapperDiv = styled.div`
	
`;

const WrapperBox = styled(Box)`
	border-top: 1px solid #B8B8B8;
    padding: 10px 0px;
    margin: 15px 0px;
`;

function OrderSummary({ handleCurStep }) {
	const navigate = useNavigate();

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" padding="8px 16px" color="purple" size={'32'} fontFamily='Karbon_semiBold'>
						<FormattedMessage id="autoFinance.yourOrderSummary" />
					</H5Text>

					<div className="content" style={{ padding: "0 15px 15px 15px" }}>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
										<FormattedMessage id="autoFinance.initialInformation" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.employerType" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.occupation" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.anyMonthlyPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.totalCreditCardLimit" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.netIncome" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.anyMortage" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Private Sector
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Accountant
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											0.00 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											0.00 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											0.00 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											No
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>

						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
									<FormattedMessage id="autoFinance.drivingLicense" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.licenseType" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.drivingLicenseExpiryDate" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.LicenseHeldforYears" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Private
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											12/11/2030
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											4
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>

						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
									<FormattedMessage id="insuranceDetails" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="name" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="amount" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="insurancePriceVAT" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="deductible" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="NCDDiscount" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Alrajhi Takaful
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											4,500.00 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											344.00 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											1,000.00 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											2,000.00 SAR
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>



						{/* start=============== */}
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
										<FormattedMessage id="financingResults" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="monthlyPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="financePeriod" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="downPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="residualValue" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="issueExpenses" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="requiredPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="totalVAT" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											13,000 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											60 Months
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											0.00 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											30,000 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											862.00 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											13,191.00 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											10,638.00 SAR
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
										<FormattedMessage id="autoFinance.personalInformation" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.numberofDependents" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.levelofEducation" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.residenceType" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.houseType" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											3
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Bachelor’s Degree
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Rent
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Apartment
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
										<FormattedMessage id="autoFinance.workInformation" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.employerNumber" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.workPostCode" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.workLocation" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.workCity" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											987523
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											2547
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											abc Street
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Jeddah
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
										<FormattedMessage id="autoFinance.relativeInformation" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.relativeName" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.designation" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.relation" />&nbsp;ALJUF
											</Text>
											
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Saeed Khaled
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Army
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											No
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
					
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
										<FormattedMessage id="autoFinance.expensesObligations" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.totalExpenses" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.totalObligations" />
											</Text>
										
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											3,400.00 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											3,400.00 SAR
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
										<FormattedMessage id="autoFinance.bankDetails" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.IBAN" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.bankName" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.purposeofLoan" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.PreferredPaymentMode" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											1254687951653232611
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											National Saudi Bank
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											0 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											Bank Transfer
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						<WrapperBox>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
										<FormattedMessage id="documents" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6} display='flex' alignItems={'center'}>
											<img alt="" src={require('assets/images/financing/pdf.png')} height='25.74' width='24' style={{marginRight:'16px', marginTop:'10px'}} />
											<Text size="20" color="dark" fontWeight="600">
											Driver’s License.pdf
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>
						<WrapperBox style={{borderBottom: '1px solid #B8B8B8'}}>
							<Grid container>
								<Grid item xs={12} lg={4}>
									<Text size="20" color="lightDark" fontWeight="800" fontFamily="KarbonSemiBold" textTransform="uppercase">
										<FormattedMessage id="autoFinance.applicationFees" />
									</Text>
								</Grid>
								<Grid item xs={12} lg={8}>
									<Grid container>
										<Grid item xs={6}>
											<Text size="20" color="dark" fontWeight="600">
												<FormattedMessage id="autoFinance.totalIncludingVAT" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="autoFinance.applicationFees" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="monthlyPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="downPayment" />
											</Text>
											<Text size="20" color="dark" fontWeight="600">
											<FormattedMessage id="totalVAT" />
											</Text>
										</Grid>
										<Grid item xs={6} display='flex' flexDirection= 'column' alignItems={{xs:'end', lg:'start'}}>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											31,715.65 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											40.65 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											3,175 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											1,500 SAR
											</Text>
											<Text size="20" color="purple" fontWeight="800" fontFamily="KarbonSemiBold">
											1,500 SAR
											</Text>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</WrapperBox>




						{/* start=============== */}










					</div>
				</Grid>
			</Grid>

			<Grid container style={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(4)}
								/>
								<ButtonInput
									title={<FormattedMessage id="continue" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									// onClick={() => navigate(getRevisedFinancingCongratulationsURL('en'))}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	)
}


export default OrderSummary;