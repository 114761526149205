import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { string } from "prop-types";

const Wrapper = styled.p`
	margin-bottom:0;
	font-size: ${(props) => (props.size + "px")};
	color:  ${(props) => (props.theme[props.color])};
	margin-top: ${(props) => (props.marginTop + "px")};
	font-family: ${(props) => (props.fontFamily)};
	font-weight: ${(props) => (props.fontWeight)};
	line-height: ${(props) => (props.lineHeight + "px")};
	overflow:hidden;
	text-overflow:ellipsis;
	margin:${(props) => (props.margin)};
	height:${(props) => props.height + "px" ? props.height + "px" : ''};
	text-align: ${(props) => (props.TextAlign)};

	${(props) =>
		props.textTransform && `
 	    text-transform:  ${props.textTransform};
    `}
`;

const WrapperSpan = styled.span`
	margin-bottom:0;
	font-weight: bold;
	font-size: ${(props) => (props.size + "px")};
	color: rgba(63, 25, 86, 1);
	margin-top: ${(props) => (props.marginTop + "px")};
`;

const WrapperSub = styled.sub`
    vertical-align: top;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    font-family: Karbon_light;
`;

function SummaryText({ text, spanText, containerProps, ...props }) {
	return (
		<Wrapper {...props}>
			<FormattedMessage id={text} />

			{props.subText &&
				<WrapperSub>{props.subText && props.subText}</WrapperSub>
			}

			{spanText &&
				<WrapperSpan {...props}>
					&nbsp;<FormattedMessage id={spanText} />
				</WrapperSpan>
			}
		</Wrapper>
	);
}

SummaryText.propTypes = {
	marginTop: string.isRequired,
	size: string.isRequired,
	subSize: string,
	subMarginTop: string,
	fontWeight: string,
	fontFamily: string
};

SummaryText.defaultProps = {
	marginTop: 10,
	size: 24,
	subSize: 16,
	subMarginTop: 10,
	fontWeight: 600,
	fontFamily: 'Karbon_light',
	TextAlign: 'left'
};

export default SummaryText;