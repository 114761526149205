import { takeLatest, put } from "redux-saga/effects";
import {
	GET_VEHICLE_DETAILS, GET_VEHICLE_DETAILS_ERROR, GET_VEHICLE_DETAILS_SUCCESS,
} from "./constant";
import url from "utils/services/urls.json";
import { getMethodParamSlashWithToken } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";

function* getVehicleDetailsCall(action) {
	try {
		const response = yield getMethodParamSlashWithToken(url.getVehicleListDetails, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: GET_VEHICLE_DETAILS_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: GET_VEHICLE_DETAILS_ERROR, payload: response });
			if (action.external && action.external.errorStep) {
				action.external.handleStep(action.external.errorStep);
			}
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}
	} catch (error) {
		yield put({ type: GET_VEHICLE_DETAILS_ERROR, payload: error });
	}
};


function* getVehicleDetailsCallSaga() {
	yield takeLatest(GET_VEHICLE_DETAILS, getVehicleDetailsCall);
};

export default getVehicleDetailsCallSaga;
