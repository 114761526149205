import { takeLatest, put } from "redux-saga/effects";
import {
	APP_STATIC_IMAGE,
	APP_STATIC_IMAGE_ERROR,
	APP_STATIC_IMAGE_SUCCESS,
} from "./constant";
import url from "utils/services/urls.json";
import { getMethod } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";

function* appStaticImageDetail(action) {
	try {
		const response = yield getMethod(url.appStaticImage, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: APP_STATIC_IMAGE_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: APP_STATIC_IMAGE_ERROR, payload: response });
			if (action.external && action.external.errorStep) {
				action.external.handleStep(action.external.errorStep);
			};
			if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
		}
	} catch (error) {
		yield put({ type: APP_STATIC_IMAGE_ERROR, payload: error });
	}
};


function* appStaticImageSaga() {
	yield takeLatest(APP_STATIC_IMAGE, appStaticImageDetail);
};

export default appStaticImageSaga;
