import { FormControl, FormHelperText, InputAdornment, OutlinedInput } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';

const SearchStyle = styled.div`

    .MuiInputBase-root{
        border-radius:${(props) => props.borderRadius ? props.borderRadius : '8px'};
        background:${(props) => props.background ? props.background : '#ffffff'};
        height:${(props) => props.height ? props.height : '62px'};
        margin-top:${(props) => props.marginTop ? props.marginTop : '10px'};
        font-family: Karbon_light;
        font-weight:600;
        border:${(props) => props.border ? props.border : 'none'};
        box-shadow:${(props) => props.boxShadow ? props.boxShadow : 'none'};
        color: ${(props) => (props.theme[props.color])};
    };

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        font-size: ${(props) => props.size ? props.size : ''};
        color: ${(props) => (props.theme[props.color])};
    };

    .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
        padding-left:  ${(props) => props.paddingLeft};
    }

    .MuiFormControl-root{
        margin:0;
    };

    fieldset{
        border:none;
    };
`
const SearchInput = (props) => {
  return (
    <SearchStyle {...props}>
      <FormControl sx={{ m: 1, width: props.width ? props.width : '25ch' }} variant="outlined">
        <OutlinedInput
          placeholder={props.placeholder ? props.placeholder : 'Search by brands,year,etc.'}
          id="outlined-adornment-weight"
          startAdornment={props.startAdornment && <InputAdornment position="start"><SearchIcon /></InputAdornment>}
          endAdornment={props.endAdornment && <InputAdornment position="end"><SearchIcon /></InputAdornment>}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': 'weight',
          }}
        />
      </FormControl>
    </SearchStyle>
  )
}

export default SearchInput
