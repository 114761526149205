import {
   CONTRACT_LIST,
   CONTRACT_LIST_ERROR,
   CONTRACT_LIST_SUCCESS,
} from "./constant";

const contractListReducer = (state = {}, action) => {
   switch (action.type) {
      case CONTRACT_LIST:
         return { ...state, loading: true, error: '' };
      case CONTRACT_LIST_ERROR:
         return { ...state, type: CONTRACT_LIST_ERROR, loading: false, error: action.payload };
      case CONTRACT_LIST_SUCCESS:
         return { ...state, type: CONTRACT_LIST_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default contractListReducer;