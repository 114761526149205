import {
    GET_NOTIFICATIONS_LIST,
    NOTIFICATIONS_ERROR,
    NOTIFICATIONS_SUCCESS
} from "./constant";


export const notificationsCallAPI = (type, data, external = {}) => {
    switch (type) {
        case GET_NOTIFICATIONS_LIST:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

