import messagesGlobalEn from "lang/global/en.json";
import messagesLoginEn from "lang/login/en.json";
import messagesRegisterEn from "lang/register/en.json";
import messagesForgotEn from "lang/forgot/en.json";
import messagesDashboardEn from "lang/dashboard/en.json";
import messagesAutoFinanceEn from "lang/autoFinance/en.json";
import findVehicleEn from "lang/findVehicle/en.json";
import financeCalculatorEn from "lang/financeCalculator/en.json";
import messagesAccountEn from "lang/account/en.json";
import cashFinanceEn from "lang/cashFinance/en.json";

export default function TranslationsEn() {
    return {
        ...messagesGlobalEn,
        ...messagesLoginEn,
        ...messagesRegisterEn,
        ...messagesForgotEn,
        ...messagesDashboardEn,
        ...messagesAutoFinanceEn,
        ...findVehicleEn,
        ...financeCalculatorEn,
        ...messagesAccountEn,
        ...cashFinanceEn
    };
}