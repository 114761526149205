import { takeLatest, put } from "redux-saga/effects";
import {
	OTP_VERIFY,
	OTP_VERIFY_ERROR,
	OTP_VERIFY_SUCCESS,
} from "./constant";
import url from "utils/services/urls.json";
import { postMethod } from "utils/services/apis";
import { ClearTokenRedirectLogin } from "utils/general";
import { unsetClient } from "store/token/action";
import LocalStorageManager from "managers/LocalStorageManger";

function* otpVerify(action) {
	try {
		const response = yield postMethod(url.userOTPVerify, action.data);
		if (response.status === 200 || response.status === 201) {
			yield put({ type: OTP_VERIFY_SUCCESS, payload: response });

			if (response.data.data && response.data.data.routingKey === 'login' && response.data.data.key) {
				LocalStorageManager.setItem('RefreshToken', response.data.data.key);
			}
			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			}

			if (action.external && action.external.step && response.data?.data?.migratedUser) {
				action.external.handleStep(action.external.step);
			} else if (action.external && action.external.step && response.data?.data?.force_reset) {
				action.external.handleStep(action.external.step);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}

		} else {
			yield put({ type: OTP_VERIFY_ERROR, payload: response });
			if (action.external && action.external.errorStep && response?.data?.data?.action_event === "login") {
				action.external.handleStep(action.external.errorStep);
			}
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}
	} catch (error) {
		yield put({ type: OTP_VERIFY_ERROR, payload: error });
	}
}

function* otpVerifySaga() {
	yield takeLatest(OTP_VERIFY, otpVerify);
};

export default otpVerifySaga;
