import React, { useState } from 'react';
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';
import IconWithText from "components/IconWithText";
// import Text from "components/Text";
import ButtonInput from 'components/Input/ButtonInput';
import ArrowLeftIcon from "assets/images/Application/ApplicationDeatils/ArrowLeft.png";

import { FormattedMessage, useIntl } from "react-intl";
import CardContent from '@mui/material/CardContent';
import H5Text from "components/Text/H5Text";
import SideBySideText from "components/SideBySideText";
import Text from "components/Text";
import MuiCards from "components/MuiCards";

const WrapperDiv = styled.div`
	background: ${(props) => (props.theme[props.bgColor])};
	border-radius: 15px;
	margin-top: 0px;
	padding: 20px;
`;

const arr = [{ id: 1, name: "All Cars", is_select: false }, { id: 2, name: "20x  Yaris", is_select: false }, { id: 3, name: "10x  KIA Sonet", is_select: false },
{ id: 4, name: "30x  KIA Sonet X", is_select: false }, { id: 5, name: "20x  Mercedes E Class", is_select: false }, { id: 6, name: "20x  Yaris", is_select: false }];

function Payment({ containerProps, ...props }) {

    return (
        <>
            <Grid container marginTop={"0px"}>
                <Grid item xs={12}>
                    <Text marginTop="0" color="purple" size="24" fontFamily="KarbonBold" fontWeight='800' lineHeight={'36'}>
                       	<FormattedMessage id="autoFinance.paymentDetails" />
                    </Text>
                </Grid>
            </Grid>

            <WrapperDiv {...props}>
                <Grid container>
                    <Grid item xs={12}>
                        <SideBySideText leftText={<FormattedMessage id="autoFinance.applicationFee" />} RightText="40.65 SR" leftTextColor="lightMediumBlack" rightTextColor="lightPrimary" leftFontFamily="Karbon_light" leftFontWight="600" size="24" />

                        <SideBySideText leftText={<FormattedMessage id="monthlyPayment" />}  RightText="3,175 SR" leftTextColor="lightMediumBlack" rightTextColor="lightPrimary" leftFontFamily="Karbon_light" leftFontWight="600" size="24" />

                        <SideBySideText leftText={<FormattedMessage id="downPayment" />} RightText="1,500 SR" leftTextColor="lightMediumBlack" rightTextColor="lightPrimary" leftFontFamily="Karbon_light" leftFontWight="600" size="24" />

                        <SideBySideText leftText={<FormattedMessage id="totalVAT" />} RightText="27,000 SR" leftTextColor="lightMediumBlack" rightTextColor="lightPrimary" leftFontFamily="Karbon_light" leftFontWight="600" size="24" />
                    </Grid>

                    <Grid item xs={12}>
                        <SideBySideText leftText={<FormattedMessage id="autoFinance.totalIncludingVAT" />} RightText="131,715.65" subText="SAR" leftFontFamily="Karbon_semibold" leftTextColor="green" rightTextColor="green"  leftFontWight="800" size="24" border={true} borderColor="green" />
                    </Grid>
                </Grid>
            </WrapperDiv>
        </>
    );
}

export default Payment;