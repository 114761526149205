import {
    OTP_VERIFY, OTP_VERIFY_INITIAL_STATE
} from "./constant";

export const otpVerifyCallAPI = (type, data, external = {}) => {
    switch (type) {
        case OTP_VERIFY:
            return {
                type: type,
                data: data,
                external: external
            }
        case OTP_VERIFY_INITIAL_STATE:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

