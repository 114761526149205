import React from 'react'
import styled from 'styled-components'

const Styledul = styled.ul`
display: flex;
flex-wrap: wrap;
padding-top:32px !important;
margin-bottom:0;
display:flex;
align-items:center;
Font-family:Karbon_medium;
font-weight:700;
font-size:16px;
padding:0;
.Bullet{
  background:var(--primary);
  height:8px;
  width:8px;
  border-radius:50%;
  margin:0 8px;
}
`
const Styledli = styled.li`
display:inline-block;
`
const NavList = () => {
  return (
    <div>
             <Styledul>
        <Styledli>Home</Styledli>
        <div className="Bullet"></div>
        <Styledli>Individuals</Styledli>
        <div className="Bullet"></div>
        <Styledli>Auto Financing</Styledli>
        <div className="Bullet"></div>
        <Styledli>New Vehicles Financial Leasing</Styledli>
        <div className="Bullet"></div>
        <Styledli>Vehicles</Styledli>
      </Styledul>
    </div>
  )
}

export default NavList
