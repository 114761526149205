import { Box, Grid } from '@mui/material'
import SideBySideText from 'components/SideBySideText'
import H1Text from 'components/Text/H1Text'
import H5Text from 'components/Text/H5Text'
import React, { useState } from 'react'
import styled from 'styled-components'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ButtonInput from 'components/Input/ButtonInput'
import { Stack } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import MuiBreadcrumbs from 'components/MuiBreadcrumbs'
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import SelectInput from 'components/Input/SelectInput'
import YesNoInput from 'components/Input/YesNoInput'
import SelectAmongs from 'components/SelectAmongs'

const IneligibleStyle = styled.div`
background:${(props) => props.theme.lightSecondary};
padding:0.5% 5% 2.4% 5%;
`
const GridStyle = styled(Grid)`
background:${(props) => props.theme.white};
border-radius:16px;
display:flex;
justify-content:center;
padding:24px 10px 72px 10px;
`
const ApplicationRejected = () => {
    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="home" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="onlineFinancing" />,
            Link: "",
            isDisbale: true,
        },
    ])
	const reconsider = [
		{
			id: 1,
			type: <FormattedMessage id="Yes" />
		},
		{
			id: 2,
			type: <FormattedMessage id="No" />
		},
	]
    return (
        <IneligibleStyle>
            <Box margin='38px 0 12px 0'>
                <MuiBreadcrumbs size='20' list={breadcrumbsList} />
            </Box>
            <GridStyle  >
                <Grid container justifyContent={'center'}>
                    <Grid container maxWidth={'672px'}>
                        <Grid item xs={12} textAlign='center' marginTop={'24px'} >
                            <img src={require('assets/images/feeling-sorry-animate.png')} alt="" />
                            <H1Text size={'40'} lineHeight={'49'} fontFamily="Karbon_bold">Sorry to see you leave</H1Text>
                            <H5Text size={'24'} lineHeight={'25.48'} fontFamily="Karbon_light" color='lightGrey' marginTop={'8'}>To better serve you in future, kindly answer this simple survey</H5Text>
                           
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container marginTop='20px' maxWidth={'672px'}>

                                <Grid item xs={12}>
                                    <SelectInput
                                        label={<FormattedMessage id="Reason for your cancellation" />}
                                        placeholder="Reason for your cancellation"
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid item xs={12}>
                                        <SelectAmongs data={reconsider} label={"Would you reconsider if we provided good offers?"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={10} marginTop='100px'>
                        <Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
                            <ButtonInput
                                title={<FormattedMessage id="Cancel Application" />}
                                variant="text"
                                bgColor="transparent"
                                textColor="purple"
                                borderRadius="100"
                                TextTransform="capitalize"
                            // onClick={() => handleCurStep(2)}
                            />
                            <ButtonInput
                                title={<FormattedMessage id="Submit Survey" />}
                                variant="contained"
                                bgColor={"yellow"}
                                textColor="purple"
                                borderRadius="100"
                                TextTransform="capitalize"
                                endIcon={<ArrowForwardIcon />}
                            // onClick={() => handleSubmit()}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </GridStyle>
        </IneligibleStyle>
    )
}

export default ApplicationRejected;
