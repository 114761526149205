import React from "react";
import { Row } from "react-bootstrap";
import styled from 'styled-components';
import downloadIcon from "assets/icons/downloadIcon.png";
import pdfIcon from "assets/icons/pdfIcon.png";
import H5Text from 'components/Text/H5Text';
import Text from 'components/Text';
import { FormattedMessage } from "react-intl";

const StyleWrapper = styled.div`
  align-items: center;

    .right_container{
        background:#ffffff;
        border-radius:16px;
    };

    .right_container_top{
        background:#E6D9F5;
        padding: 8px 24px 8px 24px;
        border-radius:8px;
        height:52px;
        align-items:center;
        display:flex;
    };

    .right_container_style{
        padding:20px 28px 0px 28px;
    };

    .right_container_body{
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-bottom:20px;
    };
 
`

const Documents = () => {

    const arr = [1, 2, 3, 4, 5, 6];

    return (
        <StyleWrapper>
            <Row className="right_container">
                <div className="right_container_top">
                    <H5Text size="24" marginTop="0" color="purple" >
                        <FormattedMessage id="documents"/>
                    </H5Text>
                </div>

                <div className="right_container_style">
                    {arr.map((k, i) => (
                        <div className="right_container_body">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src={pdfIcon} alt="icon" style={{ marginRight: "12px" }} />
                                <Text size="20" marginTop="0" color="darkPrimary">
                                    Application Document.pdf
                                </Text>
                            </div>
                            <div>
                                <img src={downloadIcon} alt="icon" />
                            </div>
                        </div>
                    ))}
                </div>
            </Row>
        </StyleWrapper>
    );
};

export default Documents;