import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "react-bootstrap";
import styled from 'styled-components';
import ButtonInput from "components/Input/ButtonInput";
import CircularProgress from "components/CircularProgressbar";
import H5Text from 'components/Text/H5Text';
import Text from 'components/Text';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { LocaleContext } from 'routes/Router';

const StyleWrapper = styled.div`
    align-items: center;

    .right_container{
        background:#ffffff;
        border-radius:16px;
    };

    .right_container_top{
        background:#E6D9F5;
        padding: 8px 24px 8px 24px;
        border-radius:8px;
        height:52px;
        align-items:center;
        display:flex;
    };

    .right_container_style{
        padding:20px 28px 20px 28px;
    };

    .right_container_body{
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-top:20px;
    };

    .finance_container{
        width:95%;
    }
 
`

const ContractInfo = (props) => {
    const { locale } = useContext(LocaleContext);

    const list = [
        { name: "startDate", value: "11/12/23" }, { name: "endDate", value: "11/12/23" },
        { name: "period", value: "60 Months" }, { name: "downPayment", value: "0 SAR" },
        { name: "residualValue", value: "26,654 SAR" }
    ];
    return (
        <StyleWrapper>
            <Row className="right_container">
                <div className="right_container_top">
                    <H5Text size="24" marginTop="0" color="purple" >
                        <FormattedMessage id="contractInfo" />
                    </H5Text>
                </div>

                <div className="right_container_style">
                    <div className="finance_container">
                        <H5Text size="18" marginTop="0" color="purple" >
                            <FormattedMessage id="finance" />
                        </H5Text>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ height: "30%", width: "30%" }}>
                                <CircularProgress percentage={"76"} />
                            </div>
                            <div style={{ marginLeft: locale === 'en' && "24px", marginRight: locale === 'ar' && "24px", width: "100%" }}>
                                <H5Text size={'20'} marginTop='0' lineHeight={'24'} bgColor='secondaryPink' padding='12px 16px' borderRadius='8' fontFamily="Karbon_light" color="purple"> Monthly Amount: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: "Karbon_semiBold" }}>35,000 SAR</p> </H5Text>
                                <H5Text size={'20'} lineHeight={'24'} padding='0px 16px' borderRadius='8' fontFamily="Karbon_light" color="purple"> <FormattedMessage id="nextInstallment" />: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: "Karbon_semiBold" }}>{props?.data?.nextEmiAmount} SAR</p> </H5Text>
                                <H5Text size={'20'} lineHeight={'24'} padding='0px 16px' borderRadius='8' fontFamily="Karbon_light" color="purple"> <FormattedMessage id="balanceAmount" />: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: "Karbon_semiBold" }}>{props?.extraLoanData?.loanBalancePrincipal} SAR</p> </H5Text>
                                <H5Text size={'20'} lineHeight={'24'} padding='0px 16px' borderRadius='8' fontFamily="Karbon_light" color="purple"> <FormattedMessage id="remainingPeriod" />: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: "Karbon_semiBold" }}>12 Months</p> </H5Text>
                            </div>
                        </div>
                        <Grid container display="flex" justifyContent="space-between" marginTop="16px">
                            <Grid item style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ height: "14px", width: "14px", background: "#24174C" }}></div>
                                <H5Text marginTop={'0'} size={'20'} lineHeight={'24'} padding='0px 6px' borderRadius='8' fontFamily="Karbon_light" color="lightGrey">   <FormattedMessage id="collectionToDate" />: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: "Karbon_semiBold", color: '#3F1956' }}>12 Months</p> </H5Text>
                            </Grid>

                            <Grid item style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ height: "14px", width: "14px", background: "#DBD7EA" }}></div>
                                <H5Text marginTop={'0'} size={'20'} lineHeight={'24'} padding='0px 6px' borderRadius='8' fontFamily="Karbon_light" color="lightGrey">  <FormattedMessage id="overdueAmount" />: <p style={{ marginBottom: '0', marginleft: '8px', display: 'inline-block', fontFamily: "Karbon_semiBold", color: '#3F1956' }}> {props?.data?.interestOverdue} SAR</p> </H5Text>
                            </Grid>
                        </Grid>
                    </div>
                    <Box marginTop='12px'>
                        <div className="right_container_body">
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"startDate"} />
                            </Text>
                            <Text size="20" marginTop="0" color="lightDark" style={{ fontFamily: "Karbon_medium" }} >
                                {"11/12/23"}
                            </Text>
                        </div>

                        <div className="right_container_body">
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"endDate"} />
                            </Text>
                            <Text size="20" marginTop="0" color="lightDark" style={{ fontFamily: "Karbon_medium" }} >
                            {"11/12/23"}
                            </Text>
                        </div>

                        <div className="right_container_body">
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"tenure"} />
                            </Text>
                            <Text size="20" marginTop="0" color="lightDark" style={{ fontFamily: "Karbon_medium" }} >
                                {props?.data?.tenure} Months
                            </Text>
                        </div>

                        <div className="right_container_body">
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"downPayment"} />
                            </Text>
                            <Text size="20" marginTop="0" color="lightDark" style={{ fontFamily: "Karbon_medium" }} >
                                {"0 SAR"}
                            </Text>
                        </div>

                        <div className="right_container_body">
                            <Text size="20" marginTop="0" color="lightGrey">
                                <FormattedMessage id={"residualValue"} />
                            </Text>
                            <Text size="20" marginTop="0" color="lightDark" style={{ fontFamily: "Karbon_medium" }} >
                                {"0 SAR"}
                            </Text>
                        </div>

                    </Box>

                    <Grid container display="flex" justifyContent="space-between" marginTop="20px">
                        <Grid item>
                            <Text size="18" marginTop="0" color="lightGrey">
                                <FormattedMessage id="settlementAmount" />
                            </Text>
                            <Text size="32" marginTop="10" color="purple" style={{ fontFamily: "Karbon_semiBold" }}>
                                81,285.00&nbsp;<FormattedMessage id="sar" />
                            </Text>
                        </Grid>
                        <Grid item sx={{ marginTop: { xs: '10px', xl: '0' } }}>
                            <ButtonInput
                                title={<FormattedMessage id="earlySettlement" />}
                                variant="text"
                                bgColor="transparent"
                                borderColor="purple"
                                textColor="purple"
                                borderRadius="100"
                                TextTransform="capitalize"
                                size={"24"}
                                padding={"10px 16px"}
                                endIcon={<ArrowForwardIcon />}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Row>
        </StyleWrapper >
    );
};

export default ContractInfo;