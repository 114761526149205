import {
    AUTH_LOGOUT
} from "./constant";

export const logoutCallAPI = (type, data, external = {}) => {
    switch (type) {
        case AUTH_LOGOUT:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

