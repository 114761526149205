import { Box, Grid } from '@mui/material'
import SideBySideText from 'components/SideBySideText'
import H1Text from 'components/Text/H1Text'
import H5Text from 'components/Text/H5Text'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ButtonInput from 'components/Input/ButtonInput'
import { getCashFinancingApplicationApprovedURL, getCashFinancingInitialDataURL, getRequestIneligible } from 'routes/AppUrls'
import { useNavigate } from 'react-router-dom'
import { LocaleContext } from 'routes/Router'
import HeaderBanner from '../HeaderBanner'
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import MuiBreadcrumbs from 'components/MuiBreadcrumbs'
import { FormattedMessage } from 'react-intl'

const IneligibleStyle = styled.div`
background:${(props) => props.theme.lightSecondary};
padding:0.5% 5%;
min-height:100vh;
`
const GridStyle = styled(Grid)`
background:${(props) => props.theme.white};
border-radius:16px;
padding:24px 10px 48px 10px;
`
const VerificationCall = ({ handleCurStep }) => {
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="home" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="onlineFinancing" />,
            Link: "",
            isDisbale: true,
        },
    ])
    return (
        <IneligibleStyle>
            <Grid container>
                <Grid item xs={12}>
                    <HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} />
                </Grid>
            </Grid>
            <Grid container marginTop={"42px"}>
                <Grid item xs={12} md={4} display='flex' alignItems={'end'}>
                    <Box margin='0 0 12px 0'>
                        <MuiBreadcrumbs size='20' list={breadcrumbsList} />
                    </Box>
                </Grid>
            </Grid>
            <GridStyle  >
                <Grid container width={'100%'} margin="auto">
                    <Grid item xs={12} textAlign='center' marginTop={'24px'} >
                        <img src={require('assets/images/CashFinance/verificationCall.png')} alt="" />
                        <H1Text size={'40'} lineHeight={'48'} fontFamily="Karbon_bold"><FormattedMessage id="cashFinance.verificationAutoCall"/></H1Text>
                        <H5Text size={'24'} lineHeight={'25.48'} fontFamily="Karbon_light" color='lightGrey' marginTop={'8'}><FormattedMessage id="cashFinance.dearGuest"/></H5Text>
                    </Grid>
                </Grid>
                <Grid item xs={12} textAlign='end' marginTop={'64px'} marginRight={{xs:"0", lg:locale=="en"&&"64px"}} marginLeft={{xs:"0", lg:locale=="ar"&&"64px"}} >
                    <ButtonInput
                        title={<FormattedMessage id="proceed"/>}
                        variant="contained"
                        bgColor="yellow"
                        textColor="purple"
                        borderRadius="100"
                        TextTransform="capitalize"
                        fontFamily="Karbon_semiBold"
                        endIcon={<ArrowForwardIcon />}
                        // onClick={() => navigate(getCashFinancingApplicationApprovedURL(locale))}
                        onClick={() => navigate(getRequestIneligible(locale))}
                    />
                </Grid>
            </GridStyle>
        </IneligibleStyle>
    )
}

export default VerificationCall
