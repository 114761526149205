import React from 'react';
import styled from 'styled-components';
import EastIcon from '@mui/icons-material/East';

const ButtonStyle = styled.button`
  display: flex;
  align-items: center;
  color:${(props) => props.color ? props.color : 'var(--primary)'};
  background:${(props) => props.disabled ? "#fecb267a" : props.background ? props.background : '#FECB26'};
  border:${(props) => props.border ? props.border : 'none'};
  border-radius:${(props) => props.borderRadius ? props.borderRadius : "100px"};
  padding:${(props) => props.padding ? props.padding : "0"};
  font-size:${(props) => props.fontSize ? props.fontSize : '24px'};
  font-family:Karbon_medium;
  cursor:${(props) => props.disabled ? 'no-drop' : 'pointer'};
  height:${(props) => props.height ? props.height : '64px'};
  width:${(props) => props.width ? props.width : '222px'};
  display: flex;
  justify-content: center;
  margin:${(props) => props.margin ? props.margin : "0px"};
  overflow:hidden;
  text-overflow:ellipsis;
`

const ContinueButton = ({ ...props }) => {

  return (
    <ButtonStyle {...props}>
      {props.text}{props.icon && <EastIcon style={{ width: '18px', marginLeft: '6px' }} />}
    </ButtonStyle>
  )
};

export default ContinueButton;
