import {
   CREATE_PASSWORD,
   CREATE_PASSWORD_ERROR,
   CREATE_PASSWORD_INITIAL_STATE,
   CREATE_PASSWORD_SUCCESS
} from "./constant";

const createPasswordReducer = (state = {}, action) => {
   switch (action.type) {
      case CREATE_PASSWORD:
         return { ...state, loading: true, error: '' };
      case CREATE_PASSWORD_INITIAL_STATE:
         return { state, loading: false, error: '' };
      case CREATE_PASSWORD_ERROR:
         return { ...state, type: CREATE_PASSWORD_ERROR, loading: false, error: action.payload };
      case CREATE_PASSWORD_SUCCESS:
         return { ...state, type: CREATE_PASSWORD_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default createPasswordReducer;