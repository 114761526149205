import {
   AUTH_LOGIN,
   AUTH_OTP_VERIFY,
   AUTH_SET_PASSWORD,
   AUTH_CREATE_PASSWORD,
   AUTH_API_ERROR,
   AUTH_API_ERROR_RESET,
   AUTH_API_SUCCESS,
   AUTH_WATHIQ_VERIFY,
   AUTH_USER_VERIFY,
   AUTH_INITIAL_STATE,
} from "./constant";

const authReducer = (state = {}, action) => {
   switch (action.type) {
      case AUTH_USER_VERIFY:
         return { ...state, loading: true, error: '' };
      case AUTH_LOGIN:
         return { ...state, loading: true, error: '' };
      case AUTH_OTP_VERIFY:
         return { ...state, loading: true, error: '' };
      case AUTH_SET_PASSWORD:
         return { ...state, loading: true, error: '' };
      case AUTH_CREATE_PASSWORD:
         return { ...state, loading: true, error: '' };
      case AUTH_WATHIQ_VERIFY:
         return { ...state, loading: true, error: '' };
      case AUTH_INITIAL_STATE:
         return { state, loading: false, error: '' };
      case AUTH_API_ERROR_RESET:
         return { state, loading: false, error: '' };
      case AUTH_API_ERROR:
         return { ...state, type: AUTH_API_ERROR, loading: false, error: action.payload };
      case AUTH_API_SUCCESS:
         return { ...state, type: AUTH_API_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default authReducer;