import {
   UPLOAD_DOCUMENT_GOSI,
   UPLOAD_DOCUMENT_GOSI_ERROR,
   UPLOAD_DOCUMENT_GOSI_SUCCESS,
} from "./constant";

const uploadDocumentGosiReducer = (state = {}, action) => {
   switch (action.type) {
      case UPLOAD_DOCUMENT_GOSI:
         return { ...state, loading: true, error: '' };
      case UPLOAD_DOCUMENT_GOSI_ERROR:
         return { ...state, type: UPLOAD_DOCUMENT_GOSI_ERROR, loading: false, error: action.payload };
      case UPLOAD_DOCUMENT_GOSI_SUCCESS:
         return { ...state, type: UPLOAD_DOCUMENT_GOSI_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default uploadDocumentGosiReducer;