import {
    PAGE_LIST,
    HELPER_API_SUCCESS,
    HELPER_API_ERROR
} from "./constant";


const helperReducer = (state = {}, action) => {
    switch (action.type) {
        case PAGE_LIST:
            return { ...state, loading: true };
        case HELPER_API_SUCCESS:
            return { ...state, type: HELPER_API_SUCCESS,  loading: true, payload: action.payload };
        case HELPER_API_ERROR:
            return { ...state, type: HELPER_API_ERROR,  loading: true, payload: action.payload };
        default:
            return state;
    }
};

export default helperReducer;
