import {
   CAMPAIGN_SEARCH, CAMPAIGN_SEARCH_ERROR, CAMPAIGN_SEARCH_SUCCESS
} from "./constant";

const campaignSearchReducer = (state = {}, action) => {
   switch (action.type) {
      case CAMPAIGN_SEARCH:
         return { ...state, loading: true, error: '' };
      case CAMPAIGN_SEARCH_ERROR:
         return { ...state, type: CAMPAIGN_SEARCH_ERROR, loading: false, error: action.payload };
      case CAMPAIGN_SEARCH_SUCCESS:
         return { ...state, type: CAMPAIGN_SEARCH_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default campaignSearchReducer;