import React from 'react';
import { FormattedMessage } from "react-intl";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';

import Text from "components/Text";
import LabelWithInput from "components/Input/LabelWithInput";
import ButtonInput from "components/Input/ButtonInput";
import H5Text from 'components/Text/H5Text';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import MaskInput from 'components/Input/MaskInput';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useMediaQuery } from '@mui/material';



function DetailRequireModal({ modalActive, handleModalPopup }) {
  const isSmallScreen = useMediaQuery('(max-width:901px)');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? '95%' : 862,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
  };
  return (
    <Modal
      open={modalActive}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Grid container sx={style}>
        <Grid item xs={9}>
          <H5Text fontFamily='Karbon_semiBold' size='32' lineHeight='38.4' color='purple' marginTop='0' style={{borderBottom:'0.5px solid #D5D5D5'}}>
            Detail required for cancellation
          </H5Text>
        </Grid>
        <Grid item xs={3} display='flex' justifyContent='end'>
          <ClearOutlinedIcon style={{ width: '32', height: '32', cursor: 'pointer' }} onClick={() => handleModalPopup(false)}/>
        </Grid>
        <Grid item xs={12} display='flex' justifyContent='end' marginTop='8px'>
        <MaskInput
              mask="9999 9999 9999 9999"
              disabled={false}
              maskChar=" "
              label={<FormattedMessage id="IBAN Certificate" />}
              placeholder="4444 4444 4444 4444"
              name="IBAN Certificate"
              endAdornment={true}
              InputProps={{
                endAdornment: (
                  <>
                  <img src={require('assets/images/Support/UploadSimple.png')} style={{ width: '20px' }} />
                  <MoreVertOutlinedIcon style={{ marginLeft: '20px', fill: '#3C1053' }} />
               </>
                ),
                startAdornment: (
                  <>
                 <H5Text color='purple' size='16' lineHeight='19.2' marginTop='0'>
                 SA&nbsp;|
                 </H5Text>
                 &nbsp;
               </>
                )
            }}
            // onChange={handleInputChange}
            // errorText={isError && isError.contactNumber.error && isError.contactNumber.errorMessage}
            />
        </Grid>
      </Grid>
    </Modal>
  );
}

export default DetailRequireModal;