import React, { useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import styled from 'styled-components';
import ShareIcon from "assets/images/Application/ShareNetwork.png";
import ArrowLeftIcon from "assets/images/Application/ApplicationDeatils/ArrowLeft.png";
import CrossIcon from "assets/images/Application/ApplicationDeatils/X.png";
import CopyIcon from "assets/images/Application/ApplicationDeatils/CopyIcon.png";
import CarLogo from "assets/images/Application/ApplicationDeatils/monogramLogo.png";
import BackIcon from "components/Buttons/BackButton/BackIcon";
import carServiceImage from "assets/icons/E_Service/carService.png";
import carServiceImageWhite from "assets/icons/E_Service/carServiceWhite.png";
import H5Text from 'components/Text/H5Text';
import SummaryText from 'components/Text/SummaryText';
import { Grid } from "@mui/material";
import Text from "components/Text";
import ButtonInput from "components/Input/ButtonInput";
import { Drawer } from '@material-ui/core';
import SearchInput from "components/SearchInput";
import ContinueButton from "components/Buttons/ContinueButton/ContinueButton";
import { FormattedMessage } from "react-intl";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getApplicationsURL } from 'routes/AppUrls';
import {useNavigate } from "react-router-dom";
import { LocaleContext } from "routes/Router";

const StyleWrapper = styled.div`
    align-items: center;

    .left_container{
          background:#ffffff;
          border-radius:16px;
          padding: 30px 20px;
      };
    
    // ul{
    //     display: inline;
    //   };
    
    // li {
    //     // display: inline-block;
    //     padding-right:30px;
    //     float: left;
    //   };

    .service_box{
        height:100px;
        // width:234px;
        background:#F5ECFF;
        padding:16px 8px 16px 8px;
        color:#6D4C7E;
        align-items:center;
        text-align:center;
        justify-content:center;
        margin-bottom:12px;
        cursor:pointer;
    };

    .service_box_selected{
        height:100px;
        // width:234px;
        background:#3F1956;
        padding:16px 8px 16px 8px;
        color:#ffffff;
        align-items:center;
        text-align:center;
        justify-content:center;
        margin-bottom:12px;
        cursor:pointer;
    };
 
`

const Details = (props) => {
    const e_ser_arr = [
        { id: 1, name: "Renewal of Registration", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }, { id: 2, name: "Buy Your Leased Vehicle", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false },
        { id: 3, name: "Ownership Transfer", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }, { id: 4, name: "Add Additional Driver", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false },
        { id: 5, name: "Retrieve Your Vehicle", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }, { id: 6, name: "Re-lease Your Vehicle", image: carServiceImage, imageSelect: carServiceImageWhite, is_select: false }
    ];
    const arr = [{ id: 1, name: "All Cars", is_select: false }, { id: 2, name: "20x  Yaris", is_select: false }, { id: 3, name: "10x  KIA Sonet", is_select: false },
    { id: 4, name: "30x  KIA Sonet X", is_select: false }, { id: 5, name: "20x  Mercedes E Class", is_select: false }, { id: 6, name: "20x  Yaris", is_select: false }];

    const DrawerList = [
        { id: 1, name: "Kia Sonet X-Line", descripion: "2021 GU M/R 4x215", mobile: "879631452698" }, { id: 2, name: "Kia Sonet X-Line", descripion: "2021 GU M/R 4x215", mobile: "879631452698" },
        { id: 3, name: "Kia Sonet X-Line", descripion: "2021 GU M/R 4x215", mobile: "879631452698" }, { id: 4, name: "Kia Sonet X-Line", descripion: "2021 GU M/R 4x215", mobile: "879631452698" },
        { id: 5, name: "Kia Sonet X-Line", descripion: "2021 GU M/R 4x215", mobile: "879631452698" }, { id: 6, name: "Kia Sonet X-Line", descripion: "2021 GU M/R 4x215", mobile: "879631452698" },
        { id: 7, name: "Kia Sonet X-Line", descripion: "2021 GU M/R 4x215", mobile: "879631452698" }, { id: 8, name: "Kia Sonet X-Line", descripion: "2021 GU M/R 4x215", mobile: "879631452698" },
        { id: 9, name: "Kia Sonet X-Line", descripion: "2021 GU M/R 4x215", mobile: "879631452698" }, { id: 10, name: "Kia Sonet X-Line", descripion: "2021 GU M/R 4x215", mobile: "879631452698" }
    ];
    const { locale } = useContext(LocaleContext);
    const navigate = useNavigate();
    
    const [selectArray, setSelectArray] = useState([]);
    const [serviceList, setServiceList] = useState(e_ser_arr);
    const [carlistArray, setCarListArray] = useState(arr);
    const [open, setOpen] = useState(false);

    const handleSelect = (data) => {
        if (selectArray.includes(data.id)) {
            let index = selectArray.findIndex((obj) => obj === data.id);
            selectArray.splice(index, 1);
            let index1 = serviceList.findIndex((obj) => obj.id === data.id);
            setServiceList([...serviceList], serviceList[index1].is_select = false);
        } else {
            selectArray.push(data.id);
            let index = serviceList.findIndex((obj) => obj.id === data.id);
            setServiceList([...serviceList], serviceList[index].is_select = true);
        }
    };

    const handleClick = (data) => {
        carlistArray.map((k, i) => {
            if (k.id === data.id) {
                setCarListArray([...carlistArray], carlistArray[i].is_select = true);
                setOpen(true);
            } else {
                setCarListArray([...carlistArray], carlistArray[i].is_select = false);
            }
        })

    }
    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="home" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="applications" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="applicationDetails" />,
            Link: "",
            isDisbale: true,
        },
    ])
    return (
        <StyleWrapper>
            <Row className="left_container">
            <MuiBreadcrumbs
                list={breadcrumbsList}
            />


                <Grid container alignItems={'center'} marginTop={'16px'}>
                    <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginBottom:'15px'}}>
                            <ArrowBackIcon sx={{color: "#3F1956", fontSize: "24", cursor:'pointer'}} onClick={()=>navigate(getApplicationsURL(locale))} />
                        </div>
                        <H5Text size="32" marginTop="0" color="purple" style={{paddingLeft:props.locale === 'en' && '10px', paddingRight:props.locale === 'ar' && '10px'}}  >
                            1234 5521 2312 5521 4453
                        </H5Text>
                    </Grid>
                    <Grid item xs={2} justifyContent={'end'} display={'flex'}>
                        <img src={ShareIcon} alt="share" />
                    </Grid>
                </Grid>

                <Grid container alignItems={'center'} marginTop={'16px'}>
                    <Grid item xs={12} style={{ marginLeft: "35px" }}>
                        <Text color="lightGrey" size="18" marginTop="0" lineHeight="32" fontWeight="600" fontFamily="KarbonMedium">
                         
                            <FormattedMessage id="contract.contractNumber" />
                        </Text>
                    </Grid>
                </Grid>

                <Grid container alignItems={'center'} marginTop={'48px'}>
                    <Grid item xs={12}>
                        <Text color="lightGrey" size="24" marginTop="0" lineHeight="24" fontWeight="700" fontFamily="KarbonSemiBold">
                        <FormattedMessage id="contract.totalNoOfVehicles" />
                            <span >
                                <Text color="purple" size="32" marginTop="0" lineHeight="32" fontWeight="800" fontFamily="KarbonBold">
                                    {'80'}
                                </Text>
                            </span>
                        </Text>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ display: "flex", alignItems: "center", marginTop: "12px", flexWrap: "wrap" }}>
                    {carlistArray.map((k, i) => (
                        <Grid item >
                            <ButtonInput
                                title={k.name}
                                variant="text"
                                bgColor={k.is_select ? "checkboxPurple" : "transparent"}
                                textColor="purple"
                                borderRadius="50"
                                fontSize="20"
                                border={k.is_select ? '1px solid #D4BDE0' : '1px solid #E2E2E2'}
                                icon={ArrowLeftIcon}
                                minWidth='auto'
                                onClick={() => handleClick(k)}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Drawer anchor={'right'} open={open} onClose={() => setOpen(false)}>
                    <Grid container width={'838px'} padding={'30px'}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Text color="purple" size="32" marginTop="0" lineHeight="32" fontWeight="800" fontFamily="KarbonBold">
                                    {'Selected Cars'}
                                </Text>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
                                    <img src={CrossIcon} alt='icon' onClick={() => setOpen(false)} />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container marginTop={'24px'}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <SearchInput width="100%" endAdornment={true} placeholder="Search..." border="0.5px solid #B8B8B8" boxShadow="0px 4px 4px 0px #3C105314" borderRadius="20px" height="40px" marginTop="0px" />
                            </Grid>
                        </Grid>

                        {props.e_service &&
                            <Grid container marginTop={'24px'} alignItems={'center'}>
                                <Grid item lg={8} md={8} sm={8} xs={8}>
                                    <Text color="lightDark" size="24" marginTop="0" lineHeight="25.73" fontWeight="700" fontFamily="KarbonSemiBold">
                                        {'Registration Renewal E-service'}
                                    </Text>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Grid container alignItems={'center'} justifyContent={'end'}>
                                        <ContinueButton width="202px" icon={true} height="62px" fontSize="24px" boxShadow='0px 4px 4px 0px #FECB2629' margin="0px" padding="0px" border="1.31px solid #C7B7CF" text={'Proceed'} color="#3F1956"
                                            boderRadius="100px" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        {DrawerList.map((k, i) => (
                            <Grid container marginTop={'20px'} alignItems={'center'} alignSelf={'center'} borderBottom={'1px solid #DEDEDE'}>

                                <Grid item lg={8} md={8} sm={12} xs={12}>
                                    <Grid container marginTop={'20px'} alignItems={'center'}>
                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                            <div>
                                                <img src={CarLogo} alt='icon' />
                                            </div>
                                        </Grid>
                                        <Grid item lg={10} md={10} sm={10} xs={10}>
                                            <div>
                                                <Text color="black" size="20" marginTop="0" lineHeight="21.45" fontWeight="700" fontFamily="KarbonSemiBold">
                                                    {k.name}
                                                </Text>
                                            </div>
                                            <div>
                                                <Text color="lightDark" size="16" marginTop="0" lineHeight="16.98" fontWeight="600" fontFamily="KarbonMedium">
                                                    {k.descripion}
                                                </Text>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item lg={4} md={4} sm={12} xs={12} justifyContent={'end'}>
                                    <Grid container alignItems={'center'} justifyContent={'end'}>
                                        <Text color="purple" size="20" marginTop="0" lineHeight="21.6" fontWeight="600" fontFamily="KarbonMedium">
                                            {k.mobile}
                                        </Text>
                                        <img src={CopyIcon} alt='icon' />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}

                    </Grid>
                </Drawer>

                {props.e_service &&
                    <>
                        <div style={{ marginTop: "36px" }}>
                            <H5Text size="24" marginTop="0" color="purple" >
                            <FormattedMessage id="contract.eServices" />
                            </H5Text>
                        </div>

                        <Row xs={1} md={3} style={{ margin: "0px", padding: "0px" }}>
                            {serviceList.length > 0 && serviceList.map((k, idx) => (
                                <Col key={idx}>
                                    <div className={k.is_select ? "service_box_selected" : "service_box"} onClick={() => handleSelect(k)}>
                                        <div style={{ marginBottom: "8px" }}>
                                            <img src={k.is_select ? k.imageSelect : k.image} alt="icon" style={{ height: "29px" }} />
                                        </div>
                                        <div>
                                            <SummaryText text={k.name} size="18" color={k.is_select ? "white" : "purple"} TextAlign="center" lineHeight="21.6" />
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </>
                }

            </Row>
        </StyleWrapper>
    );
};

export default Details;
