import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';
import { createStore, applyMiddleware, } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import rootReducers from 'store/reducers';
import rootSagas from 'store/sagas';
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
   key: 'persist-store',
   storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = createStore(
   persistedReducer,
   applyMiddleware(sagaMiddleware, logger),
);

sagaMiddleware.run(rootSagas);

export const persistor = persistStore(store);

export default store;