import { takeLatest, put } from "redux-saga/effects";
import {
    YAKEEN_USER_INFO,
    YAKEEN_USER_INFO_SUCCESS,
    YAKEEN_USER_INFO_ERROR,
    YAKEEN_USER_INFO_SAUDI,
    YAKEEN_USER_INFO_NON_SAUDI
} from "./constant";
import url from "utils/services/urls.json";
import { getMethodParamWithToken } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";


function* getYakeenUserSaudiInfo(action) {
    try {
        const response = yield getMethodParamWithToken(url.yakeenSearchUserInfoSaudi, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: YAKEEN_USER_INFO_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: YAKEEN_USER_INFO_ERROR, payload: response });
            action.external.redirectNavigate(action.external.path);
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: YAKEEN_USER_INFO_ERROR, payload: error });
    }
};

function* getYakeenUserNonSaudiInfo(action) {
    try {
        const response = yield getMethodParamWithToken(url.yakeenSearchUserInfoNonSaudi, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: YAKEEN_USER_INFO_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: YAKEEN_USER_INFO_ERROR, payload: response });
            action.external.redirectNavigate(action.external.path);
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: YAKEEN_USER_INFO_ERROR, payload: error });
    }
};


function* financingYakeenUserInfoSaga() {
    yield takeLatest(YAKEEN_USER_INFO_SAUDI, getYakeenUserSaudiInfo);
    yield takeLatest(YAKEEN_USER_INFO_NON_SAUDI, getYakeenUserNonSaudiInfo);
};

export default financingYakeenUserInfoSaga;