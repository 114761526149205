import {
    ACCOUNT_API_ERROR,
    ACCOUNT_API_SUCCESS,
    ACCOUNT_REGISTER_DEVICE,
    ACCOUNT_PASSWORD_CHANGE,
    ACCOUNT_INITIAL_STATE,
    ACCOUNT_AUDIT_LOG_DATA
} from "./constant";

const accountReducer = (state = {}, action) => {
    switch (action.type) {
        case ACCOUNT_REGISTER_DEVICE:
            return { ...state, loading: true, error: '' };
        case ACCOUNT_PASSWORD_CHANGE:
            return { ...state, loading: true, error: '' };
        case ACCOUNT_AUDIT_LOG_DATA:
            return { state, loading: true, error: '' };
        case ACCOUNT_INITIAL_STATE:
            return { state, loading: false, error: '' };
        case ACCOUNT_API_ERROR:
            return { ...state, type: ACCOUNT_API_ERROR, loading: false, error: action.payload };
        case ACCOUNT_API_SUCCESS:
            return { ...state, type: ACCOUNT_API_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default accountReducer;
