import React, { useState, useContext } from "react";
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import MuiCards from "components/MuiCards";
import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import { FormattedMessage } from "react-intl";
import { LocaleContext } from 'routes/Router';
import FinancingResultsData from "features/CashFinancing/FinancingResults";
import MuiBreadcrumbs from "components/MuiBreadcrumbs";
import HeaderBanner from "features/CashFinancing/HeaderBanner";
import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import { Stack } from "@mui/material";
import ButtonInput from "components/Input/ButtonInput";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputAdornment from '@mui/material/InputAdornment';
import UploadIcon from "assets/images/financing/UploadIcon.png";
import ThreeDotIcon from "assets/images/financing/DotThreeIcon.png";
import { Navigate, useNavigate } from "react-router-dom";
import { getCashFinancingApplicationApprovedURL, getQuotationCongratulation } from "routes/AppUrls";

export const WrapperBox = styled(Box)`
    display: "flex";
    background-color: ${(props) => props.theme.lightSecondary}
`;

const UploadQuotation = () => {
    const [curStep, setCurStep] = useState(0);
    const { locale } = useContext(LocaleContext);
    const navigate = useNavigate();

    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="home" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="Cash Financing" />,
            Link: "",
            isDisbale: true,
        },
    ]);

    const [isModalActive, setIsModalActive] = useState(false);

    const handleModalPopup = (value) => {
        setIsModalActive(value);
    }

    return (
        <WrapperBox>
            <Container maxWidth={'xl'} style={{ padding: "10px" }} disableGutters>
                <Grid container>
                    <Grid item xs={12}>
                        <HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} md={4} display='flex' alignItems={'end'}>
                        <Box margin='45px 0 12px 0'>
                            <MuiBreadcrumbs size='20' list={breadcrumbsList} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} xl={3}>
                        <FinancingResultsData title="financingResults" bgColor={"white"} curStep={curStep} />
                    </Grid>

                    <Grid item xs={12} lg={8} xl={9}>
                        <MuiCards borderRadius="16" padding="0">

                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
                                            <FormattedMessage id={'Upload Signed Quotation'} />
                                            {/* <img src={require('assets/images/icon/exclamation.png')} height='20px' width='20px' style={{ marginLeft: '16px' }} /> */}
                                        </H5Text>

                                        <Box component="form" sx={{ padding: "15px" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={12} lg={6}>
                                                    <LabelWithInput
                                                        label={<FormattedMessage id={'Signed Quotation'} />}
                                                        placeholder=""
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <img src={UploadIcon} onClick={() => setIsModalActive(true)} style={{ cursor: "pointer" }} alt='icon' />
                                                                    <img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ marginTop: "79px" }}>
                                    <Grid item xs={12}>
                                        <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                                            <Box component="form">
                                                <Stack spacing={2} direction="row">
                                                    <ButtonInput
                                                        title={<FormattedMessage id="back" />}
                                                        variant="text"
                                                        bgColor="transparent"
                                                        textColor="purple"
                                                        borderRadius="100"
                                                        TextTransform="uppercase"
                                                        onClick={() => navigate(getQuotationCongratulation(locale))}
                                                    />
                                                    <ButtonInput
                                                        title={<FormattedMessage id="Submit" />}
                                                        variant="contained"
                                                        bgColor="yellow"
                                                        textColor="purple"
                                                        borderRadius="100"
                                                        TextTransform="capitalize"
                                                        endIcon={<ArrowForwardIcon />}
                                                        onClick={() => navigate(getCashFinancingApplicationApprovedURL(locale))}
                                                    />
                                                </Stack>
                                            </Box>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>

                        </MuiCards>
                    </Grid>
                </Grid>
            </Container>
        </WrapperBox>
    );

};

export default UploadQuotation;