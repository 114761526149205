import {
   WORK_ITEM_CREATE,
   WORK_ITEM_ERROR,
   WORK_ITEM_SUCCESS
} from "./constant";

const workItemReducer = (state = {}, action) => {
   switch (action.type) {
      case WORK_ITEM_CREATE:
         return { ...state, loading: true, error: '' };
      case WORK_ITEM_ERROR:
         return { ...state, type: WORK_ITEM_ERROR, loading: false, error: action.payload };
      case WORK_ITEM_SUCCESS:
         return { ...state, type: WORK_ITEM_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default workItemReducer;