import { Box, Grid, Stack } from '@mui/material'
import SideBySideText from 'components/SideBySideText'
import H1Text from 'components/Text/H1Text'
import H5Text from 'components/Text/H5Text'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ButtonInput from 'components/Input/ButtonInput'
import { getCashFinancingApplicationApprovedURL } from 'routes/AppUrls'
import { useNavigate } from 'react-router-dom'
import { LocaleContext } from 'routes/Router'
import { FormattedMessage } from 'react-intl'
import SelectInput from 'components/Input/SelectInput'
import SelectAmongs from 'components/SelectAmongs'
import MuiBreadcrumbs from 'components/MuiBreadcrumbs'

const IneligibleStyle = styled.div`
background:${(props) => props.theme.lightSecondary};
padding:2.4% 5%;
min-height:100vh;
`
const GridStyle = styled(Grid)`
background:${(props) => props.theme.white};
border-radius:16px;
// display:flex;
// justify-content:center;
padding:24px 10px 72px 10px;
min-height:80vh;
position:relative;
@media screen and (max-height:800px){
    position:unset;

}
`
const SurveyIneligible = () => {
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const offers = [
		{
			id: 1,
			type: <FormattedMessage id="yes" />
		},
		{
			id: 2,
			type: <FormattedMessage id="no" />
		},
	]
    const [breadcrumbsList, setBreadcrumbsList] = useState([
        {
            title: <FormattedMessage id="home" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="applyFinance" />,
            Link: ""
        },
        {
            title: <FormattedMessage id="onlineFinancing" />,
            Link: "",
            isDisbale: true,
        },
    ])
    return (
        <IneligibleStyle>
            <Grid container>
                <Grid item xs={12} md={4} display='flex' alignItems={'end'}>
                    <Box margin='0 0 12px 0'>
                        <MuiBreadcrumbs size='20' list={breadcrumbsList} />
                    </Box>
                </Grid>
            </Grid>
            <GridStyle  >
                <Grid container width={'100%'} margin="auto" maxWidth={"575px"}>
                    <Grid item xs={12} textAlign='center' marginTop={'24px'} >
                        <img src={require('assets/images/feeling-sorry-animate.png')} alt="" />
                        <H1Text size={'40'} lineHeight={'48'} fontFamily="Karbon_bold">Sorry to see you leave</H1Text>
                        <H5Text size={'24'} lineHeight={'28.8'} fontFamily="Karbon_light" color='lightGrey' marginTop={'8'}>To better serve you in future, kindly answer this simple survey</H5Text>
                    </Grid>
                        <Grid item xs={12} md={12}>
                            <SelectInput label={<FormattedMessage id="Reason for your cancellation" />} placeholder="Reason for your cancellation" />
                        </Grid>
                        <Grid item xs={12} md={12}>
								<SelectAmongs data={offers} label={<FormattedMessage id="Would you reconsider if we provided good offers?" />} />
							</Grid>
                </Grid>
                <Grid item xs={12} textAlign='end' position="absolute" bottom={"51px"} right={{ xs: "20px", md: "40px" }} marginTop={'64px'} marginRight={{ xs: "0", lg: locale == "en" && "64px" }} marginLeft={{ xs: "0", lg: locale == "ar" && "64px" }} >
                    {/* <ButtonInput
                            title="Re-apply to 12,500 SAR"
                            variant="contained"
                            bgColor="yellow"
                            textColor="purple"
                            borderRadius="100"
                            TextTransform="capitalize"
                            fontFamily="Karbon_semiBold"
                            endIcon={<ArrowForwardIcon />}
                            onClick={() => navigate(getCashFinancingApplicationApprovedURL(locale))}
                        /> */}
                    <Box component="form">
                        <Stack spacing={2} direction="row">
                            <ButtonInput
                                title={<FormattedMessage id="cancel" />}
                                variant="text"
                                bgColor="transparent"
                                textColor="purple"
                                borderRadius="100"
                                TextTransform="capitalize"
                                onClick={() => navigate(-1)}
                            />
                            <ButtonInput
                                title={<FormattedMessage id="Submit Survey" />}
                                variant="contained"
                                bgColor="yellow"
                                textColor="purple"
                                borderRadius="100"
                                TextTransform="capitalize"
                                endIcon={<ArrowForwardIcon />}
                                onClick={() => navigate(getCashFinancingApplicationApprovedURL(locale))}
                            />
                        </Stack>
                    </Box>
                </Grid>
            </GridStyle>
        </IneligibleStyle>
    )
}

export default SurveyIneligible
