import { LOGIN_DETAILS } from "./constant";

const initialSate = { data: "" };

const loginReducer = function tokenReducer(state = initialSate, action) {
    switch (action.type) {
        case LOGIN_DETAILS:
            return {
                data: action,
            }
        default:
            return state
    }
}

export default loginReducer;