import { FormattedMessage } from "react-intl"
import { CrNumberValidation, IqamaNumberValidation } from "utils/general"

const CheckValidation = value => {

    const formError = {
        submit: true,
        nationalId: {
            error: false,
            errorMessage: <FormattedMessage id="invalidIdIqamaNumber"/>
        },
        crNumber: {
            error: false,
            errorMessage: <FormattedMessage id="invalidCRNumber"/>
        },
    };

    const emptyKeys = [
        "nationalId",
        "crNumber",
    ];


    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "crNumber" && !CrNumberValidation(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "nationalId" && !IqamaNumberValidation(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }
    }

    return formError;
}

export default CheckValidation;