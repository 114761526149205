import {
    ACCOUNT_COMPLAINTS
} from "./constant";

export const accountComplaintsCallAPI = (type, data, external = {}) => {
    switch (type) {
        case ACCOUNT_COMPLAINTS:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};


