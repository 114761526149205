import {
    ACCOUNT_COMPLAINTS_FORM
} from "./constant";

export const accountComplaintsFormCallAPI = (type, data, external = {}) => {
    switch (type) {
        case ACCOUNT_COMPLAINTS_FORM:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};


