import {
   AUTH_SEND_OTP,
   AUTH_SEND_OTP_ERROR,
   AUTH_SEND_OTP_INITIAL_STATE,
   AUTH_SEND_OTP_SUCCESS
} from "./constant";

const authSendOtpReducer = (state = {}, action) => {
   switch (action.type) {
      case AUTH_SEND_OTP:
         return { ...state, loading: true, error: '' };
      case AUTH_SEND_OTP_INITIAL_STATE:
         return { state, loading: false, error: '' };
      case AUTH_SEND_OTP_ERROR:
         return { ...state, type: AUTH_SEND_OTP_ERROR, loading: false, error: action.payload };
      case AUTH_SEND_OTP_SUCCESS:
         return { ...state, type: AUTH_SEND_OTP_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default authSendOtpReducer;