import {
    GET_VEHICAL_LIST,
    GET_VEHICAL_CATEGORY,
    GET_VEHICAL_LIST_DETAIL,
    GET_CAMPAIGNS_LIST,
} from "./constant";


export const screenCallAPI = (type, data, external = {}) => {
    switch (type) {
        case GET_VEHICAL_LIST:
            return {
                type: type,
                data: data,
                external: external
            }
        case GET_VEHICAL_CATEGORY:
            return {
                type: type,
                data: data,
                external: external
            }
        // case GET_VEHICAL_LIST_DETAIL:
        //     return {
        //         type: type,
        //         data: data,
        //         external: external
        //     }
        case GET_CAMPAIGNS_LIST:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

