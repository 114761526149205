import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import H1Text from "components/Text/H1Text";
import Text from "components/Text";
import LabelWithInput from "components/Input/LabelWithInput";
import MaskInput from "components/Input/MaskInput";
import ButtonInput from "components/Input/ButtonInput";
import MuiLink from "components/MuiLink";
import { getSignUpURL, getForgotPasswordURL } from "routes/AppUrls"
import CheckValidation from "./validation";
import { authCallAPI } from "store/auth/action";
import { AUTH_INITIAL_STATE, AUTH_LOGIN } from "store/auth/constant";
import { setLoginDetails } from "store/login/action";
import { LocaleContext } from 'routes/Router';
import { USER_LOGIN } from "store/auth/login/constant";
import { userLoginCallAPI } from "store/auth/login/action";

function LoginAccount({ handleCurStep, handleFormData }) {
	const isMobile = window.innerWidth < 900;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { locale } = useContext(LocaleContext);
	const [isError, setIsError] = useState(false);
	const [isPassVisible, setIsPassVisible] = useState(false);
	const [formValue, setFormValue] = useState({
		crNumber: "",
		nationalId: "",
		password: "",
		routingKey: "login"
	});
	const AuthError = useSelector((state) => (state.authReducer?.error?.data?.message));
	const AuthData = useSelector((state) => (state.authReducer && state.authReducer?.payload?.data));
	const userLogin = useSelector((state) => (state?.userLoginReducer?.payload?.data));
	const loader = useSelector((state) => state?.userLoginReducer?.loading);
	useEffect(() => {
		dispatch(authCallAPI(AUTH_INITIAL_STATE));
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValue((prevState) => ({
			...formValue,
			[name]: value,
		}));
	};

	const handleVisiblePassword = (value) => {
		setIsPassVisible(!value);
	};

	const handleSubmit = () => {
		formValue.nationalId = formValue.nationalId.split(' ').join('');
		formValue.crNumber = formValue.crNumber.split(' ').join('');
		const error = CheckValidation(formValue);
		setIsError(error);
		if (error.submit) {
			handleFormData(formValue);
			dispatch(setLoginDetails(formValue));
			// dispatch(authCallAPI(AUTH_LOGIN, formValue, { handleStep: handleCurStep, navigate: navigate, step: 2, errorStep: 1 }));
			dispatch(userLoginCallAPI(USER_LOGIN, formValue, { handleStep: handleCurStep, navigate: navigate, step: 2, errorStep: 1 }));
		}
	};
	return (
		<>
			<H1Text color="primary" marginTop={30} size={isMobile ? 46 : 64} lineHeight="50">
				<FormattedMessage id="login.welcome_account" />
			</H1Text>

			<Text size="24" color="lightDark" lineHeight="36" fontWeight="600" marginTop="0">
				<FormattedMessage id="login.welcome_account_sub_text" />
			</Text>

			<Grid container sx={{ marginTop: "30px" }}>
				<Grid item xs={12} md={10} lg={8}>
					<MaskInput
						mask="9999999999"
						disabled={false}
						maskChar=""
						label={<FormattedMessage id="login.CRNumber" />}
						placeholder="0000000000"
						name="crNumber"
						onChange={handleInputChange}
						errorText={isError && isError.crNumber.error && isError.crNumber.errorMessage}
					/>
				</Grid>
				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<MaskInput
						mask="9999999999"
						disabled={false}
						maskChar=""
						label={<FormattedMessage id="idIqamaNumber" />}
						placeholder="0000000000"
						name="nationalId"
						onChange={handleInputChange}
						errorText={isError && isError.nationalId.error && isError.nationalId.errorMessage}
					/>
				</Grid>
				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<LabelWithInput
						label={<FormattedMessage id="enterPassword" />}
						placeholder="Enter Password"
						name="password"
						type={isPassVisible ? "text" : "password"}
						onChange={handleInputChange}
						InputProps={{
							endAdornment: (
								<>
									{isPassVisible ? (
										<VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
									) : (
										<VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => handleVisiblePassword(isPassVisible)} />
									)}
								</>
							)
						}}
						errorText={isError && isError.password.error && isError.password.errorMessage}
					/>
				</Grid>

				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "20px" }}>
					<MuiLink onClick={() => navigate(getForgotPasswordURL(locale))}>
						<FormattedMessage id="forgotPassword" />
					</MuiLink>
				</Grid>
				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<div style={{ display: "flex" }}>
						<Text size="18" marginTop="0" color="lightGrey" fontWeight="600" lineHeight="27">
							<FormattedMessage id="dontHaveAccount" />&nbsp;
						</Text>
						<MuiLink size="18" TextDecoration="underline" onClick={() => navigate(getSignUpURL(locale))}>
							<FormattedMessage id="createAccount" />
						</MuiLink>
					</div>
				</Grid>

				<Grid item xs={12} md={10} lg={8} sx={{ marginTop: "30px" }}>
					<Grid container>
						<Grid item xs={12}>
							<Box component="form" sx={{ display: "flex", justifyContent: "end" }}>
								<Stack spacing={2} direction="row">
									<ButtonInput
										title={<FormattedMessage id="continue" />}
										variant="contained"
										bgColor="yellow"
										textColor="purple"
										borderRadius="100"
										TextTransform="capitalize"
										disabled={loader}
										endIcon={loader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : <ArrowForwardIcon />}
										onClick={() => handleSubmit()}
									/>
								</Stack>
							</Box>
						</Grid>

						{!loader &&
							<Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
								{AuthError ?
									<Text size="18" marginTop="5" color="errorColor" fontWeight="600" lineHeight="27">
										{AuthError}
									</Text>
									:
									<Text size="18" marginTop="5" color="sucessColor" fontWeight="600" lineHeight="27">
										{AuthData?.message}
									</Text>
								}
							</Grid>
						}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default LoginAccount;