import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BackIcons from "assets/images/Application/backIcon.png";

const ButtonStyle = styled.div`
    display: flex;
    cursor:pointer;
    align-items:center;

    @media screen and (max-width: 576px) {
        padding:9px 15px;
    }
`

const BackIcon = (props) => {
    const navigate = useNavigate();
    return (
        <ButtonStyle>
            <img src={BackIcons} alt="icon" onClick={() => navigate(-1)} />
        </ButtonStyle>
    )
};

export default BackIcon;
