import {
    USER_API,
    USER_API_ERROR,
    USER_API_SUCCESS,
    USER_PROFILE_UPDATE_API,
    USER_UPDATE_API
} from "./constant";


const userReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_API:
            return { ...state, loading: true, error: '' };
        case USER_UPDATE_API:
            return { ...state, loading: true, error: '' };
        case USER_PROFILE_UPDATE_API:
            return { ...state, loading: true, error: '' };
        case USER_API_ERROR:
            return { ...state, type: USER_API_ERROR, loading: false, error: action.payload };
        case USER_API_SUCCESS:
            return { ...state, type: USER_API_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default userReducer;
