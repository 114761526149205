import React from 'react';
import styled from 'styled-components';
import { string } from "prop-types";

const WrapperH5 = styled.h5`
    margin-bottom: 0;
    font-size: ${(props) => (props.size+"px")};
    font-weight: ${(props) => (props.fontWeight)};;
    font-family: ${(props) => (props.fontFamily)};
    line-height: ${(props) => (props.lineHeight+"px")};
    color: ${(props) => (props.theme[props.color])};
    margin-top: ${(props) => (props.marginTop+"px")};
    background: ${(props) => (props.theme[props.bgColor])};
    border-radius: ${(props) => (props.borderRadius+"px")};
    padding: ${(props) => (props.padding)};
    // overflow:hidden;
	text-overflow:ellipsis;
    margin:${(props) => (props.margin)};
	height:${(props) => props.height + "px" ? props.height + "px" : ''};
    @media screen and (max-width:768px){
        font-size: 24px;
    }
`;

function H5Text(props) {
    return (
        <WrapperH5 {...props}>
            {props.children}
        </WrapperH5>
    );
}

H5Text.propTypes = {
    color: string.isRequired,
};

H5Text.defaultProps = {
    marginTop: 10,
    size: 24,
    fontFamily: 'Karbon_semibold',
    fontWeight: 800,
    lineHeight: 36,
};

export default H5Text;
