import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputAdornment from '@mui/material/InputAdornment';

import H5Text from "components/Text/H5Text";
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import ButtonInput from "components/Input/ButtonInput";
import CheckboxInput from "components/Input/CheckboxInput";
import ExpensesModal from "components/Modals/ExpensesModal";
import Text from "components/Text";
import UploadIcon from "assets/images/financing/UploadIcon.png";
import ThreeDotIcon from "assets/images/financing/DotThreeIcon.png";

import NotePencilIcon from "assets/images/icon/NotePencil.png";

const WrapperDiv = styled.div`
	
`;

function ExpensesBankForm({ handleCurStep }) {
	const [isModalActive, setIsModalActive] = useState(false);

	const handleModalPopup = (value) => {
		setIsModalActive(value);
	}

	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage id="Document Upload" />
						<img alt="" src={require('assets/images/icon/exclamation.png')} style={{ marginLeft: '15px' }} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							{/* <Grid item xs={12}>
								<div style={{ display: 'flex', marginTop: "30px" }}>
									<Text marginTop="0" size="24" color="dark" fontWeight="800" fontFamily="KarbonSemiBold">
										<FormattedMessage id="autoFinance.basicExpenses" /> 3,400.00 <FormattedMessage id="sar" />
									</Text>
									<div style={{ marginLeft: "15px", cursor: "pointer" }}>
										<img alt="" src={NotePencilIcon} onClick={() => setIsModalActive(true)} />
									</div>
								</div>
								<ExpensesModal modalActive={isModalActive} handleModalPopup={handleModalPopup} />
							</Grid> */}

							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'IBAN Certificate'} />}
									placeholder="SA |4444 4444 4444 4444"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={UploadIcon} onClick={() => setIsModalActive(true)} style={{ cursor: "pointer" }} alt='icon' />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'VAT Certificate'} />}
									placeholder="VAT No. |"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={UploadIcon} onClick={() => setIsModalActive(true)} style={{ cursor: "pointer" }} alt='icon' />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'GOSI Certificate'} />}
									placeholder="No. of Employees|"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={UploadIcon} onClick={() => setIsModalActive(true)} style={{ cursor: "pointer" }} alt='icon' />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'Tax & Zatka Certificate'} />}
									placeholder=""
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={UploadIcon} onClick={() => setIsModalActive(true)} style={{ cursor: "pointer" }} alt='icon' />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'Bank Statement'} />}
									placeholder=""
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={UploadIcon} onClick={() => setIsModalActive(true)} style={{ cursor: "pointer" }} alt='icon' />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'Commercial Activity License'} />}
									placeholder=""
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={UploadIcon} onClick={() => setIsModalActive(true)} style={{ cursor: "pointer" }} alt='icon' />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'Article of Association'} />}
									placeholder=""
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={UploadIcon} onClick={() => setIsModalActive(true)} style={{ cursor: "pointer" }} alt='icon' />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<LabelWithInput
									label={<FormattedMessage id={'Budget Documents'} />}
									placeholder=""
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<img src={UploadIcon} onClick={() => setIsModalActive(true)} style={{ cursor: "pointer" }} alt='icon' />
												<img src={ThreeDotIcon} style={{ cursor: "pointer" }} alt='icon' />
											</InputAdornment>
										)
									}}
								/>
							</Grid>

						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container style={{ marginTop: "30px" }}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="back" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(0)}
								/>
								<ButtonInput
									title={<FormattedMessage id="Submit" />}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									onClick={() => handleCurStep(2)}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	);
}

export default ExpensesBankForm;