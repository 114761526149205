import {
   USED_CARS, USED_CARS_ERROR, USED_CARS_SUCCESS
} from "./constant";

const usedCarsReducer = (state = {}, action) => {
   switch (action.type) {
      case USED_CARS:
         return { ...state, loading: true, error: '' };
      case USED_CARS_ERROR:
         return { ...state, type: USED_CARS_ERROR, loading: false, error: action.payload };
      case USED_CARS_SUCCESS:
         return { ...state, type: USED_CARS_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default usedCarsReducer;