import {
    PAGE_LIST,
    HELPER_API_SUCCESS,
    HELPER_API_ERROR
} from "./constant";


export const helperCallAPI = (type, data, external = {}) => {
    switch (type) {
         case PAGE_LIST:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

export const helperAPIStatus = (type, data) => {
    switch (type) {
        case HELPER_API_ERROR:
            return {
                type: type,
                payload: data
            }
        case HELPER_API_SUCCESS:
            return {
                type: type,
                payload: data
            }
        default:
            return {
                type: type,
            }
    }
}


