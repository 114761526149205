import React, { useState, useEffect } from "react";
import { useLocation, BrowserRouter } from "react-router-dom";
import LangRoute from './routes/Router';
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import TranslationsEn from "./lang/translations_en"
import TranslationsAr from "./lang/translations_ar"
import { Provider } from "react-redux";
import store from "store";
import theme from "theme";
import { RequestPermission } from './firebase/Firebase.js';
import LocalStorageManager from "managers/LocalStorageManger";

function loadMessages(locale) {
    switch (locale) {
        case "en":
            return TranslationsEn;
        case "ar":
            return TranslationsAr;
        default:
            return TranslationsEn;
    }
}

function getDirection(locale) {
    switch (locale) {
        case "en":
            return "ltr";
        case "ar":
            return "rtl";
        default:
            return "ltr";
    }
}

function App() {
    const [locale, setLocale] = useState('en');
    const [direction, setDirection] = useState(getDirection('en'));
    const [messages, setMessages] = useState(loadMessages('en'));

    const onChangeLanguage = (lang) => {
        setLocale(lang);
        setDirection(getDirection(lang));
        setMessages(loadMessages(lang));
    }

    LocalStorageManager.setItem('locale', locale);

    return (
        <IntlProvider locale={locale} messages={messages}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <div dir={direction}>
                        <BrowserRouter>
                            <LangRoute LanguageSwitcher={(lang) => onChangeLanguage(lang)} />
                        </BrowserRouter>
                        <RequestPermission />
                    </div>
                </Provider>
            </ThemeProvider>
        </IntlProvider>
    );
};

export default App;
