import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';

const WrapperDiv = styled.div`
	padding: ${(props) => (props.paddingAll)+"px"};
	padding-bottom: ${(props) => (props.pBottom)+"px"};
`;

const GridContainer = styled(Grid)`
	${(props) =>
      	props.border === true && `
 	 	border: 0.5px solid ${props.theme[props.borderColor]};
 	    padding: 15px 10px;
 	    border-radius: 8px;
    `}

    ${(props) =>
      	props.brTopLeft === false && `
 	    border-radius: 0px 0px 16px 16px;
    `}
`;

const GridLeft = styled(Grid)`
	color: ${(props) => (props.theme[props.leftTextColor])};
	font-size: ${(props) => (props.size)+"px"};
	font-family: ${(props) => (props.leftFontFamily)};
	font-weight: ${(props) => (props.leftFontWight)};
	line-height: 27px;
`;

const GridRight = styled(Grid)`
	text-align: right;
	color: ${(props) => (props.theme[props.rightTextColor])};
	font-weight: 800;
	font-size: ${(props) => (props.size)+"px"};
	font-family: Karbon_semibold;
	line-height: 27px;
`;

const WrapperSub = styled.sub`
    vertical-align: top;
    font-size: 18px;
    font-weight: 600;
    font-family: Karbon_light;
`;

function SideBySideText({leftText, RightText, containerProps, ...props }) {
	return (
		<WrapperDiv {...props}>
			<GridContainer container {...props}>
				<GridLeft item xs={6} {...props}>
					{leftText}
				</GridLeft>
				<GridRight item xs={6} {...props}>
					{RightText}
					<WrapperSub>{props.subText && props.subText}</WrapperSub>
				</GridRight>
			</GridContainer>	
			
		</WrapperDiv>
	);
}

SideBySideText.propTypes = {
  	leftTextColor: string.isRequired,
  	rightTextColor: string.isRequired,
  	border: bool.isRequired
};

SideBySideText.defaultProps = {
  	border: false,
  	leftFontFamily: 'Karbon_light',
  	pBottom: 15,
  	paddingAll: 0,
};

export default SideBySideText;