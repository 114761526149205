import React, { useEffect } from 'react'
import styled from 'styled-components'
import AddIcon from '@mui/icons-material/Add';
import AnimatedImage from 'components/AnimatedImage';
import { FormattedMessage } from 'react-intl';

const CardStyle = styled.div`
    background:#ffffff;
    display:${(props) => props.listtype ? "block" : "flex"};
    // max-width: ${(props) => props.listtype ? "380px" : "100%"};
    // max-height: 480px;
    width:${(props) => props.listtype ? "fit-content" : "100%"};
    width:100%;
    height: fit-content;
    border-radius: 12px;
    box-shadow:${(props) => props.boxShadow ? props.boxShadow : "0px 3px 12px 0px #0000001F"};
    position:relative;
    // margin-top:${(props) => props.listtype ? "" : "32px"};

    @media screen and (max-width:1200px){
        flex-direction:${(props) => props.listtype ? "row" : "column"}; 
        overflow:hidden;
        margin-top:${(props) => props.listtype ? "" : "32px"};
    }
    .cardImage{
        transition: all .3s ease-in-out;
    }
    &:hover .cardImage{
        transform: scale(1.08);
        margin-left:15px;
        
    }
`
const MainContainer = styled.div`
    display:${(props) => props.listtype ? 'flex' : 'block'};
    flex-wrap:wrap;
    gap:40px;
    justify-content:center;
    `
const CardDivide = styled.div`
    background: ${(props) => props?.background};
    height: ${(props) => props?.height};
    border-radius: ${(props) => props?.borderradius};
    padding: ${(props) => props?.padding};
    width:${(props) => props?.width};
    @media screen and (max-width:1200px){
        width:100% !important; 
    }
    .heading {
    margin:0;
    font-size:${(props) => props.listtype ? '20px' : '32px'};
    }
.subheading{
    margin:0;
    font-family:Karbon_light;
    font-weight:600;
    line-height:30px;
    font-size:${(props) => props.listtype ? '16px' : '24px'};
    
}
.headingPart{
    margin: 0 10px 0 0;
}
.p1{
    margin:0;
    font-family:Karbon_light;
    font-weight:600;
    font-size:${(props) => props.listtype ? '16px' : '21px'};
}
.p2{
    margin:0;
    font-weight:800;
    font-family: Karbon_semibold;
    font-size:${(props) => props.listtype ? '32px' : '42px'};
    span{
        font-size:20px;
    }
}
.buttonContainer{
    display:flex;
    margin-top:${(props) => props.listtype ? '14px' : '8px'};
    justify-content:${(props) => props.listtype ? 'end' : 'end'};
    max-height:40px;
    width:${(props) => props.listtype ? '100%' : '42%'};
    
}
.dividePrice{
    display:flex;
     min-height:88px;
     margin-bottom:${(props) => props.listtype ? '' : '43px'};
}
.priceTag{
    flex-wrap: wrap;
    padding-top: 11px;
    display:${(props) => props.listtype ? 'flex' : 'flex'};
    align-items:center;
    // width:20%;
    // padding:0 60px;
    padding: ${(props) => props.listtype ? '' : '0 34px 0 58px'};

    .priceDiv{
        width:${(props) => props.listtype ? '50%' : '33%'};
    }
    .colorDiv{
     width:${(props) => props.listtype ? '50%' : '25%'};
    display: ${(props) => props.listtype ? 'flex' : ''};
    flex-direction: column;
    align-items: end;
    .p1{
        margin-right:40px;
    }
    }
    @media screen and (max-width:992px){
           .priceDiv{
            width:50%;
           }
           .colorDiv{
            width:50%;
           }
        }
    }

    @media screen and (max-width:992px){
       padding: 10px ;
       .priceDiv{
        width:50%;
       }
       .colorDiv{
        width:50%;
        display:flex;
       }
       .buttonContainer{
        width:100%;
        justify-content:end;
        margin-bottom:${(props) => props.listtype ? '6px' : '14px'};
       }
    }
   
}

    @media screen and (max-width:1200px){
        width:${(props) => props.listtype ? "" : "100%"}; 
        overflow:hidden;
    }
    @media screen and (max-width:992px){
        padding: 18px 8px 18px 8px;
    }
`
const IconGroup = styled.div`
        position: ${(props) => props.listtype ? "" : "absolute"}; 
        right: 0;
        display: flex;
        justify-content: end;
        padding: 10px 20px 0 0;
        border-radius: 12px;
        max-height:25%;
    `
const IconStyle = styled.div`
        background:${(props) => props?.background};
        border-radius:50%;
        min-width:40px;
        min-height:40px;
        max-width:40px;
        max-height:40px; style={{margin:'0'}}
        display:flex;
        align-items:center;
        justify-content:center;
        margin-left:${(props) => props?.marginleft};
    `

const ColorBox = styled.div`
        background: ${(props) => props?.color};
        margin-left: ${(props) => props?.marginleft};
        height:${(props) => props.listtype ? "28px" : "38px"}; 
        width:${(props) => props.listtype ? "28px" : "38px"}; 
        border-radius:4px;
    `
const ColorBoxContainer = styled.div`
        display:flex;
        p{
            margin:0;
            margin-left:4px;
            align-items: center;
            display: flex;
        }
    `
const ButtonStyle = styled.div`
        color:${(props) => props?.color};
        max-height:${(props) => props.listtype ? '38px' : '52px'};
        height:${(props) => props.listtype ? '38px' : '52px'};
        // padding:2px 0px 0px 0px;
        font-size:${(props) => props.listtype ? '14px' : '18.35px'};
        font-family:Karbon_semibold;
        background:${(props) => props?.background};
        border:${(props) => props?.border};
        border-radius:100px;
        width: ${(props) => props.listtype ? '140px' : '183px'};
        justify-content: center;
        display: flex;
        align-items: center;
        margin-left:${(props) => props?.marginleft};
    p{
        margin:0;
        margin-top:3px;
        margin-left:8px;
        margin-bottom: ${(props) => props.listtype ? '0' : '-5px'};
    }
`
const Cards = ({ listtype, data, boxShadow }) => {

    useEffect(() => {
    }, [listtype]);

    return (
        <MainContainer listtype={listtype}>
            <CardStyle listtype={listtype} boxShadow={boxShadow}  >
                <CardDivide borderradius={'12px 12px 0 0'} height={listtype ? '60%' : ''} width={listtype ? '' : '25%'}  >
                    <IconGroup>
                        <IconStyle background={'#FEF7FF'} >
                            <img src={require('assets/images/icon/Union.png')} alt="" />
                        </IconStyle>
                        <IconStyle background={'var(--primary)'} marginleft={'8px'}>
                            <img src={require('assets/images/icon/percent.png')} alt="" />
                        </IconStyle>
                    </IconGroup>
                    <AnimatedImage src={require('assets/images/carTransprent.png')} />
                </CardDivide>
                <CardDivide background={'#FEF7FF'} borderradius={'0 0 12px 12px'} padding={'19px 16px 18px 16px'} width={listtype ? '' : '75%'} height={listtype ? '40%' : ''} listtype={listtype} >
                    <div className='dividePrice'>
                        <IconStyle background={'#fff'}  >
                            <img src={require('assets/images/icon/DemoToyota.png')} alt="" style={{ maxHeight: '40px', maxWidth: '40px' }} />
                        </IconStyle >
                        <div className='headingPart' style={{ marginLeft: '16px' }}>
                            <h3 className='heading'>{data?.BrandDescriptionEn} {data?.ProductTypeDescriptionEn} {data?.YearModel}</h3>
                            <p className='subheading'>{data?.ModelDescriptionEn}</p>
                        </div>
                    </div>
                    <div className='priceTag'>
                        <div className='priceDiv' >
                            <p className='p1'>Available at</p>
                            <p className='p2'>{(+data?.PriceShow)?.toFixed(2)} <span>SAR</span> </p>
                        </div>
                        <div className='colorDiv'>
                            <p className='p1'>Colors</p>
                            <ColorBoxContainer>
                                <ColorBox listtype={listtype} color={'#426985'} marginleft={'0px'}></ColorBox>
                                <ColorBox listtype={listtype} color={'#1D1F1E'} marginleft={'4px'}></ColorBox>
                                <p>+4</p>
                            </ColorBoxContainer>
                        </div>
                        <div className='buttonContainer'>
                            <ButtonStyle listtype={listtype} background={'transprent'} border={'1px solid var(--primary)'} color={'var(--primary)'} marginleft={'0px'}><AddIcon /> <p><FormattedMessage id="compareCar"/></p> </ButtonStyle>
                            <ButtonStyle listtype={listtype} background={'#FECB26'} border={'1px solid #FECB26'} color={'#333333'} marginleft={'8px'}><p><FormattedMessage id="applyNow"/></p></ButtonStyle>
                        </div>
                    </div>
                </CardDivide>
            </CardStyle>
        </MainContainer>
    )
};

export default Cards;
