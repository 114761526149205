import React from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Progress = (props) => {
    return (
        <CircularProgressbar
            value={props.percentage}
            text={`${props.percentage}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
                backgroundColor: "#F4F1FF",
                textColor: "#3F1956",
                pathColor: "#3F1956",
                trailColor: "transparent"
            })}
        />
    )
};

export default Progress;