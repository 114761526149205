import {
    YAKEEN_USER_INFO_NON_SAUDI,
    YAKEEN_USER_INFO_SAUDI,
    YAKEEN_USER_INFO_ERROR,
    YAKEEN_USER_INFO_SUCCESS
} from "./constant";


const financingYakeenUserInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case YAKEEN_USER_INFO_SAUDI:
            return { ...state, loading: true, error: '' };
        case YAKEEN_USER_INFO_NON_SAUDI:
            return { ...state, loading: true, error: '' };
        case YAKEEN_USER_INFO_ERROR:
            return { ...state, type: YAKEEN_USER_INFO_ERROR, loading: false, error: action.payload };
        case YAKEEN_USER_INFO_SUCCESS:
            return { ...state, type: YAKEEN_USER_INFO_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default financingYakeenUserInfoReducer;