import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import styled from 'styled-components';
import { bool, oneOf, string } from "prop-types";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import H5Text from "components/Text/H5Text";
import ButtonInput from "components/Input/ButtonInput";
import SideBySideText from "components/SideBySideText";

const WrapperDiv = styled.div`
	
`;

const WrapperContentDiv = styled.div`
	border: 0.5px solid #B8B8B8;
	border-radius: 16px;
	margin-top: 30px;
`;

function AttachApplyForm({handleCurStep}) {
	return (
		<WrapperDiv>
			<Grid container>
				<Grid item xs={12}>
					<H5Text borderRadius="8" bgColor="secondary" padding="8px 16px" color="lightDark">
						<FormattedMessage  id={'Upload Documents & Application Fees'} />
					</H5Text>

					<Box component="form" sx={{ padding: "15px" }}>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<WrapperContentDiv>
									<SideBySideText leftText="Application Fee" RightText="40.65 SR" paddingAll="15" />
									<SideBySideText leftText="Monthly Payment" RightText="3,175 SR" paddingAll="15" />
									<SideBySideText leftText="Down Payment" RightText="1,500 SR" paddingAll="15" />
									<SideBySideText leftText="Total VAT" RightText="27,000 SR" paddingAll="15" />
									<SideBySideText leftText="Total Including VAT" RightText="31,715.65" subText="SAR" leftFontFamily="Karbon_semibold" leftTextColor="green" rightTextColor="green" leftFontWight="800" size="20" border={true} borderColor="green" pBottom="0" brTopLeft={false} />
								</WrapperContentDiv>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Grid container style={{marginTop: "30px"}}>
				<Grid item xs={12}>
					<div className="content" style={{ display: "flex", justifyContent: "end" }}>
						<Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput 
									title="Back"
									variant="text" 
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="uppercase"
									onClick={() => handleCurStep(3)}
								/>
								<ButtonInput 
									title="Save & Proceed Towards Summary"
									variant="contained" 
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
									onClick={() => handleCurStep(5)}
								/>
							</Stack>
						</Box>
					</div>
				</Grid>
			</Grid>
		</WrapperDiv>
	)
}


export default AttachApplyForm;