import {
    SHIMA_CONSENT_FORM
} from "./constant";

export const shimaConsentCallAPI = (type, data, external = {}) => {
    switch (type) {
        case SHIMA_CONSENT_FORM:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

