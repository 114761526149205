import {
   AUTH_LOGOUT,
   LOGOUT_API_ERROR,
   LOGOUT_API_SUCCESS,
} from "./constant";

const logoutReducer = (state = {}, action) => {
   switch (action.type) {
      case AUTH_LOGOUT:
         return { ...state, loading: true, error: '' };
      case LOGOUT_API_ERROR:
         return { ...state, type: LOGOUT_API_ERROR, loading: false, error: action.payload };
      case LOGOUT_API_SUCCESS:
         return { ...state, type: LOGOUT_API_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default logoutReducer;