import {
   USER_LOGIN,
   USER_LOGIN_ERROR,
   USER_LOGIN_INITIAL_STATE,
   USER_LOGIN_SUCCESS
} from "./constant";

const userLoginReducer = (state = {}, action) => {
   switch (action.type) {
      case USER_LOGIN:
         return { ...state, loading: true, error: '' };
      case USER_LOGIN_INITIAL_STATE:
         return { state, loading: false, error: '' };
      case USER_LOGIN_ERROR:
         return { ...state, type: USER_LOGIN_ERROR, loading: false, error: action.payload };
      case USER_LOGIN_SUCCESS:
         return { ...state, type: USER_LOGIN_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default userLoginReducer;