import {
   SHIMA_CONSENT_ERROR,
   SHIMA_CONSENT_FORM,
   SHIMA_CONSENT_SUCCESS
} from "./constant";

const shimaConsentReducer = (state = {}, action) => {
   switch (action.type) {
      case SHIMA_CONSENT_FORM:
         return { ...state, loading: true, error: '' };
      case SHIMA_CONSENT_ERROR:
         return { ...state, type: SHIMA_CONSENT_ERROR, loading: false, error: action.payload };
      case SHIMA_CONSENT_SUCCESS:
         return { ...state, type: SHIMA_CONSENT_SUCCESS, loading: false, payload: action.payload, error: '' };
      default:
         return state;
   }
};

export default shimaConsentReducer;