import {
    SET_PASSWORD, SET_PASSWORD_INITIAL_STATE
} from "./constant";

export const setPasswordCallAPI = (type, data, external = {}) => {
    switch (type) {
        case SET_PASSWORD:
            return {
                type: type,
                data: data,
                external: external
            }
        case SET_PASSWORD_INITIAL_STATE:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
};

