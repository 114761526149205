import { Box, Grid, Stack } from '@mui/material'
import SideBySideText from 'components/SideBySideText'
import H1Text from 'components/Text/H1Text'
import H5Text from 'components/Text/H5Text'
import React, { useContext } from 'react'
import styled from 'styled-components'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ButtonInput from 'components/Input/ButtonInput'
import { getCashFinancingApplicationApprovedURL } from 'routes/AppUrls'
import { useNavigate } from 'react-router-dom'
import { LocaleContext } from 'routes/Router'
import { FormattedMessage } from 'react-intl'

const IneligibleStyle = styled.div`
background:${(props) => props.theme.lightSecondary};
padding:2.4% 5%;
min-height:100vh;
`
const GridStyle = styled(Grid)`
background:${(props) => props.theme.white};
border-radius:16px;
// display:flex;
// justify-content:center;
padding:24px 10px 72px 10px;
min-height:80vh;
position:relative;
@media screen and (max-height:800px){
    position:unset;

}
`
const RequestIneligible = () => {
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    return (
        <IneligibleStyle>
            <GridStyle  >
                <Grid container width={'100%'} margin="auto" maxWidth={"537px"}>
                    <Grid item xs={12} textAlign='center' marginTop={'24px'} >
                        <img src={require('assets/images/feeling-sorry-animate.png')} alt="" />
                        <H1Text size={'40'} lineHeight={'48'} fontFamily="Karbon_bold"><FormattedMessage id="cashFinance.yourRequestDidNotPass"/></H1Text>
                        <H5Text size={'24'} lineHeight={'28.8'} fontFamily="Karbon_light" color='lightGrey' marginTop={'8'}><FormattedMessage id="cashFinance.sorryBut"/> <H5Text size={'24'} lineHeight={'28.8'} fontFamily="Karbon_semiBold" color='purple' marginTop={'0'} style={{display:"inline-block"}}> &nbsp;15,000 SAR</H5Text> </H5Text>
                        <H5Text size={'24'} lineHeight={'28.8'} fontFamily="Karbon_light" color='purple' marginTop={'24'}><FormattedMessage id="cashFinance.youCanReApply"/> </H5Text>
                        <H5Text size={'32'} lineHeight={'38.4'} fontFamily="Karbon_semiBold" color='purple' marginTop={'8'}>12,500 SAR </H5Text>
                        <H5Text size={'24'} lineHeight={'28.8'} fontFamily="Karbon_light" color='purple' marginTop={'8'}><FormattedMessage id="cashFinance.monthlyInstallments"/> <H5Text fontFamily="Karbon_semiBold" color='purple' marginTop={'0'} style={{display:"inline-block"}} >550 SAR</H5Text></H5Text>
                    </Grid>
                </Grid>
                    <Grid item xs={12} textAlign='end' position="absolute" bottom={"51px"} right={{xs:"20px",md:"40px"}} marginTop={'64px'} marginRight={{xs:"0", lg:locale=="en"&&"64px"}} marginLeft={{xs:"0", lg:locale=="ar"&&"64px"}} >
                        {/* <ButtonInput
                            title="Re-apply to 12,500 SAR"
                            variant="contained"
                            bgColor="yellow"
                            textColor="purple"
                            borderRadius="100"
                            TextTransform="capitalize"
                            fontFamily="Karbon_semiBold"
                            endIcon={<ArrowForwardIcon />}
                            onClick={() => navigate(getCashFinancingApplicationApprovedURL(locale))}
                        /> */}
                        <Box component="form">
							<Stack spacing={2} direction="row">
								<ButtonInput
									title={<FormattedMessage id="cancel" />}
									variant="text"
									bgColor="transparent"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									onClick={() => navigate(-1)}
								/>
								<ButtonInput
									title={<><FormattedMessage id="cashFinance.reApplyTo" /> 12,500 SAR</>}
									variant="contained"
									bgColor="yellow"
									textColor="purple"
									borderRadius="100"
									TextTransform="capitalize"
									endIcon={<ArrowForwardIcon />}
                                    onClick={() => navigate(getCashFinancingApplicationApprovedURL(locale))}
								/>
							</Stack>
						</Box>
                    </Grid>
            </GridStyle>
        </IneligibleStyle>
    )
}

export default RequestIneligible
