import React from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "react-bootstrap";
import styled from 'styled-components';
import downloadIcon from "assets/icons/downloadIcon.png";
import H5Text from 'components/Text/H5Text';
import Text from 'components/Text';

const StyleWrapper = styled.div`
    align-items: center;

    .right_container{
        background:#ffffff;
        border-radius:16px;
    };

    .right_container_top{
        background:#E6D9F5;
        padding: 8px 24px 8px 24px;
        border-radius:8px;
        height:52px;
        align-items:center;
        display:flex;
        justify-content:space-between;
    };

    .right_container_style{
        padding:20px 20px 0px 20px;
    };

    .right_container_body{
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-bottom:20px;
    };
 
`

const Statement = () => {

    const arr = [1, 2, 3];

    return (
        <StyleWrapper>
            <Row className="right_container">
                <div className="right_container_top">
                    <H5Text size="24" marginTop="0" color="purple" >
                        <FormattedMessage id="statement" />
                    </H5Text>
                    <div>
                        <img src={downloadIcon} alt="logo" />
                    </div>
                </div>

                <div className="right_container_style">
                    {arr.map((k, i) => (
                        <div className="right_container_body">
                            <div>
                                <Text size="20" marginTop="0" color="lightGrey">
                                    <FormattedMessage id="administrationFees" />
                                </Text>
                                <Text size="20" marginTop="0" color="lightDark">
                                    <FormattedMessage id="sar" />&nbsp;866
                                </Text>
                            </div>
                            <div style={{ textAlign: "end" }}>
                                <Text size="20" marginTop="0" color="lightGrey">
                                    <FormattedMessage id="dueDate" />
                                </Text>
                                <Text size="20" marginTop="0" color="lightDark">
                                    11/12/23
                                </Text>
                            </div>
                        </div>
                    ))}
                </div>
            </Row>
        </StyleWrapper>
    );
};

export default Statement;