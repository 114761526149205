import React, { useState, useEffect, useContext } from "react";
import LayoutStyleWrapper from "styles/common.styles";
import styled from 'styled-components';
import AccountNavbar from "features/Account/AccountNavbar";
import AccountSidebar from "features/Account/AccountSidebar";
import AccountRoute from "features/Account/AccountRoute";
import { useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useSelector } from "react-redux";

import { LocaleContext } from 'routes/Router';

const StyleWrapper = styled.div`
    padding:32px;
    box-shadow: -4px 8px 12px 0px #3C105329;
    border-right: 0.5px solid #3D3D4E;
    background:#FFFFFF;
    min-height:85vh;
    @media (max-width: 768px) {
        padding: 14px; 
    }
`

const AccountNavbarWrapper = styled.div`
    // min-height:${props => !props.isMobile ? '5vh' : '50vh'};
`

const AccountLayoutWrapper = styled.div`
    height:60vh;
    overflow:auto;

    &::-webkit-scrollbar {
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0px;
    };
`

const Account = () => {
    const { locale } = useContext(LocaleContext);

    const location = useLocation();
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <LayoutStyleWrapper background={"#EFE8F3"} locale={locale}>
            <StyleWrapper>
                {location.pathname !== "/en/account/offer-details" &&
                    <AccountNavbar />
                }

                {location.pathname === "/en/account/offer-details" ?
                    <AccountRoute />
                    :

                    <Grid container >
                            <Grid item md={3} xs={12} sx={{ marginTop: "0px" }}>
                                <AccountNavbarWrapper isMobile={isMobile}>
                                    <AccountSidebar />
                                </AccountNavbarWrapper>
                            </Grid>
                        <Grid item md={9} xs={12} sx={{ marginTop: "0px" }}>
                            <AccountLayoutWrapper>
                                <AccountRoute />
                            </AccountLayoutWrapper>
                        </Grid>
                    </Grid>
                }
            </StyleWrapper>
        </LayoutStyleWrapper>
    );
};

export default Account;