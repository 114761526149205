import { takeLatest, put } from "redux-saga/effects";
import {
	AUTH_LOGIN,
	AUTH_OTP_VERIFY,
	AUTH_SET_PASSWORD,
	AUTH_CREATE_PASSWORD,
	AUTH_API_ERROR,
	AUTH_API_ERROR_RESET,
	AUTH_API_SUCCESS,
	AUTH_WATHIQ_VERIFY,
	AUTH_USER_VERIFY
} from "./constant";
import url from "utils/services/urls.json";
import { getMethodParam, postMethod } from "utils/services/apis";
import LocalStorageManager from 'managers/LocalStorageManger';
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";

function* userPasswordVerify(action) {
	try {
		const response = yield postMethod(url.userSetPassword, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: AUTH_API_SUCCESS, response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: AUTH_API_ERROR, payload: response });
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}
	} catch (error) {
		yield put({ type: AUTH_API_ERROR, payload: error });
	}
};

function* userVerify(action) {
	try {
		const response = yield postMethod(url.userVerify, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: AUTH_API_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: AUTH_API_ERROR, payload: response });
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}

	} catch (error) {
		yield put({ type: AUTH_API_ERROR, payload: error });
	}
};

function* userOTPVerify(action) {
	try {
		const response = yield postMethod(url.userOTPVerify, action.data);
		if (response.status === 200 || response.status === 201) {
			yield put({ type: AUTH_API_SUCCESS, payload: response });

			if (response.data.data && response.data.data.routingKey === 'login' && response.data.data.key) {
				LocalStorageManager.setItem('RefreshToken', response.data.data.key);
			}
			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			}

			if (action.external && action.external.step && response.data?.data?.migratedUser) {
				action.external.handleStep(action.external.step);
			} else if (action.external && action.external.step && response.data?.data?.force_reset) {
				action.external.handleStep(action.external.step);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}

		} else {
			yield put({ type: AUTH_API_ERROR, payload: response });
			if (action.external && action.external.errorStep && response?.data?.data?.action_event === "login") {
				action.external.handleStep(action.external.errorStep);
			}
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}
	} catch (error) {
		yield put({ type: AUTH_API_ERROR, payload: error });
	}
}

function* userLogin(action) {
	try {
		const response = yield postMethod(url.userLogin, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: AUTH_API_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: AUTH_API_ERROR, payload: response });
			if (action.external && action.external.errorStep) {
				action.external.handleStep(action.external.errorStep);
			}
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}

	} catch (error) {
		yield put({ type: AUTH_API_ERROR, payload: error });
	}
};

function* userWathiqVerify(action) {
	try {
		const response = yield getMethodParam(url.userWathiqVerification, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: AUTH_API_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: AUTH_API_ERROR, payload: response });
			if (action.external && action.external.errorStep) {
				action.external.handleStep(action.external.errorStep);
			}
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}

	} catch (error) {
		yield put({ type: AUTH_API_ERROR, payload: error });
	}
}

function* userCreatePassword(action) {
	try {
		const response = yield postMethod(url.userCreatePassword, action.data);

		if (response.status === 200 || response.status === 201) {
			yield put({ type: AUTH_API_SUCCESS, payload: response });

			if (action.external && action.external.redirect === true) {
				action.external.redirectNavigate(action.external.path);
			} else if (action.external && action.external.run === true) {
				action.external.handleFunction();
			} else if (action.external && action.external.step) {
				action.external.handleStep(action.external.step);
			}
		} else {
			yield put({ type: AUTH_API_ERROR, payload: response });
			if (response.status === 401 || response.status === 403) {
				if (response?.data?.detail === "Invalid token.") {
					yield put(unsetClient());
					ClearTokenRedirectLogin(action?.external?.navigate);
				}
			}
		}

	} catch (error) {
		yield put({ type: AUTH_API_ERROR, payload: error });
	}
};

function* authErrorReset(action) {
	yield put({ type: AUTH_API_ERROR, payload: {} });
}

function* authSaga() {
	yield takeLatest(AUTH_USER_VERIFY, userVerify);
	yield takeLatest(AUTH_LOGIN, userLogin);
	yield takeLatest(AUTH_OTP_VERIFY, userOTPVerify);
	yield takeLatest(AUTH_SET_PASSWORD, userPasswordVerify);
	yield takeLatest(AUTH_CREATE_PASSWORD, userCreatePassword);
	yield takeLatest(AUTH_API_ERROR_RESET, authErrorReset);
	yield takeLatest(AUTH_WATHIQ_VERIFY, userWathiqVerify);
}

export default authSaga;
