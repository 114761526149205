import React from "react";
import { FormattedMessage } from "react-intl";
import { Emailverification} from "utils/general"

const ComplainFormValidation = value => {

    const formError = {
        submit: true,
        firstname: {
            error: false,
            errorMessage: <FormattedMessage id='Invalid firstname' />
        },
        lastname: {
            error: false,
            errorMessage: <FormattedMessage id='Invalid lastname' />
        },
        idnumber: {
            error: false,
            errorMessage: <FormattedMessage id='Invalid idnumber' />
        },
        emailAddress: {
            error: false,
            errorMessage: <FormattedMessage id='Invalid emailAddress' />
        },
        message: {
            error: false,
            errorMessage: <FormattedMessage id='Invalid message' />
        }
    };

    const emptyKeys = [
        "firstname",
        "lastname",
        "idnumber",
        "emailAddress",
        "message"
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        }

        if (value[emptyKeys[i]] && emptyKeys[i] === "emailAddress" && !Emailverification(value[emptyKeys[i]])) {
            formError[emptyKeys[i]].error = true;
            formError.submit = false;
        };
    }

    return formError;
}

export default ComplainFormValidation;