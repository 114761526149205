import { takeLatest, put } from "redux-saga/effects";
import {
    GET_NOTIFICATIONS_LIST,
    NOTIFICATIONS_ERROR,
    NOTIFICATIONS_SUCCESS
} from "./constant";
import url from "utils/services/urls.json";
import { getMethodWithToken } from "utils/services/apis";
import { unsetClient } from "store/token/action";
import { ClearTokenRedirectLogin } from "utils/general";

function* getNotificationsList(action) {
    try {
        const response = yield getMethodWithToken(url.getNotificationsList, action.data);

        if (response.status === 200 || response.status === 201) {
            yield put({ type: NOTIFICATIONS_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction();
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        } else {
            yield put({ type: NOTIFICATIONS_ERROR, payload: response });
            if (response.status === 401 || response.status === 403) {
                if (response?.data?.detail === "Invalid token.") {
                    yield put(unsetClient());
                    ClearTokenRedirectLogin(action?.external?.navigate);
                }
            }
        }

    } catch (error) {
        yield put({ type: NOTIFICATIONS_ERROR, payload: error });
    }
};


function* workerScreenSaga() {
    yield takeLatest(GET_NOTIFICATIONS_LIST, getNotificationsList);
};

export default workerScreenSaga;
