import { all, fork } from "redux-saga/effects";
import postRegister from 'store/auth/saga';
import workerScreenSaga from "store/screen/saga";
import accontScreenSaga from "store/account/saga";
import userMeScreenSaga from "store/user/saga"
import workerFinancingSaga from "store/financing/saga";
import helperSaga from "store/helper/saga";
import nafathSaga from "store/nafath/saga";
import tahakukSaga from "store/tahakuk/saga";
import nafathAuthSaga from "store/nafathAuth/saga";
import logoutSaga from "store/logout/saga";
import authSendOtpSaga from "store/auth/otpSend/saga";
import activeFinanceSaga from "store/dashboard/activeFinance/saga";
import paymentHistoryCallSaga from "./contracts/paymentHistory/saga";
import paymentScheduleCallSaga from "./contracts/paymentSchedule/saga";
import detailsLoanCallSaga from "./contracts/detailsLoan/saga";
import appStaticImageSaga from "./dashboard/appStaticImage/saga";
import contractListCallSaga from "./contracts/contractList/saga";
import applicationListCallSaga from "./application/applicationList/saga";
import financingDropDownSaga from "./financingDropDown/saga";
import financingYakeenUserInfoSaga from "./financing/yakeenUserInfo/saga";
import usedCarsCallSaga from "./screen/usedCar/saga";
import campaignDetailsCallSaga from "./screen/campaignDetails/saga";
import workItemSaga from "./workItem/saga";
import getVehicleDetailsCallSaga from "./screen/financeCalculator/saga";
import shimaConsentFormSaga from "./auth/shimaForm/saga";
import changePasswordSaga from "./auth/changePassword/saga";
import campaignSearchCallSaga from "./screen/campaignDetails/campaignSearch/saga";
import accountComplaintsSaga from "./account/complaints/saga";
import accountComplaintsFormSaga from "./account/complaintsForm/saga";
import notificationsSaga from "store/notifications/saga";
import otpVerifySaga from "./auth/verifyOtp/saga";
import setPasswordSaga from "./auth/setPassword/saga";
import createPasswordSaga from "./auth/createPassword/saga";
import userLoginSaga from "store/auth/login/saga";
import uploadDocumentSaga from "./uploadDocument/saga";
import uploadDocumentGosiSaga from "./uploadDocument/uploadDocumentGosi/saga";
import uploadDocumentIbanSaga from "./uploadDocument/uploadDocumentIban/saga";
import uploadDocumentVatSaga from "./uploadDocument/uploadDocumentVat/saga";

const rootSaga = function* () {
   yield all([
      fork(postRegister),
      fork(workerScreenSaga),
      fork(accontScreenSaga),
      fork(userMeScreenSaga),
      fork(workerFinancingSaga),
      fork(helperSaga),
      fork(nafathSaga),
      fork(tahakukSaga),
      fork(nafathAuthSaga),
      fork(logoutSaga),
      fork(authSendOtpSaga),
      fork(activeFinanceSaga),
      fork(paymentHistoryCallSaga),
      fork(paymentScheduleCallSaga),
      fork(detailsLoanCallSaga),
      fork(appStaticImageSaga),
      fork(contractListCallSaga),
      fork(applicationListCallSaga),
      fork(financingDropDownSaga),
      fork(financingYakeenUserInfoSaga),
      fork(usedCarsCallSaga),
      fork(campaignDetailsCallSaga),
      fork(workItemSaga),
      fork(getVehicleDetailsCallSaga),
      fork(shimaConsentFormSaga),
      fork(changePasswordSaga),
      fork(campaignSearchCallSaga),
      fork(accountComplaintsSaga),
      fork(accountComplaintsFormSaga),
      fork(notificationsSaga),
      fork(otpVerifySaga),
      fork(setPasswordSaga),
      fork(createPasswordSaga),
      fork(userLoginSaga),
      fork(uploadDocumentSaga),
      fork(uploadDocumentGosiSaga),
      fork(uploadDocumentIbanSaga),
      fork(uploadDocumentVatSaga),
   ]);
};

export default rootSaga;