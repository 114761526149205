import React, { useState } from "react";
import styled from 'styled-components';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';

import HeaderBanner from "features/OnlineFinancing/HeaderBanner";
import FinancingResults from "features/OnlineFinancing/FinancingResults";
import ProductDetails from "features/OnlineFinancing/ProductDetails";
import MuiCards from "components/MuiCards";

import HeaderBannerImage from "assets/images/financing/finacingBanner.png";
import StepperHorizontalWithTitle from "components/StepperHorizontalWithTitle";

import PersonalWorkForm from "features/OnlineFinancing/PersonalWorkForm";
import RevisedFinancingForm from "features/OnlineFinancing/RevisedFinancingForm";
import ExpensesBankForm from "features/OnlineFinancing/ExpensesBankForm";
import AttachApplyForm from "features/OnlineFinancing/AttachApplyForm";
import OrderSummary from "features/OnlineFinancing/OrderSummary";
import {requestPermission} from '../../../../firebase/Firebase.js';
import { FormattedMessage } from "react-intl";
import MuiBreadcrumbs from "components/MuiBreadcrumbs/index.js";

export const WrapperBox = styled(Box)`
    display: "flex";
    background-color: ${(props) => props.theme.lightSecondary}
`;

const Financing = () => {
	const [curStep, setCurStep] = useState(0);

	const handleCurStep = (value) => {
		setCurStep(value);
	}

	const steps = [
		'Initial Data',
		'Revised Financing',
		'Personal & Work',
		'Expenses & Bank',
		'Attach & Apply'
	];
	const [breadcrumbsList, setBreadcrumbsList] = useState([
		{
			title:<FormattedMessage id="dashboard"/>,
			Link: ""
		},
		{
			title:<FormattedMessage id="vehicleSelection"/>,
			Link: ""
		},
		{
			title:<FormattedMessage id="financeCalculator"/>,
			Link: "",
		},
		{
			title:<FormattedMessage id="autoFinancing"/>,
			Link: "",
			isDisbale: true,
		},
	])
	return (
		<WrapperBox>
			<Container maxWidth={'xl'} style={{padding:"10px" }} disableGutters>
				<Grid container>
					<Grid item xs={12}>
						<HeaderBanner bgBanner={HeaderBannerImage} bgColor={'primary'} />
					</Grid>
				</Grid>
				<Box margin='48px 0 16px 0'>
					<MuiBreadcrumbs size='20' list={breadcrumbsList} />
				</Box>

				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<FinancingResults title="financingResults" bgColor={"white"} />
						<ProductDetails bgColor={"white"} />
					</Grid>
					<Grid item xs={12} md={8}>
						<MuiCards borderRadius="16" padding="0">
							<CardContent>
								<PersonalWorkForm handleCurStep={handleCurStep} />
							</CardContent>
						</MuiCards>
					</Grid>
				</Grid>

				<Grid container style={{ marginTop: "15px" }}>
					<Grid item xs={12}>
					</Grid>
				</Grid>
			</Container>
		</WrapperBox>
	);

};

export default Financing;