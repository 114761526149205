import React, { useContext } from "react";
import styled from 'styled-components';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';

import { LocaleContext } from 'routes/Router';

const WrapperTextField = styled(TextField)`
	width: 100%;
	margin-top: 20px !important;

	label{
		width:100%
	}
	.MuiInputLabel-root {
		font-size: 16px;
		line-height: 24px;
		font-weight: 700;
		font-family: ${(props) => props.theme.KarbonLight};
		color: ${(props) => props.theme.darkSecondary};
		transform: none;
		top: -5px;
	}

	.MuiInputLabel-root.Mui-focused  {
		color: ${(props) => props.theme.darkSecondary};
	}

	.MuiInput-root {
		border: 1px solid ${(props) => props.theme.lightDarkSecondary};
		border-radius: 4px;
		height: ${(props) => props.height + 'px'};
		padding: 15px;
		color: ${(props) => props.theme.lightGray};
		margin-top: 25px;
		font-family: Karbon_light;

		&:before {
			border: none !important;
		}
		&:after {
			border: none !important;
		}

		.MuiSelect-select {
			background: none !important;
		}

		.MuiSelect-nativeInput {
			padding: ${(props) => props.inputPadding};;
			color: ${(props) => props.theme.lightGray};
			opacity: 1;
			border: none;
			font-weight: 600;
			bottom: 1px;

			&::placeholder {
				color: ${(props) => props.theme.lightGray};
				font-size: 16px;
				font-weight: 600;
			}
		}

		.MuiSvgIcon-root {
			position: ${(props) => props.locale === 'en' ? 'unset' : 'relative'};
   		    margin-left: ${(props) => props.locale === 'en' ? '' : '16px'};
    		margin-top: ${(props) => props.locale === 'en' ? '' : '-16px'};
			border: 1px solid ${(props) => props.theme.darkArrow};
		    border-width: 0 2px 2px 0;
		    display: inline-block;
		    transform: rotate(45deg);
		    -webkit-transform: rotate(45deg);
		    padding: 3px;
		    font-size: 16px;
		    right: 15px;
    		top: calc(50% - 0.5em);
		    color: transparent;
		    width: 12px;
    		height: 12px;
		}

		.MuiSvgIcon-root.MuiSelect-iconOpen {
			transform: rotate(-135deg);
  			-webkit-transform: rotate(-135deg);
  			top: calc(50% - 0em);
		}
	}

	.MuiInput-root.Mui-focused {
		border: 1px solid ${(props) => props.theme.lightDark};
		color: ${(props) => props.theme.darkSecondary};

		.MuiInput-input {
			color: ${(props) => props.theme.darkSecondary};
		}
		
		&:before {
			border: none !important;
		}
		&:after {
			border: none !important;
		}
	}
`;

function SelectInput({ containerProps, ...props }) {
	const { locale } = useContext(LocaleContext);

	console.log(props?.data)

	return (
		<FormControl fullWidth>
			<WrapperTextField
				locale={locale}
				select
				id="standard-required"
				variant="standard"
				fullWidth={true}
				InputLabelProps={{
					shrink: true
				}}
				{...props}
			>
				{props?.data?.length > 0 && props?.data.map((k, i) => (
					<MenuItem value={k?.Description}>{k?.Description}</MenuItem>
				))}

			</WrapperTextField>
		</FormControl>
	);
}

SelectInput.defaultProps = {
	height: 56,
	inputPadding: "14px"
};

export default SelectInput;