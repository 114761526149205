import React, { useContext } from "react";
import styled from 'styled-components';
import { bool } from "prop-types";
import { FormattedMessage } from "react-intl";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';

import Text from "components/Text";
import Sub from "components/Text/Sub";

import { LocaleContext } from 'routes/Router';

const WrapperDiv = styled.div`
	padding-${(props) => props.locale === 'en' ? 'left' : 'right' } : 15px;
`;

const WrapperCard = styled(Card)`
	background-color: ${(props) => (props.theme[props.bgColor])} !important;

	${(props) =>
      	props.boxShadow !== true && `
 	 	box-shadow: none !important;
    `}
`;

const WrapperCardContent = styled(CardContent)`
	display: flex;
	padding: 0 !important;
	margin-bottom: ${(props) => (props.marginBottom+"px")};
`;

const AvatarWrapper = styled(Avatar)`
	.MuiAvatar-img {
		object-fit: contain;
	}
`;

function IconWithText({containerProps, ...props }) {
	const { locale } = useContext(LocaleContext);

	return (
		<div>
			<WrapperCard {...props}>
				<WrapperCardContent {...props}>
					<AvatarWrapper alt="brand" src={props.BrandIcon} variant={props.variant} sx={{ width: props.BrandSize, height: props.BrandSize }} />
					<WrapperDiv locale={locale}>
						<Text lineHeight={props.textLineHeight} color={props.textColor} marginTop="0" size={props.textSize} fontFamily="Karbon_semibold">
							{props.text?props.text:' '}
						</Text>
						<Text lineHeight={props.secondLineHeight} color={props.secondTextColor} marginTop="8" marginBottom='8' size={props.secondTextSize} fontFamily={props.secondFontWeight}>
							{props.secondText?props.secondText:" "}
						</Text>
						{props.thirdText && 
							<Text color={props.thirdTextColor} marginTop="0" size={props.thirdTextSize} fontFamily="Karbon_semibold">
								{props.thirdText?props.thirdText:" "}
								<Sub textTransform="uppercase" color="lightDark" size="10" lineHeight="12" fontFamily="Karbon_light" fontWeight="700">	
									<FormattedMessage id={"sr"} />
								</Sub>
							</Text>
						}
						
					</WrapperDiv>
				</WrapperCardContent>
			</WrapperCard>
		</div>
	);
}

IconWithText.propTypes = {
  	boxShadow: bool.isRequired,
};

IconWithText.defaultProps = {
  	boxShadow: false,
  	BrandSize: 48,
  	marginBottom: 20,
  	textColor: "purple",
  	secondTextColor: "darkPrimary",
  	bgColor: "white",
  	textLineHeight: 24,
  	secondLineHeight: 24,
};

export default IconWithText;