import React, { useEffect, useState, useContext } from "react";
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import H5Text from "components/Text/H5Text";
import { LocaleContext } from 'routes/Router';
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ButtonInput from "components/Input/ButtonInput";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LabelWithInput from "components/Input/LabelWithInput";
import SelectInput from "components/Input/SelectInput";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { accountComplaintsFormCallAPI } from "store/account/complaintsForm/action";
import { ACCOUNT_COMPLAINTS_FORM } from "store/account/complaintsForm/constant";
import CircularProgress from '@mui/material/CircularProgress';
import ComplainFormValidation from "./ComplainFormValidation";

const StyleWrapper = styled(Box)`
    align-items: center;
    margin:${props => props.margin};
    .application_top{
        display:flex;
        align-items:center;
    };
`

const AccountComplaintForm = () => {
    const [isMobile, setIsMobile] = useState(false);
    const { locale } = useContext(LocaleContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const ComplaintsList = useSelector((state) => (state.accountComplaintsFormReducer && state.accountComplaintsFormReducer?.payload?.data));
    const error = useSelector((state) => (state.accountComplaintsFormReducer?.error?.data?.message));
    const loader = useSelector((state) => state.accountComplaintsFormReducer?.loading);
    const userMeData = useSelector((state) => state.userReducer && state.userReducer?.payload?.data);

    const [formValue, setFormValue] = useState({
        firstname: "",
        lastname: "",
        idnumber: userMeData?.data?.nationalId,
        emailAddress: "",
        cityID: "112004",
        branch: "1069",
        message: ""
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleRedirectComplaints = () => {
        navigate(`/${locale}/account/complaints`);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    console.log(formValue)

    const handleSubmitForm = () => {
        const error = ComplainFormValidation(formValue);
        setIsError(error);
        if (error.submit) {
            let body = {
                "firstname": formValue.firstname,
                "lastname": formValue.lastname,
                "idnumber": formValue.idnumber,
                "emailAddress": formValue.emailAddress,
                "mobileNumber": userMeData?.data?.contactNumber,
                "cityID": formValue.cityID,
                "branch": formValue.branch,
                "message": formValue.message
            }
            dispatch(accountComplaintsFormCallAPI(ACCOUNT_COMPLAINTS_FORM, body, { handleFunction: handleRedirectComplaints, run: true, navigate: navigate, }));
        }
    };


    return (
        <StyleWrapper margin={isMobile ? '0%' : locale === 'en' ? '2% 0% 2% 6%' : '2% 10% 2% 0%'}>
            <Grid container alignItems={'center'}>
                <ArrowBackIcon sx={{ color: "#3F1956", fontSize: "24", cursor: 'pointer', marginRight: locale == 'en' && '10px', marginLeft: locale == 'ar' && '10px' }} onClick={() => navigate(`/${locale}/account/complaints`)} />
                <H5Text color="primary" size={'27'} lineHeight="29.1" fontFamily="Karbon_semiBold">
                    <FormattedMessage id="account.complaints" />
                </H5Text>
            </Grid>

            <Grid container marginTop={'20px'}>
                <Grid item xs={12} sm={10} md={12} lg={11}>
                    <Box component="form" sx={{ padding: "0px 15px 15px 15px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <LabelWithInput
                                    label={<FormattedMessage id="First Name" />}
                                    placeholder="Lorem Ipsum"
                                    name="firstname"
                                    value={formValue.firstname}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.firstname.error && isError.firstname.errorMessage}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <LabelWithInput
                                    label={<FormattedMessage id="Last Name" />}
                                    placeholder="Lorem Ipsum"
                                    name="lastname"
                                    value={formValue.lastname}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.lastname.error && isError.lastname.errorMessage}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <LabelWithInput
                                    label={<FormattedMessage id="Email" />}
                                    placeholder="abcd789@gmail.com"
                                    name="emailAddress"
                                    value={formValue.emailAddress}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.emailAddress.error && isError.emailAddress.errorMessage}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <LabelWithInput
                                    label={<FormattedMessage id="ID Number" />}
                                    placeholder="89765321"
                                    name="idnumber"
                                    value={formValue.idnumber}
                                    // onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.idnumber.error && isError.idnumber.errorMessage}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <SelectInput
                                    label={<FormattedMessage id="City" />}
                                    placeholder="Jeddah"
                                    name="cityID"
                                    value={formValue.cityID}
                                // onChange={(e) => handleChange(e)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <SelectInput
                                    label={<FormattedMessage id="Branch" />}
                                    placeholder="Lorem ipsum"
                                    name="branch"
                                    value={formValue.branch}
                                // onChange={(e) => handleChange(e)}
                                />
                            </Grid>

                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <LabelWithInput
                                    label={<FormattedMessage id="Message" />}
                                    placeholder="Lorem ipsum dolor sit amet consectetur. A sed maecenas semper posuere pharetra dictum blandit vivamus eget. Quis lacinia gravida proin bibendum nec pellentesque lacus."
                                    height="150px"
                                    multiline
                                    rows={5}
                                    name="message"
                                    value={formValue.message}
                                    onChange={(e) => handleChange(e)}
                                    errorText={isError && isError.message.error && isError.message.errorMessage}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid >

            <Grid container sx={{ marginTop: "40px" }}>
                <Grid item item xs={12} sm={10} md={12} lg={11}>
                    <div className="content" style={{ display: "flex", justifyContent: "end" }}>
                        <Box component="form">
                            <ButtonInput
                                title={<Box display={{ xs: 'none', md: 'block' }}><FormattedMessage id="Submit Complaint" /></Box>}
                                variant="contained"
                                bgColor={"yellow"}
                                textColor="purple"
                                borderRadius="100"
                                TextTransform="capitalize"
                                endIcon={loader ? <CircularProgress style={{ 'color': 'black', 'width': '25px', 'height': '25px' }} /> : <ArrowForwardIcon />}
                                onClick={() => handleSubmitForm()}
                            />
                        </Box>
                    </div>
                </Grid>
            </Grid>

        </StyleWrapper >
    );
};

export default AccountComplaintForm;
