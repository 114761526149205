import CryptoJS from 'crypto-js';
import LocalStorageManager from 'managers/LocalStorageManger';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import storage from 'redux-persist/lib/storage';
import { getSignInURL } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';

export const availableLocales = ['en', 'ar'];

const secretKey = 'Empowering Small and Medium Enterprises with customized financing solutions';

export const encryptData = (data) => {
   return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = (data) => {
   const bytes = CryptoJS.AES.decrypt(data, secretKey);
   return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export function isAuthenticated() {
   return LocalStorageManager.getItem('token');
};

export const getDefaultLanguage = ({ local }) => {
   let browserLang = window.navigator.languages ? window.navigator.languages[0] : null;

   browserLang = browserLang || window.navigator.language || window.navigator?.browserLanguage || window.navigator?.userLanguage;

   let shortLang = browserLang;

   if (shortLang?.indexOf("-") !== -1)
      shortLang = shortLang?.split("-")[0]

   if (shortLang?.indexOf("_") !== -1)
      shortLang = shortLang?.split("_")[0]

   shortLang = availableLocales.includes(shortLang) ? shortLang : local;

   return shortLang
};

export function injectString(str, index, stringToAdd) {
   /** Inject a string into another string. */
   return (
      str.substring(0, index) + stringToAdd + str.substring(index, str.length)
   );
};

export function IqamaNumberValidation(id) {

   const type = id[0];
   let sum = 0;

   for (let num = 0; num < 10; num++) {
      const digit = Number(id[num]);
      if (num % 2 === 0) {
         const doubled = digit * 2;
         const ZFOdd = `00${doubled}`.slice(-2);
         sum += Number(ZFOdd[0]) + Number(ZFOdd[1]);
      } else {
         sum += digit;
      }
   }
   return (sum % 10 !== 0) ? false : Number(type);
};


export function PhoneNumberValidation(value) {
   const regex = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
   return regex.test(value);
};

export function Emailverification(value) {
   const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
   return regex.test(value);
};

export function PasswordValidation(value) {
   const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*£])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*£]{8,16}$/;
   return regex.test(value);
};

export function PriceFomate(value) {
   return Number(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
};

export function checkAuthorization() {
   const storedToken = LocalStorageManager.getItem('token');

   if (storedToken) {
      const token = storedToken;

      // this just all works to compare the total seconds of the created
      // time of the token vs the ttl (time to live) seconds
      const createdDate = new Date(token.created);
      const created = Math.round(createdDate.getTime() / 1000);
      const ttl = 1209600;
      const expiry = created + ttl;

      // if the token has expired return false
      if (created > expiry) return false

      return true
   }

   return false
};

export function CrNumberValidation(value) {
   const regex = /^\d{10}$/;
   return regex.test(value);
}

export function ClearTokenRedirectLogin(navigate) {
   LocalStorageManager.removeItem("token");
   LocalStorageManager.removeItem("RefreshToken");
   storage.removeItem("persist:persist-store");
   navigate("/");
}

export function checkClientAuthorization({ dispatch, getState }) {
   // Same format - we do this to have the Redux State available.
   // The difference is that this time we also pull in the helper
   // `getState` which will allow us to.....
   // ....
   // get the state.
   //
   return (nextState, replace, next) => {
      // reference to the `client` piece of state
      const client = getState().client

      // is it defined and does it have a token? good, go ahead to widgets
      if (client && client.token) return next()

      // not set yet?  Let's try and set it and if so, go ahead to widgets
      if (checkAuthorization(dispatch)) return next()

      // nope?  okay back to login ya go.
      replace('signin')
      return next()
   }
};

export const getDeviceName = () => {
   const userAgent = navigator.userAgent;
   let deviceName = '';

   if (userAgent.match(/Android/)) {
      deviceName = 'Android';
   } else if (userAgent.match(/iPhone/)) {
      deviceName = 'iPhone';
   } else if (userAgent.match(/iPad/)) {
      deviceName = 'iPad';
   } else if (userAgent.match(/Macintosh/)) {
      deviceName = 'Mac';
   } else {
      deviceName = 'Desktop';
   }
   return deviceName;
};

export const getDeviceVersion = () => {
   let deviceVersion = '';
   const userAgent = navigator.userAgent;
   const regex = /\(.*?([0-9]+(?:(?:\.|-)[0-9]+)*)/;
   const match = userAgent.match(regex);
   if (match && match.length > 1) {
      deviceVersion = match[1]
   }
   return deviceVersion;
};

export const getOSType = () => {
   const osType = navigator.platform;
   return osType;
};

export const getDeviceId = () => {
   let deviceId = '';

   if (navigator.hardwareConcurrency) {
      deviceId = navigator.hardwareConcurrency.toString();
   } else {
      deviceId = navigator.productSub;
   }
   return deviceId;
};

export const getDeviceIMEI = () => {
   const userAgent = navigator.userAgent;
   return userAgent;
};

